import { Flex } from '@mantine/core'

import LanguageMenu from '@/components/LanguageMenu'

import UserMenu from '../../UserMenu'

const AppHeader: React.FC = () => {
  return (
    <Flex
      justify='space-between'
      align={{ base: 'start', md: 'center' }}
      direction={{ base: 'column', md: 'row' }}
      w='100%'
      gap={12}
      sx={(_theme) => ({
        background: _theme.colors.gray[0],
      })}
    >
      <Flex justify={{ base: 'start', md: 'space-between' }} align='center' gap={12}>
        <LanguageMenu />
        <UserMenu />
      </Flex>
    </Flex>
  )
}

export default AppHeader
