import { Models } from '@kicksplanet/interfaces'

export function getDisplayName(
  user: Pick<
    Models.User,
    'user_id' | 'first_name' | 'last_name' | 'first_sell_at' | 'role' | 'phone_number' | 'email'
  >,
) {
  return user.first_name || user.last_name || user.email
}

export function getUserFullName(user: Models.User) {
  return `${user.first_name} ${user.last_name}`
}

export function getUserInfoInLine(user: Models.User) {
  return `${getUserFullName(user)} - ${user.phone_number} - ${user.email}`
}

export const renderName = ({ first_name, last_name }: Models.User) => {
  return `${first_name || ''}${last_name ? ` ${last_name}` : ''}`
}
