import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { HiEye } from 'react-icons/hi'
import { Link } from 'react-router-dom'
import { OnlinePaymentStatus } from '@kicksplanet/enums'
import { OrderResponses } from '@kicksplanet/interfaces'
import { Badge, Button, Flex, Text, Tooltip } from '@mantine/core'
import find from 'lodash/find'

import OrderConditionBage from '@/components/ConditionBage'
import { OrderStatusData } from '@/constants/order'
import { formatAddress, formatInfo } from '@/utils/address'
import { formatPrice } from '@/utils/number'
import { getOrderDetailUrl } from '@/utils/url'

type OrderListRowProps = {
  order: OrderResponses.OrderListItem
}

const OrderListRow: React.FC<OrderListRowProps> = ({ order }) => {
  const { t } = useTranslation()

  const orderItem = useMemo(() => {
    return order.order_item
  }, [order.order_item])

  const paymentInfoText = useMemo(() => {
    if (order.is_paid) {
      return 'order.paymentStatus.paid'
    }
    switch ((order as any).payment_transaction?.status) {
      case OnlinePaymentStatus.Pending: {
        return 'order.paymentStatus.pending'
      }
      case OnlinePaymentStatus.Success: {
        return 'order.paymentStatus.paid'
      }
      case OnlinePaymentStatus.Error: {
        return 'order.paymentStatus.failed'
      }
      default: {
        return null
      }
    }
  }, [order])

  return (
    <>
      <tr>
        <td style={{ minWidth: '160px' }}>
          {order.status ? (
            <Badge
              variant='checked-outline'
              p={8}
              sx={(_theme) => ({
                color: _theme.white,
                backgroundColor: find(OrderStatusData, { value: order?.status })?.color || '',
                borderColor: 'transparent',
              })}
            >
              <Text>{t(find(OrderStatusData, { value: order?.status })?.label || '')}</Text>
            </Badge>
          ) : null}
        </td>
        <td style={{ minWidth: '120px' }}>
          <OrderConditionBage condition={orderItem?.condition} />
        </td>
        <td style={{ minWidth: '160px' }}>
          <Link
            to={getOrderDetailUrl(order.order_id)}
            style={{ textDecoration: 'none', color: 'black' }}
          >
            <Tooltip label={order.code}>
              <Text weight={600}>{order.code}</Text>
            </Tooltip>
          </Link>
        </td>
        <td style={{ minWidth: '200px' }}>
          {(() => {
            const value = formatInfo(order.delivery_name, order.delivery_phone)
            return (
              <Tooltip label={value}>
                <Text lineClamp={1} w='fit-content'>
                  {value}
                </Text>
              </Tooltip>
            )
          })()}
        </td>
        <td style={{ minWidth: '350px' }}>
          {(() => {
            const value = formatAddress(
              order.delivery_address,
              order.delivery_ward,
              order.delivery_district,
              order.delivery_province,
            )
            return (
              <Tooltip label={value}>
                <Text lineClamp={1} w='fit-content'>
                  {value}
                </Text>
              </Tooltip>
            )
          })()}
        </td>
        <td style={{ minWidth: '240px' }}>
          {(() => {
            const value = formatInfo(order.pickup_name, order.pickup_phone)
            return (
              <Tooltip label={value}>
                <Text lineClamp={1} w='fit-content'>
                  {value}
                </Text>
              </Tooltip>
            )
          })()}
        </td>
        <td style={{ minWidth: '350px' }}>
          {(() => {
            const value = formatAddress(
              order.pickup_address,
              order.pickup_ward,
              order.pickup_district,
              order.pickup_province,
            )
            return (
              <Tooltip label={value}>
                <Text lineClamp={1} w='fit-content'>
                  {value}
                </Text>
              </Tooltip>
            )
          })()}
        </td>
        <td style={{ minWidth: '160px' }}>
          <Tooltip label={order.payment_method}>
            <Text lineClamp={1} w='fit-content'>
              {order.payment_method}
            </Text>
          </Tooltip>
        </td>
        <td style={{ minWidth: '200px' }}>
          <Tooltip label={t(paymentInfoText || '')}>
            <Text lineClamp={1} w='fit-content'>
              {t(paymentInfoText || '')}
            </Text>
          </Tooltip>
        </td>
        <td style={{ minWidth: '140px' }}>
          {(() => {
            const value = formatPrice(order.shipping_fee || 0)
            return (
              <Tooltip label={value}>
                <Text lineClamp={1} w='fit-content'>
                  {value}
                </Text>
              </Tooltip>
            )
          })()}
        </td>
        <td style={{ minWidth: '140px' }}>
          {(() => {
            const value = formatPrice(order.service_fee || 0)
            return (
              <Tooltip label={value}>
                <Text lineClamp={1} w='fit-content'>
                  {value}
                </Text>
              </Tooltip>
            )
          })()}
        </td>
        <td style={{ minWidth: '140px' }}>
          {(() => {
            const value = formatPrice(order.total || 0)
            return (
              <Tooltip label={value}>
                <Text lineClamp={1} w='fit-content'>
                  {value}
                </Text>
              </Tooltip>
            )
          })()}
        </td>
        <td>
          <Flex justify='start' align='center'>
            <Link to={getOrderDetailUrl(order.order_id)} style={{ textDecoration: 'none' }}>
              <Button variant='subtle' p={10}>
                <HiEye color='red' />
              </Button>
            </Link>
          </Flex>
        </td>
      </tr>
    </>
  )
}

export default OrderListRow
