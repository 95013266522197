import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { LiaAngleLeftSolid, LiaAngleRightSolid } from 'react-icons/lia'
import { Flex, Select, Text } from '@mantine/core'

import { DEFAULT_PAGINATION_QUERY } from '@/constants/pagination'

import { BasePaginationProps } from './types'
import { calculateTotalPages } from './utils'

export type PaginationProps = BasePaginationProps & {
  isShowLimitOptions?: boolean
  onChangeLimit?: (limit: number) => void
  onChange?: (page: number) => void
}

const LimitItemsData: string[] = [DEFAULT_PAGINATION_QUERY.limit.toString(), '40', '50', '100']

const Pagination: React.FC<PaginationProps> = ({
  total = 0,
  limit = 0,
  page,
  isShowLimitOptions = false,
  onChange,
  onChangeLimit,
}) => {
  const { t } = useTranslation()

  const totalPages = useMemo(() => {
    const value = calculateTotalPages(total, limit)
    if (isNaN(value)) return 0
    return value
  }, [limit, total])

  const pageListStartIndex = useMemo(() => {
    let value = 0
    if (totalPages > 5) {
      if (page >= totalPages - 1 && page < totalPages) {
        value = page - 3
      } else if (page == totalPages) {
        value = page - 4
      } else {
        value = page >= 4 ? page - 2 : 0
      }
    }

    return value
  }, [page, totalPages])

  const pageListEndIndex = useMemo(() => {
    let value = 5
    if (totalPages > 5) {
      value = page >= 4 ? page + 1 : 4
    }

    return value
  }, [page, totalPages])

  const onChangePage = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: any) => {
      const element = e.currentTarget
      const dataPage = element.getAttribute('data-page')
      if (!dataPage) return
      const targetPage = parseInt(dataPage)
      if (targetPage < 1 || isNaN(targetPage)) {
        return
      }
      onChange?.(targetPage)
    },
    [onChange],
  )

  const fromItem = useMemo(() => {
    if (!limit || limit == 0) return 0
    return (page - 1) * limit + 1
  }, [limit, page])

  const toItem = useMemo(() => {
    let toItem = 0
    if (limit < total) {
      toItem = limit * page
      if (toItem > total) {
        toItem = total
      }
    } else if (limit >= total) {
      toItem = total
    }

    return toItem
  }, [limit, page, total])

  const onSelectLimitOption = useCallback(
    (item: string) => {
      onChangeLimit?.(item ? parseInt(item) : parseInt(LimitItemsData[0]))
    },
    [onChangeLimit],
  )

  return limit && limit > 0 ? (
    <Flex my={40} justify='center' align='center' direction='column' gap={12}>
      <Flex justify='center' align='center'>
        {page > 1 && (
          <Flex
            justify='center'
            align='center'
            w={20}
            h={40}
            sx={{ cursor: 'pointer' }}
            data-page={page - 1}
            onClick={onChangePage}
          >
            <LiaAngleLeftSolid />
          </Flex>
        )}
        {totalPages > 5 && pageListStartIndex > 1 && (
          <>
            <Flex
              justify='center'
              align='center'
              w={40}
              h={40}
              sx={{
                cursor: 'pointer',
                transition: '0.2s ease-in-out',
                '&:hover': {
                  background: 'black',
                  color: 'white',
                },
              }}
              data-page={1}
              onClick={onChangePage}
            >
              <Text>1</Text>
            </Flex>
            <Flex justify='center' align='center' w={20} h={40}>
              <Text>...</Text>
            </Flex>
          </>
        )}
        {Array(totalPages)
          .fill(0, pageListStartIndex, pageListEndIndex)
          .map((_, index) =>
            page === index + 1 ? (
              <Flex key={index} justify='center' align='center' w={40} h={40} bg='black'>
                <Text color='white' align='center'>
                  {index + 1}
                </Text>
              </Flex>
            ) : (
              <Flex
                key={index}
                justify='center'
                align='center'
                w={40}
                h={40}
                sx={{
                  cursor: 'pointer',
                  transition: '0.2s ease-in-out',
                  '&:hover': {
                    background: 'black',
                    color: 'white',
                  },
                }}
                data-page={index + 1}
                onClick={onChangePage}
              >
                <Text align='center'>{index + 1}</Text>
              </Flex>
            ),
          )}
        {totalPages > 5 && pageListEndIndex < totalPages - 1 && (
          <Flex justify='center' align='center' w={20} h={40}>
            <Text>...</Text>
          </Flex>
        )}
        {totalPages > 5 && page < totalPages - 1 && (
          <>
            {page === totalPages ? (
              <Flex justify='center' align='center' w={40} h={40}>
                <Text>{totalPages}</Text>
              </Flex>
            ) : (
              <Flex
                justify='center'
                align='center'
                w={40}
                h={40}
                sx={{
                  cursor: 'pointer',
                  transition: '0.2s ease-in-out',
                  '&:hover': {
                    background: 'black',
                    color: 'white',
                  },
                }}
                data-page={totalPages}
                onClick={onChangePage}
              >
                <Text>{totalPages}</Text>
              </Flex>
            )}
          </>
        )}
        {page < totalPages && (
          <Flex
            justify='center'
            align='center'
            w={20}
            h={40}
            sx={{ cursor: 'pointer' }}
            data-page={page + 1}
            onClick={onChangePage}
          >
            <LiaAngleRightSolid />
          </Flex>
        )}
      </Flex>

      {isShowLimitOptions ? (
        <Flex justify='space-between' align='center' gap={12}>
          <Select
            data={LimitItemsData}
            value={limit.toString()}
            onChange={onSelectLimitOption}
            w={100}
          />
          <Text>{` / ${t('common.page')}`}</Text>
        </Flex>
      ) : null}

      <Text align='center'>
        {`${t('pagination.show')} ${fromItem} - ${toItem} ${t('pagination.of')} ${total}`}
      </Text>
    </Flex>
  ) : null
}

export default Pagination
