import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BsCheckCircleFill } from 'react-icons/bs'
import { IoIosArrowDown } from 'react-icons/io'
import { MdCancel } from 'react-icons/md'
import { useMutation, useQuery } from 'react-query'
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import { SellingResponses } from '@kicksplanet/interfaces'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Button, Card, Checkbox, Flex, Menu, Modal, Skeleton, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { notifications } from '@mantine/notifications'
import defaultTo from 'lodash/defaultTo'

import Empty from '@/components/Empty'
import ListFilterLayout from '@/components/ListFilterLayout'
import ConfirmationModal from '@/components/ModalForm/Confirmation'
import QueryPagination from '@/components/Pagination/QueryPagination'
import TableWrapper from '@/components/TableWrapper'
import { DEFAULT_PAGINATION_QUERY } from '@/constants/pagination'
import { SellingStatusType } from '@/constants/selling'
import { useApp } from '@/contexts/AppProvider'
import { ROUTES } from '@/routes'
import { formatKeyFilter } from '@/utils/string'

import SellingItemDetailRejectForm from '../Detail/Components/RejectForm'

import SellingItemListFilter from './Components/Filter'
import SellingItemListRow from './Components/Row'

const COLUMN_CAPTION = [
  'common.condition',
  'product.productTitle',
  'product.sku',
  'product.productSize',
  'sellingItem.itemPrice',
  // 'sellingItem.sellerName',
  // 'user.phoneNumber',
  // 'user.email',
  'sellingItem.sellingStatus',
  'common.action',
]

const SellingItemList: React.FC = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const sellingService = useAPIService('Selling')
  const { registerBreadcrumb, unregisterBreadcrumb } = useApp()

  useEffect(() => {
    registerBreadcrumb({
      key: 'sellingItems',
      label: t('page.sellingItems'),
      url: ROUTES.ADMIN.SELLING_ITEM.LIST,
    })

    return () => {
      unregisterBreadcrumb('sellingItems')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [rejectSellingOpened, rejectSellingModal] = useDisclosure(false)
  const [allowSellingOpened, allowSellingModal] = useDisclosure(false)

  const [checkedItems, setCheckedItems] = useState<SellingResponses.SellingItem[]>()

  const defaultQueryParams = useMemo(() => {
    return {
      page: defaultTo(parseInt(searchParams.get('page') as string), 1),
      perPage: defaultTo(
        parseInt(searchParams.get('per') as string),
        DEFAULT_PAGINATION_QUERY.limit,
      ),
      product: defaultTo(searchParams.get('product') as string, ''),
      seller: defaultTo(searchParams.get('seller') as string, ''),
      status: defaultTo(searchParams.get('status') as string, ''),
      condition: defaultTo(searchParams.get('condition') as string, ''),
    }
  }, [searchParams])

  const {
    page: pageQuery,
    perPage: perPageQuery,
    product: productQuery,
    seller: sellerQuery,
    status: statusQuery,
    condition: conditionQuery,
  } = defaultQueryParams

  const getListQuery = useMemo(() => {
    const productKey = formatKeyFilter(productQuery)
    const sellerKey = formatKeyFilter(sellerQuery)

    return {
      pagination: {
        page: pageQuery,
        perPage: perPageQuery,
      },
      filter: {
        product_info: {
          'op.or': [
            {
              title: {
                'op.like': productKey,
              },
            },
            {
              code: {
                'op.like': productKey,
              },
            },
          ],
        },
        user_info: {
          'op.or': [
            {
              first_name: {
                'op.like': sellerKey,
              },
            },
            {
              last_name: {
                'op.like': sellerKey,
              },
            },
            {
              phone_number: {
                'op.like': sellerKey,
              },
            },
            {
              email: {
                'op.like': sellerKey,
              },
            },
          ],
        },
        ...(statusQuery && {
          is_validated: {
            'op.eq': statusQuery === SellingStatusType.APPROVED.toString() ? true : false,
          },
          is_rejected: {
            'op.eq': statusQuery === SellingStatusType.REJECTED.toString() ? true : false,
          },
        }),
        ...(conditionQuery && {
          condition: {
            'op.eq': conditionQuery,
          },
        }),
      },
    }
  }, [conditionQuery, pageQuery, perPageQuery, productQuery, sellerQuery, statusQuery])

  const dataSearchQuery: SellingItemListSearchType = useMemo(() => {
    return {
      ...(productQuery && { product: productQuery }),
      ...(sellerQuery && { seller: sellerQuery }),
      ...(statusQuery && { status: statusQuery }),
      ...(conditionQuery && { condition: conditionQuery }),
    } as SellingItemListSearchType
  }, [conditionQuery, productQuery, sellerQuery, statusQuery])

  const {
    data: sellings,
    refetch: getSellings,
    isFetching: isLoadingTable,
  } = useQuery(
    ['sellingService', 'getList', getListQuery],
    () => sellingService.getList(getListQuery).then((res) => res.data),
    {
      onSuccess: () => {
        setCheckedItems([])
      },
    },
  )
  const onChangeQueries = useCallback(
    (query: SellingItemListSearchType) => {
      navigate({
        pathname: ROUTES.ADMIN.SELLING_ITEM.LIST,
        search: createSearchParams({
          ...query,
        }).toString(),
      })
    },
    [navigate],
  )

  const onRefresh = useCallback(() => {
    getSellings()
  }, [getSellings])

  const onToggleAllRow = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.target.checked

      if (isChecked) {
        setCheckedItems(sellings?.data || [])
      } else {
        setCheckedItems([])
      }
    },
    [sellings?.data],
  )

  const onToggleRow = useCallback(
    (event: ChangeEvent<HTMLInputElement>, item: SellingResponses.SellingItem) => {
      const isChecked = event.target.checked

      if (isChecked) {
        if (checkedItems && checkedItems.length > 0) {
          setCheckedItems([...checkedItems, item])
        } else {
          setCheckedItems([item])
        }
      } else {
        setCheckedItems(checkedItems?.filter((x) => x != item))
      }
    },
    [checkedItems],
  )

  const { mutate: approve, isLoading: allowLoading } = useMutation(
    async () => {
      if (!checkedItems || checkedItems.length <= 0) return null

      return sellingService.approveSellingItems({
        data: {
          ids: checkedItems.map((x) => x.selling_id),
        },
      })
    },
    {
      onSuccess: () => {
        notifications.show({
          variant: 'success',
          message: t('success.allowSelling'),
        })
        onRefresh()
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        const errorMessage = t(err?.response?.data, {
          ns: 'backend',
        })

        notifications.show({
          variant: 'danger',
          message: errorMessage,
        })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        err?.response?.data?.errors?.forEach((err: any) => {
          notifications.show({
            variant: 'danger',
            message: t(err, {
              ns: 'backend',
            }),
          })
        })
      },
    },
  )

  const onAllowSelling = useCallback(() => {
    if (!checkedItems || checkedItems.length <= 0) return
    approve()
  }, [checkedItems, approve])

  const RowData = useMemo(() => {
    if (isLoadingTable) {
      return (
        <tr>
          <td colSpan={11}>
            <Skeleton height={10} my={15} radius='md' />
            <Skeleton height={10} my={15} radius='md' />
            <Skeleton height={10} my={15} radius='md' />
          </td>
        </tr>
      )
    }

    if (!sellings || !sellings.data || sellings.data.length <= 0)
      return (
        <tr>
          <td colSpan={11}>
            <Empty />
          </td>
        </tr>
      )

    return sellings.data.map((item) => {
      return (
        <SellingItemListRow
          key={item.selling_id}
          selling={item}
          checkedItems={checkedItems}
          onToggleRow={onToggleRow}
        />
      )
    })
  }, [isLoadingTable, sellings, checkedItems, onToggleRow])

  return (
    <>
      <Card>
        <ListFilterLayout onRefresh={onRefresh}>
          <SellingItemListFilter queryData={dataSearchQuery} onChangeQueries={onChangeQueries} />
        </ListFilterLayout>
        <Flex justify='end'>
          <Menu shadow='md' width={200}>
            <Menu.Target>
              <Button
                color='gray.5'
                rightIcon={<IoIosArrowDown size={16} />}
                disabled={!checkedItems || checkedItems.length <= 0}
              >
                {t('common.action')}
              </Button>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item>
                <Button
                  variant='subtle'
                  p={4}
                  leftIcon={<BsCheckCircleFill color='green' />}
                  onClick={allowSellingModal.open}
                >
                  <Text color='success.3'>{t('sellingItem.allowSelling')}</Text>
                </Button>
              </Menu.Item>
              <Menu.Item>
                <Button
                  variant='subtle'
                  p={4}
                  leftIcon={<MdCancel color='red' />}
                  onClick={rejectSellingModal.open}
                >
                  <Text color='danger.3'>{t('sellingItem.rejectSelling')}</Text>
                </Button>
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Flex>
        <TableWrapper isStickyColumm={Boolean(sellings?.data && sellings?.data.length > 0)}>
          <thead>
            <tr>
              <th>
                <Checkbox
                  size='sm'
                  checked={Boolean(
                    sellings?.data &&
                      sellings?.data?.length > 0 &&
                      checkedItems &&
                      checkedItems?.length === sellings?.data?.length,
                  )}
                  onChange={(e) => onToggleAllRow(e)}
                />
              </th>
              {COLUMN_CAPTION.map((item, key) => {
                return <th key={key}>{t(item)}</th>
              })}
            </tr>
          </thead>
          <tbody>{RowData}</tbody>
        </TableWrapper>
        <QueryPagination
          limit={perPageQuery}
          page={pageQuery}
          total={sellings?.total}
          isShowLimitOptions
        />
      </Card>

      <Modal
        title={t('sellingItem.rejectSelling')}
        opened={rejectSellingOpened}
        onClose={rejectSellingModal.close}
      >
        <SellingItemDetailRejectForm
          sellingIDs={checkedItems?.map((x) => x.selling_id) || []}
          onSubmit={rejectSellingModal.close}
          onRefresh={onRefresh}
        />
      </Modal>

      <ConfirmationModal
        title={t('sellingItem.confirmApproveModalHeader')}
        description={t('sellingItem.confirmApproveMessage')}
        isLoading={allowLoading}
        opened={allowSellingOpened}
        onClose={allowSellingModal.close}
        onConfirm={onAllowSelling}
      />
    </>
  )
}

export default SellingItemList
