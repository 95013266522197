import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SelectItem } from '@mantine/core'
import get from 'lodash/get'

import { cleanAccents } from '@/utils/string'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UseSelectionParams<TData = any> = {
  items: TData[]
  onSelect?: (item: TData | null | undefined) => void
  valueKey?: string
  labelKey?: string
  defaultValue?: TData
  allOption?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useSelection = <TData = any>({
  items,
  onSelect,
  valueKey = 'value',
  labelKey = 'label',
  defaultValue,
  allOption,
}: UseSelectionParams<TData>) => {
  const { t } = useTranslation()
  const [value, setValue] = useState<SelectItem['value']>(get(defaultValue, valueKey))

  const data = useMemo(() => {
    let options = items.map<SelectItem>((item) => ({
      value: get(item, valueKey),
      label: get(item, labelKey),
      selected: get(item, valueKey) === value,
    }))
    if (allOption) {
      options = [
        {
          value: '',
          label: t('common.all'),
          selected: value === null,
        },
        ...options,
      ]
    }

    return options
  }, [items, allOption, valueKey, labelKey, value, t])

  const _onSelect = useCallback(
    (value: SelectItem['value']) => {
      setValue(value)
      const item = items.find((i) => get(i, valueKey) === value)
      onSelect?.(item)
    },
    [items, onSelect, valueKey],
  )

  const filter = useCallback((value: string, item: SelectItem) => {
    return cleanAccents(item.label || '')
      .toLowerCase()
      .includes(cleanAccents(value).toLowerCase())
  }, [])

  return {
    value,
    data,
    onSelect: _onSelect,
    filter,
  }
}
