export type ForControlCheckStatusType = {
  value: boolean
  label: string
}

export const ForControlCheckStatusData: ForControlCheckStatusType[] = [
  {
    value: true,
    label: 'order.checkStatus.checked',
  },
  {
    value: false,
    label: 'order.checkStatus.notChecked',
  },
]
