import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { GroupRequests, GroupResponses, Models } from '@kicksplanet/interfaces'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Box, Button, Flex, Grid, Input } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { notifications } from '@mantine/notifications'
import { object, string } from 'yup'

import CardImageForm from '@/components/CardImageForm'
import ConfirmationModal from '@/components/ModalForm/Confirmation'
import UploadMedia from '@/components/UploadMedia'

import { GroupImageType } from '../GroupForm'
import ProductGroup from '../ProductGroup'

type SubGroupFormProps = {
  group?: GroupResponses.Group
  isNew?: boolean
  isUpdate?: boolean
  onRefresh: () => void
}

enum GroupImageEnum {
  THUMBNAIL = 1,
  BANNER = 2,
}

const validateSchema = object().shape({
  name: string().max(225, 'group.groupNameTooLong').required('group.missingGroupName'),
  parent_id: string(),
  position: string(),
})

const SubGroupForm: React.FC<SubGroupFormProps> = ({
  group,
  isUpdate = false,
  isNew = false,
  onRefresh,
}) => {
  const { t } = useTranslation()

  const { id: parentID } = useParams()

  const groupService = useAPIService('Group')

  const [selectedImageType, setSelectedImageType] = useState<GroupImageEnum>()

  const [uploadMediaOpend, uploadMediaModal] = useDisclosure(false)
  const [removeOpened, removeModal] = useDisclosure(false)

  const [image, setImage] = useState<GroupImageType | undefined>()
  const [productGroups, setProductGroups] = useState<GroupResponses.Group['product_groups'] | []>(
    [],
  )

  useEffect(() => {
    if (group && group.product_groups) {
      setProductGroups(group.product_groups || [])
    }
  }, [group])

  const {
    setValue,
    reset,
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitSuccessful },
  } = useForm<GroupRequests.GroupUpsertPayload>({
    resolver: yupResolver(validateSchema),
    mode: 'all',
  })

  // Default value
  useEffect(() => {
    setDefaultValue()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group])

  useEffect(() => {
    if (!isSubmitSuccessful) return

    if (isUpdate) return

    reset()
    setImage(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful])

  const setDefaultValue = useCallback(() => {
    if (!group) {
      reset()
      return
    }
    setValue('name', group.name || '', {
      shouldValidate: true,
    })
    setValue('position', group.position || '', {
      shouldValidate: true,
    })
  }, [group, reset, setValue])

  const onSetImage = useCallback((data: GroupImageType | undefined) => {
    setImage(data)
  }, [])

  useEffect(() => {
    if (group) {
      onSetImage?.({
        thumbnail: group.thumbnail_info || undefined,
        banner: group.banner_info || undefined,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group])

  const onSetProductGroups = useCallback((_products: GroupResponses.Group['product_groups']) => {
    setProductGroups(_products)
  }, [])

  const onSelectedImageType = useCallback((type: GroupImageEnum) => {
    setSelectedImageType(type)
  }, [])

  const onSuccessUploadFile = useCallback(
    (data: Models.File[]) => {
      let result = image
      if (selectedImageType === GroupImageEnum.THUMBNAIL) {
        result = { ...result, thumbnail: data[0] || undefined }
      } else if (selectedImageType === GroupImageEnum.BANNER) {
        result = { ...result, banner: data[0] || undefined }
      }

      onSetImage?.(result)
    },
    [image, selectedImageType, onSetImage],
  )

  const { mutate: create, isLoading: isCreateLoading } = useMutation(
    (data: GroupRequests.GroupUpsertPayload) => {
      return groupService.create({
        data: {
          ...data,
          ...(image?.thumbnail && { thumbnail: image.thumbnail.file_id }),
          ...(image?.banner && { banner: image.banner.file_id }),
          parent_id: parentID ? parentID : undefined,
          ...(productGroups && {
            products: productGroups.map((item) => {
              return { product_id: item.product_id, ordinal: item.ordinal || 0 }
            }),
          }),
        },
      })
    },
    {
      onSuccess: async () => {
        notifications.show({
          variant: 'success',
          message: t('success.createSubGroup'),
        })
        onRefresh()
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        const errorMessage = t(err?.response?.data, {
          ns: 'backend',
        })

        notifications.show({
          variant: 'danger',
          message: errorMessage,
        })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        err?.response?.data?.errors?.forEach((err: any) => {
          notifications.show({
            variant: 'danger',
            message: t(err, {
              ns: 'backend',
            }),
          })
        })
      },
    },
  )

  const { mutate: update, isLoading: isUpdateLoading } = useMutation(
    (data: GroupRequests.GroupUpsertPayload) => {
      return groupService.update({
        id: group?.group_id || '',
        data: {
          data: {
            ...data,
            ...(image?.thumbnail && { thumbnail: image.thumbnail.file_id }),
            ...(image?.banner && { banner: image.banner.file_id }),
            parent_id: group?.parent_id ? group.parent_id : undefined,
            ...(productGroups && {
              products: productGroups.map((item) => {
                return { product_id: item.product_id, ordinal: item.ordinal || 0 }
              }),
            }),
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any,
      })
    },
    {
      onSuccess: async () => {
        notifications.show({
          variant: 'success',
          message: t('success.updatedSubGroup'),
        })
        onRefresh()
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        const errorMessage = t(err?.response?.data, {
          ns: 'backend',
        })

        notifications.show({
          variant: 'danger',
          message: errorMessage,
        })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        err?.response?.data?.errors?.forEach((err: any) => {
          notifications.show({
            variant: 'danger',
            message: t(err, {
              ns: 'backend',
            }),
          })
        })
      },
    },
  )

  const { mutate: remove, isLoading: isRemoveLoading } = useMutation(
    (groupID: string) => {
      return groupService.delete({ id: groupID })
    },
    {
      onSuccess: async () => {
        removeModal.close()
        notifications.show({
          title: t('common.removeTitle'),
          variant: 'success',
          message: t('success.deletedSubGroup'),
        })
        onRefresh()
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        const errorMessage = t(err?.response?.data, {
          ns: 'backend',
        })

        notifications.show({
          variant: 'danger',
          message: errorMessage,
        })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        err?.response?.data?.errors?.forEach((err: any) => {
          notifications.show({
            variant: 'danger',
            message: t(err, {
              ns: 'backend',
            }),
          })
        })
      },
    },
  )

  const _onRemove = useCallback(() => {
    if (!group?.group_id) return
    remove(group.group_id)
  }, [group?.group_id, remove])

  const onSubmit = useCallback(
    (data: GroupRequests.GroupUpsertPayload) => {
      if (!data) return
      if (isUpdate) {
        update(data)
      } else {
        create(data)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [update, create],
  )

  return (
    <>
      <form>
        <Grid gutter={20}>
          <Grid.Col>
            <Input.Wrapper
              label={`${t('group.name')} *`}
              error={errors.name && t(errors.name.message as string)}
            >
              <Input {...register('name')} />
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col>
            <Input.Wrapper
              label={t('group.position')}
              error={errors.position && t(errors.position.message as string)}
            >
              <Input {...register('position')} />
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col xs={6} sm={4} md={3} lg={2}>
            <Box onClick={() => onSelectedImageType(GroupImageEnum.THUMBNAIL)}>
              <CardImageForm
                imageData={image?.thumbnail ? [image.thumbnail] : []}
                controlLabel={t('group.thumbnail')}
                onSubmit={onSuccessUploadFile}
              />
            </Box>
          </Grid.Col>
          <Grid.Col xs={6} sm={4} md={3} lg={2}>
            <Box onClick={() => onSelectedImageType(GroupImageEnum.BANNER)}>
              <CardImageForm
                imageData={image?.banner ? [image.banner] : []}
                controlLabel={t('group.banner')}
                onSubmit={onSuccessUploadFile}
              />
            </Box>
          </Grid.Col>
          <Grid.Col>
            <ProductGroup
              group={group}
              onRefresh={onRefresh}
              onSetProductGroups={onSetProductGroups}
            />
          </Grid.Col>
          <Grid.Col>
            <Flex my={40} justify='center' align='center'>
              {!isNew && (
                <Button variant='highlight' mr={10} onClick={removeModal.open}>
                  {t('common.delete')}
                </Button>
              )}
              <Button
                variant='highlight'
                ml={10}
                disabled={!isValid}
                loading={isUpdate ? isUpdateLoading : isCreateLoading}
                onClick={handleSubmit(onSubmit)}
              >
                {t('common.save')}
              </Button>
            </Flex>
          </Grid.Col>
        </Grid>
      </form>

      <UploadMedia
        opened={uploadMediaOpend}
        onClose={uploadMediaModal.close}
        onSuccess={onSuccessUploadFile}
      />

      <ConfirmationModal
        title={t('common.removeTitle')}
        description={t('common.removeText', { title: group?.name })}
        isLoading={isRemoveLoading}
        opened={removeOpened}
        onClose={removeModal.close}
        onConfirm={_onRemove}
      />
    </>
  )
}

export default SubGroupForm
