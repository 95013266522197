import React, { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaFileInvoice } from 'react-icons/fa'
import { IoIosArrowDown } from 'react-icons/io'
import { useMutation, useQuery } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import { OrderStatus } from '@kicksplanet/enums'
import { Models, OrderResponses } from '@kicksplanet/interfaces'
import { useAPIService } from '@kicksplanet/react/hooks'
import { GetListParams } from '@kicksplanet/services/dist/services/core/CURDService'
import { Button, Checkbox, Flex, Menu, Skeleton, Text } from '@mantine/core'
import { notifications } from '@mantine/notifications'

import Empty from '@/components/Empty'
import ListFilterLayout from '@/components/ListFilterLayout'
import QueryPagination from '@/components/Pagination/QueryPagination'
import TableWrapper from '@/components/TableWrapper'
import { DEFAULT_PAGINATION_QUERY } from '@/constants/pagination'
import { formatKeyFilter } from '@/utils/string'

import OrderListFilter from './Filter'
import OrderListRow from './Row'

const COLUMN_CAPTION = [
  'order.code',
  'order.paymentMethod',
  'order.serviceFee',
  'order.totalAmount',
]

const Invoices_OrderList: React.FC = () => {
  const { t } = useTranslation()
  const orderService = useAPIService('Order')

  const [searchParams, setSearchParams] = useSearchParams(
    new URLSearchParams({
      page: '1',
      per: `${DEFAULT_PAGINATION_QUERY.limit}`,
      key: '',
    }),
  )

  const [checkedItems, setCheckedItems] = useState<Models.Order[]>()

  const defaultQueryParams = useMemo(() => {
    return {
      page: parseInt(searchParams.get('page') as string),
      perPage: parseInt(searchParams.get('per') as string),
      key: searchParams.get('key') as string,
    }
  }, [searchParams])

  const { page: pageQuery, perPage: perPageQuery, key: keyQuery } = defaultQueryParams

  const getListQuery = useMemo<GetListParams<Models.Order>>(() => {
    const likeFilter = formatKeyFilter(keyQuery)
    return {
      pagination: {
        page: pageQuery,
        perPage: perPageQuery,
      },
      filter: {
        code: {
          'op.like': likeFilter,
        },
        invoice_id: {
          'op.isNull': true,
        },
        status: OrderStatus.COMPLETED,
        is_checked: true,
      },
      sort: {
        field: 'created_at',
        order: 'DESC',
      },
    }
  }, [keyQuery, pageQuery, perPageQuery])

  const dataSearchQuery: OrderListSearchType = useMemo(() => {
    return {
      ...(keyQuery && { key: keyQuery }),
    } as OrderListSearchType
  }, [keyQuery])

  const {
    data: orders,
    isFetching: isLoadingTable,
    refetch: getOrder,
  } = useQuery(['orderService', 'getList', getListQuery], () =>
    orderService.getList(getListQuery).then((res) => res.data),
  )

  const { mutate: createInvoices, isLoading: isCreatingInvoices } = useMutation({
    mutationFn: (ids: string[]) =>
      orderService.createInvoices({
        orders: ids,
      }),
    onSuccess: () => {
      getOrder()
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (err: any) => {
      const errorMessage = t(err?.response?.data, {
        ns: 'backend',
      })

      notifications.show({
        variant: 'danger',
        message: errorMessage,
      })

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      err?.response?.data?.errors?.forEach((err: any) => {
        notifications.show({
          variant: 'danger',
          message: t(err, {
            ns: 'backend',
          }),
        })
      })
    },
  })

  const onToggleRow = useCallback(
    (event: ChangeEvent<HTMLInputElement>, item: Models.Order) => {
      const isChecked = event.target.checked

      if (isChecked) {
        if (checkedItems && checkedItems.length > 0) {
          setCheckedItems([...checkedItems, item])
        } else {
          setCheckedItems([item])
        }
      } else {
        setCheckedItems(checkedItems?.filter((x) => x != item))
      }
    },
    [checkedItems],
  )

  const RowData = useMemo(() => {
    if (isLoadingTable) {
      return (
        <tr>
          <td colSpan={5}>
            <Skeleton height={10} my={15} radius='md' />
            <Skeleton height={10} my={15} radius='md' />
            <Skeleton height={10} my={15} radius='md' />
          </td>
        </tr>
      )
    }

    if (!orders || !orders.data || orders.data.length <= 0)
      return (
        <tr>
          <td colSpan={5}>
            <Empty />
          </td>
        </tr>
      )

    return orders.data.map((item) => {
      return (
        <OrderListRow
          key={item.order_id}
          order={item as OrderResponses.OrderListItem}
          checkedItems={checkedItems}
          onToggleRow={onToggleRow}
        />
      )
    })
  }, [isLoadingTable, orders, checkedItems, onToggleRow])

  const onChangeQueries = useCallback(
    (query: OrderListSearchType) => {
      const newSearchParams = new URLSearchParams(searchParams.toString())
      Object.entries(query).forEach(([key, value]) => {
        newSearchParams.set(key, value)
      })
      setSearchParams(newSearchParams)
    },
    [searchParams, setSearchParams],
  )

  const onRefresh = useCallback(() => {
    getOrder()
  }, [getOrder])

  const onToggleAllRow = (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked

    if (isChecked) {
      setCheckedItems(orders?.data || [])
    } else {
      setCheckedItems([])
    }
  }

  return (
    <>
      <ListFilterLayout onRefresh={onRefresh}>
        <OrderListFilter queryData={dataSearchQuery} onChangeQueries={onChangeQueries} />
      </ListFilterLayout>
      <Flex justify='end'>
        <Menu shadow='md' width={200}>
          <Menu.Target>
            <Button
              color='gray.5'
              rightIcon={<IoIosArrowDown size={16} />}
              disabled={!checkedItems || checkedItems.length <= 0}
            >
              {t('common.action')}
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item>
              <Button
                variant='subtle'
                p={4}
                leftIcon={<FaFileInvoice />}
                loading={isCreatingInvoices}
                onClick={() =>
                  checkedItems?.length
                    ? createInvoices(checkedItems.map((item) => item.order_id))
                    : undefined
                }
              >
                <Text>{t('invoice.actions.createInvoices')}</Text>
              </Button>
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Flex>
      <TableWrapper>
        <thead>
          <tr>
            <th>
              <Checkbox
                size='sm'
                checked={Boolean(
                  orders?.data &&
                    orders?.data?.length > 0 &&
                    checkedItems &&
                    checkedItems?.length === orders?.data?.length,
                )}
                onChange={(e) => onToggleAllRow(e)}
              />
            </th>
            {COLUMN_CAPTION.map((item, key) => {
              return <th key={key}>{t(item)}</th>
            })}
          </tr>
        </thead>
        <tbody>{RowData}</tbody>
      </TableWrapper>
      <QueryPagination
        limit={perPageQuery}
        page={pageQuery}
        total={orders?.total}
        isShowLimitOptions
      />
    </>
  )
}

export default Invoices_OrderList
