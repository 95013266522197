import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { GoPencil } from 'react-icons/go'
import { HiOutlineTrash } from 'react-icons/hi'
import { useMutation } from 'react-query'
import { Link } from 'react-router-dom'
import { VoucherResponses } from '@kicksplanet/interfaces'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Button, Flex, Text, Tooltip } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { notifications } from '@mantine/notifications'

import ConfirmationModal from '@/components/ModalForm/Confirmation'
import { formatDate } from '@/utils/date'
import { DATE_FORMATS } from '@/utils/date-format'
import { formatNumber } from '@/utils/number'
import { getVoucherUpdateUrl } from '@/utils/url'

type VoucherListRowProps = {
  voucher: VoucherResponses.VoucherList
  onRefresh: () => void
}

const VoucherListRow: React.FC<VoucherListRowProps> = ({ voucher, onRefresh }) => {
  const { t } = useTranslation()
  const voucherService = useAPIService('Voucher')
  const [removeOpened, removeModal] = useDisclosure(false)

  const { mutate: remove, isLoading: isRemoveLoading } = useMutation(
    (voucherID: string) => {
      return voucherService.delete({ id: voucherID })
    },
    {
      onSuccess: async () => {
        removeModal.close()
        notifications.show({
          title: t('common.removeTitle'),
          variant: 'success',
          message: t('success.deletedVoucher'),
        })
        onRefresh()
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        const errorMessage = t(err?.response?.data, {
          ns: 'backend',
        })

        notifications.show({
          variant: 'danger',
          message: errorMessage,
        })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        err?.response?.data?.errors?.forEach((err: any) => {
          notifications.show({
            variant: 'danger',
            message: t(err, {
              ns: 'backend',
            }),
          })
        })
      },
    },
  )

  const _onRemove = useCallback(() => {
    if (!voucher.voucher_id) return
    remove(voucher.voucher_id)
  }, [voucher.voucher_id, remove])

  return (
    <>
      <tr>
        <td style={{ minWidth: '180px' }}>
          <Tooltip label={voucher.title}>
            <Text weight={600} lineClamp={1} w='fit-content'>
              {voucher.title}
            </Text>
          </Tooltip>
        </td>
        <td style={{ minWidth: '150px' }}>
          <Tooltip label={voucher.code}>
            <Text lineClamp={1} w='fit-content'>
              {voucher.code}
            </Text>
          </Tooltip>
        </td>
        <td style={{ minWidth: '120px' }}>
          <Tooltip label={voucher.value}>
            <Text lineClamp={1} w='fit-content'>
              {formatNumber(voucher.value) || '--'}
            </Text>
          </Tooltip>
        </td>
        <td style={{ minWidth: '120px' }}>
          {voucher.quantity && voucher.quantity >= 0 ? (
            <Tooltip label={voucher.quantity}>
              <Text lineClamp={1} w='fit-content'>
                {formatNumber(voucher.quantity) || '--'}
              </Text>
            </Tooltip>
          ) : (
            <Text>--</Text>
          )}
        </td>
        <td style={{ minWidth: '150px' }}>
          <Tooltip label={voucher.unit}>
            <Text lineClamp={1} w='fit-content'>
              {voucher.unit}
            </Text>
          </Tooltip>
        </td>
        <td style={{ minWidth: '200px' }}>
          {voucher.valid_from ? (
            (() => {
              const value = formatDate(voucher.valid_from, DATE_FORMATS.HH_MM_AA_DOT_DD_MMMM_YYYY)
              return (
                <Tooltip label={value}>
                  <Text lineClamp={1} w='fit-content'>
                    {value}
                  </Text>
                </Tooltip>
              )
            })()
          ) : (
            <Text>--</Text>
          )}
        </td>
        <td style={{ minWidth: '200px' }}>
          {voucher.valid_through ? (
            (() => {
              const value = formatDate(
                voucher.valid_through,
                DATE_FORMATS.HH_MM_AA_DOT_DD_MMMM_YYYY,
              )
              return (
                <Tooltip label={value}>
                  <Text lineClamp={1} w='fit-content'>
                    {value}
                  </Text>
                </Tooltip>
              )
            })()
          ) : (
            <Text>--</Text>
          )}
        </td>
        <td style={{ minWidth: '160px' }}>
          <Tooltip label={voucher.applied_on}>
            <Text lineClamp={1} w='fit-content'>
              {voucher.applied_on}
            </Text>
          </Tooltip>
        </td>
        <td style={{ minWidth: '160px' }}>
          <Tooltip label={voucher.applicable_for}>
            <Text lineClamp={1} w='fit-content'>
              {voucher.applicable_for}
            </Text>
          </Tooltip>
        </td>
        <td>
          <Flex justify='start' align='center'>
            <Link to={getVoucherUpdateUrl(voucher.voucher_id)} style={{ textDecoration: 'none' }}>
              <Button variant='subtle' p={10}>
                <GoPencil color='black' />
              </Button>
            </Link>
            <Button variant='subtle' p={10} onClick={removeModal.open}>
              <HiOutlineTrash color='red' />
            </Button>
          </Flex>
        </td>
      </tr>

      <ConfirmationModal
        title={t('common.removeTitle')}
        description={t('common.removeText', {
          title: `${voucher.title} (${voucher.code})`,
        })}
        isLoading={isRemoveLoading}
        opened={removeOpened}
        onClose={removeModal.close}
        onConfirm={_onRemove}
      />
    </>
  )
}

export default VoucherListRow
