import { OrderResponses } from '@kicksplanet/interfaces'

import OrderDetailReturnPickupInfo from './PickupInfo'
import OrderDetailReturnInfo from './ReturnInfo'

type OrderDetailReturnProps = {
  order: OrderResponses.OrderDetails
  onRefresh: () => void
}

const OrderDetailReturn: React.FC<OrderDetailReturnProps> = ({ order, onRefresh }) => {
  return order.order_return_info ? (
    <>
      <OrderDetailReturnPickupInfo order={order} />
      <OrderDetailReturnInfo order={order} onRefresh={onRefresh} />
    </>
  ) : null
}

export default OrderDetailReturn
