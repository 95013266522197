import { useCallback, useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { MdAddCircle } from 'react-icons/md'
import { TbGridDots } from 'react-icons/tb'
import { useMutation, useQuery } from 'react-query'
import { DragDropContext, Draggable, Droppable, DropResult } from '@hello-pangea/dnd'
import { yupResolver } from '@hookform/resolvers/yup'
import { GroupRequests, GroupResponses, Models } from '@kicksplanet/interfaces'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Accordion, ActionIcon, Box, Button, Flex, Grid, Input, Select, Text } from '@mantine/core'
import { useListState } from '@mantine/hooks'
import { notifications } from '@mantine/notifications'
import { object, string } from 'yup'

import CardImageForm from '@/components/CardImageForm'
import { useSelection } from '@/hooks/useSelection'

import ProductGroup from '../ProductGroup'
import SubGroupForm from '../SubGroupForm'

type GroupFormProps = {
  group?: GroupResponses.Group
  isParent?: boolean
  isSubmitLoading?: boolean
  image: GroupImageType | undefined
  onSubmit?: (data: GroupFormType) => void
  onRefresh?: () => void
  onSetImage: (file: GroupImageType | undefined) => void
  onSetProductGroups?: (products: GroupResponses.Group['product_groups']) => void
}

export type GroupImageType = {
  thumbnail?: Models.File
  banner?: Models.File
}

export type GroupFormType = GroupRequests.GroupUpsertPayload & {
  sub_groups?: string
}

type ElementType<T> = T extends (infer U)[] ? U : never
export type ProdGroupElementType = ElementType<GroupResponses.Group['product_groups']>

const validateSchema = object().shape({
  name: string().max(255, 'group.groupNameTooLong').required('group.missingGroupName'),
  parent_id: string(),
  position: string(),
})

const GroupForm: React.FC<GroupFormProps> = ({
  group,
  isParent = false,
  isSubmitLoading,
  image,
  onSubmit,
  onRefresh,
  onSetImage,
  onSetProductGroups,
}) => {
  const { t } = useTranslation()
  const groupService = useAPIService('Group')

  const [subGroupState, subGroupHandlers] = useListState<GroupResponses.Group>([])
  const [reorder, setReorder] = useState<boolean>(false)

  const [added, setAdded] = useState<number>(0)

  const {
    setValue,
    reset,
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<GroupFormType>({
    resolver: yupResolver(validateSchema),
    mode: 'all',
  })

  // Parent Group
  const parentID = useWatch({
    name: 'parent_id',
    control,
  })

  // Default value
  useEffect(() => {
    defaultValue()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group])

  const defaultValue = useCallback(() => {
    if (!group) {
      reset()
      return
    }
    setValue('name', group.name || '', {
      shouldValidate: true,
    })
    setValue('position', group.position || '', {
      shouldValidate: true,
    })
  }, [group, reset, setValue])

  const onSelectParentGroupOption = useCallback(
    (item?: Models.Group | null) => {
      setValue('parent_id', item ? item.group_id : '', {
        shouldValidate: true,
      })
    },
    [setValue],
  )

  const { data: parentGroups } = useQuery(
    ['groupService', 'list', 'selectOptions'],
    () => groupService.list({}),
    {
      enabled: isParent,
      select: ({ data: res }) => {
        return (
          res?.data?.map((item) => ({
            ...item,
            label: item.name,
          })) || []
        )
      },
      onSuccess: (data) => {
        if (group) {
          const parentGroup = data?.find((x) => x.group_id === group.parent_id)
          if (!parentGroup) return
          onSelectParentGroupOption(parentGroup)
        }
      },
    },
  )

  const {
    data: parentGroupOptions,
    onSelect: onSelectParentGroup,
    value: parentGroupValue,
    filter: parentGroupFilter,
  } = useSelection<Models.Group>({
    items: parentGroups || [],
    onSelect: onSelectParentGroupOption,
    valueKey: 'group_id',
    defaultValue: parentGroups?.find((x) => x.group_id === parentID),
  })

  // Default value
  useEffect(() => {
    setDefaultValue()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group])

  const setDefaultValue = useCallback(() => {
    if (!group) {
      reset()
      return
    }
    setValue('name', group.name || '', {
      shouldValidate: true,
    })
  }, [group, reset, setValue])

  const _onSubmit = useCallback(
    (data: GroupFormType) => {
      if (!data) return
      onSubmit?.(data)
    },
    [onSubmit],
  )

  const _onRefresh = useCallback(() => {
    onRefresh?.()
  }, [onRefresh])

  const onSuccessUploadThumbnail = useCallback(
    (data: Models.File[]) => {
      const result = { ...image, thumbnail: data[0] || null }
      onSetImage?.(result)
    },
    [image, onSetImage],
  )

  const onSuccessUploadBanner = useCallback(
    (data: Models.File[]) => {
      const result = { ...image, banner: data[0] || null }
      onSetImage?.(result)
    },
    [image, onSetImage],
  )

  // sort handling
  const { mutate: sortSubGroup } = useMutation(
    (data: GroupResponses.Group[]) => {
      return groupService.sort({
        groups: data,
      } as any)
    },
    {
      onSuccess: () => {
        _onRefresh()
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        const errorMessage = t(err?.response?.data, {
          ns: 'backend',
        })

        notifications.show({
          variant: 'danger',
          message: errorMessage,
        })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        err?.response?.data?.errors?.forEach((err: any) => {
          notifications.show({
            variant: 'danger',
            message: t(err, {
              ns: 'backend',
            }),
          })
        })
      },
    },
  )

  useEffect(() => {
    subGroupHandlers.setState(group?.sub_groups || [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group])

  const onDragEnd = useCallback(
    (result: DropResult) => {
      const { source, destination } = result

      if (!destination) {
        return
      }
      if (destination.index === source.index) {
        return
      }

      const items = Array.from(subGroupState || [])
      const [reorderedItem] = items.splice(source.index, 1)
      items.splice(destination.index, 0, reorderedItem)

      const updateOrdinal = items.map((item, index) => ({
        ...item,
        ordinal: index + 1,
      }))

      subGroupHandlers.setState(updateOrdinal)
      setReorder(true)
    },
    [subGroupHandlers, subGroupState],
  )

  const onSortSubGroup = useCallback(() => {
    if (reorder) {
      sortSubGroup(subGroupState)
      setReorder(false)
    }
  }, [reorder, sortSubGroup, subGroupState])

  useEffect(() => {
    onSortSubGroup()
  }, [onSortSubGroup])

  return (
    <Box>
      <form>
        <Grid gutter={20}>
          <Grid.Col>
            <Input.Wrapper
              label={`${t('group.name')} *`}
              error={errors.name && t(errors.name.message as string)}
            >
              <Input {...register('name')} />
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col>
            <Input.Wrapper
              label={t('group.position')}
              error={errors.position && t(errors.position.message as string)}
            >
              <Input {...register('position')} />
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col xs={6} sm={4} md={3} lg={2}>
            <CardImageForm
              imageData={image?.thumbnail ? [image.thumbnail] : []}
              controlLabel={t('group.thumbnail')}
              onSubmit={onSuccessUploadThumbnail}
            />
          </Grid.Col>
          <Grid.Col xs={6} sm={4} md={3} lg={2}>
            <CardImageForm
              imageData={image?.banner ? [image.banner] : []}
              controlLabel={t('group.banner')}
              onSubmit={onSuccessUploadBanner}
            />
          </Grid.Col>
          {group ? (
            <Grid.Col>
              <Grid>
                <Grid.Col>
                  <Text weight={600} mb={8}>
                    {t('group.subGroups')}
                  </Text>
                  <Button
                    variant='subtle'
                    leftIcon={<MdAddCircle size={24} />}
                    sx={(_theme) => ({
                      '.mantine-Button-icon': {
                        color: _theme.colors.primary[4],
                      },
                      '.mantine-Button-label': {
                        color: _theme.colors.primary[4],
                      },
                    })}
                    onClick={() => setAdded((added) => added + 1)}
                  >
                    {t('group.addSubGroup')}
                  </Button>

                  {added > 0 ? (
                    <Box my={12}>
                      <Accordion
                        chevronPosition='left'
                        variant='separated'
                        defaultValue='customization'
                      >
                        {Array.from({ length: added }).map((_, index) => (
                          <Accordion.Item value={index.toString()} key={index}>
                            <Accordion.Control>{t('group.newGroup')}</Accordion.Control>
                            <Accordion.Panel>
                              <SubGroupForm onRefresh={_onRefresh} isNew={true} />
                            </Accordion.Panel>
                          </Accordion.Item>
                        ))}
                      </Accordion>
                    </Box>
                  ) : null}
                </Grid.Col>

                {subGroupState?.length > 0 && (
                  <Grid.Col
                    p={12}
                    sx={(_theme) => ({
                      borderBottom: `1px solid ${_theme.colors.gray[2]}`,
                    })}
                  >
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId='dnd-list' direction='vertical'>
                        {(dropProvided) => (
                          <Box {...dropProvided.droppableProps} ref={dropProvided.innerRef}>
                            <Accordion variant='separated' defaultValue='customization'>
                              {subGroupState?.map((item, index) => (
                                <Draggable
                                  key={item.group_id}
                                  index={index}
                                  draggableId={item.group_id}
                                >
                                  {(dragProvided) => (
                                    <Box
                                      ref={dragProvided.innerRef}
                                      {...dragProvided.draggableProps}
                                      mb={10}
                                    >
                                      <Accordion.Item value={index.toString()}>
                                        <Accordion.Control>
                                          <Flex justify='start' align='center' gap={8}>
                                            <ActionIcon>
                                              <Box
                                                {...dragProvided.dragHandleProps}
                                                style={{ cursor: 'grab' }}
                                              >
                                                <TbGridDots />
                                              </Box>
                                            </ActionIcon>
                                            {item.name}
                                          </Flex>
                                        </Accordion.Control>
                                        <Accordion.Panel>
                                          <SubGroupForm
                                            group={item}
                                            isUpdate={true}
                                            onRefresh={_onRefresh}
                                          />
                                        </Accordion.Panel>
                                      </Accordion.Item>
                                    </Box>
                                  )}
                                </Draggable>
                              ))}
                            </Accordion>
                            {dropProvided.placeholder}
                          </Box>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Grid.Col>
                )}
              </Grid>
            </Grid.Col>
          ) : null}

          {isParent ? (
            <Grid.Col>
              <Select
                label={t('group.parentGroup')}
                data={parentGroupOptions}
                value={parentGroupValue}
                onChange={onSelectParentGroup}
                filter={parentGroupFilter}
                clearable
                searchable
              />
            </Grid.Col>
          ) : null}

          <Grid.Col>
            <ProductGroup
              group={group}
              onRefresh={onRefresh}
              onSetProductGroups={onSetProductGroups}
            />
          </Grid.Col>

          <Grid.Col>
            <Flex my={40} justify='center' align='center'>
              <Button
                variant='highlight'
                loading={isSubmitLoading}
                disabled={!isValid}
                onClick={handleSubmit(_onSubmit)}
              >
                {t('common.save')}
              </Button>
            </Flex>
          </Grid.Col>
        </Grid>
      </form>
    </Box>
  )
}

export default GroupForm
