import { useCallback, useEffect } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Grid } from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import { date, object } from 'yup'

import ListFilterLayout from '@/components/ListFilterLayout'
import { useApp } from '@/contexts/AppProvider'
import { ROUTES } from '@/routes'

import DashboardGeneralInfo from './Components/GeneralInfo'
import DashboardProgress from './Components/Progress'

type DashboardType = {
  from_date: Date
  to_date: Date
}

const validateSchema = object().shape({
  from_date: date().required('dashboard.missingFromDate'),
  to_date: date().required('dashboard.missingToDate'),
})

const Dashboard: React.FC = () => {
  const { t } = useTranslation()
  const reportService = useAPIService('Report')

  const { registerBreadcrumb, unregisterBreadcrumb } = useApp()

  useEffect(() => {
    registerBreadcrumb({
      key: 'dashboard',
      label: t('page.dashboard'),
      url: ROUTES.ADMIN.DASHBOARD.INDEX,
    })

    return () => {
      unregisterBreadcrumb('dashboard')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    setValue,
    control,
    formState: { errors },
  } = useForm<DashboardType>({
    mode: 'all',
    resolver: yupResolver(validateSchema),
    defaultValues: {
      from_date: new Date(new Date().setDate(1)),
      to_date: new Date(),
    },
  })

  // From date
  const fromDate = useWatch({
    name: 'from_date',
    control,
  })

  const onChangeFromDate = useCallback(
    (date: Date) => {
      setValue('from_date', date, {
        shouldValidate: true,
      })
    },
    [setValue],
  )

  // To date
  const toDate = useWatch({
    name: 'to_date',
    control,
  })

  const onChangeToDate = useCallback(
    (date: Date) => {
      setValue('to_date', date, {
        shouldValidate: true,
      })
    },
    [setValue],
  )

  const {
    data: revenue,
    isFetching: revenueLoading,
    refetch: getRevenue,
  } = useQuery(
    ['reportService', 'sellerRevenue', fromDate, toDate],
    () =>
      reportService
        .revenue({
          filter: {
            start_date: fromDate.toString(),
            end_date: toDate.toString(),
          },
        })
        .then((res) => res.data),
    {
      onError: (err) => {
        reportError(err)
      },
    },
  )

  const onRefresh = useCallback(() => {
    getRevenue()
  }, [getRevenue])

  return (
    <Grid gutter={32}>
      <Grid.Col>
        <form>
          <ListFilterLayout onRefresh={onRefresh}>
            <Grid>
              <Grid.Col md={3}>
                <DatePickerInput
                  label={t('dashboard.fromDate')}
                  valueFormat={'DD/MM/YYYY'}
                  value={fromDate ? new Date(fromDate) : null}
                  error={errors.from_date && t(errors.from_date.message as string)}
                  onChange={onChangeFromDate}
                />
              </Grid.Col>
              <Grid.Col md={3}>
                <DatePickerInput
                  label={t('dashboard.toDate')}
                  valueFormat={'DD/MM/YYYY'}
                  value={toDate ? new Date(toDate) : null}
                  error={errors.to_date && t(errors.to_date.message as string)}
                  onChange={onChangeToDate}
                />
              </Grid.Col>
            </Grid>
          </ListFilterLayout>
        </form>
      </Grid.Col>

      <Grid.Col>
        <DashboardGeneralInfo revenue={revenue} isLoading={revenueLoading} />
      </Grid.Col>
      <Grid.Col>
        <DashboardProgress
          revenue={revenue}
          fromDate={fromDate.toString()}
          toDate={toDate.toString()}
        />
      </Grid.Col>
    </Grid>
  )
}

export default Dashboard
