import { useTranslation } from 'react-i18next'
import { SellingItemCondition } from '@kicksplanet/enums'
import { Badge, Text } from '@mantine/core'

type OrderConditionBageProps = {
  condition?: SellingItemCondition
}

const OrderConditionBage: React.FC<OrderConditionBageProps> = ({ condition }) => {
  const { t } = useTranslation()

  return (
    <Badge
      variant='checked-outline'
      sx={(_theme) => ({
        color: _theme.white,
        background:
          condition === SellingItemCondition.USED
            ? _theme.colors.warning[4]
            : _theme.colors.success[4],
        border:
          condition === SellingItemCondition.USED
            ? _theme.colors.warning[4]
            : _theme.colors.success[4],
      })}
    >
      <Text fw={600}>
        {condition === SellingItemCondition.USED ? t('common.used') : t('common.new')}
      </Text>
    </Badge>
  )
}

export default OrderConditionBage
