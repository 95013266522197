import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Grid } from '@mantine/core'

import { useApp } from '@/contexts/AppProvider'

import ForControlFormCheckInfo from './Components/CheckInfo'
import FormControlFormAConfirm from './Components/Confirm'
import ForControlFormOrderInfo from './Components/OrderInfo'
import ForControlFormPackageInfo from './Components/PackageInfo'

const OrderCheckForm: React.FC = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const orderService = useAPIService('Order')

  const { registerBreadcrumb, unregisterBreadcrumb } = useApp()

  const { data: order, refetch: getOrder } = useQuery(
    ['orderService', 'getOne', id],
    () => orderService.getOne({ id: id || '' }).then((res) => res.data.data),
    {
      enabled: Boolean(id?.toString()),
    },
  )

  useEffect(() => {
    registerBreadcrumb({
      key: 'accounting',
      label: t('page.accounting'),
    })

    return () => {
      unregisterBreadcrumb('accounting')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data: inhouseItem, refetch: refetchInhouseItem } = useQuery({
    queryKey: ['orderService.bo_getInhouseItemBySeller', id, order?.seller_id],
    queryFn: () => {
      return orderService.bo_getInhouseItemBySeller({
        id: id || '',
        sellerId: order?.seller_id || '',
      })
    },
    enabled: Boolean(id && order?.seller_id),
    select: (res) => res.data,
  })

  const onRefresh = useCallback(() => {
    getOrder()
    refetchInhouseItem()
  }, [getOrder, refetchInhouseItem])

  return order ? (
    <Grid>
      <Grid.Col>
        <ForControlFormOrderInfo order={order} />
      </Grid.Col>
      <Grid.Col>
        <ForControlFormCheckInfo order={order} />
      </Grid.Col>
      <Grid.Col>
        <ForControlFormPackageInfo order={order} inhouseItem={inhouseItem} />
      </Grid.Col>
      <Grid.Col>
        <FormControlFormAConfirm inhouseItem={inhouseItem} order={order} onRefresh={onRefresh} />
      </Grid.Col>
    </Grid>
  ) : null
}

export default OrderCheckForm
