import React, { PropsWithChildren, useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BrandResponses } from '@kicksplanet/interfaces'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'

import { ROUTES } from '@/routes'

type AppContextValue = {
  breadcrumbs: BreadcrumbItem[]
  registerBreadcrumb: (item: BreadcrumbItem) => void
  unregisterBreadcrumb: (key: string) => void
}

const AppContext = React.createContext<AppContextValue>({
  breadcrumbs: [],
  registerBreadcrumb: noop,
  unregisterBreadcrumb: noop,
})

const AppProvider: React.FC<
  PropsWithChildren<{
    brands?: BrandResponses.BrandResponse[]
  }>
> = ({ children }) => {
  const { t } = useTranslation()
  const [breadcrumbs, setBreadcumbs] = useState<AppContextValue['breadcrumbs']>([])

  const registerBreadcrumb = useCallback((item: BreadcrumbItem) => {
    setBreadcumbs((prev) => {
      const _breadcrumbs = [...prev]
      if (isEmpty(_breadcrumbs)) {
        _breadcrumbs.push({
          key: 'dashboard',
          label: t('page.dashboard'),
          url: ROUTES.ADMIN.DASHBOARD.INDEX,
        })
      }
      const existingIndex = _breadcrumbs.findIndex((i) => i.key === item.key)
      if (existingIndex !== -1) {
        _breadcrumbs.splice(existingIndex, 1, item)
      } else {
        _breadcrumbs.push(item)
      }
      return _breadcrumbs
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const unregisterBreadcrumb = useCallback((key: string) => {
    setBreadcumbs((prev) => {
      const _breadcrumbs = prev.filter((item) => item.key !== key)
      if (_breadcrumbs.length <= 1) return []
      return _breadcrumbs
    })
  }, [])

  return (
    <AppContext.Provider
      value={{
        registerBreadcrumb,
        unregisterBreadcrumb,
        breadcrumbs,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export function useApp() {
  return useContext(AppContext)
}

export default AppProvider
