import { useTranslation } from 'react-i18next'
import { Button, Flex, Modal, Text } from '@mantine/core'

type ConfirmationModalProps = {
  title: string
  description: string
  options?: {
    cancelButton?: string
    confirmButton?: string
  }
  isLoading?: boolean
  opened: boolean
  onClose: () => void
  onConfirm: () => void
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  title,
  description,
  options,
  isLoading,
  opened,
  onClose,
  onConfirm,
}) => {
  const { t } = useTranslation()

  return (
    <Modal opened={opened} onClose={onClose} title={title}>
      <Text mb={40}>{description}</Text>
      <Flex
        justify='end'
        align='center'
        gap={20}
        onClick={onClose}
        direction={{ base: 'column', md: 'row' }}
      >
        <Button variant='subtle' onClick={onClose} fullWidth>
          {options?.cancelButton ? options.cancelButton : t('common.cancel')}
        </Button>
        <Button variant='highlight' loading={isLoading} onClick={onConfirm} fullWidth>
          {options?.confirmButton ? options.confirmButton : t('common.confirm')}
        </Button>
      </Flex>
    </Modal>
  )
}

export default ConfirmationModal
