import { useTranslation } from 'react-i18next'
import { Flex, Image, Text } from '@mantine/core'

import EmptyData from '@/assets/images/EmptyData.png'

const Empty: React.FC = () => {
  const { t } = useTranslation()
  return (
    <Flex direction='column' align='center' py={20}>
      <Flex
        justify='center'
        align='center'
        sx={{
          position: 'relative',
          width: 100,
          height: 100,
          img: {
            width: '100%',
            height: '100%',
            objectFit: 'contain',
          },
        }}
      >
        <Image src={EmptyData} width='100%' height='100%' alt='empty data' />
      </Flex>
      <Text weight={600}>{t('common.emptyData')}</Text>
    </Flex>
  )
}

export default Empty
