import { useTranslation } from 'react-i18next'
import { OrderResponses } from '@kicksplanet/interfaces'
import { Badge, Card, Divider, Grid, Text } from '@mantine/core'
import find from 'lodash/find'

import { OrderStatusData } from '@/constants/order'
import { formatDate } from '@/utils/date'
import { DATE_FORMATS } from '@/utils/date-format'

type ForControlFormOrderInfoProps = {
  order: OrderResponses.OrderDetails
}

const ForControlFormOrderInfo: React.FC<ForControlFormOrderInfoProps> = ({ order }) => {
  const { t } = useTranslation()

  return (
    <Card>
      <Grid gutter={16} mb={30}>
        <Grid.Col>
          <Text weight={600} transform='uppercase'>
            {t('forControl.cardTitle.orderInfo')}
          </Text>
          <Divider my={12} color='gray.2' />
        </Grid.Col>
        <Grid.Col>
          <Grid gutter={8} justify='space-between'>
            <Grid.Col md={2}>
              <Text weight={600}>{t('order.code')}</Text>
              <Text>{order.code}</Text>
            </Grid.Col>
            <Grid.Col md={2}>
              <Text weight={600}>{t('order.status')}</Text>
              <Text>
                {order.status ? (
                  <Badge
                    variant='checked-outline'
                    p={8}
                    sx={(_theme) => ({
                      color: _theme.white,
                      backgroundColor: find(OrderStatusData, { value: order?.status })?.color || '',
                      borderColor: 'transparent',
                    })}
                  >
                    <Text>{t(find(OrderStatusData, { value: order?.status })?.label || '')}</Text>
                  </Badge>
                ) : null}
              </Text>
            </Grid.Col>
            <Grid.Col md={2}>
              <Text weight={600}>{t('order.paymentMethod')}</Text>
              <Text>{order.payment_method}</Text>
            </Grid.Col>
            <Grid.Col md={2}>
              <Text weight={600}>{t('common.createdAt')}</Text>
              <Text>
                {order.created_at
                  ? formatDate(order.created_at, DATE_FORMATS.HH_MM_AA_DOT_DD_MMMM_YYYY)
                  : '--'}
              </Text>
            </Grid.Col>
            <Grid.Col md={2}>
              <Text weight={600}>{t('forControl.completedAt')}</Text>
              <Text>
                {order.completed_at
                  ? formatDate(order.completed_at, DATE_FORMATS.HH_MM_AA_DOT_DD_MMMM_YYYY)
                  : '--'}
              </Text>
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid>
    </Card>
  )
}

export default ForControlFormOrderInfo
