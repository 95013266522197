import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { yupResolver } from '@hookform/resolvers/yup'
import { OrderRequests } from '@kicksplanet/interfaces'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Button, Grid, Textarea } from '@mantine/core'
import { notifications } from '@mantine/notifications'
import { object, string } from 'yup'

type OrderRejectReturnRequestFormProps = {
  orderID: string
  onSubmit: () => void
  onRefresh: () => void
}

const rejectReturnOrderSchema = object().shape({
  reject_reason: string().required('order.missingRejectReason'),
})

const OrderRejectReturnRequestForm: React.FC<OrderRejectReturnRequestFormProps> = ({
  orderID,
  onSubmit,
  onRefresh,
}) => {
  const { t } = useTranslation()
  const orderService = useAPIService('Order')

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<OrderRequests.RejectReturnOrderPayload>({
    resolver: yupResolver(rejectReturnOrderSchema),
    defaultValues: {
      reject_reason: '',
    },
    mode: 'onChange',
  })

  const { mutate: rejectReturnOrder, isLoading: rejectReturnOrderLoading } = useMutation(
    (data: OrderRequests.RejectReturnOrderPayload) => {
      return orderService.rejectReturnOrder({
        id: orderID,
        data: {
          reject_reason: data.reject_reason,
        },
      })
    },
    {
      onSuccess: () => {
        notifications.show({
          variant: 'success',
          message: t('success.rejectedReturnOrder'),
        })
        onSubmit()
        onRefresh()
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        const { response: { data: { errorKey = '', errors = [] } = {} } = {} } = err
        const errorMessage = t(errorKey, {
          ns: 'backend',
        })

        notifications.show({
          variant: 'danger',
          message: errorMessage,
        })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        errors?.forEach((err: any) => {
          notifications.show({
            variant: 'danger',
            message: t(err, {
              ns: 'backend',
            }),
          })
        })
      },
    },
  )

  const onRejectReturnOrder = useCallback(
    (data: OrderRequests.RejectReturnOrderPayload) => {
      if (!orderID) return
      rejectReturnOrder(data)
    },
    [orderID, rejectReturnOrder],
  )

  return (
    <form>
      <Grid>
        <Grid.Col>
          <Textarea
            label={t('order.rejectReason')}
            autosize
            minRows={2}
            maxRows={4}
            error={errors.reject_reason && t(errors.reject_reason.message as string)}
            {...register('reject_reason')}
          />
        </Grid.Col>
        <Grid.Col>
          <Button
            variant='highlight'
            loading={rejectReturnOrderLoading}
            disabled={!isValid}
            onClick={handleSubmit(onRejectReturnOrder)}
            fullWidth
          >
            {t('common.save')}
          </Button>
        </Grid.Col>
      </Grid>
    </form>
  )
}

export default OrderRejectReturnRequestForm
