import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineTrash } from 'react-icons/hi'
import { IoMdDownload } from 'react-icons/io'
import { useMutation } from 'react-query'
import { Models } from '@kicksplanet/interfaces'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Box, Button, Flex, Grid, Image, Input, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { notifications } from '@mantine/notifications'

import ConfirmationModal from '@/components/ModalForm/Confirmation'
import { formatBytes } from '@/utils/byte'
import { formatDate } from '@/utils/date'
import { DATE_FORMATS } from '@/utils/date-format'

type MediaDetailProps = {
  file: Models.File
  onRemove: () => void
}

const MediaDetail: React.FC<MediaDetailProps> = ({ file, onRemove }) => {
  const { t } = useTranslation()
  const fileService = useAPIService('File')

  const [removeOpened, removeModal] = useDisclosure(false)

  const [dimensions, setDimensions] = useState<{
    width: string
    height: string
  }>()

  const { mutate: remove, isLoading: isRemoveLoading } = useMutation(
    (fileID: string) => {
      return fileService.delete({ id: fileID })
    },
    {
      onSuccess: async () => {
        removeModal.close()
        notifications.show({
          title: t('common.removeTitle'),
          variant: 'success',
          message: t('success.deletedFile'),
        })
        onRemove()
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        const errorMessage = t(err?.response?.data, {
          ns: 'backend',
        })

        notifications.show({
          variant: 'danger',
          message: errorMessage,
        })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        err?.response?.data?.errors?.forEach((err: any) => {
          notifications.show({
            variant: 'danger',
            message: t(err, {
              ns: 'backend',
            }),
          })
        })
      },
    },
  )

  const _onRemove = useCallback(() => {
    if (!file.file_id) return
    remove(file.file_id)
  }, [file.file_id, remove])

  const { mutate: download } = useMutation(
    (fileID: string) => {
      return fileService.getFileUrl({ id: fileID })
    },
    {
      onSuccess: async ({ data }) => {
        const link = document.createElement('a')
        link.href = data.url
        document.body.appendChild(link)
        link.click()
        link?.parentNode?.removeChild(link)
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        const errorMessage = t(err?.response?.data, {
          ns: 'backend',
        })

        notifications.show({
          variant: 'danger',
          message: errorMessage,
        })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        err?.response?.data?.errors?.forEach((err: any) => {
          notifications.show({
            variant: 'danger',
            message: t(err, {
              ns: 'backend',
            }),
          })
        })
      },
    },
  )

  const _onDownload = useCallback(() => {
    if (!file.file_id) return
    download(file.file_id)
  }, [file.file_id, download])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onLoadImage = useCallback((event?: any) => {
    setDimensions({
      width: event?.target?.naturalWidth,
      height: event?.target?.naturalHeight,
    })
  }, [])

  return (
    <>
      <Grid gutter={12}>
        <Grid.Col md={6}>
          <Flex
            pos='relative'
            justify='center'
            align='center'
            bg='gray.0'
            p={8}
            sx={{
              width: '100%',
              minHeight: 500,
              img: {
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              },
            }}
          >
            <Image src={file.file_path} alt='' fit='contain' onLoad={onLoadImage} />
            <Flex
              pos='absolute'
              justify='center'
              align='center'
              sx={{
                top: 8,
                right: 8,
                zIndex: 2,
              }}
              gap={4}
            >
              <Box
                sx={{
                  width: 40,
                  height: 40,
                }}
              >
                <Button
                  p={0}
                  sx={{
                    borderRadius: '0 0 2px 2px',
                  }}
                  fullWidth
                  onClick={_onRemove}
                >
                  <HiOutlineTrash color='white' size={16} />
                </Button>
              </Box>
              <Box
                sx={{
                  width: 40,
                  height: 40,
                }}
              >
                <Button
                  p={0}
                  sx={{
                    borderRadius: '0 0 2px 2px',
                  }}
                  fullWidth
                  color='gray.2'
                  onClick={_onDownload}
                >
                  <IoMdDownload color='black' size={16} />
                </Button>
              </Box>
            </Flex>
          </Flex>
        </Grid.Col>
        <Grid.Col md={6}>
          <Grid>
            <Grid.Col>
              <Box
                p={12}
                sx={(_theme) => ({
                  background: _theme.colors.gray[0],
                })}
              >
                <Grid>
                  <Grid.Col md={6}>
                    <Text fw={600}>{t('common.size')}</Text>
                    <Text>{formatBytes(file.file_size)}</Text>
                  </Grid.Col>
                  <Grid.Col md={6}>
                    <Text fw={600}>{t('common.date')}</Text>
                    <Text>
                      {formatDate(file.created_at, DATE_FORMATS.HH_MM_AA_DOT_DD_MMMM_YYYY)}
                    </Text>
                  </Grid.Col>
                  <Grid.Col md={6}>
                    <Text fw={600}>{t('media.dimensions')}</Text>
                    <Text>{`${dimensions?.width}x${dimensions?.height}`}</Text>
                  </Grid.Col>
                  <Grid.Col md={6}>
                    <Text fw={600}>{t('media.extension')}</Text>
                    <Text>{file.mime_type}</Text>
                  </Grid.Col>
                </Grid>
              </Box>
            </Grid.Col>
            <Grid.Col>
              <Input.Wrapper label={t('media.fileName')}>
                <Input value={file.file_name} readOnly />
              </Input.Wrapper>
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid>

      <ConfirmationModal
        title={t('common.removeTitle')}
        description={t('common.removeText')}
        isLoading={isRemoveLoading}
        opened={removeOpened}
        onClose={removeModal.close}
        onConfirm={_onRemove}
      />
    </>
  )
}

export default MediaDetail
