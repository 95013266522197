import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { ProductRequests } from '@kicksplanet/interfaces'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Button, Card, Flex, Grid } from '@mantine/core'
import { notifications } from '@mantine/notifications'
import chunk from 'lodash/chunk'
import groupBy from 'lodash/groupBy'

import { useApp } from '@/contexts/AppProvider'
import { ROUTES } from '@/routes'
import { dataURLtoFile, toDataURL } from '@/utils/file'

import ProductImportData from './Components/Data'
import ProductImportSelectFile, { ProductImportFileType } from './Components/SelectFile'

const ProductImport: React.FC = () => {
  const { t } = useTranslation()

  const productService = useAPIService('Product')
  const brandService = useAPIService('Brand')
  const sizeChartService = useAPIService('SizeChart')
  const fileService = useAPIService('File')

  const { registerBreadcrumb, unregisterBreadcrumb } = useApp()

  useEffect(() => {
    registerBreadcrumb({
      key: 'products',
      label: t('page.products'),
      url: ROUTES.ADMIN.PRODUCT.LIST,
    })

    registerBreadcrumb({
      key: 'importProduct',
      label: t('page.importProduct'),
    })

    return () => {
      unregisterBreadcrumb('products')
      unregisterBreadcrumb('importProduct')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [importData, setImportData] = useState<ProductImportFileType[]>([])
  const [currentFile, setCurrentFile] = useState<File>()

  const brandNames = useMemo(() => {
    return Object.keys(groupBy(importData, 'brand')).filter((key) => !!key)
  }, [importData])

  const sizeChartNames = useMemo(() => {
    return Object.keys(groupBy(importData, 'size_chart')).filter((key) => !!key)
  }, [importData])

  const { data: brands } = useQuery(
    ['brandService', 'getList', 'options', brandNames],
    () =>
      brandService
        .getList({
          filter: {
            name: {
              'op.in': brandNames,
            },
          },
        })
        .then((res) => res.data.data),
    { enabled: Boolean(brandNames?.length) },
  )

  const { data: sizeCharts } = useQuery(
    ['sizeChartService', 'getList', 'options', sizeChartNames],
    () =>
      sizeChartService
        .getList({
          filter: {
            name: {
              'op.in': sizeChartNames,
            },
          },
        })
        .then((res) => res.data.data),
    { enabled: Boolean(sizeChartNames?.length) },
  )

  const checkValidData = useCallback(
    (data: ProductImportFileType[]) => {
      // brand
      const missingBrands = data.filter((p) => !p.brand)
      if (missingBrands.length > 0) {
        return t('product.importFile.missingBrands', {
          products: missingBrands
            .map((x) => x.title)
            .filter((elem, index, self) => {
              return index === self.indexOf(elem)
            })
            .join(', '),
        })
      }

      const invalidBrands = data.filter((p) => !brands?.map((x) => x.name)?.includes(p.brand))
      if (invalidBrands.length > 0) {
        return t('product.importFile.invalidBrands', {
          brands: invalidBrands
            .map((x) => x.brand)
            .filter((elem, index, self) => {
              return index === self.indexOf(elem)
            })
            .join(', '),
        })
      }

      // size chart
      const missingSizeCharts = data.filter((p) => !p.size_chart)
      if (missingSizeCharts.length > 0) {
        return t('product.importFile.missingSizeCharts', {
          products: missingSizeCharts
            .map((x) => x.title)
            .filter((elem, index, self) => {
              return index === self.indexOf(elem)
            })
            .join(', '),
        })
      }

      const invalidSizeCharts = data.filter(
        (p) => !sizeCharts?.map((x) => x.name)?.includes(p.size_chart),
      )
      if (invalidSizeCharts.length > 0) {
        return t('product.importFile.invalidSizeCharts', {
          sizeCharts: invalidSizeCharts
            .map((x) => x.size_chart)
            .filter((elem, index, self) => {
              return index === self.indexOf(elem)
            })
            .join(', '),
        })
      }
    },
    [brands, sizeCharts, t],
  )

  const onDataProcessed = (data: ProductImportFileType[], file?: File) => {
    setImportData(data)
    setCurrentFile(file)
  }

  const onMappingProducts = async () => {
    if (!importData?.length) return

    let result: ProductRequests.ProductUpsertPayload[] = []

    const chunks = chunk(importData, 1)

    for (const productChunk of chunks) {
      const imageUploadRequests = productChunk.map((product) => {
        return Promise.allSettled(
          product.images.map((image) => {
            const imagePath = image.split('?')[0]
            return toDataURL(image)
              .then((dataUrl) => {
                const pathComponents = imagePath.split('/')
                const fileName = pathComponents[pathComponents.length - 1]
                return dataURLtoFile(dataUrl as string, fileName)
              })
              .then((file) => {
                const formData = new FormData()
                formData.append('file', file)
                return fileService.upload(formData).then(({ data }) => data?.data?.file_id)
              })
          }),
        ).then((uploadResults) => {
          const uploadResultsFullfilledValue = uploadResults
            .filter((res) => res.status === 'fulfilled' && Boolean(res.value))
            .map((res) => {
              if (res.status === 'fulfilled') {
                return res.value
              }
              return ''
            })

          return {
            ...product,
            images: uploadResultsFullfilledValue,
            thumbnail: uploadResultsFullfilledValue[0],
          }
        })
      })

      const productsWithUploadedImages = await Promise.allSettled(imageUploadRequests)

      const productsWithUploadedImagesFullfilled = productsWithUploadedImages
        .filter((res) => res.status === 'fulfilled' && Boolean(res.value))
        .map((res) => {
          if (res.status === 'fulfilled') {
            return res.value
          }
          return null
        })

      const mappedProducts = productsWithUploadedImagesFullfilled.map((data) => {
        const product = {
          code: data?.code,
          images: data?.images,
          title: data?.title,
          color: data?.color,
          retail_price: data?.retail_price,
          release_date: data?.release_date || undefined,
          thumbnail: data?.thumbnail,
          brand_id: brands?.find((x) => x.name === data?.brand)?.brand_id,
          size_chart_id: sizeCharts?.find((x) => x.name === data?.size_chart)?.size_chart_id,
          gender: data?.gender,
        } as ProductRequests.ProductUpsertPayload
        return product
      })

      result = [...result, ...mappedProducts]
    }

    return result
  }

  const { mutate: importProduct, isLoading: isImportLoading } = useMutation(
    async () => {
      const mappedProducts = await onMappingProducts()

      if (!mappedProducts?.length) throw null

      return productService.bulkImport({
        data: mappedProducts,
      })
    },
    {
      onSuccess: async () => {
        notifications.show({
          variant: 'success',
          message: t('success.createdProduct'),
        })
        setImportData([])
        setCurrentFile(undefined)
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        if (!err) {
          notifications.show({
            variant: 'danger',
            message: t('error.processFile'),
          })
          return
        }

        const errorMessage = t(err?.response?.data, {
          ns: 'backend',
        })

        notifications.show({
          variant: 'danger',
          message: errorMessage,
        })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        err?.response?.data?.errors?.forEach((err: any) => {
          notifications.show({
            variant: 'danger',
            message: t(err, {
              ns: 'backend',
            }),
          })
        })
      },
    },
  )

  const onImportProduct = async () => {
    let errorMessage = ''
    errorMessage = checkValidData(importData) || ''

    if (errorMessage) {
      notifications.show({
        variant: 'error',
        message: errorMessage,
      })
      return
    }

    importProduct()
  }

  return (
    <Card>
      <Grid gutter={32}>
        <Grid.Col>
          <ProductImportSelectFile currentFile={currentFile} onDataProcessed={onDataProcessed} />
        </Grid.Col>
        <Grid.Col>
          {importData?.length ? (
            <Grid>
              <Grid.Col>
                <Flex justify='start' align='center'>
                  <Button variant='highlight' onClick={onImportProduct} loading={isImportLoading}>
                    {t('product.import')}
                  </Button>
                </Flex>
              </Grid.Col>
              <Grid.Col>
                <ProductImportData importData={importData} />
              </Grid.Col>
            </Grid>
          ) : null}
        </Grid.Col>
      </Grid>
    </Card>
  )
}

export default ProductImport
