import { useTranslation } from 'react-i18next'
import { IoMdRefreshCircle } from 'react-icons/io'
import { Button, Tooltip } from '@mantine/core'

type RefreshButtonProps = {
  onRefresh: () => void
}

const RefreshButton: React.FC<RefreshButtonProps> = ({ onRefresh }) => {
  const { t } = useTranslation()

  return (
    <Tooltip label={t('common.refresh')}>
      <Button variant='subtile' p={4} onClick={onRefresh}>
        <IoMdRefreshCircle size={28} />
      </Button>
    </Tooltip>
  )
}

export default RefreshButton
