export const formatAddress = (
  address?: string,
  ward?: string,
  district?: string,
  province?: string,
) => {
  return (
    (address ? address : '') +
    (ward ? `, ${ward}` : '') +
    (district ? `, ${district}` : '') +
    (province ? `, ${province}` : '')
  )
}

export const formatInfo = (name?: string, phone?: string) => {
  return (name ? name : '') + (phone ? ` - ${phone}` : '')
}
