import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { HiEye } from 'react-icons/hi'
import { Link } from 'react-router-dom'
import { Models } from '@kicksplanet/interfaces'
import { Badge, Button, Flex, Text, Tooltip, useMantineTheme } from '@mantine/core'
import find from 'lodash/find'

import { OrderStatusData } from '@/constants/order'
import { formatDate } from '@/utils/date'
import { DATE_FORMATS } from '@/utils/date-format'
import { getOrderCheckFormUrl } from '@/utils/url'

type ForControlListRowProps = {
  order: Models.Order
  onRefresh: () => void
}

const ForControlListRow: React.FC<ForControlListRowProps> = ({ order }) => {
  const { t } = useTranslation()
  const theme = useMantineTheme()

  const checkStatus = useMemo(() => {
    if (!order.is_checked)
      return {
        text: 'order.checkStatus.notChecked',
        color: theme.colors.warning[5],
      }
    return {
      text: 'order.checkStatus.checked',
      color: theme.colors.success[3],
    }
  }, [order.is_checked, theme.colors.success, theme.colors.warning])

  return (
    <>
      <tr>
        <td style={{ minWidth: '160px' }}>
          <Link
            to={getOrderCheckFormUrl(order.order_id)}
            style={{ textDecoration: 'none', color: 'black' }}
          >
            <Tooltip label={order.code}>
              <Text weight={600} lineClamp={1} w='fit-content'>
                {order.code}
              </Text>
            </Tooltip>
          </Link>
        </td>
        <td>
          <Badge
            variant='checked-outline'
            p={8}
            sx={(_theme) => ({
              color: _theme.white,
              backgroundColor: find(OrderStatusData, { value: order?.status })?.color || '',
              borderColor: 'transparent',
            })}
          >
            <Text>{t(`order.orderStatus.${order.status.toLowerCase()}`)}</Text>
          </Badge>
        </td>
        <td style={{ minWidth: '160px' }}>
          {order.completed_at
            ? (() => {
                const value = formatDate(order.completed_at, DATE_FORMATS.HH_MM_AA_DOT_DD_MMMM_YYYY)
                return (
                  <Tooltip label={value}>
                    <Text lineClamp={1} w='fit-content'>
                      {value}
                    </Text>
                  </Tooltip>
                )
              })()
            : '--'}
        </td>
        <td style={{ minWidth: '160px' }}>
          <Badge
            variant='checked-outline'
            p={8}
            sx={(_theme) => ({
              color: _theme.white,
              backgroundColor: checkStatus.color,
              borderColor: 'transparent',
            })}
          >
            <Text>{t(checkStatus.text)}</Text>
          </Badge>
        </td>
        <td style={{ minWidth: '160px' }}>
          {order.checked_at
            ? (() => {
                const value = formatDate(order.checked_at, DATE_FORMATS.HH_MM_AA_DOT_DD_MMMM_YYYY)
                return (
                  <Tooltip label={value}>
                    <Text lineClamp={1} w='fit-content'>
                      {value}
                    </Text>
                  </Tooltip>
                )
              })()
            : '--'}
        </td>
        <td style={{ width: '40px' }}>
          <Flex justify='start' align='center'>
            <Link to={getOrderCheckFormUrl(order.order_id)} style={{ textDecoration: 'none' }}>
              <Button variant='subtle' p={10}>
                <HiEye color='red' />
              </Button>
            </Link>
          </Flex>
        </td>
      </tr>
    </>
  )
}

export default ForControlListRow
