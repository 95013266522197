import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Card, Skeleton } from '@mantine/core'
import defaultTo from 'lodash/defaultTo'

import Empty from '@/components/Empty'
import ListFilterLayout from '@/components/ListFilterLayout'
import QueryPagination from '@/components/Pagination/QueryPagination'
import TableWrapper from '@/components/TableWrapper'
import { DEFAULT_PAGINATION_QUERY } from '@/constants/pagination'
import { useApp } from '@/contexts/AppProvider'
import { ROUTES } from '@/routes'
import { formatKeyFilter } from '@/utils/string'

import ProductListFilter from './Components/Filter'
import ProductListRow from './Components/Row'

const COLUMN_CAPTION = [
  'product.productTitle',
  'product.sku',
  'product.productColor',
  'product.productGender',
  'product.productRetailPrice',
  'product.productReleaseDate',
  'common.action',
]

const ProductList: React.FC = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const productService = useAPIService('Product')
  const { registerBreadcrumb, unregisterBreadcrumb } = useApp()

  useEffect(() => {
    registerBreadcrumb({
      key: 'products',
      label: t('page.products'),
    })

    return () => {
      unregisterBreadcrumb('products')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const defaultQueryParams = useMemo(() => {
    return {
      page: defaultTo(parseInt(searchParams.get('page') as string), 1),
      perPage: defaultTo(
        parseInt(searchParams.get('per') as string),
        DEFAULT_PAGINATION_QUERY.limit,
      ),
      key: defaultTo(searchParams.get('key') as string, ''),
      brand: defaultTo(searchParams.get('brand') as string, ''),
      gender: defaultTo(searchParams.get('gender') as string, ''),
    }
  }, [searchParams])

  const {
    page: pageQuery,
    perPage: perPageQuery,
    key: keyQuery,
    gender: genderQuery,
    brand: brandQuery,
  } = defaultQueryParams

  const getListQuery = useMemo(() => {
    const likeFilter = formatKeyFilter(keyQuery)
    return {
      pagination: {
        page: pageQuery,
        perPage: perPageQuery,
      },
      filter: {
        'op.or': [
          {
            title: {
              'op.like': likeFilter,
            },
          },
          {
            code: {
              'op.like': likeFilter,
            },
          },
        ],
        ...(brandQuery && {
          brand_info: {
            name: {
              'op.eq': brandQuery,
            },
          },
        }),
        ...(genderQuery && {
          gender: { 'op.eq': genderQuery },
        }),
      },
    }
  }, [brandQuery, genderQuery, keyQuery, pageQuery, perPageQuery])

  const dataSearchQuery: ProductListSearchType = useMemo(() => {
    return {
      ...(keyQuery && { key: keyQuery }),
      ...(brandQuery && { brand: brandQuery }),
      ...(genderQuery && { gender: genderQuery }),
    } as ProductListSearchType
  }, [brandQuery, genderQuery, keyQuery])

  const {
    data: products,
    isFetching: isLoadingTable,
    refetch: getProducts,
  } = useQuery(['productService', 'getList', getListQuery], () =>
    productService.getList(getListQuery).then((res) => res.data),
  )

  const onRefresh = useCallback(() => {
    getProducts()
  }, [getProducts])

  const onChangeQueries = useCallback(
    (query: ProductListSearchType) => {
      navigate({
        pathname: ROUTES.ADMIN.PRODUCT.LIST,
        search: createSearchParams({
          ...query,
        }).toString(),
      })
    },
    [navigate],
  )

  const RowData = useMemo(() => {
    if (isLoadingTable) {
      return (
        <tr>
          <td colSpan={7}>
            <Skeleton height={10} my={15} radius='md' />
            <Skeleton height={10} my={15} radius='md' />
            <Skeleton height={10} my={15} radius='md' />
          </td>
        </tr>
      )
    }

    if (!products || !products.data || products.data.length <= 0)
      return (
        <tr>
          <td colSpan={7}>
            <Empty />
          </td>
        </tr>
      )

    return products.data.map((item) => {
      return <ProductListRow key={item.product_id} product={item} onRefresh={onRefresh} />
    })
  }, [isLoadingTable, onRefresh, products])

  return (
    <Card>
      <ListFilterLayout onRefresh={onRefresh}>
        <ProductListFilter queryData={dataSearchQuery} onChangeQueries={onChangeQueries} />
      </ListFilterLayout>
      <TableWrapper isStickyColumm={Boolean(products?.data && products?.data.length > 0)}>
        <thead>
          <tr>
            {COLUMN_CAPTION.map((item, key) => {
              return <th key={key}>{t(item)}</th>
            })}
          </tr>
        </thead>
        <tbody>{RowData}</tbody>
      </TableWrapper>
      <QueryPagination
        limit={perPageQuery}
        page={pageQuery}
        total={products?.total}
        isShowLimitOptions
      />
    </Card>
  )
}

export default ProductList
