import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BiSearch } from 'react-icons/bi'
import { Button, Grid, Text, TextInput } from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import { useDebouncedState } from '@mantine/hooks'
import { endOfDay, startOfDay, startOfMonth } from 'date-fns'

import { convertDateToTimestamp } from '@/utils/date'
import { cleanAccents } from '@/utils/string'

import { InvoiceListSearchParams } from '../types'

type InvoiceListFilterProps = {
  queryData: InvoiceListSearchParams
  onChangeQueries: (query: InvoiceListSearchParams) => void
}

const InvoiceListFilter: React.FC<InvoiceListFilterProps> = ({ queryData, onChangeQueries }) => {
  const { t } = useTranslation()

  const [keysearch, setKeysearch] = useDebouncedState(queryData.key, 400)
  const [fromDate, setFromDate] = useState<Date>(
    queryData.from ? new Date(parseInt(queryData.from)) : startOfDay(startOfMonth(new Date())),
  )
  const [toDate, setToDate] = useState<Date>(
    queryData.to ? new Date(parseInt(queryData.to)) : endOfDay(new Date()),
  )

  const onSetKeysearch = useCallback(
    async (event?: React.ChangeEvent<HTMLInputElement>) => {
      const value = event?.target?.value as string
      setKeysearch(value)
    },
    [setKeysearch],
  )

  const _onChangeQueries = useCallback(() => {
    const result = {
      key: cleanAccents(keysearch ?? ''),
      ...(fromDate ? { from: convertDateToTimestamp(fromDate).toString() } : ''),
      ...(toDate ? { to: convertDateToTimestamp(toDate).toString() } : ''),
    }

    onChangeQueries(result)
  }, [fromDate, keysearch, onChangeQueries, toDate])

  const onChangeFromDate = useCallback((date: Date) => {
    setFromDate(date)
  }, [])

  const onChangeToDate = useCallback((date: Date) => {
    setToDate(date)
  }, [])

  return (
    <Grid gutter={16} align='end'>
      <Grid.Col md={3}>
        <TextInput
          label={t('common.keyword')}
          icon={<BiSearch size='1rem' />}
          placeholder={t('order.searchByKeyword')}
          onChange={onSetKeysearch}
          defaultValue={keysearch}
        />
      </Grid.Col>
      <Grid.Col md={3}>
        <DatePickerInput
          label={t('invoice.filter.fromDate')}
          valueFormat={'DD/MM/YYYY'}
          maxDate={toDate}
          value={fromDate ? new Date(fromDate) : null}
          onChange={onChangeFromDate}
        />
      </Grid.Col>
      <Grid.Col md={3}>
        <DatePickerInput
          label={t('invoice.filter.toDate')}
          valueFormat={'DD/MM/YYYY'}
          minDate={fromDate}
          maxDate={new Date()}
          value={toDate ? new Date(toDate) : null}
          onChange={onChangeToDate}
        />
      </Grid.Col>
      <Grid.Col md={3}>
        <Button color='success.4' onClick={_onChangeQueries}>
          <Text>{t('common.searchButton')}</Text>
        </Button>
      </Grid.Col>
    </Grid>
  )
}

export default InvoiceListFilter
