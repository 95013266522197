import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'

import AdminLayout from '@/layouts/Admin'
import AuthLayout from '@/layouts/Auth'
import { ROUTES } from '@/routes'
// Auth
import SignIn from '@/views/Auth/SignIn'
// Admin
import BrandCreate from '@/views/Brand/Create'
import BrandList from '@/views/Brand/List'
import BrandUpdate from '@/views/Brand/Update'
import CategoryCreate from '@/views/Category/Create'
import CategoryList from '@/views/Category/List'
import CategoryUpdate from '@/views/Category/Update'
import Dashboard from '@/views/Dashboard'
import OrderCheckForm from '@/views/ForControl/Form'
import ForControlList from '@/views/ForControl/List'
import GroupCreate from '@/views/Group/Create'
import GroupList from '@/views/Group/List'
import GroupUpdate from '@/views/Group/Update'
import InvoiceListPage from '@/views/Invoices'
import MediaList from '@/views/Media/List'
import OrderDetail from '@/views/Order/Detail'
import OrderList from '@/views/Order/List'
import ProductCreate from '@/views/Product/Create'
import ProductDetail from '@/views/Product/Detail'
import ProductImport from '@/views/Product/Import'
import ProductList from '@/views/Product/List'
import ProductUpdate from '@/views/Product/Update'
import SellingItemDetail from '@/views/SellingItem/Detail'
import SellingItemList from '@/views/SellingItem/List'
import SizeChartCreate from '@/views/SizeChart/Create'
import SizeChartList from '@/views/SizeChart/List'
import SizeChartUpdate from '@/views/SizeChart/Update'
import UserList from '@/views/User/List'
import UserUpdate from '@/views/User/Update'
import VoucherCreate from '@/views/Voucher/Create'
import VoucherList from '@/views/Voucher/List'
import VoucherUpdate from '@/views/Voucher/Update'
import CreateWithdrawalRequest from '@/views/WithdrawalRequest/Create'
import WithdrawalRequestDetail from '@/views/WithdrawalRequest/Detail'
import WithdrawalRequestList from '@/views/WithdrawalRequest/List'

const AppRoute: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path='/admin' element={<AdminLayout />}>
          {/* Dashboard */}
          <Route path={ROUTES.ADMIN.DASHBOARD.INDEX} element={<Dashboard />} />
          {/* Group */}
          <Route path={ROUTES.ADMIN.GROUP.LIST} element={<GroupList />} />
          <Route path={ROUTES.ADMIN.GROUP.CREATE} element={<GroupCreate />} />
          <Route path={ROUTES.ADMIN.GROUP.UPDATE} element={<GroupUpdate />} />
          {/* Product */}
          <Route path={ROUTES.ADMIN.PRODUCT.LIST} element={<ProductList />} />
          <Route path={ROUTES.ADMIN.PRODUCT.CREATE} element={<ProductCreate />} />
          <Route path={ROUTES.ADMIN.PRODUCT.UPDATE} element={<ProductUpdate />} />
          <Route path={ROUTES.ADMIN.PRODUCT.DETAIL} element={<ProductDetail />} />
          <Route path={ROUTES.ADMIN.PRODUCT.IMPORT} element={<ProductImport />} />
          {/* Brand */}
          <Route path={ROUTES.ADMIN.BRAND.LIST} element={<BrandList />} />
          <Route path={ROUTES.ADMIN.BRAND.CREATE} element={<BrandCreate />} />
          <Route path={ROUTES.ADMIN.BRAND.UPDATE} element={<BrandUpdate />} />
          {/* Category */}
          <Route path={ROUTES.ADMIN.CATEGORIES.LIST} element={<CategoryList />} />
          <Route path={ROUTES.ADMIN.CATEGORIES.CREATE} element={<CategoryCreate />} />
          <Route path={ROUTES.ADMIN.CATEGORIES.UPDATE} element={<CategoryUpdate />} />
          {/* Order */}
          <Route path={ROUTES.ADMIN.ORDER.LIST} element={<OrderList />} />
          <Route path={ROUTES.ADMIN.ORDER.DETAIL} element={<OrderDetail />} />
          {/* Voucher */}
          <Route path={ROUTES.ADMIN.VOUCHER.LIST} element={<VoucherList />} />
          <Route path={ROUTES.ADMIN.VOUCHER.CREATE} element={<VoucherCreate />} />
          <Route path={ROUTES.ADMIN.VOUCHER.UPDATE} element={<VoucherUpdate />} />
          {/* Media */}
          <Route path={ROUTES.ADMIN.MEDIA.LIST} element={<MediaList />} />
          {/* Selling Item */}
          <Route path={ROUTES.ADMIN.SELLING_ITEM.LIST} element={<SellingItemList />} />
          <Route path={ROUTES.ADMIN.SELLING_ITEM.DETAIL} element={<SellingItemDetail />} />
          {/* Accounting */}
          <Route path={ROUTES.ADMIN.ACCOUNTING.LIST} element={<ForControlList />} />
          <Route path={ROUTES.ADMIN.ACCOUNTING.CHECKFORM} element={<OrderCheckForm />} />
          <Route
            path={ROUTES.ADMIN.ACCOUNTING.CREATE_WITHDRAWAL_REQUEST}
            element={<CreateWithdrawalRequest />}
          />
          <Route
            path={ROUTES.ADMIN.ACCOUNTING.WITHDRAWAL_REQUEST_DETAIL}
            element={<WithdrawalRequestDetail />}
          />
          <Route
            path={ROUTES.ADMIN.ACCOUNTING.WITHDRAWAL_REQUESTS}
            element={<WithdrawalRequestList />}
          />
          <Route path={ROUTES.ADMIN.ACCOUNTING.INVOICES} element={<InvoiceListPage />} />
          {/* User */}
          <Route path={ROUTES.ADMIN.USER.LIST} element={<UserList />} />
          <Route path={ROUTES.ADMIN.USER.UPDATE} element={<UserUpdate />} />
          {/* Size Chart */}
          <Route path={ROUTES.ADMIN.SIZE_CHART.LIST} element={<SizeChartList />} />
          <Route path={ROUTES.ADMIN.SIZE_CHART.CREATE} element={<SizeChartCreate />} />
          <Route path={ROUTES.ADMIN.SIZE_CHART.UPDATE} element={<SizeChartUpdate />} />
          {/* {DashRoutes.map((item, key) => {
            if (item.views && item.views.length > 0) {
              item.views.map((child, keyChild) => {
                return (
                  <Route
                    key={keyChild}
                    path={child.path}
                    element={child.component}
                  />
                );
              });
            } else {
              return (
                <Route key={key} path={item.path} element={item.component} />
              );
            }
          })} */}
        </Route>
        <Route path='/auth' element={<AuthLayout />}>
          <Route path={ROUTES.AUTH.SIGNIN} element={<SignIn />} />
        </Route>
        <Route path='/' element={<Navigate to={ROUTES.ADMIN.DASHBOARD.INDEX} />} />
      </Routes>
    </Router>
  )
}

export default AppRoute
