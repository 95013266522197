import { useCallback, useEffect } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { yupResolver } from '@hookform/resolvers/yup'
import { OrderRequests, OrderResponses } from '@kicksplanet/interfaces'
import { BankModel } from '@kicksplanet/interfaces/dist/models/bank.model'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Button, Grid, Input, Loader, Select } from '@mantine/core'
import { notifications } from '@mantine/notifications'
import { object, string } from 'yup'

import { useSelection } from '@/hooks/useSelection'
import { formatPrice } from '@/utils/number'

type OrderRefundFormType = {
  order: OrderResponses.OrderDetails
  onSubmit: () => void
  onRefresh: () => void
}

const approveReturnOrderSchema = object().shape({
  bank_code: string().required('order.missingFullName'),
  bank_account_name: string().required('order.missingBankAccountName'),
  bank_account_number: string().required('order.missingBankAccountNumber'),
})

const OrderRefundForm: React.FC<OrderRefundFormType> = ({ order, onSubmit, onRefresh }) => {
  const { t } = useTranslation()
  const orderService = useAPIService('Order')
  const qrService = useAPIService('QR')

  const {
    register,
    setValue,
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<OrderRequests.RefunOrderPayload>({
    resolver: yupResolver(approveReturnOrderSchema),
    mode: 'onChange',
  })

  const { mutate: refundOrder, isLoading: refundOrderLoading } = useMutation(
    (data: OrderRequests.RefunOrderPayload) => {
      return orderService.refundOrder(
        {
          id: order?.order_id || '',
        },
        {
          data: {
            refunded_amount: order?.order_item?.price,
            bank_account_number: data.bank_account_number,
            bank_account_name: data.bank_account_name,
            bank_code: data.bank_code,
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any,
      )
    },
    {
      onSuccess: () => {
        notifications.show({
          variant: 'success',
          message: t('success.refundOrder'),
        })
        onSubmit()
        onRefresh()
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        const errorMessage = t(err?.response?.data, {
          ns: 'backend',
        })

        notifications.show({
          variant: 'danger',
          message: errorMessage,
        })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        err?.response?.data?.errors?.forEach((err: any) => {
          notifications.show({
            variant: 'danger',
            message: t(err, {
              ns: 'backend',
            }),
          })
        })
      },
    },
  )

  const onRefundOrder = useCallback(
    (data: OrderRequests.RefunOrderPayload) => {
      if (!order) return
      refundOrder(data)
    },
    [refundOrder, order],
  )

  // Bank
  const bankCode = useWatch({
    name: 'bank_code',
    control,
  })

  const { data: banks = [], isLoading: banksLoading } = useQuery(
    ['qrService', 'getBanks', 'selectOptions'],
    () => qrService.getBanks().then((res) => res.data.data),
    {
      select: (data) => {
        return (
          data?.map((item) => ({
            ...item,
            label: `${item.name} (${item.shortName})`,
          })) || []
        )
      },
    },
  )

  const onSelectBankOptions = useCallback(
    (item?: BankModel | null) => {
      setValue('bank_code', item ? item.code : '', {
        shouldValidate: true,
      })
    },
    [setValue],
  )

  const {
    data: bankOptions,
    onSelect: onSelectBank,
    value: bankValue,
    filter: bankFilter,
  } = useSelection<BankModel>({
    items: banks,
    onSelect: onSelectBankOptions,
    valueKey: 'code',
    defaultValue: banks.find((x) => x.code.toString() == bankCode),
  })

  useEffect(() => {
    if (bankCode) {
      onSelectBank(bankCode)
    } else {
      onSelectBank('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankCode])

  return (
    <form>
      <Grid>
        <Grid.Col>
          <Select
            label={`${t('order.bank')} *`}
            nothingFound={t('common.emptyData')}
            searchable
            data={bankOptions}
            value={bankValue}
            onChange={onSelectBank}
            filter={bankFilter}
            rightSection={banksLoading && <Loader size='xs' color='red.5' />}
            error={errors.bank_code && t(errors.bank_code.message as string)}
          />
        </Grid.Col>
        <Grid.Col md={6}>
          <Input.Wrapper
            label={`${t('order.bankAccountName')} *`}
            error={errors.bank_account_name && t(errors.bank_account_name.message as string)}
          >
            <Input {...register('bank_account_name')} />
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col md={6}>
          <Input.Wrapper
            label={`${t('order.bankAccountNumber')} *`}
            error={errors.bank_account_number && t(errors.bank_account_number.message as string)}
          >
            <Input {...register('bank_account_number')} />
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col>
          <Button
            variant='highlight'
            loading={refundOrderLoading}
            disabled={!isValid}
            onClick={handleSubmit(onRefundOrder)}
            fullWidth
            my={12}
          >
            {`${t('order.refund')} ${formatPrice(order?.order_item?.price)}`}
          </Button>
        </Grid.Col>
      </Grid>
    </form>
  )
}

export default OrderRefundForm
