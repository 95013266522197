import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { SizeChartRequests, SizeChartResponses } from '@kicksplanet/interfaces'
import { Button, Card, Divider, Flex, Grid, Input, Text } from '@mantine/core'
import { object, string } from 'yup'

import SizeChartListSize, { SizeChartAddSizeType } from '../ListSize'

type SizeChartFormProps = {
  sizeChart?: SizeChartResponses.SizeChart
  isSubmitLoading?: boolean
  onSubmit?: (
    sizeChart: SizeChartRequests.SizeChartUpsertPayload,
    sizes: SizeChartAddSizeType[],
  ) => void
  onRefresh?: () => void
}

const validateSchema = object().shape({
  name: string()
    .max(225, 'sizeChart.sizeChartNameTooLong')
    .required('sizeChart.missingSizeChartName'),
})

const SizeChartForm: React.FC<SizeChartFormProps> = ({
  sizeChart,
  isSubmitLoading,
  onSubmit,
  onRefresh,
}) => {
  const { t } = useTranslation()

  const [sizes, setSizes] = useState<SizeChartAddSizeType[]>(sizeChart?.sizes || [])

  const {
    setValue,
    reset,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<SizeChartRequests.SizeChartUpsertPayload>({
    resolver: yupResolver(validateSchema),
    mode: 'all',
  })

  // Default value
  useEffect(() => {
    setDefaultValue()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sizeChart])

  const setDefaultValue = useCallback(() => {
    if (!sizeChart) {
      reset()
      return
    }
    setValue('name', sizeChart.name || '', {
      shouldValidate: true,
    })
  }, [sizeChart, reset, setValue])

  const _onRefresh = useCallback(() => {
    onRefresh?.()
  }, [onRefresh])

  const _onSubmit = useCallback(
    (dataSizeChart: SizeChartRequests.SizeChartUpsertPayload) => {
      if (!dataSizeChart) return

      onSubmit?.(dataSizeChart, sizes)
    },
    [onSubmit, sizes],
  )

  return (
    <Grid gutter={20}>
      <Grid.Col>
        <Card>
          <Grid gutter={16} mb={30}>
            <Grid.Col>
              <Text weight={600} transform='uppercase'>
                {t('sizeChart.sizeChartHeading')}
              </Text>
              <Divider my={12} color='gray.2' />
            </Grid.Col>
            <Grid.Col>
              <form>
                <Grid gutter={20}>
                  <Grid.Col>
                    <Input.Wrapper
                      label={`${t('sizeChart.sizeChartName')} *`}
                      error={errors.name && t(errors.name.message as string)}
                    >
                      <Input {...register('name')} />
                    </Input.Wrapper>
                  </Grid.Col>
                </Grid>
              </form>
            </Grid.Col>
          </Grid>
        </Card>
      </Grid.Col>
      <Grid.Col>
        <Card>
          <Grid gutter={16} mb={30}>
            <Grid.Col>
              <Text weight={600} transform='uppercase'>
                {t('size.sizeHeading')}
              </Text>
              <Divider my={12} color='gray.2' />
            </Grid.Col>
            <Grid.Col>
              <SizeChartListSize
                sizes={sizeChart?.sizes}
                onSetSizes={setSizes}
                onRefresh={_onRefresh}
              />
            </Grid.Col>
          </Grid>
        </Card>
      </Grid.Col>
      <Grid.Col>
        <Flex justify='center' align='center'>
          <Button
            variant='highlight'
            loading={isSubmitLoading}
            disabled={!isValid}
            onClick={handleSubmit(_onSubmit)}
          >
            {t('common.save')}
          </Button>
        </Flex>
      </Grid.Col>
    </Grid>
  )
}

export default SizeChartForm
