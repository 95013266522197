import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { WalletApi } from '@kicksplanet/api-client'
import { WithdrawalRequestType } from '@kicksplanet/enums'
import { Models, UserRequests } from '@kicksplanet/interfaces'
import { useAPIService } from '@kicksplanet/react/hooks'
import { GetListParams } from '@kicksplanet/services/dist/services/core/CURDService'
import {
  Box,
  Button,
  Card,
  Group,
  Input,
  Paper,
  Select,
  Skeleton,
  Stack,
  Text,
  Textarea,
  TextInput,
  Transition,
} from '@mantine/core'
import { useClickOutside, useDebouncedState, useDisclosure } from '@mantine/hooks'
import omit from 'lodash/omit'
import { number, object, string } from 'yup'

import { useApp } from '@/contexts/AppProvider'
import { ROUTES } from '@/routes'
import { formatPrice } from '@/utils/number'
import { formatKeyFilter } from '@/utils/string'
import { getUserInfoInLine } from '@/utils/user'

type CreateWithdrawalRequestFormData = {
  amount: number
  remark?: string
  type: WithdrawalRequestType
}

const CreateWithdrawalRequest: React.FC = () => {
  const { t } = useTranslation()
  const { registerBreadcrumb, unregisterBreadcrumb } = useApp()
  const navigate = useNavigate()

  const userService = useAPIService('User')

  const [userDropdownOpened, userDropdown] = useDisclosure(false)

  const userRef = useClickOutside(() => {
    userDropdown.close()
  })

  const [userId, setUserId] = useState<string>('')
  const [userKeysearch, setUserKeysearch] = useDebouncedState('', 400)
  const [getListQuery, setSearchQuery] = useState<UserRequests.GetList.Query>({
    pagination: { page: 1, perPage: 20 },
  })

  const { data: users = [], isLoading: isUserLoading } = useQuery({
    queryKey: ['userService', 'getList', 'selectOptions', getListQuery],
    queryFn: () => userService.getList(getListQuery),
    select: ({ data: res }) =>
      res?.data?.map((item) => ({
        ...item,
        label: getUserInfoInLine(item),
      })) || [],
  })

  const { data: userWallet } = useQuery({
    queryKey: ['walletService.getWalletByUserId', userId],
    queryFn: () => {
      const walletService = new WalletApi()

      return walletService.getUserWallet({
        userId,
      })
    },
    enabled: Boolean(userId),
    select: (res) => res.data,
  })

  const { mutate: createWithdrawalRequest, isLoading: isSubmitting } = useMutation({
    mutationFn: (
      data: CreateWithdrawalRequestFormData & {
        userId: string
        type: WithdrawalRequestType
      },
    ) => {
      const walletService = new WalletApi()

      return walletService.boCreateWithdrawalRequest({
        createWithdrawalRequestDTO: omit(data, 'userId'),
        userId: data.userId,
      })
    },
    onSuccess: (res) => {
      navigate(
        ROUTES.ADMIN.ACCOUNTING.WITHDRAWAL_REQUEST_DETAIL.replaceAll(':id', res.data.request_id),
        {
          replace: true,
        },
      )
    },
  })

  const schema = useMemo(
    () =>
      object({
        amount: number()
          .required()
          .min(0, 'withdrawalRequest.errors.invalidAmount')
          .max(userWallet?.balance || 0, 'withdrawalRequest.errors.invalidAmount'),
        type: string().required().oneOf(Object.values(WithdrawalRequestType)),
      }),
    [userWallet?.balance],
  )

  const methods = useForm<CreateWithdrawalRequestFormData>({
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    registerBreadcrumb({
      key: 'accounting',
      label: t('page.accounting'),
    })

    registerBreadcrumb({
      key: 'createWithdrawalRequest',
      label: t('page.createWithdrawalRequest'),
    })

    return () => {
      unregisterBreadcrumb('accounting')
      unregisterBreadcrumb('createWithdrawalRequest')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSearchUser = useCallback(
    (event?: React.ChangeEvent<HTMLInputElement>) => {
      const value = event?.target?.value as string
      setUserKeysearch(value)
      userDropdown.open()
    },
    [userDropdown, setUserKeysearch],
  )

  const onSetGetListQuery = useCallback(() => {
    const likeFilter = formatKeyFilter(userKeysearch)

    const query: GetListParams<Models.User> = {
      pagination: { page: 1, perPage: userKeysearch ? 10 : 0 },
      filter: {
        'op.or': [
          {
            first_name: {
              'op.like': likeFilter,
            },
          },
          {
            last_name: {
              'op.like': likeFilter,
            },
          },
          {
            phone_number: {
              'op.like': likeFilter,
            },
          },
          {
            email: {
              'op.like': likeFilter,
            },
          },
        ],
      },
    }
    setSearchQuery(query)
  }, [userKeysearch])

  useEffect(() => {
    onSetGetListQuery()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userKeysearch])

  const onSelectUser = useCallback(
    (item: Models.User) => {
      setUserId(item.user_id)
      userDropdown.close()
    },
    [userDropdown],
  )

  const onSubmit = useCallback(
    (data: CreateWithdrawalRequestFormData) => {
      if (!userId) return
      createWithdrawalRequest({
        ...data,
        userId,
      })
    },
    [createWithdrawalRequest, userId],
  )

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <Stack>
        <Card>
          <Card.Section inheritPadding py='sm'>
            <Text weight={500}>{t('withdrawalRequest.sections.detailInfo')}</Text>
          </Card.Section>
          <Card.Section inheritPadding py='sm'>
            <Stack>
              <Stack spacing='xs'>
                {/* <Select
                  searchable
                  onSearchChange={onSearchUser}
                  label={t('wallet.user')}
                  value={userId}
                  data={userOptions}
                  onChange={onSelectUser}
                  filter={filterUsers}
                  clearable={false}
                /> */}
                <Box ref={userRef} pos='relative' w='100%'>
                  <TextInput
                    placeholder={t('user.inputUserInfo')}
                    onChange={onSearchUser}
                    onClick={userDropdown.toggle}
                  />
                  <Transition
                    mounted={userDropdownOpened}
                    transition='scale-y'
                    duration={150}
                    timingFunction='ease'
                  >
                    {(styles) => (
                      <Paper
                        pos='absolute'
                        shadow='sm'
                        p={8}
                        my={4}
                        w='100%'
                        style={{ ...styles }}
                        sx={(_theme) => ({
                          border: `1px solid ${_theme.colors.gray[2]}`,
                          borderRadius: '3px',
                          boxShadow: _theme.shadows.sm,
                          maxHeight: '250px',
                          overflowY: 'auto',
                          zIndex: 1000,
                        })}
                      >
                        <Box>
                          <Skeleton visible={isUserLoading}>
                            {users &&
                              users.length > 0 &&
                              users.map((item) => (
                                <Box
                                  key={item.user_id}
                                  sx={(_theme) => ({
                                    cursor: 'pointer',
                                    transition: '0.1s',
                                    '&:hover': {
                                      background: _theme.colors.gray[2],
                                      transition: '0.1s',
                                    },
                                  })}
                                  py={4}
                                  px={8}
                                  onClick={() => onSelectUser(item)}
                                >
                                  <Text>{item.label}</Text>
                                </Box>
                              ))}
                          </Skeleton>
                        </Box>
                      </Paper>
                    )}
                  </Transition>
                </Box>
                {userWallet ? (
                  <Box>
                    <Text>{t('withdrawalRequest.sections.walletInfo')}:</Text>
                    <Text>
                      {t('wallet.balance')}:{' '}
                      <strong>{formatPrice(userWallet.balance, '0', userWallet.currency)}</strong>
                    </Text>
                    <Text>
                      {t('wallet.currency')}: <strong>{userWallet.currency}</strong>
                    </Text>
                  </Box>
                ) : null}
              </Stack>
              {userId ? (
                <>
                  <Input.Wrapper
                    label={t('withdrawalRequest.amount')}
                    error={
                      methods.formState.errors.amount?.message
                        ? t(methods.formState.errors.amount.message)
                        : undefined
                    }
                  >
                    <Input
                      type='number'
                      min={10000}
                      max={userWallet?.balance}
                      {...methods.register('amount')}
                    />
                  </Input.Wrapper>
                  <Textarea
                    label={t('withdrawalRequest.remark')}
                    minRows={4}
                    autosize
                    maxLength={500}
                    {...methods.register('remark')}
                  />
                  <Controller
                    name='type'
                    control={methods.control}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <Select
                        label={t('withdrawalRequest.type')}
                        error={
                          methods.formState.errors.type?.message
                            ? t(methods.formState.errors.type.message)
                            : undefined
                        }
                        data={Object.values(WithdrawalRequestType).map((type) => ({
                          value: type,
                          label: t(`withdrawalRequest.types.${type}`),
                        }))}
                        onBlur={onBlur}
                        value={value}
                        onChange={(val) =>
                          onChange({
                            target: {
                              value: val,
                            },
                          })
                        }
                      />
                    )}
                  />
                </>
              ) : null}
            </Stack>
          </Card.Section>
        </Card>
        <Group position='right'>
          <Button loading={isSubmitting} color='blue' type='submit'>
            {t('common.save')}
          </Button>
        </Group>
      </Stack>
    </form>
  )
}

export default CreateWithdrawalRequest
