import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import { OrderStatus } from '@kicksplanet/enums'
import { OrderResponses } from '@kicksplanet/interfaces'
import { OrderModel } from '@kicksplanet/interfaces/dist/models/order.model'
import { useAPIService } from '@kicksplanet/react/hooks'
import { GetListParams } from '@kicksplanet/services/dist/services/core/CURDService'
import { Card, Skeleton } from '@mantine/core'
import defaultTo from 'lodash/defaultTo'

import Empty from '@/components/Empty'
import ListFilterLayout from '@/components/ListFilterLayout'
import QueryPagination from '@/components/Pagination/QueryPagination'
import TableWrapper from '@/components/TableWrapper'
import { RefundStatusType } from '@/constants/order'
import { DEFAULT_PAGINATION_QUERY } from '@/constants/pagination'
import { useApp } from '@/contexts/AppProvider'
import { ROUTES } from '@/routes'
import { formatKeyFilter } from '@/utils/string'

import OrderListFilter from './Components/Filter'
import OrderListRow from './Components/Row'

const COLUMN_CAPTION = [
  'order.status',
  'common.condition',
  'order.code',
  'order.deliveryInfo',
  'order.deliveryAddress',
  'order.pickupInfo',
  'order.pickupAddress',
  'order.paymentMethod',
  'order.payment',
  'order.shippingFee',
  'order.serviceFee',
  'order.totalAmount',
  'common.action',
]

const OrderList: React.FC = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const orderService = useAPIService('Order')
  const { registerBreadcrumb, unregisterBreadcrumb } = useApp()

  useEffect(() => {
    registerBreadcrumb({
      key: 'orders',
      label: t('page.orders'),
    })

    return () => {
      unregisterBreadcrumb('orders')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const defaultQueryParams = useMemo(() => {
    return {
      page: defaultTo(parseInt(searchParams.get('page') as string), 1),
      perPage: defaultTo(
        parseInt(searchParams.get('per') as string),
        DEFAULT_PAGINATION_QUERY.limit,
      ),
      key: defaultTo(searchParams.get('key') as string, ''),
      status: defaultTo(searchParams.get('status') as string, ''),
      refund: defaultTo(searchParams.get('refund') as string, ''),
      condition: defaultTo(searchParams.get('condition') as string, ''),
    }
  }, [searchParams])

  const {
    page: pageQuery,
    perPage: perPageQuery,
    key: keyQuery,
    status: statusQuery,
    refund: refundQuery,
    condition: conditionQuery,
  } = defaultQueryParams

  const getListQuery = useMemo<GetListParams<OrderModel>>(() => {
    const likeFilter = formatKeyFilter(keyQuery)
    return {
      pagination: {
        page: pageQuery,
        perPage: perPageQuery,
      },
      filter: {
        code: {
          'op.like': likeFilter,
        },
        ...(statusQuery && {
          status: {
            'op.eq': statusQuery,
            ...(refundQuery &&
              refundQuery === RefundStatusType.REFUND.toString() && {
                'op.in': [OrderStatus.CANCELLED, OrderStatus.RETURNED],
              }),
          },
        }),
        ...(refundQuery &&
          refundQuery !== RefundStatusType.ALL.toString() &&
          (refundQuery === RefundStatusType.REFUND.toString()
            ? {
                is_paid: true,
                is_refunded: false,
              }
            : {
                is_refunded: true,
              })),
        ...(conditionQuery && {
          order_item: {
            condition: {
              'op.eq': conditionQuery,
            },
          },
        }),
      },
      sort: {
        field: 'created_at',
        order: 'DESC',
      },
    }
  }, [conditionQuery, keyQuery, pageQuery, perPageQuery, refundQuery, statusQuery])

  const dataSearchQuery: OrderListSearchType = useMemo(() => {
    return {
      ...(keyQuery && { key: keyQuery }),
      ...(statusQuery && { status: statusQuery }),
      ...(refundQuery && { refund: refundQuery }),
      ...(conditionQuery && { condition: conditionQuery }),
    } as OrderListSearchType
  }, [conditionQuery, keyQuery, refundQuery, statusQuery])

  const {
    data: orders,
    isFetching: isLoadingTable,
    refetch: getOrder,
  } = useQuery(['orderService', 'getList', getListQuery], () =>
    orderService.getList(getListQuery).then((res) => res.data),
  )

  const onChangeQueries = useCallback(
    (query: OrderListSearchType) => {
      navigate({
        pathname: ROUTES.ADMIN.ORDER.LIST,
        search: createSearchParams({
          ...query,
        }).toString(),
      })
    },
    [navigate],
  )

  const onRefresh = useCallback(() => {
    getOrder()
  }, [getOrder])

  const RowData = useMemo(() => {
    if (isLoadingTable) {
      return (
        <tr>
          <td colSpan={11}>
            <Skeleton height={10} my={15} radius='md' />
            <Skeleton height={10} my={15} radius='md' />
            <Skeleton height={10} my={15} radius='md' />
          </td>
        </tr>
      )
    }

    if (!orders || !orders.data || orders.data.length <= 0)
      return (
        <tr>
          <td colSpan={11}>
            <Empty />
          </td>
        </tr>
      )

    return orders.data.map((item) => {
      return <OrderListRow key={item.order_id} order={item as OrderResponses.OrderListItem} />
    })
  }, [orders, isLoadingTable])

  return (
    <Card>
      <ListFilterLayout onRefresh={onRefresh}>
        <OrderListFilter
          total={orders?.total}
          queryData={dataSearchQuery}
          onChangeQueries={onChangeQueries}
        />
      </ListFilterLayout>
      <TableWrapper isStickyColumm={Boolean(orders?.data && orders?.data.length > 0)}>
        <thead>
          <tr>
            {COLUMN_CAPTION.map((item, key) => {
              return <th key={key}>{t(item)}</th>
            })}
          </tr>
        </thead>
        <tbody>{RowData}</tbody>
      </TableWrapper>
      <QueryPagination
        limit={perPageQuery}
        page={pageQuery}
        total={orders?.total}
        isShowLimitOptions
      />
    </Card>
  )
}

export default OrderList
