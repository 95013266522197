import { useTranslation } from 'react-i18next'
import { FiPrinter } from 'react-icons/fi'
import { OrderResponses } from '@kicksplanet/interfaces'
import { Button, Card, Divider, Flex, Grid, Text } from '@mantine/core'
import find from 'lodash/find'

import { ShippingProviderData } from '@/constants/shipping'
import { formatAddress } from '@/utils/address'

type OrderDetailSummaryShippingInfoProps = {
  order: OrderResponses.OrderDetails
  isPrintTrackingIDLoading?: boolean
  onPrintTrackingID?: (trackingID: string) => void
}

const OrderDetailSummaryShippingInfo: React.FC<OrderDetailSummaryShippingInfoProps> = ({
  order,
  isPrintTrackingIDLoading = false,
  onPrintTrackingID,
}) => {
  const { t } = useTranslation()

  return (
    <Card h='100%'>
      <Flex justify='space-between' direction='column' h='100%'>
        <Grid gutter={16} mb={30}>
          <Grid.Col>
            <Text weight={600} transform='uppercase'>
              {t('order.shippingHeading')}
            </Text>
            <Divider my={12} color='gray.2' />
          </Grid.Col>
          <Grid.Col>
            <Grid>
              <Grid.Col md={3}>
                <Text weight={600}>{`${t('order.fullName')}: `}</Text>
              </Grid.Col>
              <Grid.Col md={9}>
                <Text>{order.delivery_name || '--'}</Text>
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col md={3}>
                <Text weight={600}>{`${t('order.phoneNumber')}: `}</Text>
              </Grid.Col>
              <Grid.Col md={9}>
                <Text>{order.delivery_phone || '--'}</Text>
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col md={3}>
                <Text weight={600}>{`${t('order.address')}: `}</Text>
              </Grid.Col>
              <Grid.Col md={9}>
                <Text>
                  {formatAddress(
                    order.delivery_address,
                    order.delivery_ward,
                    order.delivery_district,
                    order.delivery_province,
                  ) || '--'}
                </Text>
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col md={3}>
                <Text weight={600}>{`${t('order.shippingProvider')}: `}</Text>
              </Grid.Col>
              <Grid.Col md={9}>
                <Text>
                  {t(
                    find(ShippingProviderData, {
                      code: order.delivery_provider,
                    })?.name || '--',
                  ) || '--'}
                </Text>
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col md={3}>
                <Text weight={600}>{`${t('order.trackingNumber')}: `}</Text>
              </Grid.Col>
              <Grid.Col md={9}>
                <Text>{order.delivery_order_id || '--'}</Text>
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>
        {order.delivery_order_id && (
          <Button
            variant='highlight'
            leftIcon={<FiPrinter />}
            loading={isPrintTrackingIDLoading}
            onClick={() => onPrintTrackingID?.(order.delivery_order_id || '')}
          >
            {t('order.printLabel')}
          </Button>
        )}
      </Flex>
    </Card>
  )
}

export default OrderDetailSummaryShippingInfo
