import { createContext, useContext, useEffect, useState } from 'react'
import { AuthResponses } from '@kicksplanet/interfaces'
import {
  $accessToken,
  $refreshToken,
  AuthService,
  setAccessToken,
  setRefreshToken,
} from '@kicksplanet/services'
import noop from 'lodash/noop'

import { reportError } from '@/utils/logger'

type LoginType = {
  phone: string
  password: string
}

type SessionContextValue = {
  user?: AuthResponses.Login['user']
  isInitialized?: boolean
  loginUser: (req: LoginType) => void
  logoutUser: () => void
}

export const SessionContext = createContext<SessionContextValue>({
  logoutUser: noop,
  loginUser: noop,
})

export const useAuth = () => {
  return useContext(SessionContext)
}

export const STORAGE_KEYS = {
  ACCESS_TOKEN: 'kp_acstkn',
  REFRESH_TOKEN: 'kp_rftkn',
}

const cachedAccessToken = localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) ?? undefined
if (cachedAccessToken) {
  setAccessToken(cachedAccessToken)
}
const cachedRefreshToken = localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN) ?? undefined
if (cachedRefreshToken) {
  setRefreshToken(cachedRefreshToken)
}

$accessToken.subscribe((token) => {
  if (token) {
    localStorage.setItem(STORAGE_KEYS.ACCESS_TOKEN, token)
  } else {
    localStorage.removeItem(STORAGE_KEYS.ACCESS_TOKEN)
  }
})

$refreshToken.subscribe((token) => {
  if (token) {
    localStorage.setItem(STORAGE_KEYS.REFRESH_TOKEN, token)
  } else {
    localStorage.removeItem(STORAGE_KEYS.REFRESH_TOKEN)
  }
})

const SessionProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [isInitialized, setInitialized] = useState<boolean>(false)
  const [user, setUser] = useState<AuthResponses.Login['user'] | undefined>()

  const init = async () => {
    const authService = new AuthService()
    try {
      const {
        data: { user: loggedInUser, accessToken, refreshToken },
      } = await authService.refreshToken()
      setAccessToken(accessToken)
      setRefreshToken(refreshToken)
      setUser(loggedInUser)
    } catch (error) {
      await authService.logout()
      reportError(error)
    } finally {
      setInitialized(true)
    }
  }

  useEffect(() => {
    init()
  }, [])

  const loginUser = async (req: LoginType) => {
    const authService = new AuthService()
    const res = await authService.login({
      phone: req.phone,
      password: req.password,
    })
    const {
      data: { user: loggedInUser },
    } = res
    setUser(loggedInUser)
    return res
  }

  const logoutUser = () => {
    const authService = new AuthService()
    setUser(undefined)
    return authService.logout()
  }

  return (
    <SessionContext.Provider
      value={{
        isInitialized,
        user,
        loginUser,
        logoutUser,
      }}
    >
      {children}
    </SessionContext.Provider>
  )
}

export default SessionProvider
