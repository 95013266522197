import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { CategoryRequests } from '@kicksplanet/interfaces'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Card } from '@mantine/core'
import { notifications } from '@mantine/notifications'

import { useApp } from '@/contexts/AppProvider'
import { ROUTES } from '@/routes'

import CategoryForm from '../Components/CategoryForm'

const CategoryCreate: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const categoryService = useAPIService('Category')

  const { registerBreadcrumb, unregisterBreadcrumb } = useApp()

  useEffect(() => {
    registerBreadcrumb({
      key: 'categories',
      label: t('page.categories'),
      url: ROUTES.ADMIN.CATEGORIES.LIST,
    })

    registerBreadcrumb({
      key: 'addCategory',
      label: t('page.addCategory'),
    })

    return () => {
      unregisterBreadcrumb('categories')
      unregisterBreadcrumb('addCategory')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { mutate: create, isLoading: isCreateLoading } = useMutation(
    (data: CategoryRequests.CategoryUpsertPayload) => {
      return categoryService.create({
        data: {
          ...data,
          parent_id: data.parent_id ? data.parent_id : undefined,
        },
      })
    },
    {
      onSuccess: async () => {
        notifications.show({
          variant: 'success',
          message: t('success.createdCategory'),
        })
        navigate(ROUTES.ADMIN.CATEGORIES.LIST)
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        const errorMessage = t(err?.response?.data, {
          ns: 'backend',
        })

        notifications.show({
          variant: 'danger',
          message: errorMessage,
        })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        err?.response?.data?.errors?.forEach((err: any) => {
          notifications.show({
            variant: 'danger',
            message: t(err, {
              ns: 'backend',
            }),
          })
        })
      },
    },
  )

  const onCreate = useCallback(
    (data: CategoryRequests.CategoryUpsertPayload) => {
      if (!data) return
      create(data)
    },
    [create],
  )

  return (
    <Card>
      <CategoryForm isSubmitLoading={isCreateLoading} onSubmit={onCreate} isParent />
    </Card>
  )
}

export default CategoryCreate
