import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { SizeChartRequests } from '@kicksplanet/interfaces'
import { useAPIService } from '@kicksplanet/react/hooks'
import { notifications } from '@mantine/notifications'
import { OK } from 'http-status'

import { useApp } from '@/contexts/AppProvider'
import { ROUTES } from '@/routes'

import SizeChartForm from '../Components/Form'
import { SizeChartAddSizeType } from '../Components/ListSize'

const SizeChartCreate: React.FC = () => {
  const { t } = useTranslation()
  const sizeChartService = useAPIService('SizeChart')
  const navigate = useNavigate()

  const { registerBreadcrumb, unregisterBreadcrumb } = useApp()

  useEffect(() => {
    registerBreadcrumb({
      key: 'sizeCharts',
      label: t('page.sizeCharts'),
      url: ROUTES.ADMIN.SIZE_CHART.LIST,
    })

    registerBreadcrumb({
      key: 'addSizeChart',
      label: t('page.addSizeChart'),
    })

    return () => {
      unregisterBreadcrumb('sizeCharts')
      unregisterBreadcrumb('addSizeChart')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [isCreateLoading, setIsCreateLoading] = useState<boolean>(false)

  const onCreate = useCallback(
    async (
      sizeChartData: SizeChartRequests.SizeChartUpsertPayload,
      sizeData: SizeChartAddSizeType[],
    ) => {
      setIsCreateLoading(true)

      try {
        const createSizesData = sizeData
          .filter((x) => x.is_new)
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          .map(({ is_new, size_id, ...keepAttrs }) => keepAttrs)

        const { status: createSizeChartStatus, data: createSizeChartData } =
          await sizeChartService.create({
            data: {
              name: sizeChartData.name,
            },
          })

        const {
          data: { size_chart_id },
        } = createSizeChartData

        await sizeChartService.createSize(size_chart_id, {
          data: {
            sizes: createSizesData,
          },
        })

        if (createSizeChartStatus === OK) {
          notifications.show({
            variant: 'success',
            message: t('success.createdSizeChart'),
          })
          navigate(ROUTES.ADMIN.SIZE_CHART.LIST)
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        const errorMessage = t(err?.response?.data, {
          ns: 'backend',
        })

        notifications.show({
          variant: 'danger',
          message: errorMessage,
        })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        err?.response?.data?.errors?.forEach((err: any) => {
          notifications.show({
            variant: 'danger',
            message: t(err, {
              ns: 'backend',
            }),
          })
        })
      } finally {
        setIsCreateLoading(false)
      }
    },
    [navigate, sizeChartService, t],
  )

  return <SizeChartForm isSubmitLoading={isCreateLoading} onSubmit={onCreate} />
}

export default SizeChartCreate
