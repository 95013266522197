import { ReturnReason } from '@kicksplanet/enums'

export type ReturnReasonDataType = {
  code: string
  name: string
}

export const ReturnReasonData: ReturnReasonDataType[] = [
  {
    code: ReturnReason.FAKE_PRODUCT,
    name: 'Hàng giả, hàng nhái',
  },
  {
    code: ReturnReason.SECONDHAND_PRODUCT,
    name: 'Hàng đã qua sử dụng',
  },
  {
    code: ReturnReason.INCORRECT_PRODUCT,
    name: 'Hàng không đúng mô tả',
  },
  {
    code: ReturnReason.ERRORS_ON_PRODUCT,
    name: 'Hàng bị lỗi',
  },
  {
    code: ReturnReason.DAMAGED_PRODUCT,
    name: 'Hàng bị hư hỏng do quá trình vận chuyển',
  },
]
