import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { VoucherApplyFor, VoucherApplyOn, VoucherUnit } from '@kicksplanet/enums'
import { Models, VoucherResponses } from '@kicksplanet/interfaces'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Card } from '@mantine/core'
import { notifications } from '@mantine/notifications'

import { useApp } from '@/contexts/AppProvider'
import { ROUTES } from '@/routes'
import { VoucherUserModel } from '@/types/voucher'

import VoucherForm, { VoucherFormType } from '../Components/VoucherForm'

const VoucherUpdate: React.FC = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const voucherService = useAPIService('Voucher')

  const { registerBreadcrumb, unregisterBreadcrumb } = useApp()

  const [image, setImage] = useState<Models.File | undefined>()
  const [voucherUsers, setVoucherUsers] = useState<VoucherUserModel[]>()

  const { data: voucher } = useQuery(
    ['voucherService', 'getOne', id],
    () => voucherService.getOne({ id: id ? id : '' }).then((res) => res.data.data),
    {
      enabled: Boolean(id?.toString()),
      onSuccess: (data: VoucherResponses.VoucherDetail) => {
        setImage(data.thumbnail_info)
      },
    },
  )

  useEffect(() => {
    registerBreadcrumb({
      key: 'vouchers',
      label: t('page.vouchers'),
      url: ROUTES.ADMIN.VOUCHER.LIST,
    })

    if (voucher) {
      registerBreadcrumb({
        key: voucher.voucher_id,
        label: voucher.code,
      })
    }

    return () => {
      unregisterBreadcrumb('vouchers')
      if (voucher) {
        unregisterBreadcrumb(voucher.voucher_id)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voucher])

  const onSetImage = useCallback((data: Models.File | undefined) => {
    setImage(data)
  }, [])

  const { mutate: update, isLoading: isUpdateLoading } = useMutation(
    (data: VoucherFormType) => {
      return voucherService.update({
        id: id?.toString() || '',
        data: {
          data: {
            ...data,
            unit: VoucherUnit[data.unit as VoucherUnit],
            applied_on: VoucherApplyOn[data.applied_on as VoucherApplyOn],
            applicable_for: VoucherApplyFor[data.applicable_for as VoucherApplyFor],
            ...(image && { thumbnail: image.file_id }),
            ...(voucherUsers &&
              data.applicable_for === VoucherApplyFor.INDIVIDUALS && {
                users: voucherUsers.map(({ user_id, quantity }) => ({ user_id, quantity })),
              }),
            quantity: data.quantity === null ? null : data.quantity,
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any,
      })
    },
    {
      onSuccess: async () => {
        notifications.show({
          variant: 'success',
          message: t('success.updateVoucher'),
        })
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        const errorMessage = t(err?.response?.data, {
          ns: 'backend',
        })

        notifications.show({
          variant: 'danger',
          message: errorMessage,
        })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        err?.response?.data?.errors?.forEach((err: any) => {
          notifications.show({
            variant: 'danger',
            message: t(err, {
              ns: 'backend',
            }),
          })
        })
      },
    },
  )

  const onUpdate = useCallback(
    (data: VoucherFormType) => {
      if (!id) return
      update(data)
    },
    [id, update],
  )

  return (
    <Card>
      <VoucherForm
        voucher={voucher}
        isSubmitLoading={isUpdateLoading}
        onSetVoucherUsers={setVoucherUsers}
        image={image}
        onSetImage={onSetImage}
        onSubmit={onUpdate}
      />
    </Card>
  )
}

export default VoucherUpdate
