import { createStyles, Image, Navbar } from '@mantine/core'

import webLogo from '@/assets/images/logo-black-horizon.png'

import SidebarContent from './SidebarContent'

const useStyles = createStyles((_theme) => ({
  navbar: {
    paddingTop: 0,
    background: _theme.colors.gray[0],
    height: 'unset',
  },
  section: {
    padding: 20,
  },
}))

type AppSidebarProps = {
  pathname?: string
}

const AppSidebar: React.FC<AppSidebarProps> = ({ pathname }) => {
  const { classes } = useStyles()

  return (
    <Navbar maw={400} className={classes.navbar}>
      <Navbar.Section className={classes.section}>
        <Image src={webLogo} alt='' mx='auto' />
      </Navbar.Section>

      <Navbar.Section
        className={classes.section}
        sx={{ overflowY: 'auto', overflowX: 'hidden', minHeight: '100%' }}
      >
        <SidebarContent pathname={pathname} />
      </Navbar.Section>
    </Navbar>
  )
}

export default AppSidebar
