import { ChangeEvent, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { HiEye } from 'react-icons/hi'
import { Link } from 'react-router-dom'
import { SellingResponses } from '@kicksplanet/interfaces'
import { Badge, Button, Checkbox, Flex, Text, Tooltip } from '@mantine/core'

import OrderConditionBage from '@/components/ConditionBage'
import { formatPrice } from '@/utils/number'
import { getSellingItemDetailUrl } from '@/utils/url'

type SellingItemListRowProps = {
  selling: SellingResponses.SellingItem
  checkedItems?: SellingResponses.SellingItem[]
  onToggleRow?: (event: ChangeEvent<HTMLInputElement>, item: SellingResponses.SellingItem) => void
}

const SellingItemListRow: React.FC<SellingItemListRowProps> = ({
  selling,
  checkedItems,
  onToggleRow,
}) => {
  const { t } = useTranslation()

  const _onToggleRow = useCallback(
    (event: ChangeEvent<HTMLInputElement>, item: SellingResponses.SellingItem) => {
      onToggleRow?.(event, item)
    },
    [onToggleRow],
  )

  return (
    <>
      <tr>
        <td>
          <Checkbox
            size='sm'
            checked={checkedItems?.includes(selling)}
            onChange={(e) => _onToggleRow(e, selling)}
          />
        </td>
        <td style={{ minWidth: '120px' }}>
          <OrderConditionBage condition={selling.condition} />
        </td>
        <td style={{ minWidth: '360px' }}>
          <Link
            to={getSellingItemDetailUrl(selling.selling_id)}
            style={{ textDecoration: 'none', color: 'black' }}
          >
            <Tooltip label={selling.product_info?.title}>
              <Text weight={600} lineClamp={1} w='fit-content'>
                {selling.product_info?.title}
              </Text>
            </Tooltip>
          </Link>
          <Tooltip label={selling.product_info?.subtitle}>
            <Text lineClamp={1} w='fit-content'>
              {selling.product_info?.subtitle}
            </Text>
          </Tooltip>
        </td>
        <td style={{ minWidth: '160px' }}>
          <Tooltip label={selling.product_info?.code}>
            <Text lineClamp={1} w='fit-content'>
              {selling.product_info?.code}
            </Text>
          </Tooltip>
        </td>
        <td style={{ minWidth: '140px' }}>
          {(() => {
            const value = `EU ${selling.size_info?.EU}`
            return (
              <Tooltip label={value}>
                <Text lineClamp={1} w='fit-content'>
                  {value}
                </Text>
              </Tooltip>
            )
          })()}
        </td>
        <td style={{ minWidth: '140px' }}>
          {(() => {
            const value = formatPrice(selling.price || 0)
            return (
              <Tooltip label={value}>
                <Text lineClamp={1} w='fit-content'>
                  {value}
                </Text>
              </Tooltip>
            )
          })()}
        </td>
        {/* <td style={{ minWidth: '200px' }}>
          {selling.user_info ? (
            (() => {
              const value = getUserFullName(selling.user_info)
              return (
                <Tooltip label={value}>
                  <Text lineClamp={1} w='fit-content'>
                    {value}
                  </Text>
                </Tooltip>
              )
            })()
          ) : (
            <></>
          )}
        </td>
        <td style={{ minWidth: '120px' }}>
          <Tooltip label={selling.user_info?.phone_number}>
            <Text lineClamp={1} w='fit-content'>
              {selling.user_info?.phone_number}
            </Text>
          </Tooltip>
        </td>
        <td style={{ minWidth: '200px' }}>
          <Tooltip label={selling.user_info?.email}>
            <Text lineClamp={1} w='fit-content'>
              {selling.user_info?.email}
            </Text>
          </Tooltip>
        </td> */}
        <td style={{ minWidth: '160px' }}>
          <Badge
            variant='checked-outline'
            p={8}
            sx={(_theme) => ({
              color: _theme.white,
              backgroundColor: selling.is_validated
                ? _theme.colors.success[4]
                : selling.is_rejected
                ? _theme.colors.danger[4]
                : _theme.colors.warning[4],
              borderColor: 'transparent',
            })}
          >
            <Text color='white'>
              {selling.is_validated
                ? t('sellingItem.sellingApproved')
                : selling.is_rejected
                ? t('sellingItem.sellingRejected')
                : t('sellingItem.sellingPending')}
            </Text>
          </Badge>
        </td>
        <td>
          <Flex justify='start' align='center'>
            <Link
              to={getSellingItemDetailUrl(selling.selling_id)}
              style={{ textDecoration: 'none' }}
            >
              <Button variant='subtle' p={10}>
                <HiEye color='red' />
              </Button>
            </Link>
          </Flex>
        </td>
      </tr>
    </>
  )
}

export default SellingItemListRow
