import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Accordion, Box, Flex, Text } from '@mantine/core'

import { DashRoutes, DashRouteType } from '@/routes'

type SidebarContentProps = {
  pathname?: string
}

const SidebarContent: React.FC<SidebarContentProps> = ({ pathname }) => {
  const { t } = useTranslation()

  const menuItem = useCallback(
    (item: DashRouteType) => {
      return (
        <Link to={item.path || '/'} style={{ textDecoration: 'none' }}>
          <Flex
            justify='start'
            align='center'
            gap={2}
            mb={5}
            sx={(_theme) => ({
              borderRadius: '10px',
              background: item.path === pathname ? _theme.colors.primary[2] : '',
              cursor: 'pointer',
              transition: '0.1s',
              '&:hover': {
                background: _theme.colors.primary[2],
                transition: '0.1s',
                '.mantine-Text-root': {
                  color: _theme.white,
                },
                svg: {
                  color: _theme.white,
                },
              },
            })}
          >
            {item.icon ? (
              <Flex
                h={40}
                w={40}
                align='center'
                justify='center'
                sx={(_theme) => ({
                  borderRadius: '40%',
                  svg: {
                    color: item.path === pathname ? _theme.white : _theme.black,
                    fontSize: 16,
                  },
                })}
              >
                {item.icon}
              </Flex>
            ) : null}
            <Text weight={600} color={item.path === pathname ? 'white' : 'gray.5'}>
              {t(item.name)}
            </Text>
          </Flex>
        </Link>
      )
    },
    [pathname, t],
  )

  return (
    <Box>
      {DashRoutes.map((item, index) => {
        return (
          <Box key={index}>
            {item.category ? (
              <Accordion
                defaultValue={pathname?.split('/')?.[2]}
                sx={(_theme) => ({
                  '.mantine-Accordion-control': {
                    padding: '0 0 0 12px',
                  },
                  '.mantine-Accordion-item': {
                    borderColor: _theme.colors.gray[2],
                  },
                  '.mantine-Accordion-label': {
                    paddingTop: 12,
                    paddingBottom: 12,
                  },
                  '.mantine-Accordion-content': {
                    paddingTop: 0,
                    paddingBottom: 0,
                  },
                })}
              >
                <>
                  {item.hideInMenu ? null : (
                    <Accordion.Item key={item.name} value={item.category}>
                      <Accordion.Control icon={item.icon}>
                        <Text weight={600} color='gray.5'>
                          {t(item.name)}
                        </Text>
                      </Accordion.Control>
                      {!item.hideInMenu && item.views ? (
                        <Accordion.Panel>
                          {item.views.map((child, childIndex) => {
                            return child.hideInMenu ? null : (
                              <Box key={childIndex}>{menuItem(child)}</Box>
                            )
                          })}
                        </Accordion.Panel>
                      ) : null}
                    </Accordion.Item>
                  )}
                </>
              </Accordion>
            ) : item.hideInMenu ? null : (
              menuItem(item)
            )}
          </Box>
        )
      })}
    </Box>
  )
}

export default SidebarContent
