import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineCloudUpload } from 'react-icons/ai'
import { BsFiletypeCsv, BsFiletypeXls, BsFiletypeXlsx } from 'react-icons/bs'
import { HiUpload } from 'react-icons/hi'
import { MdCancel } from 'react-icons/md'
import { useMutation } from 'react-query'
import { Box, Flex, Text } from '@mantine/core'
import { Dropzone, MIME_TYPES } from '@mantine/dropzone'
import { notifications } from '@mantine/notifications'
import sortBy from 'lodash/sortBy'

import { formatDate, getLocalDate } from '@/utils/date'

type ProductImportSelectFileProps = {
  currentFile?: File
  onDataProcessed: (data: ProductImportFileType[], file?: File) => void
}

export type ProductImportFileType = {
  index: number
  brand: string
  code: string
  title: string
  color: string
  gender: string
  retail_price: number
  release_date: string
  images: string[]
  size_chart: string
}

const PRODUCT_FILE_FIELD_MAPPIMG = {
  index: 'STT',
  brand: 'BRAND',
  code: 'SKU',
  title: 'NAME',
  color: 'COLOR',
  gender: 'SEX',
  retail_price: 'RETAIL PRICE ($)',
  release_date: 'RELEASE DATE',
  images: 'LINK HÌNH ẢNH',
  size_chart: 'SIZE CHART',
}

const ProductImportSelectFile: React.FC<ProductImportSelectFileProps> = ({
  currentFile,
  onDataProcessed,
}) => {
  const { t } = useTranslation()
  const openRef = useRef<() => void>(null)

  const [file, setFile] = useState<File | undefined>(currentFile)

  useEffect(() => {
    setFile(currentFile)
  }, [currentFile])

  const { mutate: processFile } = useMutation(
    ['sellingService', 'processImportItems'],
    async () => {
      // convert sheet to json
      const buffer = await file?.arrayBuffer()

      const { read, utils } = await import('xlsx')

      const wb = read(buffer, {
        codepage: 65001, // utf-8
      })
      const ws = wb.Sheets[wb.SheetNames[0]]
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const wsData: any[] = utils.sheet_to_json<any>(ws, {
        skipHidden: true,
        blankrows: false,
        dateNF: 'dd/mm/yyyy',
        raw: false,
      })

      const data = wsData.map<ProductImportFileType>((item, index) => {
        return {
          index: index,
          brand: item[PRODUCT_FILE_FIELD_MAPPIMG.brand],
          code: item[PRODUCT_FILE_FIELD_MAPPIMG.code],
          title: item[PRODUCT_FILE_FIELD_MAPPIMG.title],
          color: item[PRODUCT_FILE_FIELD_MAPPIMG.color],
          gender: item[PRODUCT_FILE_FIELD_MAPPIMG.gender],
          retail_price: item[PRODUCT_FILE_FIELD_MAPPIMG.retail_price],
          release_date: formatDate(
            getLocalDate(new Date(item[PRODUCT_FILE_FIELD_MAPPIMG.release_date])),
            'yyyy-MM-dd',
          ).toString(),
          images:
            item[PRODUCT_FILE_FIELD_MAPPIMG.images]?.split('|').map((x: string) => x.trim()) ||
            undefined,
          size_chart: item[PRODUCT_FILE_FIELD_MAPPIMG.size_chart],
        }
      })

      let errorMessage = ''
      if (
        data.some(
          (item) =>
            !item.code ||
            !item.title ||
            !item.brand ||
            !item.size_chart ||
            !item.gender ||
            !item.images ||
            !item.color,
        )
      ) {
        errorMessage = t('error.invalidFile')
      }

      if (errorMessage) {
        notifications.show({
          variant: 'error',
          message: errorMessage,
        })

        const error = {
          message: errorMessage,
          shouldIgnore: true,
        }

        throw error
      }

      return data
    },
    {
      onSuccess: (res: ProductImportFileType[]) => {
        if (!file) return

        onDataProcessed(sortBy(res || [], [(item) => Boolean(item.index)]), file)
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        if (!err.shouldIgnore) reportError(err)
        notifications.show({
          variant: 'error',
          message: t('error.processFile'),
        })

        onDataProcessed([], undefined)
      },
    },
  )

  const onProcessFile = useCallback(async () => {
    if (!file) return
    processFile()
  }, [file, processFile])

  useEffect(() => {
    onProcessFile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file])

  const onDrop = useCallback((data: File[]) => {
    setFile(data[0])
  }, [])

  const FileIcon = useMemo(() => {
    switch (file?.type) {
      case MIME_TYPES.csv:
        return <BsFiletypeCsv size={22} />
      case MIME_TYPES.xls:
        return <BsFiletypeXls size={22} />
      case MIME_TYPES.xlsx:
        return <BsFiletypeXlsx size={22} />
      default:
        return null
    }
  }, [file?.type])

  return (
    <Box>
      <Dropzone
        openRef={openRef}
        onDrop={onDrop}
        accept={[MIME_TYPES.xlsx, MIME_TYPES.xls, MIME_TYPES.csv]}
        multiple={false}
        p={0}
        sx={(_theme) => ({
          height: '100%',
          border: `2px dashed ${_theme.colors.gray[3]}`,
          '.mantine-Dropzone-inner': {
            height: '100%',
          },
        })}
      >
        <Flex
          justify='center'
          align='center'
          gap='xl'
          p={20}
          mih={120}
          direction={{ base: 'column', xs: 'row' }}
          style={{ pointerEvents: 'none' }}
        >
          <Dropzone.Accept>
            <HiUpload />
          </Dropzone.Accept>
          <Dropzone.Reject>
            <MdCancel />
          </Dropzone.Reject>
          <Dropzone.Idle>
            <AiOutlineCloudUpload size={32} />
          </Dropzone.Idle>

          <Flex justify='center' align='center'>
            <Text align='center' size='lg' inline>
              {t('media.dragUpload')}
            </Text>
          </Flex>
        </Flex>
      </Dropzone>

      {file ? (
        <Box my={12}>
          <Flex
            maw={320}
            w='auto'
            gap={16}
            c='green.3'
            align='center'
            px={20}
            py={10}
            sx={(theme) => ({
              borderWidth: 2,
              borderStyle: 'solid',
              borderColor: theme.colors.green[2],
              borderRadius: 2,
            })}
          >
            {FileIcon}
            <Text component='p' fz='sm' m={0} lineClamp={1}>
              {file?.name}
            </Text>
          </Flex>
        </Box>
      ) : null}
    </Box>
  )
}

export default ProductImportSelectFile
