import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { GroupRequests, GroupResponses } from '@kicksplanet/interfaces'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Card } from '@mantine/core'
import { notifications } from '@mantine/notifications'

import { useApp } from '@/contexts/AppProvider'
import { ROUTES } from '@/routes'

import GroupForm, { GroupImageType } from '../Components/GroupForm'

const GroupCreate: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const groupService = useAPIService('Group')

  const [image, setImage] = useState<GroupImageType | undefined>()
  const [productGroups, setProductGroups] = useState<GroupResponses.Group['product_groups']>()

  const { registerBreadcrumb, unregisterBreadcrumb } = useApp()

  useEffect(() => {
    registerBreadcrumb({
      key: 'groups',
      label: t('page.groups'),
      url: ROUTES.ADMIN.GROUP.LIST,
    })

    registerBreadcrumb({
      key: 'addGroup',
      label: t('page.addGroup'),
    })

    return () => {
      unregisterBreadcrumb('groups')
      unregisterBreadcrumb('addGroups')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { mutate: create, isLoading: isCreateLoading } = useMutation(
    (data: GroupRequests.GroupUpsertPayload) => {
      return groupService.create({
        data: {
          ...data,
          thumbnail: image?.thumbnail ? image.thumbnail.file_id : null,
          banner: image?.banner ? image.banner.file_id : null,
          parent_id: data.parent_id ? data.parent_id : undefined,
          ...(productGroups && {
            products: productGroups.map((item) => {
              return { product_id: item.product_id, ordinal: item.ordinal || 0 }
            }),
          }),
        },
      })
    },
    {
      onSuccess: async () => {
        notifications.show({
          variant: 'success',
          message: t('success.createdGroup'),
        })
        navigate(ROUTES.ADMIN.GROUP.LIST)
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        const errorMessage = t(err?.response?.data, {
          ns: 'backend',
        })

        notifications.show({
          variant: 'danger',
          message: errorMessage,
        })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        err?.response?.data?.errors?.forEach((err: any) => {
          notifications.show({
            variant: 'danger',
            message: t(err, {
              ns: 'backend',
            }),
          })
        })
      },
    },
  )

  const onCreate = useCallback(
    (data: GroupRequests.GroupUpsertPayload) => {
      if (!data) return
      create(data)
    },
    [create],
  )

  const onSetImage = useCallback((data: GroupImageType | undefined) => {
    setImage(data)
  }, [])

  return (
    <Card>
      <GroupForm
        isSubmitLoading={isCreateLoading}
        onSubmit={onCreate}
        image={image}
        onSetProductGroups={setProductGroups}
        onSetImage={onSetImage}
        isParent
      />
    </Card>
  )
}

export default GroupCreate
