import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { BrandRequests } from '@kicksplanet/interfaces'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Card } from '@mantine/core'
import { notifications } from '@mantine/notifications'

import { useApp } from '@/contexts/AppProvider'
import { ROUTES } from '@/routes'

import BrandForm, { BrandLogoType } from '../Components/BrandForm'

const BrandCreate: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const brandService = useAPIService('Brand')

  const { registerBreadcrumb, unregisterBreadcrumb } = useApp()

  useEffect(() => {
    registerBreadcrumb({
      key: 'brands',
      label: t('page.brands'),
      url: ROUTES.ADMIN.BRAND.LIST,
    })

    registerBreadcrumb({
      key: 'addBrand',
      label: t('page.addBrand'),
    })

    return () => {
      unregisterBreadcrumb('brands')
      unregisterBreadcrumb('addBrand')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [brandLogo, setBrandLogo] = useState<BrandLogoType | undefined>()

  const onSetBrandLogo = useCallback(
    (data: BrandLogoType | undefined) => {
      if (!data) return
      setBrandLogo(data)
    },
    [setBrandLogo],
  )

  const { mutate: create, isLoading: isCreateLoading } = useMutation(
    (data: BrandRequests.BrandUpsertPayload) => {
      return brandService.create({
        data: {
          ...data,
          ...(brandLogo?.logoOnDark && { thumbnail: brandLogo?.logoOnDark.file_id }),
          ...(brandLogo?.logoOnLight && { thumbnail_on_light: brandLogo?.logoOnLight.file_id }),
        },
      })
    },
    {
      onSuccess: async () => {
        notifications.show({
          variant: 'success',
          message: t('success.createdBrand'),
        })
        navigate(ROUTES.ADMIN.BRAND.LIST)
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        const errorMessage = t(err?.response?.data, {
          ns: 'backend',
        })

        notifications.show({
          variant: 'danger',
          message: errorMessage,
        })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        err?.response?.data?.errors?.forEach((err: any) => {
          notifications.show({
            variant: 'danger',
            message: t(err, {
              ns: 'backend',
            }),
          })
        })
      },
    },
  )

  const onCreate = useCallback(
    (data: BrandRequests.BrandUpsertPayload) => {
      if (!data) return
      create(data)
    },
    [create],
  )

  return (
    <Card>
      <BrandForm
        isSubmitLoading={isCreateLoading}
        brandLogo={brandLogo}
        onSetBrandLogo={onSetBrandLogo}
        onSubmit={onCreate}
      />
    </Card>
  )
}

export default BrandCreate
