import { ReactNode } from 'react'
import { AiFillAccountBook, AiOutlineUser } from 'react-icons/ai'
import { BiLogIn, BiMoneyWithdraw, BiSolidHome } from 'react-icons/bi'
import {
  FaCloudUploadAlt,
  FaFileImport,
  FaFileInvoice,
  FaLayerGroup,
  FaList,
  FaMoneyCheckAlt,
} from 'react-icons/fa'
import { HiPlus } from 'react-icons/hi'
import { MdBrandingWatermark, MdPermMedia, MdSell, MdTableChart } from 'react-icons/md'
import { PiTicketFill } from 'react-icons/pi'
import { RiBillFill } from 'react-icons/ri'
import { SiProducthunt } from 'react-icons/si'
import { TbCategory } from 'react-icons/tb'

// Auth
import SignIn from '@/views/Auth/SignIn'
// Admin
import BrandCreate from '@/views/Brand/Create'
import BrandList from '@/views/Brand/List'
import BrandUpdate from '@/views/Brand/Update'
import CategoryCreate from '@/views/Category/Create'
import CategoryList from '@/views/Category/List'
import CategoryUpdate from '@/views/Category/Update'
import Dashboard from '@/views/Dashboard'
import GroupCreate from '@/views/Group/Create'
import GroupList from '@/views/Group/List'
import GroupUpdate from '@/views/Group/Update'
import MediaList from '@/views/Media/List'
import OrderDetail from '@/views/Order/Detail'
import OrderList from '@/views/Order/List'
import ProductCreate from '@/views/Product/Create'
import ProductDetail from '@/views/Product/Detail'
import ProductImport from '@/views/Product/Import'
import ProductList from '@/views/Product/List'
import ProductUpdate from '@/views/Product/Update'
import VoucherCreate from '@/views/Voucher/Create'
import VoucherList from '@/views/Voucher/List'
import VoucherUpdate from '@/views/Voucher/Update'

import OrderCheckForm from './views/ForControl/Form'
import ForControlList from './views/ForControl/List'
import InvoiceListPage from './views/Invoices'
import SellingItemDetail from './views/SellingItem/Detail'
import SellingItemList from './views/SellingItem/List'
import SizeChartCreate from './views/SizeChart/Create'
import SizeChartList from './views/SizeChart/List'
import SizeChartUpdate from './views/SizeChart/Update'
import UserList from './views/User/List'
import UserUpdate from './views/User/Update'
import CreateWithdrawalRequest from './views/WithdrawalRequest/Create'
import WithdrawalRequestDetail from './views/WithdrawalRequest/Detail'
import WithdrawalRequestList from './views/WithdrawalRequest/List'

export const ROUTES = {
  ADMIN: {
    DASHBOARD: {
      INDEX: '/admin/dashboard',
    },
    GROUP: {
      PATH: 'groups',
      LIST: '/admin/groups',
      CREATE: '/admin/groups/create',
      UPDATE: '/admin/groups/:id/edit',
    },
    PRODUCT: {
      PATH: 'products',
      LIST: '/admin/products',
      CREATE: '/admin/products/create',
      DETAIL: '/admin/products/:id/detail',
      UPDATE: '/admin/products/:id/edit',
      IMPORT: '/admin/products/import',
    },
    BRAND: {
      PATH: 'brands',
      LIST: '/admin/brands',
      CREATE: '/admin/brands/create',
      UPDATE: '/admin/brands/:id/edit',
    },
    CATEGORIES: {
      PATH: 'categories',
      LIST: '/admin/categories',
      CREATE: '/admin/categories/create',
      UPDATE: '/admin/categories/:id/edit',
    },
    ORDER: {
      PATH: 'orders',
      LIST: '/admin/orders',
      DETAIL: '/admin/orders/:id/detail',
    },
    VOUCHER: {
      PATH: 'vouchers',
      LIST: '/admin/vouchers',
      CREATE: '/admin/vouchers/create',
      UPDATE: '/admin/vouchers/:id/edit',
    },
    MEDIA: {
      PATH: 'files',
      LIST: '/admin/files',
    },
    SELLING_ITEM: {
      PATH: 'selling-items',
      LIST: '/admin/selling-items',
      DETAIL: '/admin/selling-items/:id/detail',
    },
    ACCOUNTING: {
      PATH: 'for-controls',
      LIST: '/admin/for-controls',
      CHECKFORM: '/admin/for-controls/:id',
      WITHDRAWAL_REQUESTS: '/admin/withdrawal-requests',
      CREATE_WITHDRAWAL_REQUEST: '/admin/withdrawal-requests/new',
      WITHDRAWAL_REQUEST_DETAIL: '/admin/withdrawal-requests/:id',
      INVOICES: '/admin/invoices',
    },
    USER: {
      PATH: 'users',
      LIST: '/admin/users',
      UPDATE: '/admin/users/:id/edit',
    },
    SIZE_CHART: {
      PATH: 'size-charts',
      LIST: '/admin/size-charts',
      CREATE: '/admin/size-charts/create',
      UPDATE: '/admin/size-charts/:id/edit',
    },
  },
  AUTH: {
    SIGNIN: '/auth/signin',
  },
}

export type DashRouteType = {
  path?: string
  name: string
  icon?: ReactNode
  component?: React.ReactNode
  layout?: string
  category?: string
  categoryPath?: string
  state?: string
  views?: DashRouteType[]
  collapse?: boolean
  hideInMenu?: boolean
}

export const DashRoutes: DashRouteType[] = [
  {
    path: ROUTES.ADMIN.DASHBOARD.INDEX,
    name: 'page.dashboard',
    icon: <BiSolidHome />,
    component: <Dashboard />,
    layout: '/admin',
  },
  {
    name: 'page.accounting',
    category: 'accounting',
    categoryPath: ROUTES.ADMIN.ACCOUNTING.PATH,
    state: 'pageCollapse',
    icon: <AiFillAccountBook />,
    views: [
      {
        path: ROUTES.ADMIN.ACCOUNTING.LIST,
        name: 'page.forControl',
        icon: <FaMoneyCheckAlt />,
        component: <ForControlList />,
        layout: '/admin',
      },
      {
        path: ROUTES.ADMIN.ACCOUNTING.CHECKFORM,
        name: 'page.orderCheck',
        hideInMenu: true,
        component: <OrderCheckForm />,
        layout: '/admin',
      },
      {
        path: ROUTES.ADMIN.ACCOUNTING.WITHDRAWAL_REQUESTS,
        name: 'page.withdrawalRequests',
        icon: <BiMoneyWithdraw />,
        component: <WithdrawalRequestList />,
        layout: '/admin',
      },
      {
        path: ROUTES.ADMIN.ACCOUNTING.WITHDRAWAL_REQUEST_DETAIL,
        name: 'page.withdrawalRequestDetail',
        hideInMenu: true,
        component: <WithdrawalRequestDetail />,
        layout: '/admin',
      },
      {
        path: ROUTES.ADMIN.ACCOUNTING.CREATE_WITHDRAWAL_REQUEST,
        name: 'page.createWithdrawalRequest',
        hideInMenu: true,
        component: <CreateWithdrawalRequest />,
        layout: '/admin',
      },
      {
        path: ROUTES.ADMIN.ACCOUNTING.INVOICES,
        name: 'page.invoices',
        component: <InvoiceListPage />,
        icon: <FaFileInvoice />,
        layout: '/admin',
      },
    ],
  },
  {
    name: 'page.orders',
    category: 'orders',
    categoryPath: ROUTES.ADMIN.ORDER.PATH,
    state: 'pageCollapse',
    icon: <RiBillFill />,
    views: [
      {
        path: ROUTES.ADMIN.ORDER.LIST,
        name: 'common.list',
        icon: <FaList />,
        component: <OrderList />,
        layout: '/admin',
      },
      {
        path: ROUTES.ADMIN.ORDER.DETAIL,
        name: 'page.orderDetail',
        hideInMenu: true,
        component: <OrderDetail />,
        layout: '/admin',
      },
    ],
  },
  {
    name: 'page.sellingItems',
    category: 'sellingItems',
    categoryPath: ROUTES.ADMIN.SELLING_ITEM.PATH,
    state: 'pageCollapse',
    icon: <MdSell />,
    views: [
      {
        path: ROUTES.ADMIN.SELLING_ITEM.LIST,
        name: 'common.list',
        icon: <FaList />,
        component: <SellingItemList />,
        layout: '/admin',
      },
      {
        path: ROUTES.ADMIN.SELLING_ITEM.DETAIL,
        name: 'page.itemDetail',
        hideInMenu: true,
        component: <SellingItemDetail />,
        layout: '/admin',
      },
    ],
  },
  {
    name: 'page.vouchers',
    category: 'vouchers',
    categoryPath: ROUTES.ADMIN.VOUCHER.PATH,
    state: 'pageCollapse',
    icon: <PiTicketFill />,
    views: [
      {
        path: ROUTES.ADMIN.VOUCHER.LIST,
        name: 'common.list',
        icon: <FaList />,
        component: <VoucherList />,
        layout: '/admin',
      },
      {
        path: ROUTES.ADMIN.VOUCHER.CREATE,
        name: 'page.addVoucher',
        icon: <HiPlus />,
        component: <VoucherCreate />,
        layout: '/admin',
      },
      {
        path: ROUTES.ADMIN.VOUCHER.UPDATE,
        name: 'page.updateVoucher',
        hideInMenu: true,
        component: <VoucherUpdate />,
        layout: '/admin',
      },
    ],
  },
  {
    name: 'page.groups',
    category: 'groups',
    categoryPath: ROUTES.ADMIN.GROUP.PATH,
    state: 'pageCollapse',
    icon: <FaLayerGroup />,
    views: [
      {
        path: ROUTES.ADMIN.GROUP.LIST,
        name: 'common.list',
        icon: <FaList />,
        component: <GroupList />,
        layout: '/admin',
      },
      {
        path: ROUTES.ADMIN.GROUP.CREATE,
        name: 'page.addGroup',
        icon: <HiPlus />,
        component: <GroupCreate />,
        layout: '/admin',
      },
      {
        path: ROUTES.ADMIN.GROUP.UPDATE,
        name: 'page.updateGroup',
        hideInMenu: true,
        component: <GroupUpdate />,
        layout: '/admin',
      },
    ],
  },
  {
    name: 'page.products',
    category: 'products',
    categoryPath: ROUTES.ADMIN.PRODUCT.PATH,
    state: 'pageCollapse',
    icon: <SiProducthunt />,
    views: [
      {
        path: ROUTES.ADMIN.PRODUCT.LIST,
        name: 'common.list',
        icon: <FaList />,
        component: <ProductList />,
        layout: '/admin',
      },
      {
        path: ROUTES.ADMIN.PRODUCT.CREATE,
        name: 'page.addProduct',
        icon: <HiPlus />,
        component: <ProductCreate />,
        layout: '/admin',
      },
      {
        path: ROUTES.ADMIN.PRODUCT.IMPORT,
        name: 'page.importProduct',
        icon: <FaFileImport />,
        component: <ProductImport />,
        layout: '/admin',
      },
      {
        path: ROUTES.ADMIN.PRODUCT.UPDATE,
        name: 'page.updateProduct',
        hideInMenu: true,
        component: <ProductUpdate />,
        layout: '/admin',
      },
      {
        path: ROUTES.ADMIN.PRODUCT.DETAIL,
        name: 'page.productDetail',
        hideInMenu: true,
        component: <ProductDetail />,
        layout: '/admin',
      },
    ],
  },
  {
    name: 'page.sizeCharts',
    category: 'sizeCharts',
    categoryPath: ROUTES.ADMIN.SIZE_CHART.PATH,
    state: 'pageCollapse',
    icon: <MdTableChart />,
    views: [
      {
        path: ROUTES.ADMIN.SIZE_CHART.LIST,
        name: 'common.list',
        icon: <FaList />,
        component: <SizeChartList />,
        layout: '/admin',
      },
      {
        path: ROUTES.ADMIN.SIZE_CHART.CREATE,
        name: 'page.addSizeChart',
        icon: <HiPlus />,
        component: <SizeChartCreate />,
        layout: '/admin',
      },
      {
        path: ROUTES.ADMIN.SIZE_CHART.UPDATE,
        name: 'page.updateSizeChart',
        hideInMenu: true,
        component: <SizeChartUpdate />,
        layout: '/admin',
      },
    ],
  },
  {
    name: 'page.brands',
    category: 'brands',
    categoryPath: ROUTES.ADMIN.BRAND.PATH,
    state: 'pageCollapse',
    icon: <MdBrandingWatermark />,
    views: [
      {
        path: ROUTES.ADMIN.BRAND.LIST,
        name: 'common.list',
        icon: <FaList />,
        component: <BrandList />,
        layout: '/admin',
      },
      {
        path: ROUTES.ADMIN.BRAND.CREATE,
        name: 'page.addBrand',
        icon: <HiPlus />,
        component: <BrandCreate />,
        layout: '/admin',
      },
      {
        path: ROUTES.ADMIN.BRAND.UPDATE,
        name: 'page.updateBrand',
        hideInMenu: true,
        component: <BrandUpdate />,
        layout: '/admin',
      },
    ],
  },
  {
    name: 'page.categories',
    category: 'categories',
    categoryPath: ROUTES.ADMIN.CATEGORIES.PATH,
    state: 'pageCollapse',
    icon: <TbCategory />,
    views: [
      {
        path: ROUTES.ADMIN.CATEGORIES.LIST,
        name: 'common.list',
        icon: <FaList />,
        component: <CategoryList />,
        layout: '/admin',
      },
      {
        path: ROUTES.ADMIN.CATEGORIES.CREATE,
        name: 'page.addCategory',
        icon: <HiPlus />,
        component: <CategoryCreate />,
        layout: '/admin',
      },
      {
        path: ROUTES.ADMIN.CATEGORIES.UPDATE,
        name: 'page.updateCategory',
        hideInMenu: true,
        component: <CategoryUpdate />,
        layout: '/admin',
      },
    ],
  },
  {
    name: 'page.users',
    category: 'users',
    categoryPath: ROUTES.ADMIN.USER.PATH,
    state: 'pageCollapse',
    icon: <AiOutlineUser />,
    views: [
      {
        path: ROUTES.ADMIN.USER.LIST,
        name: 'common.list',
        icon: <FaList />,
        component: <UserList />,
        layout: '/admin',
      },
      {
        path: ROUTES.ADMIN.USER.UPDATE,
        name: 'page.updateUser',
        hideInMenu: true,
        component: <UserUpdate />,
        layout: '/admin',
      },
    ],
  },
  {
    name: 'ACCOUNT PAGES',
    category: 'account',
    categoryPath: ROUTES.AUTH.SIGNIN,
    state: 'pageCollapse',
    hideInMenu: true,
    views: [
      {
        path: ROUTES.AUTH.SIGNIN,
        name: 'Sign In',
        icon: <BiLogIn />,
        component: <SignIn />,
        hideInMenu: true,
        layout: '/auth',
      },
    ],
  },
  {
    name: 'page.media',
    category: 'media',
    categoryPath: ROUTES.ADMIN.MEDIA.PATH,
    state: 'pageCollapse',
    icon: <MdPermMedia />,
    views: [
      {
        path: ROUTES.ADMIN.MEDIA.LIST,
        name: 'page.mediaLibrary',
        icon: <FaCloudUploadAlt />,
        component: <MediaList />,
        layout: '/admin',
      },
    ],
  },
]
