import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BsPlus } from 'react-icons/bs'
import { FaCheckCircle } from 'react-icons/fa'
import { HiOutlineTrash } from 'react-icons/hi'
import { Models } from '@kicksplanet/interfaces'
import { Box, Button, Flex, Grid, Group, Image, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'

import UploadMedia from '../UploadMedia'

type CardImageFormProps = {
  imageData?: Models.File[]
  mainImageData?: Models.File
  isMainImage?: boolean
  controlLabel?: string
  multiple?: boolean
  onSetMainImage?: (data: Models.File) => void
  onSubmit?: (data: Models.File[]) => void
}

const CardImageForm: React.FC<CardImageFormProps> = ({
  imageData = [],
  mainImageData,
  isMainImage,
  controlLabel,
  multiple = false,
  onSetMainImage,
  onSubmit,
}) => {
  const { t } = useTranslation()
  const [images, setImages] = useState<Models.File[]>([])
  const [mainImage, setMainImage] = useState<Models.File>()
  const [uploadMediaOpend, uploadMediaModal] = useDisclosure(false)

  useEffect(() => {
    setImages(imageData)
  }, [imageData])

  useEffect(() => {
    setMainImage(mainImageData)
  }, [mainImageData])

  const onClickItem = useCallback(
    (data: Models.File) => {
      if (multiple) {
        onSetMainImage?.(data)
      } else {
        uploadMediaModal.open()
      }
    },
    [multiple, onSetMainImage, uploadMediaModal],
  )

  const _onSubmit = useCallback(
    (data: Models.File[]) => {
      const updatedImages = [...data, ...images]?.filter(
        (obj, index, self) => index === self.findIndex((t) => t.file_id === obj.file_id),
      )
      onSubmit?.(updatedImages)
    },
    [images, onSubmit],
  )

  const onRemoveFile = useCallback(
    (fileID: string) => {
      const updatedImages = images.filter((x) => x?.file_id !== fileID)
      onSubmit?.(updatedImages)
    },
    [images, onSubmit],
  )

  return (
    <>
      <Grid gutter={8}>
        <Grid.Col>
          <Text weight={600}>{controlLabel ? controlLabel : t('group.image')}</Text>
        </Grid.Col>

        <Grid.Col>
          <Flex justify='start' align='center' gap={8} wrap='wrap'>
            {multiple || (!multiple && images?.length == 0) ? (
              <Box
                onClick={uploadMediaModal.open}
                sx={(_theme) => ({
                  width: 168,
                  height: 168,
                  border: `2px dashed ${_theme.colors.gray[3]}`,
                  borderRadius: 8,
                  backgroundColor: _theme.white,
                  aspectRatio: '1/1',
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: _theme.colors.gray[1],
                  },
                  '.mantine-Dropzone-inner': {
                    height: '100%',
                  },
                })}
              >
                <Group position='center' align='center' h='100%'>
                  <BsPlus size={40} color='gray' />
                </Group>
              </Box>
            ) : null}
            {images && images.length > 0
              ? images.map((item) => {
                  if (item) {
                    return (
                      // <Grid.Col key={item.file_id} xs={6} sm={4} md={3} lg={2}>
                      <Box
                        key={item.file_id}
                        pos='relative'
                        h='100%'
                        sx={(_theme) => ({
                          borderRadius: 8,
                          cursor: 'pointer',
                          border: '1px solid #888',
                          background: _theme.white,
                          aspectRatio: '1/1',
                        })}
                      >
                        <Box onClick={() => onClickItem(item)} h='100%'>
                          <Flex
                            justify='center'
                            align='center'
                            bg='white'
                            sx={(_theme) => ({
                              width: '100%',
                              height: '100%',
                              borderRadius: 8,
                              padding: '0 8px',
                              img: {
                                width: '100%',
                                height: '100%',
                                aspectRatio: '1/1',
                              },
                            })}
                          >
                            <Image src={item.file_path} alt='' height={150} fit='contain' />
                          </Flex>
                        </Box>
                        <Box
                          pos='absolute'
                          sx={{
                            width: 40,
                            height: 40,
                            top: 8,
                            right: 8,
                            zIndex: 2,
                          }}
                        >
                          <Button
                            p={0}
                            sx={{
                              borderRadius: '0 0 2px 2px',
                            }}
                            fullWidth
                            size='xs'
                            onClick={() => onRemoveFile(item.file_id)}
                          >
                            <HiOutlineTrash color='white' size={16} />
                          </Button>
                        </Box>
                        {isMainImage && mainImage && mainImage.file_id === item.file_id ? (
                          <Flex
                            pos='absolute'
                            justify='center'
                            align='center'
                            sx={{
                              width: 40,
                              height: 40,
                              bottom: 0,
                              left: 0,
                              zIndex: 2,
                            }}
                          >
                            <FaCheckCircle
                              color='green'
                              size={24}
                              style={{
                                background: 'white',
                                borderRadius: '50%',
                              }}
                            />
                          </Flex>
                        ) : null}
                      </Box>
                    )
                  }

                  return null
                })
              : null}
          </Flex>
        </Grid.Col>
      </Grid>

      <UploadMedia
        opened={uploadMediaOpend}
        onClose={uploadMediaModal.close}
        onSuccess={_onSubmit}
        multiple={multiple}
      />
    </>
  )
}

export default CardImageForm
