import { CancelReason, OrderStatus, ReturnReason } from '@kicksplanet/enums'

// order status
export type OrderStatusDataType = {
  value: string
  label: string
  color: string
}

export const OrderStatusData: OrderStatusDataType[] = [
  {
    value: OrderStatus.OPEN,
    label: 'order.orderStatus.open',
    color: '#5E7BDF',
  },
  {
    value: OrderStatus.CONFIRMED,
    label: 'order.orderStatus.confirmed',
    color: '#47BB78',
  },
  {
    value: OrderStatus.PICKING,
    label: 'order.orderStatus.picking',
    color: '#747C00',
  },
  {
    value: OrderStatus.CHECKED_IN,
    label: 'order.orderStatus.checkedIn',
    color: '#FFA500',
  },
  {
    value: OrderStatus.AUTHENTICATING,
    label: 'order.orderStatus.authenticating',
    color: '#ADD8E6',
  },
  {
    value: OrderStatus.AUTHENTICATED,
    label: 'order.orderStatus.authenticated',
    color: '#009797',
  },
  {
    value: OrderStatus.REJECTED,
    label: 'order.orderStatus.rejected',
    color: '#FF0000',
  },
  {
    value: OrderStatus.DELIVERING,
    label: 'order.orderStatus.delivering',
    color: '#FF95A8',
  },
  {
    value: OrderStatus.DELIVERED,
    label: 'order.orderStatus.delivered',
    color: '#26EFAF',
  },
  {
    value: OrderStatus.RETURN_REQUESTED,
    label: 'order.orderStatus.return_requested',
    color: '#FFD700',
  },
  {
    value: OrderStatus.RETURNING,
    label: 'order.orderStatus.returning',
    color: '#8B008B',
  },
  {
    value: OrderStatus.RETURNED_TO_BO,
    label: 'order.orderStatus.returnedToBo',
    color: '#8B4513',
  },
  {
    value: OrderStatus.RETURNED,
    label: 'order.orderStatus.returned',
    color: '#8B4513',
  },
  {
    value: OrderStatus.CANCELLED,
    label: 'order.orderStatus.cancelled',
    color: '#A9A9A9',
  },
  {
    value: OrderStatus.COMPLETED,
    label: 'order.orderStatus.completed',
    color: '#008000',
  },
]

export const OrderStatusForControlData: OrderStatusDataType[] = [
  {
    value: OrderStatus.COMPLETED,
    label: 'order.orderStatus.completed',
    color: '#008000',
  },
  {
    value: OrderStatus.RETURNED,
    label: 'order.orderStatus.returned',
    color: '#8B4513',
  },
]

// order cancel reason
export type ReasonOptionType = {
  label: string
  value: ReturnReason | CancelReason
}

export const ReturnReasonOptions: ReasonOptionType[] = [
  {
    label: 'fakeProduct',
    value: ReturnReason.FAKE_PRODUCT,
  },
  {
    label: 'secondhandProduct',
    value: ReturnReason.SECONDHAND_PRODUCT,
  },
  {
    label: 'incorrectProduct',
    value: ReturnReason.INCORRECT_PRODUCT,
  },
  {
    label: 'errorsOnProduct',
    value: ReturnReason.ERRORS_ON_PRODUCT,
  },
  {
    label: 'damageProduct',
    value: ReturnReason.DAMAGED_PRODUCT,
  },
]

export const CancelReasonOptions: ReasonOptionType[] = [
  {
    label: 'updateAddressOrPhone',
    value: CancelReason.UPDATE_ADDR_OR_PHONE,
  },
  {
    label: 'changeVoucher',
    value: CancelReason.CHANGE_VOUCHER,
  },
  {
    label: 'changeProduct',
    value: CancelReason.CHANGE_PRODUCT,
  },
  {
    label: 'alterPayment',
    value: CancelReason.ALTER_PAYMENT,
  },
  // {
  // 	label: 'foundBetterPlace',
  // 	value: CancelReason.FOUND_BETTER_PLACE,
  // },
  {
    label: 'noNeed',
    value: CancelReason.NO_NEED,
  },
  {
    label: 'other',
    value: CancelReason.OTHER,
  },
]

export const enum RefundStatusType {
  ALL = 1,
  REFUND = 2,
  REFUNDED = 3,
}

export type RefundStatusDataType = {
  value: RefundStatusType
  name: string
  colorOutline: string
}

export const RefundStatusData: RefundStatusDataType[] = [
  {
    value: RefundStatusType.ALL,
    name: 'All',
    colorOutline: '#D62E2F',
  },
  {
    value: RefundStatusType.REFUND,
    name: 'Refund',
    colorOutline: '#DBA900',
  },
  {
    value: RefundStatusType.REFUNDED,
    name: 'Refunded',
    colorOutline: '#24B552',
  },
]
