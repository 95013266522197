import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BsPlus } from 'react-icons/bs'
import { HiOutlineTrash } from 'react-icons/hi'
import { TbGridDots } from 'react-icons/tb'
import { useMutation } from 'react-query'
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortableElementProps,
  SortableHandle,
} from 'react-sortable-hoc'
import { Models, SellingResponses } from '@kicksplanet/interfaces'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Box, Flex, Grid, Group, Image, Stack, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { notifications } from '@mantine/notifications'
import { arrayMoveImmutable } from 'array-move'

import GalleryModal from '@/components/GalleryModal'
import UploadMedia from '@/components/UploadMedia'

const DragHandleSellingImageItem = SortableHandle(() => <TbGridDots />)

type SortableSellingImageItemProps = SortableElementProps & {
  item: Models.SellingItemImage & {
    image_info?: Models.File
  }
  onSelectImage: (item: Models.File) => void
  onDelete: (fileID: string) => void
}

const SortableSellingImageItem = SortableElement<SortableSellingImageItemProps>(
  ({ item, onSelectImage, onDelete }: SortableSellingImageItemProps) => {
    const _onSelectImage = () => {
      onSelectImage(item.image_info as Models.File)
    }

    const _onDelete = () => {
      onDelete(item.file_id)
    }

    return (
      <Grid.Col span={3}>
        <Stack
          spacing={8}
          justify='space-between'
          p={4}
          sx={(_theme) => ({
            border: `1px solid ${_theme.colors.gray[3]}`,
            background: _theme.white,
            borderRadius: '8px',
            aspectRatio: '1/1',
          })}
        >
          <Flex justify='space-between' align='center'>
            <DragHandleSellingImageItem />
            <HiOutlineTrash
              color='red'
              onClick={_onDelete}
              style={{ transition: '0.2s ease-in-out' }}
            />
          </Flex>
          <Flex
            justify='center'
            align='center'
            bg='white'
            mx='auto'
            p={{ base: 2, md: 4 }}
            sx={(_theme) => ({
              width: '100%',
              height: '100%',
              borderRadius: '8px',
              transition: '0.2s ease-in-out',
              overflow: 'hidden',
              img: {
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              },
            })}
            onClick={_onSelectImage}
          >
            {item && (
              <Image src={item?.image_info?.image_lg_path || item?.image_info?.file_path || ''} />
            )}
          </Flex>
        </Stack>
      </Grid.Col>
    )
  },
)

type SortableSellingItemImageContainerProps = SortableContainerProps & {
  children: React.ReactNode
}

const SortableSellingItemImageContainer = SortableContainer<SortableSellingItemImageContainerProps>(
  ({ children }: SortableSellingItemImageContainerProps) => {
    return children
  },
)

type SellingItemImagesProps = {
  selling: SellingResponses.SellingItem
  onSetSellingImage?: (data: SellingResponses.SellingItem['selling_item_images']) => void
  onRefresh?: () => void
}

const SellingItemImages: React.FC<SellingItemImagesProps> = ({
  selling,
  onSetSellingImage,
  // onRefresh,
}) => {
  const { t } = useTranslation()

  const sellingService = useAPIService('Selling')

  const [selectedImage, setSeletedImage] = useState<Models.File | null>()
  const [images, setImages] = useState<SellingResponses.SellingItem['selling_item_images']>([])
  const [reorder, setReorder] = useState<boolean>(false)

  const [uploadMediaOpend, uploadMediaModal] = useDisclosure(false)

  const sellingImages = useMemo(() => {
    if (!selling.selling_item_images || selling.selling_item_images?.length === 0) return

    return selling.selling_item_images?.filter((x) => x)
  }, [selling.selling_item_images])

  useEffect(() => {
    setImages(sellingImages || [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellingImages])

  const [galleryOpened, galleryModal] = useDisclosure(false, {
    onClose: () => {
      setSeletedImage(null)
    },
  })

  const onSelectImage = useCallback(
    (image: Models.File | null) => {
      setSeletedImage(image)
      galleryModal.open()
    },
    [galleryModal],
  )

  const onUploadSuccess = useCallback(
    (data: Models.File[]) => {
      const newSellingImage = data
        ?.filter((x) => !images?.map((c) => c.file_id).includes(x.file_id))
        .map((x, index) => {
          return {
            selling_id: selling.selling_id,
            file_id: x.file_id,
            ordinal: (images?.length || 0) + index + 1,
            image_info: x,
            is_new: true,
          }
        })
      const updatedImages = [...(images || []), ...newSellingImage]
      setImages(updatedImages as any)
      onSetSellingImage?.(updatedImages as any)
    },
    [images, onSetSellingImage, selling.selling_id],
  )

  const onDelete = useCallback(
    (fileID: string) => {
      const _images = images
        ?.filter((x) => x.file_id !== fileID)
        ?.map((item, index) => ({
          ...item,
          ordinal: index + 1,
        }))
      setImages(_images)
      onSetSellingImage?.(_images)
    },
    [images, onSetSellingImage],
  )

  // sort handling
  const { mutate: sortImage } = useMutation(
    (data: SellingResponses.SellingItem['selling_item_images']) => {
      return sellingService.sortSellingItemImage({
        selling_item_images: data,
      } as any)
    },
    {
      onSuccess: () => {
        onSetSellingImage?.(images)
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        const errorMessage = t(err?.response?.data, {
          ns: 'backend',
        })

        notifications.show({
          variant: 'danger',
          message: errorMessage,
        })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        err?.response?.data?.errors?.forEach((err: any) => {
          notifications.show({
            variant: 'danger',
            message: t(err, {
              ns: 'backend',
            }),
          })
        })
      },
    },
  )

  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
      if (oldIndex !== newIndex) {
        // const sortResult = arrayMoveImmutable(images || [], oldIndex, newIndex)
        const sortResult = arrayMoveImmutable(images || [], oldIndex, newIndex)

        const updateOrdinal = sortResult.map((item, index) => ({
          ...item,
          ordinal: index + 1,
        }))

        setImages(updateOrdinal)
        setReorder(true)
      }
    },
    [images],
  )

  const onSortImage = useCallback(() => {
    if (reorder) {
      sortImage(images)
      setReorder(false)
    }
  }, [images, reorder, sortImage])

  useEffect(() => {
    onSortImage()
  }, [onSortImage])

  return (
    <>
      <script src='react-sortable-hoc/dist/react-sortable-hoc.umd.js'></script>
      <Stack spacing={4}>
        <Text fw={600}>{t('common.images')}</Text>
        <SortableSellingItemImageContainer onSortEnd={onSortEnd} axis='xy'>
          <Grid gutter={8}>
            {images?.map((item, index) => (
              <SortableSellingImageItem
                key={index}
                index={index}
                item={item}
                onSelectImage={() => onSelectImage(item.image_info as Models.File)}
                onDelete={onDelete}
              />
            ))}
            <Grid.Col span={3}>
              <Box
                onClick={uploadMediaModal.open}
                sx={(_theme) => ({
                  width: '100%',
                  height: '100%',
                  border: `2px dashed ${_theme.colors.gray[3]}`,
                  borderRadius: 8,
                  backgroundColor: _theme.white,
                  cursor: 'pointer',
                  aspectRatio: '1/1',
                  '&:hover': {
                    backgroundColor: _theme.colors.gray[1],
                  },
                  '.mantine-Dropzone-inner': {
                    height: '100%',
                  },
                })}
              >
                <Group position='center' align='center' h='100%'>
                  <BsPlus size={40} color='gray' />
                </Group>
              </Box>
            </Grid.Col>
          </Grid>
        </SortableSellingItemImageContainer>
      </Stack>

      <GalleryModal
        isOpen={galleryOpened}
        onClose={galleryModal.close}
        loop
        imageUrls={sellingImages?.map((x) => {
          if (!x.image_info) return ''
          return x.image_info.image_lg_path || x.image_info.file_path
        })}
        selectedImage={selectedImage?.image_lg_path || selectedImage?.file_path || ''}
      />

      <UploadMedia
        opened={uploadMediaOpend}
        onClose={uploadMediaModal.close}
        onSuccess={onUploadSuccess}
        multiple
      />
    </>
  )
}

export default SellingItemImages
