import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { Link, useParams } from 'react-router-dom'
import { WalletApi } from '@kicksplanet/api-client'
import { WithdrawalRequestStatus } from '@kicksplanet/enums'
import { Models } from '@kicksplanet/interfaces'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Button, Card, Group, LoadingOverlay, Stack, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { uniq } from 'lodash'

import WithdrawalRequestStatusBadge from '@/components/WithdrawalRequestStatusBadge'
import { useApp } from '@/contexts/AppProvider'
import { ROUTES } from '@/routes'
import { formatDate } from '@/utils/date'
import { DATE_FORMATS } from '@/utils/date-format'
import { formatPrice } from '@/utils/number'
import { renderName } from '@/utils/user'

import WithdrawalRejectReasonModal from './WithdrawalRejectReasonModal'

const WithdrawalRequestDetail: React.FC = () => {
  const { t } = useTranslation()
  const { registerBreadcrumb, unregisterBreadcrumb } = useApp()

  const userService = useAPIService('User')
  const params = useParams<{ id: string }>()

  const [rejectReasonModalOpen, rejectReasonModal] = useDisclosure(false)

  useEffect(() => {
    registerBreadcrumb({
      key: 'accounting',
      label: t('page.accounting'),
    })

    registerBreadcrumb({
      key: 'withdrawalRequestDetail',
      label: t('page.withdrawalRequestDetail'),
    })

    return () => {
      unregisterBreadcrumb('accounting')
      unregisterBreadcrumb('withdrawalRequestDetail')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    data: withdrawalRequest,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ['walletService.bo_getWithdrawalRequestById', params.id],
    queryFn: () => {
      const walletService = new WalletApi()

      return walletService.boGetWithdrawalRequestById({
        id: params.id!,
      })
    },
    enabled: Boolean(params.id),
    select: (res) => res.data,
  })

  const { mutate: approveRequest, isLoading: isApproving } = useMutation({
    mutationFn: () => {
      if (!withdrawalRequest) return Promise.reject('No withdrawal id found')

      const walletService = new WalletApi()

      return walletService.approveWithdrawalRequest({
        requestId: withdrawalRequest.request_id,
      })
    },
    onSuccess: () => refetch(),
  })

  const { mutateAsync: rejectRequestAsync, isLoading: isRejecting } = useMutation({
    mutationFn: (reason: string) => {
      if (!withdrawalRequest) return Promise.reject('No withdrawal id found')

      const walletService = new WalletApi()

      return walletService.rejectWithdrawalRequest({
        requestId: withdrawalRequest.request_id,
        rejectWithdrawalRequestDTO: {
          reject_reason: reason,
        },
      })
    },
    onSuccess: () => refetch(),
  })

  const { mutate: markAsPaid, isLoading: isPaying } = useMutation({
    mutationFn: () => {
      if (!withdrawalRequest) return Promise.reject('No withdrawal id found')

      const walletService = new WalletApi()

      return walletService.markPaidWithdrawalRequest({
        requestId: withdrawalRequest.request_id,
      })
    },
    onSuccess: () => refetch(),
  })

  const userIdsFilter = useMemo(
    () => ({
      'op.in': uniq(
        [
          withdrawalRequest?.approved_by,
          withdrawalRequest?.rejected_by,
          withdrawalRequest?.__wallet__?.user_id,
        ].filter(Boolean),
      ),
    }),
    [withdrawalRequest],
  )

  const { data: users } = useQuery({
    queryKey: ['userService.getList'],
    queryFn: () =>
      userService.getList({
        filter: {
          user_id: userIdsFilter,
        },
      }),
    enabled: Boolean(withdrawalRequest),
    select: (res) =>
      res.data.data.reduce<Record<string, Models.User>>((usersMap, user) => {
        usersMap[user.user_id] = user
        return usersMap
      }, {}),
  })

  const onApprove = useCallback(() => {
    approveRequest()
  }, [approveRequest])

  const onReject = useCallback(
    async (reason: string) => {
      await rejectRequestAsync(reason)
      rejectReasonModal.close()
    },
    [rejectReasonModal, rejectRequestAsync],
  )

  const onMarkAsPaid = useCallback(() => {
    markAsPaid()
  }, [markAsPaid])

  if (isFetching) return <LoadingOverlay visible />

  if (!withdrawalRequest) return null

  return (
    <>
      <Stack spacing='md'>
        <Group position='right'>
          {withdrawalRequest.status === WithdrawalRequestStatus.CONFIRMED ? (
            <>
              <Button
                color='red'
                disabled={isApproving || isPaying}
                loading={isRejecting}
                onClick={rejectReasonModal.open}
              >
                {t('withdrawalRequest.actions.reject')}
              </Button>
              <Button
                color='blue'
                disabled={isRejecting || isPaying}
                loading={isApproving}
                onClick={onApprove}
              >
                {t('withdrawalRequest.actions.approve')}
              </Button>
            </>
          ) : null}
          {withdrawalRequest.status === WithdrawalRequestStatus.APPROVED ? (
            <>
              <Button
                color='green'
                disabled={isApproving || isRejecting}
                loading={isPaying}
                onClick={onMarkAsPaid}
              >
                {t('withdrawalRequest.actions.markAsPaid')}
              </Button>
            </>
          ) : null}
        </Group>
        <Card>
          <Card.Section inheritPadding py='sm'>
            <Group position='apart'>
              <Text weight={500}>{t('withdrawalRequest.sections.detailInfo')}</Text>
              <WithdrawalRequestStatusBadge status={withdrawalRequest.status} />
            </Group>
          </Card.Section>
          <Card.Section inheritPadding py='sm'>
            <Stack spacing='xs'>
              <Text>
                {t('withdrawalRequest.code')}: <strong>{withdrawalRequest.code}</strong>
              </Text>
              <Text>
                {t('withdrawalRequest.type')}:{' '}
                <strong>{t(`withdrawalRequest.types.${withdrawalRequest.type}`)}</strong>
              </Text>
              <Text>
                {t('withdrawalRequest.amount')}:{' '}
                <strong>
                  {formatPrice(
                    withdrawalRequest.amount,
                    '',
                    withdrawalRequest.__wallet__?.currency,
                  )}
                </strong>
              </Text>
              <Text>
                {t('withdrawalRequest.created_at')}:{' '}
                <strong>
                  {formatDate(withdrawalRequest.created_at, DATE_FORMATS.HH_MM_AA_DOT_DD_MMMM_YYYY)}
                </strong>
              </Text>
              <Text>
                {t('withdrawalRequest.remark')}: <strong>{withdrawalRequest.remark}</strong>
              </Text>
              {withdrawalRequest.confirmed_at ? (
                <Text>
                  {t('withdrawalRequest.confirmed_at')}:{' '}
                  <strong>
                    {formatDate(
                      withdrawalRequest.confirmed_at,
                      DATE_FORMATS.HH_MM_AA_DOT_DD_MMMM_YYYY,
                    )}
                  </strong>
                </Text>
              ) : null}
              {withdrawalRequest.approved_at ? (
                <Text>
                  {t('withdrawalRequest.approved_at')}:{' '}
                  <strong>
                    {formatDate(
                      withdrawalRequest.approved_at,
                      DATE_FORMATS.HH_MM_AA_DOT_DD_MMMM_YYYY,
                    )}
                  </strong>
                </Text>
              ) : null}
              {withdrawalRequest.approved_by ? (
                <Text>
                  {t('withdrawalRequest.approved_by')}:{' '}
                  <strong>
                    {users?.[withdrawalRequest.approved_by]
                      ? renderName(users?.[withdrawalRequest.approved_by])
                      : null}
                  </strong>
                </Text>
              ) : null}
              {withdrawalRequest.paid_at ? (
                <Text>
                  {t('withdrawalRequest.paid_at')}:{' '}
                  <strong>
                    {formatDate(withdrawalRequest.paid_at, DATE_FORMATS.HH_MM_AA_DOT_DD_MMMM_YYYY)}
                  </strong>
                </Text>
              ) : null}
              {withdrawalRequest.rejected_at ? (
                <Text>
                  {t('withdrawalRequest.rejected_at')}:{' '}
                  <strong>
                    {formatDate(
                      withdrawalRequest.rejected_at,
                      DATE_FORMATS.HH_MM_AA_DOT_DD_MMMM_YYYY,
                    )}
                  </strong>
                </Text>
              ) : null}
              {withdrawalRequest.rejected_by ? (
                <Text>
                  {t('withdrawalRequest.rejected_by')}:{' '}
                  <strong>
                    {users?.[withdrawalRequest.rejected_by]
                      ? renderName(users[withdrawalRequest.rejected_by])
                      : null}
                  </strong>
                </Text>
              ) : null}
            </Stack>
          </Card.Section>
        </Card>
        <Card>
          <Card.Section inheritPadding py='sm'>
            <Group position='apart'>
              <Text weight={500}>{t('withdrawalRequest.sections.walletInfo')}</Text>
            </Group>
          </Card.Section>
          {withdrawalRequest.__wallet__ ? (
            <Card.Section inheritPadding py='sm'>
              <Stack spacing='xs'>
                <Text>
                  {t('wallet.user')}:{' '}
                  <Text
                    weight={700}
                    component={Link}
                    to={ROUTES.ADMIN.USER.UPDATE.replaceAll(
                      ':id',
                      withdrawalRequest.__wallet__.user_id,
                    )}
                  >
                    {users?.[withdrawalRequest.__wallet__.user_id]
                      ? renderName(users[withdrawalRequest.__wallet__.user_id])
                      : null}
                  </Text>
                </Text>
                <Text>
                  {t('wallet.balance')}:{' '}
                  <strong>
                    {formatPrice(
                      withdrawalRequest.__wallet__.balance,
                      '',
                      withdrawalRequest.__wallet__.currency,
                    )}
                  </strong>
                </Text>
                <Text>
                  {t('wallet.currency')}: <strong>{withdrawalRequest.__wallet__.currency}</strong>
                </Text>
              </Stack>
            </Card.Section>
          ) : null}
        </Card>
      </Stack>
      <WithdrawalRejectReasonModal
        opened={rejectReasonModalOpen}
        onClose={rejectReasonModal.close}
        onConfirm={onReject}
        isLoading={isRejecting}
      />
    </>
  )
}

export default WithdrawalRequestDetail
