import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { OrderResponses } from '@kicksplanet/interfaces'
import { Badge, Card, Divider, Grid, Text, useMantineTheme } from '@mantine/core'

import { formatDate } from '@/utils/date'
import { DATE_FORMATS } from '@/utils/date-format'
import { renderName } from '@/utils/user'

type ForControlFormCheckInfoProps = {
  order: OrderResponses.OrderDetails
}

const ForControlFormCheckInfo: React.FC<ForControlFormCheckInfoProps> = ({ order }) => {
  const { t } = useTranslation()
  const theme = useMantineTheme()

  const checkStatus = useMemo(() => {
    if (!order.is_checked)
      return {
        text: 'order.checkStatus.notChecked',
        color: theme.colors.warning[5],
      }
    return {
      text: 'order.checkStatus.checked',
      color: theme.colors.success[3],
    }
  }, [order.is_checked, theme.colors.success, theme.colors.warning])

  return (
    <Card>
      <Grid gutter={16} mb={30}>
        <Grid.Col>
          <Text weight={600} transform='uppercase'>
            {t('forControl.cardTitle.checkInfo')}
          </Text>
          <Divider my={12} color='gray.2' />
        </Grid.Col>
        <Grid.Col>
          <Grid gutter={8} justify='space-between'>
            <Grid.Col md={4}>
              <Text weight={600}>{t('order.status')}</Text>
              <Text>
                <Badge
                  variant='checked-outline'
                  p={8}
                  sx={(_theme) => ({
                    color: _theme.white,
                    backgroundColor: checkStatus.color,
                    borderColor: 'transparent',
                  })}
                >
                  <Text>{t(checkStatus.text)}</Text>
                </Badge>
              </Text>
            </Grid.Col>
            <Grid.Col md={4}>
              <Text weight={600}>{t('forControl.checkedAt')}</Text>
              <Text>
                {order.checked_at
                  ? formatDate(order.checked_at, DATE_FORMATS.HH_MM_AA_DOT_DD_MMMM_YYYY)
                  : '--'}
              </Text>
            </Grid.Col>
            <Grid.Col md={4}>
              <Text weight={600}>{t('forControl.checkedBy')}</Text>
              <Text>{order.check_user ? renderName(order.check_user) : '--'}</Text>
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid>
    </Card>
  )
}

export default ForControlFormCheckInfo
