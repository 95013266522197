import { useCallback, useEffect } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { VoucherApplyFor } from '@kicksplanet/enums'
import { Models, VoucherResponses } from '@kicksplanet/interfaces'
import { Box, Button, Flex, Grid, Input, Select, Textarea } from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import { number, object, string } from 'yup'

import CardImageForm from '@/components/CardImageForm'
import {
  VoucherApplyForData,
  VoucherApplyOnData,
  VoucherAttributeDataType,
  VoucherUnitData,
} from '@/constants/voucher'
import { useSelection } from '@/hooks/useSelection'
import { VoucherUserModel } from '@/types/voucher'

import VoucherUser from '../VoucherUser'

type VoucherFormProps = {
  voucher?: VoucherResponses.VoucherDetail
  isSubmitLoading?: boolean
  onSetVoucherUsers?: (users: VoucherUserModel[]) => void
  image?: Models.File | undefined
  onSetImage?: (data: Models.File | undefined) => void
  onSubmit?: (voucher: VoucherFormType) => void
}

export type VoucherFormType = {
  title: string
  code: string
  value: number
  unit: string
  applied_on: string
  applicable_for: string
  description: string
  valid_from: string
  valid_through: string
  quantity?: number | null
}

const validateSchema = object().shape({
  title: string().max(150, 'voucher.voucherTitleTooLong').required('voucher.missingTitle'),
  code: string().max(150, 'voucher.voucherCodeTooLong').required('voucher.missingCode'),
  value: number().required('voucher.missingValue'),
  unit: string().required('voucher.missingUnit'),
  applied_on: string().required('voucher.missingApplyOn'),
  applicable_for: string().required('voucher.missingApplyFor'),
  description: string(),
  valid_from: string(),
  valid_through: string(),
  quantity: number()
    .transform((value) => (value === null || isNaN(value) ? null : value))
    .nullable()
    .min(0),
})

const VoucherForm: React.FC<VoucherFormProps> = ({
  voucher,
  isSubmitLoading,
  onSetVoucherUsers,
  image,
  onSetImage,
  onSubmit,
}) => {
  const { t } = useTranslation()

  const {
    setValue,
    reset,
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<VoucherFormType>({
    resolver: yupResolver(validateSchema),
    mode: 'all',
  })

  // Default value
  useEffect(() => {
    setDefaultValue()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voucher])

  const setDefaultValue = useCallback(() => {
    if (!voucher) {
      reset()
      return
    }

    setValue('title', voucher.title || '', {
      shouldValidate: true,
    })
    setValue('code', voucher.code || '', {
      shouldValidate: true,
    })
    setValue('value', voucher.value, {
      shouldValidate: true,
    })
    setValue('unit', voucher.unit || '', {
      shouldValidate: true,
    })
    setValue('applied_on', voucher.applied_on || '', {
      shouldValidate: true,
    })
    setValue('applicable_for', voucher.applicable_for || '', {
      shouldValidate: true,
    })
    setValue('valid_from', voucher.valid_from || '', {
      shouldValidate: true,
    })
    setValue('valid_through', voucher.valid_through || '', {
      shouldValidate: true,
    })
    setValue('quantity', voucher.quantity === null ? null : voucher.quantity, {
      shouldValidate: true,
    })
  }, [voucher, setValue, reset])

  // Voucher Unit
  const unit = useWatch({
    name: 'unit',
    control,
  })

  const onSelectVoucherUnitOption = useCallback(
    (item?: VoucherAttributeDataType | null) => {
      setValue('unit', item ? item.value : '', {
        shouldValidate: true,
      })
    },
    [setValue],
  )

  const {
    data: voucherUnitOptions,
    onSelect: onSelectVoucherUnit,
    value: voucherUnitValue,
    filter: voucherUnitFilter,
  } = useSelection<VoucherAttributeDataType>({
    items: VoucherUnitData.map((item) => ({
      ...item,
      label: t(item.label),
    })),
    onSelect: onSelectVoucherUnitOption,
    valueKey: 'value',
    defaultValue: VoucherUnitData?.find((x) => x.value === unit),
  })

  useEffect(() => {
    if (voucher) {
      const data = VoucherUnitData?.find((x) => x.value === voucher.unit)
      if (!data) return
      onSelectVoucherUnitOption(data)
    }

    if (unit) {
      onSelectVoucherUnit(unit)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unit])

  // Voucher Apply On
  const applyOn = useWatch({
    name: 'applied_on',
    control,
  })

  const onSelectVoucherApplyOnOption = useCallback(
    (item?: VoucherAttributeDataType | null) => {
      setValue('applied_on', item ? item.value : '', {
        shouldValidate: true,
      })
    },
    [setValue],
  )

  const {
    data: voucherApplyOnOptions,
    onSelect: onSelectVoucherApplyOn,
    value: voucherApplyOnValue,
    filter: voucherApplyOnFilter,
  } = useSelection<VoucherAttributeDataType>({
    items: VoucherApplyOnData.map((item) => ({
      ...item,
      label: t(item.label),
    })),
    onSelect: onSelectVoucherApplyOnOption,
    valueKey: 'value',
    defaultValue: VoucherApplyOnData?.find((x) => x.value === applyOn),
  })

  useEffect(() => {
    if (voucher) {
      const data = VoucherApplyOnData?.find((x) => x.value === voucher.applied_on)
      if (!data) return
      onSelectVoucherApplyOnOption(data)
    }

    if (applyOn) {
      onSelectVoucherApplyOn(applyOn)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyOn])

  // Voucher Apply For
  const applyFor = useWatch({
    name: 'applicable_for',
    control,
  })

  const onSelectVoucherApplyForOption = useCallback(
    (item?: VoucherAttributeDataType | null) => {
      setValue('applicable_for', item ? item.value : '', {
        shouldValidate: true,
      })
    },
    [setValue],
  )

  const {
    data: voucherApplyForOptions,
    onSelect: onSelectVoucherApplyFor,
    value: voucherApplyForValue,
    filter: voucherApplyForFilter,
  } = useSelection<VoucherAttributeDataType>({
    items: VoucherApplyForData.map((item) => ({
      ...item,
      label: t(item.label),
    })),
    onSelect: onSelectVoucherApplyForOption,
    valueKey: 'value',
    defaultValue: VoucherApplyForData?.find((x) => x.value === applyFor),
  })

  useEffect(() => {
    if (voucher) {
      const data = VoucherApplyForData?.find((x) => x.value === voucher.applicable_for)
      if (!data) return
      onSelectVoucherApplyForOption(data)
    }

    if (applyFor) {
      onSelectVoucherApplyFor(applyFor)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyFor])

  // Valid from
  const validFromDate = useWatch({
    name: 'valid_from',
    control,
  })

  const onChangeValidFromDate = useCallback(
    (date: Date) => {
      setValue('valid_from', date ? date.toString() : '', {
        shouldValidate: true,
      })
    },
    [setValue],
  )

  useEffect(() => {
    if (voucher && voucher.valid_from) {
      onChangeValidFromDate(new Date(voucher.valid_from))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voucher])

  // Valid Through
  const validThroughDate = useWatch({
    name: 'valid_through',
    control,
  })

  const onChangeValidThroughDate = useCallback(
    (date: Date) => {
      setValue('valid_through', date ? date.toString() : '', {
        shouldValidate: true,
      })
    },
    [setValue],
  )

  useEffect(() => {
    if (voucher && voucher.valid_through) {
      onChangeValidThroughDate(new Date(voucher.valid_through))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voucher])

  const onSuccessUploadFile = useCallback(
    (data: Models.File[]) => {
      onSetImage?.(data[0])
    },
    [onSetImage],
  )

  const _onSubmit = useCallback(
    (data: VoucherFormType) => {
      if (!data) return
      onSubmit?.(data)
    },
    [onSubmit],
  )

  return (
    <Box>
      <form>
        <Grid gutter={20}>
          <Grid.Col md={6}>
            <Input.Wrapper
              label={`${t('voucher.voucherTitle')} *`}
              error={errors.title && t(errors.title.message as string)}
            >
              <Input {...register('title')} />
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col md={6}>
            <Input.Wrapper
              label={`${t('voucher.voucherCode')} *`}
              error={errors.code && t(errors.code.message as string)}
            >
              <Input {...register('code')} />
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col md={6}>
            <Input.Wrapper label={t('voucher.voucherValue')}>
              <Input
                type='number'
                {...register('value')}
                error={errors.value && t(errors.value.message as string)}
              />
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col md={6}>
            <Select
              label={t('voucher.voucherUnit')}
              data={voucherUnitOptions}
              value={voucherUnitValue}
              onChange={onSelectVoucherUnit}
              filter={voucherUnitFilter}
              error={!unit && t('voucher.missingUnit')}
              // defaultValue={get(VoucherApplyOnData[0], "value")}
              searchable
            />
          </Grid.Col>
          <Grid.Col>
            <Input.Wrapper label={t('voucher.voucherQuantity')}>
              <Input
                type='number'
                min={0}
                {...register('quantity')}
                error={errors.quantity && t(errors.quantity.message as string)}
              />
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col md={6}>
            <DatePickerInput
              label={t('voucher.voucherValidFrom')}
              valueFormat={'DD/MM/YYYY'}
              clearable
              value={validFromDate ? new Date(validFromDate) : null}
              onChange={onChangeValidFromDate}
            />
          </Grid.Col>
          <Grid.Col md={6}>
            <DatePickerInput
              label={t('voucher.voucherValidThrough')}
              valueFormat={'DD/MM/YYYY'}
              clearable
              value={validThroughDate ? new Date(validThroughDate) : null}
              onChange={onChangeValidThroughDate}
            />
          </Grid.Col>
          <Grid.Col>
            <Select
              label={t('voucher.voucherApplyOn')}
              data={voucherApplyOnOptions}
              value={voucherApplyOnValue}
              onChange={onSelectVoucherApplyOn}
              filter={voucherApplyOnFilter}
              error={!applyOn && t('voucher.missingApplyOn')}
              searchable
            />
          </Grid.Col>
          <Grid.Col>
            <Grid
              px={voucherApplyForValue === VoucherApplyFor.INDIVIDUALS ? 8 : 0}
              sx={(_theme) => ({
                border:
                  voucherApplyForValue === VoucherApplyFor.INDIVIDUALS
                    ? `2px solid ${_theme.colors.gray[2]}`
                    : 'unset',
                borderRadius: '4px',
              })}
            >
              <Grid.Col>
                <Select
                  label={t('voucher.voucherApplyFor')}
                  data={voucherApplyForOptions}
                  value={voucherApplyForValue}
                  onChange={onSelectVoucherApplyFor}
                  filter={voucherApplyForFilter}
                  error={!applyFor && t('voucher.missingApplyFor')}
                  searchable
                />
              </Grid.Col>
              {voucherApplyForValue === VoucherApplyFor.INDIVIDUALS ? (
                <Grid.Col>
                  <VoucherUser voucher={voucher} onSetVoucherUsers={onSetVoucherUsers} />
                </Grid.Col>
              ) : null}
            </Grid>
          </Grid.Col>
          <Grid.Col>
            <Textarea
              label={t('voucher.voucherDescription')}
              autosize
              minRows={2}
              maxRows={4}
              {...register('description')}
            />
          </Grid.Col>
          <Grid.Col>
            <CardImageForm imageData={image ? [image] : []} onSubmit={onSuccessUploadFile} />
          </Grid.Col>
        </Grid>

        <Flex my={40} justify='center' align='center'>
          <Button
            variant='highlight'
            loading={isSubmitLoading}
            disabled={!isValid}
            onClick={handleSubmit(_onSubmit)}
          >
            {t('common.save')}
          </Button>
        </Flex>
      </form>
    </Box>
  )
}

export default VoucherForm
