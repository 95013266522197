import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { Models } from '@kicksplanet/interfaces'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Card } from '@mantine/core'
import { notifications } from '@mantine/notifications'

import { useApp } from '@/contexts/AppProvider'
import { ROUTES } from '@/routes'
import { getProductDetailUrl } from '@/utils/url'

import ProductForm, { ProductFormType } from '../Components/ProductForm'

const ProductUpdate: React.FC = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const productService = useAPIService('Product')

  const { registerBreadcrumb, unregisterBreadcrumb } = useApp()

  const [images, setImages] = useState<(Models.File | undefined)[]>([])

  const { data: product } = useQuery(
    ['productService', 'getOne', id],
    () => productService.getOne({ id: id ? id : '' }).then((res) => res.data.data),
    {
      enabled: Boolean(id?.toString()),
    },
  )

  useEffect(() => {
    registerBreadcrumb({
      key: 'products',
      label: t('page.products'),
      url: ROUTES.ADMIN.PRODUCT.LIST,
    })

    if (product) {
      registerBreadcrumb({
        key: `detail-${product.slug}`,
        label: product.title,
        url: getProductDetailUrl(product.product_id),
      })

      registerBreadcrumb({
        key: 'updateProduct',
        label: t('page.updateProduct'),
      })
    }

    return () => {
      unregisterBreadcrumb('products')
      if (product) {
        unregisterBreadcrumb(`detail-${product.slug}`)
        unregisterBreadcrumb('updateProduct')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product])

  useQuery(
    ['productService', 'getProductImages', product?.product_id],
    () =>
      productService
        .getProductImages({ id: product ? product?.product_id : '' })
        .then((res) => res.data.data),
    {
      enabled: Boolean(product),
      onSuccess: (data) => {
        setImages(data.map((x) => x.image_info))
      },
    },
  )

  const onSetImages = useCallback((data: (Models.File | undefined)[]) => {
    setImages(data)
  }, [])

  const { mutate: update, isLoading: isUpdateLoading } = useMutation(
    (data: ProductFormType) => {
      return productService.update({
        id: id?.toString() || '',
        data: {
          data: {
            ...data,
            images: images.map((x) => x?.file_id),
            retail_price: data.retail_price,
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any,
      })
    },
    {
      onSuccess: async () => {
        notifications.show({
          variant: 'success',
          message: t('success.updateProduct'),
        })
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        const errorMessage = t(err?.response?.data, {
          ns: 'backend',
        })

        notifications.show({
          variant: 'danger',
          message: errorMessage,
        })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        err?.response?.data?.errors?.forEach((err: any) => {
          notifications.show({
            variant: 'danger',
            message: t(err, {
              ns: 'backend',
            }),
          })
        })
      },
    },
  )

  const onUpdate = useCallback(
    (data: ProductFormType) => {
      if (!id) return
      update(data)
    },
    [id, update],
  )

  return (
    <Card>
      <ProductForm
        product={product}
        isSubmitLoading={isUpdateLoading}
        images={images}
        onSetImages={onSetImages}
        onSubmit={onUpdate}
      />
    </Card>
  )
}

export default ProductUpdate
