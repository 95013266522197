import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { OrderResponses } from '@kicksplanet/interfaces'
import { Card, Divider, Grid, Text } from '@mantine/core'

import { formatDate } from '@/utils/date'
import { DATE_FORMATS } from '@/utils/date-format'
import { renderName } from '@/utils/user'

type ForControlFormPackageInfoProps = {
  order: OrderResponses.OrderDetails
  inhouseItem?: OrderResponses.BO_GetInhouseItemBySeller
}

const ForControlFormPackageInfo: React.FC<ForControlFormPackageInfoProps> = ({
  order,
  inhouseItem,
}) => {
  const { t } = useTranslation()

  const inhouseItemData = useMemo(() => {
    return inhouseItem?.data
  }, [inhouseItem?.data])

  const dataRender = useCallback(
    (label: string, value?: string | '') => {
      return (
        <Grid
          pt={12}
          sx={(_theme) => ({
            borderBottom: `1px solid ${_theme.colors.gray[0]}`,
          })}
        >
          <Grid.Col md={2}>
            <Text>{`${t(label)}: `}</Text>
          </Grid.Col>
          <Grid.Col md={10}>
            <Text weight={600}>{value ? value : '--'}</Text>
          </Grid.Col>
        </Grid>
      )
    },
    [t],
  )

  return inhouseItemData && inhouseItemData ? (
    <Card>
      <Grid gutter={16} mb={30}>
        <Grid.Col>
          <Text weight={600} transform='uppercase'>
            {t('forControl.cardTitle.packageInfo')}
          </Text>
          <Divider my={12} color='gray.2' />
        </Grid.Col>
        <Grid.Col>
          <Grid gutter={8} justify='space-between'>
            <Grid.Col>{dataRender('inhouseItem.itemId', inhouseItemData.item_id)}</Grid.Col>
            <Grid.Col>
              {dataRender('inhouseItem.type', t(`inhouseItem.typeValue.${inhouseItemData.type}`))}
            </Grid.Col>
            <Grid.Col>
              {dataRender(
                'inhouseItem.checkedInAt',
                formatDate(inhouseItemData.checked_in_at, DATE_FORMATS.HH_MM_AA_DOT_DD_MMMM_YYYY),
              )}
            </Grid.Col>
            <Grid.Col>
              {dataRender(
                'inhouseItem.checkedInBy',
                inhouseItemData.checked_in_user && renderName(inhouseItemData.checked_in_user),
              )}
            </Grid.Col>
            <Grid.Col>
              {dataRender(
                'inhouseItem.checkedOutAt',
                formatDate(
                  inhouseItemData.checked_out_at as string,
                  DATE_FORMATS.HH_MM_AA_DOT_DD_MMMM_YYYY,
                ),
              )}
            </Grid.Col>
            <Grid.Col>
              {dataRender(
                'inhouseItem.checkedOutBy',
                inhouseItemData.checked_out_user && renderName(inhouseItemData.checked_out_user),
              )}
            </Grid.Col>
            <Grid.Col>
              {dataRender(
                'inhouseItem.seller',
                inhouseItemData.seller && renderName(inhouseItemData.seller),
              )}
            </Grid.Col>
            <Grid.Col>{dataRender('order.pickupOption', order.pickup_option)}</Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid>
    </Card>
  ) : null
}

export default ForControlFormPackageInfo
