import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Models } from '@kicksplanet/interfaces'
import { Badge, Button, Card, Flex, Image, Modal, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'

import { formatBytes } from '@/utils/byte'

import MediaDetail from '../MediaDetail'

type MediaListItemProps = {
  file: Models.File
  onRefresh: () => void
}

const MediaListItem: React.FC<MediaListItemProps> = ({ file, onRefresh }) => {
  const { t } = useTranslation()

  const [mediaDetailOpened, mediaDetailModal] = useDisclosure(false)

  const [dimensions, setDimensions] = useState<{
    width: string
    height: string
  }>()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onLoadImage = useCallback((event?: any) => {
    setDimensions({
      width: event?.target?.naturalWidth,
      height: event?.target?.naturalHeight,
    })
  }, [])

  const onRemove = useCallback(() => {
    onRefresh()
    mediaDetailModal.close()
  }, [mediaDetailModal, onRefresh])

  return (
    <>
      <Card
        shadow='sm'
        padding='sm'
        sx={(_theme) => ({
          borderRadius: '8px',
          border: `1px solid ${_theme.colors.gray[2]}`,
          cursor: 'pointer',
        })}
        onClick={mediaDetailModal.open}
        h='100%'
      >
        <Card.Section>
          <Flex justify='center' align='center' bg='gray.0' p={8}>
            <Image onLoad={onLoadImage} src={file.file_path} height={160} alt='' fit='contain' />
          </Flex>
        </Card.Section>

        <Flex
          justify='space-between'
          mt='md'
          mb='xs'
          direction={{ base: 'row', sm: 'column' }}
          gap={4}
        >
          <Text fw={500} lineClamp={1}>
            {file.file_id.substring(0, 15)}
          </Text>
          <Badge color='pink' variant='light' w='fit-content' radius={4}>
            {file.file_type}
          </Badge>
        </Flex>

        <Text size='sm' c='dimmed'>
          {`${file.mime_type} - ${dimensions?.width}x${dimensions?.height} - ${formatBytes(
            file.file_size,
          )}`}
        </Text>
      </Card>

      <Modal
        title={t('media.mediaDetails')}
        opened={mediaDetailOpened}
        onClose={mediaDetailModal.close}
        size={1000}
      >
        {mediaDetailOpened ? (
          <>
            <MediaDetail file={file} onRemove={onRemove} />
            <Flex justify='end' align='center' mt={20}>
              <Button variant='subtle' onClick={mediaDetailModal.close}>
                {t('common.close')}
              </Button>
            </Flex>
          </>
        ) : null}
      </Modal>
    </>
  )
}

export default MediaListItem
