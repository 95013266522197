import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Button, Grid, Textarea } from '@mantine/core'
import { notifications } from '@mantine/notifications'
import { object, string } from 'yup'

type SellingItemDetailRejectFormProps = {
  sellingIDs: string[]
  onSubmit: () => void
  onRefresh: () => void
}

export type RejectSellingItemFormType = {
  reject_reason: string
}

const rejectSellingItemSchema = object().shape({
  reject_reason: string().required('sellingItem.missingRejectReason'),
})

const SellingItemDetailRejectForm: React.FC<SellingItemDetailRejectFormProps> = ({
  sellingIDs,
  onSubmit,
  onRefresh,
}) => {
  const { t } = useTranslation()
  const sellingService = useAPIService('Selling')

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<RejectSellingItemFormType>({
    resolver: yupResolver(rejectSellingItemSchema),
    defaultValues: {
      reject_reason: '',
    },
    mode: 'onChange',
  })

  const { mutate: rejectSelling, isLoading: rejectSellingLoading } = useMutation(
    (data: RejectSellingItemFormType) => {
      return sellingService.rejectSellingItems({
        data: {
          ids: sellingIDs,
          reject_reason: data.reject_reason,
        },
      })
    },
    {
      onSuccess: () => {
        notifications.show({
          variant: 'success',
          message: t('success.rejectedSellingItem'),
        })
        onSubmit()
        onRefresh()
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        const { response: { data: { errorKey = '', errors = [] } = {} } = {} } = err
        const errorMessage = t(errorKey, {
          ns: 'backend',
        })

        notifications.show({
          variant: 'danger',
          message: errorMessage,
        })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        errors?.forEach((err: any) => {
          notifications.show({
            variant: 'danger',
            message: t(err, {
              ns: 'backend',
            }),
          })
        })
      },
    },
  )

  const onRejectSelling = useCallback(
    (data: RejectSellingItemFormType) => {
      if (!sellingIDs || sellingIDs.length <= 0) return
      rejectSelling(data)
    },
    [sellingIDs, rejectSelling],
  )

  return (
    <form>
      <Grid>
        <Grid.Col>
          <Textarea
            label={t('sellingItem.rejectReason')}
            autosize
            minRows={2}
            maxRows={4}
            error={errors.reject_reason && t(errors.reject_reason.message as string)}
            {...register('reject_reason')}
          />
        </Grid.Col>
        <Grid.Col>
          <Button
            variant='highlight'
            loading={rejectSellingLoading}
            disabled={!isValid}
            onClick={handleSubmit(onRejectSelling)}
            fullWidth
          >
            {t('common.save')}
          </Button>
        </Grid.Col>
      </Grid>
    </form>
  )
}

export default SellingItemDetailRejectForm
