import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { yupResolver } from '@hookform/resolvers/yup'
import { SellingItemCondition } from '@kicksplanet/enums'
import { SellingResponses } from '@kicksplanet/interfaces'
import { useAPIService } from '@kicksplanet/react/hooks'
import {
  Box,
  Button,
  Card,
  Divider,
  Flex,
  Grid,
  Image,
  Mark,
  Stack,
  Text,
  Textarea,
} from '@mantine/core'
import { notifications } from '@mantine/notifications'
import { object, string } from 'yup'

import { formatPrice } from '@/utils/number'

import SellingItemImages from '../Images'

type SellingItemProductInfoProps = {
  selling: SellingResponses.SellingItem
  onRefresh?: () => void
}

const updateSellingSchema = object().shape({
  condition: string(),
  description: string().required('sellingItem.requiredControl.description'),
})

type UpdateSellingType = {
  condition: string
  description: string
}

const SellingItemProductInfo: React.FC<SellingItemProductInfoProps> = ({ selling, onRefresh }) => {
  const { t } = useTranslation()
  const sellingService = useAPIService('Selling')
  const [sellingImages, setSellingImages] = useState<
    SellingResponses.SellingItem['selling_item_images']
  >([])

  useEffect(() => {
    setSellingImages(selling.selling_item_images || [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selling])

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<UpdateSellingType>({
    resolver: yupResolver(updateSellingSchema),
    mode: 'onChange',
    defaultValues: {
      description: selling.description || '',
    },
  })

  const { mutate: update, isLoading: isUpdateLoading } = useMutation(
    (data: UpdateSellingType) => {
      return sellingService.update({
        id: selling?.selling_id || '',
        data: {
          data: {
            description: data.description,
            condition: selling.condition,
            images: sellingImages?.map((x) => {
              return {
                file_id: x.file_id,
                ordinal: x.ordinal,
              }
            }),
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any,
      })
    },
    {
      onSuccess: async () => {
        notifications.show({
          variant: 'success',
          message: t('success.update'),
        })
        onRefresh?.()
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        const errorMessage = t(err?.response?.data, {
          ns: 'backend',
        })

        notifications.show({
          variant: 'danger',
          message: errorMessage,
        })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        err?.response?.data?.errors?.forEach((err: any) => {
          notifications.show({
            variant: 'danger',
            message: t(err, {
              ns: 'backend',
            }),
          })
        })
      },
    },
  )

  const onSubmit = useCallback(
    (data: UpdateSellingType) => {
      update(data)
    },
    [update],
  )

  return (
    <Card h='100%'>
      <Grid gutter={16} mb={30}>
        <Grid.Col>
          <Text weight={600} transform='uppercase'>
            {t('product.productHeading')}
          </Text>
          <Divider my={12} color='gray.2' />
        </Grid.Col>
        <Grid.Col>
          <Flex justify='start' align='start' direction={{ base: 'column', md: 'row' }} gap={20}>
            <Flex
              justify='center'
              align='center'
              bg='white'
              px={40}
              sx={{
                img: {
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                },
              }}
            >
              <Image
                src={selling.product_info?.thumbnail_info?.file_path}
                alt=''
                width={150}
                height={150}
                fit='contain'
              />
            </Flex>
            <Box>
              <Text>
                <Mark variant='bold'>{`${t('product.sku')}: `}</Mark>
                {selling.product_info?.code}
              </Text>
              <Text weight={600} my={4}>
                {`${selling.product_info?.title} (${selling.product_info?.gender})`}
              </Text>
              <Text>{`EU ${selling.size_info?.EU} | ${selling.product_info?.color}`}</Text>
              <Text size='lg' color='primary.4' weight={600}>
                {formatPrice(selling.price)}
              </Text>
            </Box>
          </Flex>
        </Grid.Col>
        {selling.condition === SellingItemCondition.USED ? (
          <Grid.Col>
            <Stack spacing={24}>
              <SellingItemImages
                selling={selling}
                onSetSellingImage={setSellingImages}
                onRefresh={onRefresh}
              />
              <form>
                <Stack>
                  <Textarea
                    label={`${t('sellingItem.description')} *`}
                    placeholder={t('sellingItem.placeholder.description')}
                    minRows={4}
                    size='md'
                    error={errors.description && t(errors.description.message as string)}
                    {...register('description')}
                  />
                  <Button
                    onClick={handleSubmit(onSubmit)}
                    disabled={!isValid}
                    loading={isUpdateLoading}
                  >
                    {t('sellingItem.actions.save')}
                  </Button>
                </Stack>
              </form>
            </Stack>
          </Grid.Col>
        ) : null}
      </Grid>
    </Card>
  )
}

export default SellingItemProductInfo
