import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { GoPencil } from 'react-icons/go'
import { HiOutlineTrash } from 'react-icons/hi'
import { useMutation } from 'react-query'
import { Link } from 'react-router-dom'
import { ProductResponses } from '@kicksplanet/interfaces'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Button, Flex, Text, Tooltip } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { notifications } from '@mantine/notifications'

import ConfirmationModal from '@/components/ModalForm/Confirmation'
import { formatDate } from '@/utils/date'
import { formatNumber } from '@/utils/number'
import { getProductDetailUrl, getProductUpdateUrl } from '@/utils/url'

type ProductListRow = {
  product: ProductResponses.Product
  onRefresh: () => void
}

const ProductListRow: React.FC<ProductListRow> = ({ product, onRefresh }) => {
  const { t } = useTranslation()
  const productService = useAPIService('Product')
  const [removeOpened, removeModal] = useDisclosure(false)

  const { mutate: remove, isLoading: isRemoveLoading } = useMutation(
    (productId: string) => {
      return productService.delete({ id: productId })
    },
    {
      onSuccess: async () => {
        removeModal.close()
        notifications.show({
          title: t('common.removeTitle'),
          variant: 'success',
          message: t('success.deletedProduct'),
        })
        onRefresh()
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        const errorMessage = t(err?.response?.data, {
          ns: 'backend',
        })

        notifications.show({
          variant: 'danger',
          message: errorMessage,
        })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        err?.response?.data?.errors?.forEach((err: any) => {
          notifications.show({
            variant: 'danger',
            message: t(err, {
              ns: 'backend',
            }),
          })
        })
      },
    },
  )

  const _onRemove = useCallback(() => {
    remove(product.product_id)
  }, [product, remove])

  return (
    <>
      <tr>
        <td style={{ minWidth: '360px' }}>
          <Link
            to={getProductDetailUrl(product.product_id)}
            style={{ textDecoration: 'none', color: 'black' }}
          >
            <Tooltip label={product.title}>
              <Text weight={600} lineClamp={1} w='fit-content'>
                {product.title}
              </Text>
            </Tooltip>
          </Link>
          <Tooltip label={product.subtitle}>
            <Text lineClamp={1} w='fit-content'>
              {product.subtitle}
            </Text>
          </Tooltip>
        </td>
        <td style={{ minWidth: '120px' }}>
          <Tooltip label={product.code}>
            <Text lineClamp={1} w='fit-content'>
              {product.code}
            </Text>
          </Tooltip>
        </td>
        <td style={{ minWidth: '160px' }}>
          <Tooltip label={product.color}>
            <Text lineClamp={1} w='fit-content'>
              {product.color}
            </Text>
          </Tooltip>
        </td>
        <td style={{ minWidth: '80px' }}>
          <Tooltip label={product.gender}>
            <Text lineClamp={1} w='fit-content'>
              {product.gender}
            </Text>
          </Tooltip>
        </td>
        <td style={{ minWidth: '140px' }}>
          {(() => {
            const value = `$${formatNumber(product.retail_price || 0)}`
            return (
              <Tooltip label={value}>
                <Text lineClamp={1} w='fit-content'>
                  {value}
                </Text>
              </Tooltip>
            )
          })()}
        </td>
        <td style={{ minWidth: '200px' }}>
          {product.release_date ? (
            (() => {
              const value = formatDate(product.release_date)
              return (
                <Tooltip label={value}>
                  <Text lineClamp={1} w='fit-content'>
                    {value}
                  </Text>
                </Tooltip>
              )
            })()
          ) : (
            <Text>--</Text>
          )}
        </td>
        <td>
          <Flex justify='center' align='center'>
            <Link
              to={getProductUpdateUrl(product.product_id)}
              style={{ textDecoration: 'none', color: 'black' }}
            >
              <Button variant='subtle' p={10}>
                <GoPencil color='black' />
              </Button>
            </Link>
            <Button variant='subtle' p={10} onClick={removeModal.open}>
              <HiOutlineTrash color='red' />
            </Button>
          </Flex>
        </td>
      </tr>

      <ConfirmationModal
        title={t('common.removeTitle')}
        description={t('common.removeText', { title: product.title })}
        isLoading={isRemoveLoading}
        opened={removeOpened}
        onClose={removeModal.close}
        onConfirm={_onRemove}
      />
    </>
  )
}

export default ProductListRow
