import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Card, Skeleton } from '@mantine/core'

import Empty from '@/components/Empty'
import Pagination from '@/components/Pagination/Pagination'
import RefreshButton from '@/components/RefreshButton'
import TableWrapper from '@/components/TableWrapper'
import { DEFAULT_PAGINATION_QUERY } from '@/constants/pagination'
import { useApp } from '@/contexts/AppProvider'

import CategoryListRow from './Components/Row'

const COLUMN_CAPTION = [
  'category.categoryName',
  'category.categorySlug',
  'category.subCategories',
  'common.action',
]

const CategoryList: React.FC = () => {
  const { t } = useTranslation()
  const categoryService = useAPIService('Category')

  const { registerBreadcrumb, unregisterBreadcrumb } = useApp()

  const [page, setPage] = useState<number>(1)

  useEffect(() => {
    registerBreadcrumb({
      key: 'categories',
      label: t('page.categories'),
    })

    return () => {
      unregisterBreadcrumb('categories')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    data: categories,
    isFetching: isLoadingTable,
    refetch: getCategories,
  } = useQuery(['categoryService', 'getList', DEFAULT_PAGINATION_QUERY.limit, page], () =>
    categoryService
      .getList({
        pagination: { page: 1, perPage: DEFAULT_PAGINATION_QUERY.limit },
      })
      .then((res) => res.data),
  )

  const onRefresh = useCallback(() => {
    getCategories()
  }, [getCategories])

  useEffect(() => {
    getCategories()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  const onChangePage = useCallback((page: number) => {
    setPage(page)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  const RowData = useMemo(() => {
    if (isLoadingTable) {
      return (
        <tr>
          <td colSpan={4}>
            <Skeleton height={10} my={15} radius='md' />
            <Skeleton height={10} my={15} radius='md' />
            <Skeleton height={10} my={15} radius='md' />
          </td>
        </tr>
      )
    }

    if (!categories || !categories.data || categories.data.length <= 0)
      return (
        <tr>
          <td colSpan={4}>
            <Empty />
          </td>
        </tr>
      )

    return categories.data.map((item) => {
      return <CategoryListRow key={item.category_id} category={item} onRefresh={onRefresh} />
    })
  }, [categories, isLoadingTable, onRefresh])

  return (
    <Card>
      <RefreshButton onRefresh={onRefresh} />
      <TableWrapper isStickyColumm={Boolean(categories?.data && categories?.data.length > 0)}>
        <thead>
          <tr>
            {COLUMN_CAPTION.map((item, key) => {
              return <th key={key}>{t(item)}</th>
            })}
          </tr>
        </thead>
        <tbody>{RowData}</tbody>
      </TableWrapper>
      <Pagination
        limit={DEFAULT_PAGINATION_QUERY.limit}
        page={page}
        total={categories?.total}
        onChange={onChangePage}
      />
    </Card>
  )
}

export default CategoryList
