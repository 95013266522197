import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { GoPencil } from 'react-icons/go'
import { HiOutlineTrash } from 'react-icons/hi'
import { useMutation } from 'react-query'
import { Link } from 'react-router-dom'
import { CategoryResponses } from '@kicksplanet/interfaces'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Button, Flex, Text, Tooltip } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { notifications } from '@mantine/notifications'

import ConfirmationModal from '@/components/ModalForm/Confirmation'
import { getCategoryUpdateUrl } from '@/utils/url'

type CategoryListRowProps = {
  category: CategoryResponses.Category
  onRefresh: () => void
}

const CategoryListRow: React.FC<CategoryListRowProps> = ({ category, onRefresh }) => {
  const { t } = useTranslation()
  const cateogryService = useAPIService('Category')
  const [removeOpened, removeModal] = useDisclosure(false)

  const getSubCategories = useMemo(() => {
    if (!category.sub_categories || category.sub_categories.length <= 0) return ''
    return category.sub_categories.map((x) => x.name).join(', ')
  }, [category.sub_categories])

  const { mutate: remove, isLoading: isRemoveLoading } = useMutation(
    (categoryID: string) => {
      return cateogryService.delete({ id: categoryID })
    },
    {
      onSuccess: async () => {
        removeModal.close()
        notifications.show({
          title: t('common.removeTitle'),
          variant: 'success',
          message: t('success.deletedCategory'),
        })
        onRefresh()
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        const errorMessage = t(err?.response?.data, {
          ns: 'backend',
        })

        notifications.show({
          variant: 'danger',
          message: errorMessage,
        })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        err?.response?.data?.errors?.forEach((err: any) => {
          notifications.show({
            variant: 'danger',
            message: t(err, {
              ns: 'backend',
            }),
          })
        })
      },
    },
  )

  const _onRemove = useCallback(() => {
    if (!category.category_id) return
    remove(category.category_id)
  }, [category.category_id, remove])

  return (
    <>
      <tr>
        <td style={{ minWidth: '160px' }}>
          <Tooltip label={category.name}>
            <Text weight={600} lineClamp={1} w='fit-content'>
              {category.name}
            </Text>
          </Tooltip>
        </td>
        <td style={{ minWidth: '160px' }}>
          <Tooltip label={category.slug}>
            <Text weight={600} lineClamp={1} w='fit-content'>
              {category.slug}
            </Text>
          </Tooltip>
        </td>
        <td style={{ minWidth: '160px' }}>
          <Tooltip label={getSubCategories}>
            <Text weight={600} lineClamp={1} w='fit-content'>
              {getSubCategories}
            </Text>
          </Tooltip>
        </td>
        <td>
          <Flex justify='start' align='center'>
            <Link
              to={getCategoryUpdateUrl(category.category_id)}
              style={{ textDecoration: 'none' }}
            >
              <Button variant='subtle' p={10}>
                <GoPencil color='black' />
              </Button>
            </Link>
            <Button variant='subtle' p={10} onClick={removeModal.open}>
              <HiOutlineTrash color='red' />
            </Button>
          </Flex>
        </td>
      </tr>

      <ConfirmationModal
        title={t('common.removeTitle')}
        description={t('common.removeText', { title: category.name })}
        isLoading={isRemoveLoading}
        opened={removeOpened}
        onClose={removeModal.close}
        onConfirm={_onRemove}
      />
    </>
  )
}

export default CategoryListRow
