import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BiSearch } from 'react-icons/bi'
import { Button, Grid, Select, Text, TextInput } from '@mantine/core'
import { useDebouncedState } from '@mantine/hooks'
import get from 'lodash/get'

import { ActiveStatusData, ActiveStatusDataType } from '@/constants/user'
import { useSelection } from '@/hooks/useSelection'
import { cleanAccents } from '@/utils/string'

type UserListFilterProps = {
  queryData: UserListSearchType
  onChangeQueries: (query: UserListSearchType) => void
}

const UserListFilter: React.FC<UserListFilterProps> = ({ queryData, onChangeQueries }) => {
  const { t } = useTranslation()

  const [keysearch, setKeysearch] = useDebouncedState(queryData.key, 400)
  const [selectedActiveStatus, setSelectedActiveStatus] = useState<ActiveStatusDataType | null>()

  const onSetKeysearch = useCallback(
    async (event?: React.ChangeEvent<HTMLInputElement>) => {
      const value = event?.target?.value as string
      setKeysearch(value)
    },
    [setKeysearch],
  )

  // Active Status
  const onSelectActiveStatusOption = useCallback((item?: ActiveStatusDataType | null) => {
    setSelectedActiveStatus(item)
  }, [])

  const {
    data: activeStatusOptions,
    onSelect: onSelectActiveStatus,
    value: activeStatusValue,
  } = useSelection<ActiveStatusDataType>({
    items: ActiveStatusData.map((item) => ({
      ...item,
      label: t(item.label),
    })),
    onSelect: onSelectActiveStatusOption,
    valueKey: 'value',
    allOption: true,
  })

  useEffect(() => {
    if (queryData.active) {
      const data = ActiveStatusData.find((x) => x.value.toString() == queryData.active)
      onSelectActiveStatus(data?.value as any)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData.active])

  const _onChangeQueries = useCallback(() => {
    const result = {
      ...(keysearch ? { key: cleanAccents(keysearch) } : ''),
      ...(selectedActiveStatus ? { active: selectedActiveStatus.value.toString() } : {}),
    }

    onChangeQueries(result)
  }, [keysearch, onChangeQueries, selectedActiveStatus])

  return (
    <Grid gutter={16} align='end'>
      <Grid.Col md={3}>
        <TextInput
          label={t('common.keyword')}
          icon={<BiSearch size='1rem' />}
          placeholder={t('user.searchByKeyword')}
          onChange={onSetKeysearch}
          defaultValue={keysearch}
        />
      </Grid.Col>
      <Grid.Col md={3}>
        <Select
          label={t('user.chooseStatus')}
          placeholder={t('user.chooseStatus')}
          data={activeStatusOptions}
          value={activeStatusValue}
          onChange={onSelectActiveStatus}
          defaultValue={get(activeStatusOptions[0], 'value')}
        />
      </Grid.Col>
      <Grid.Col md={2}>
        <Button color='success.4' onClick={_onChangeQueries}>
          <Text>{t('common.searchButton')}</Text>
        </Button>
      </Grid.Col>
    </Grid>
  )
}

export default UserListFilter
