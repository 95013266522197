import { format, getTime } from 'date-fns'
import { enUS, vi } from 'date-fns/locale'
import { utcToZonedTime } from 'date-fns-tz'
import Cookies from 'universal-cookie'

import { DATE_FORMATS } from './date-format'

export function getLocalDate(date: Date | number | string) {
  return utcToZonedTime(date, 'Asia/Saigon')
}

export function formatDate(date?: Date | number | string, dateFormat = DATE_FORMATS.DEFAULT) {
  if (!date) return ''

  const cookies = new Cookies()
  const currentLanguageCode = cookies.get('i18next') || 'en'

  const lang = currentLanguageCode === 'en' ? enUS : vi

  try {
    const localDate = getLocalDate(date)
    return format(new Date(localDate), dateFormat, {
      locale: lang,
    })
  } catch (err) {
    return ''
  }
}

export function convertDateToTimestamp(date: Date) {
  return getTime(new Date(date))
}
