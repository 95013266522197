import { setGlobalAxiosInstance } from '@kicksplanet/api-client'
import { $accessToken } from '@kicksplanet/services'
import axios from 'axios'

import { APP_CONFIG } from './configs'

const axiosInstance = axios.create({
  baseURL: APP_CONFIG.API_V2_ROOT,
})

axiosInstance.interceptors.request.use(async (config) => {
  config.headers = config.headers ?? {}
  config.headers['x-app-id'] = APP_CONFIG.APP_ID ?? ''

  const accessToken = $accessToken.getValue()
  if (accessToken) {
    config.headers['authorization'] = `bearer ${accessToken}`
  }
  return config
})

export function setupApiClient() {
  setGlobalAxiosInstance(axiosInstance as any)
}
