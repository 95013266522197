import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { BiUserCircle } from 'react-icons/bi'
import { HiOutlineLogout } from 'react-icons/hi'
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'
import { RiProfileLine } from 'react-icons/ri'
import { Box, Button, Collapse, Flex, Menu, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'

import { useAuth } from '@/contexts/SessionProvider'
import { getDisplayName } from '@/utils/user'

type UserMenuProps = {
  mobile?: boolean
}

const UserMenu: React.FC<UserMenuProps> = ({ mobile = false }) => {
  const { t } = useTranslation()
  const { user, logoutUser } = useAuth()
  const [opened, profileMenu] = useDisclosure(false)

  const UserButton = useMemo(() => {
    return (
      <Button
        variant='subtle'
        p={0}
        rightIcon={<MdOutlineKeyboardArrowDown size={20} />}
        styles={(theme) => ({
          inner: {
            justifyContent: 'space-between',
            color: theme.black,
          },
          root: {
            '&:hover': {
              backgroundColor: theme.colors.gray[2],
            },
          },
        })}
        fullWidth
        onClick={profileMenu.toggle}
      >
        <Flex align='center' gap={6}>
          <BiUserCircle size={25} />
          <Text>{user && getDisplayName(user)}</Text>
        </Flex>
      </Button>
    )
  }, [profileMenu.toggle, user])

  if (!user) return null

  return mobile ? (
    <Box>
      {UserButton}

      <Collapse in={opened}>
        <Box mx={20} my={10}>
          <Button
            fullWidth
            variant='subtle'
            leftIcon={<HiOutlineLogout size={14} />}
            onClick={logoutUser}
            styles={{
              inner: {
                justifyContent: 'start',
              },
            }}
            color={'dark'}
          >
            {t('common.logoutButton')}
          </Button>
        </Box>
      </Collapse>
    </Box>
  ) : (
    <Menu trigger={mobile ? 'click' : 'hover'} defaultOpened={mobile ? true : false}>
      <Menu.Target>{UserButton}</Menu.Target>

      <Menu.Dropdown>
        <Menu.Item icon={<RiProfileLine size={14} />}>{t('common.viewProfile')}</Menu.Item>
        <Menu.Divider />
        <Menu.Item icon={<HiOutlineLogout size={14} />} onClick={logoutUser}>
          {t('common.logoutButton')}
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}

export default UserMenu
