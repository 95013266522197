import { useTranslation } from 'react-i18next'
import { ReturnStatus } from '@kicksplanet/enums'
import { OrderResponses } from '@kicksplanet/interfaces'
import { Box, Button, Card, Divider, Flex, Grid, Image, Modal, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import find from 'lodash/find'

import { ReturnReasonData } from '@/constants/returnReason'
import { formatDate } from '@/utils/date'
import { DATE_FORMATS } from '@/utils/date-format'

import OrderApproveReturnRequestForm from '../ApproveReturnRequestForm'
import OrderRejectReturnRequestForm from '../RejectReturnRequestForm'

type OrderDetailReturnInfoProps = {
  order: OrderResponses.OrderDetails
  onRefresh: () => void
}

const OrderDetailReturnInfo: React.FC<OrderDetailReturnInfoProps> = ({ order, onRefresh }) => {
  const { t } = useTranslation()
  const [rejectRequestOpened, rejectRequestModal] = useDisclosure(false)
  const [approveReturnOpened, approveReturnModal] = useDisclosure(false)

  return (
    <>
      <Card>
        <Grid>
          <Grid.Col>
            <Flex justify='space-between' direction={{ base: 'column', md: 'row' }}>
              <Text weight={600} transform='uppercase'>
                {t('order.returnOrderHeading')}
              </Text>
              {order.order_return_info?.status === ReturnStatus.NEW ? (
                <Flex
                  justify='end'
                  align='center'
                  gap={20}
                  direction={{ base: 'column', md: 'row' }}
                >
                  <Button variant='subtle' fullWidth onClick={rejectRequestModal.toggle}>
                    {t('order.reject')}
                  </Button>
                  <Button variant='highlight' onClick={approveReturnModal.open} fullWidth>
                    {t('order.approve')}
                  </Button>
                </Flex>
              ) : null}
            </Flex>
            <Divider my={12} color='gray.2' />
          </Grid.Col>
          <Grid.Col>
            <Grid>
              <Grid.Col md={3}>
                <Text weight={600}>{`${t('order.reason')}: `}</Text>
              </Grid.Col>
              <Grid.Col md={9}>
                <Text weight={600} transform='uppercase'>
                  {find(ReturnReasonData, {
                    code: order.order_return_info?.return_reason,
                  })?.name || '--'}
                </Text>
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col md={3}>
                <Text weight={600}>{`${t('order.note')}: `}</Text>
              </Grid.Col>
              <Grid.Col md={9}>
                <Text>
                  {find(ReturnReasonData, {
                    code: order.order_return_info?.note,
                  })?.name || '--'}
                </Text>
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col md={3}>
                <Text weight={600}>{`${t('common.createdAt')}: `}</Text>
              </Grid.Col>
              <Grid.Col md={9}>
                <Text>
                  {order.created_at
                    ? formatDate(order.created_at, DATE_FORMATS.HH_MM_AA_DOT_DD_MMMM_YYYY)
                    : '--'}
                </Text>
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col md={3}>
                <Text weight={600}>{`${t('common.updatedAt')}: `}</Text>
              </Grid.Col>
              <Grid.Col md={9}>
                <Text>
                  {order.updated_at
                    ? formatDate(order.updated_at, DATE_FORMATS.HH_MM_AA_DOT_DD_MMMM_YYYY)
                    : '--'}
                </Text>
              </Grid.Col>
            </Grid>
          </Grid.Col>
          <Grid.Col md={3}>
            <Text weight={600}>{`${t('order.attachments')}: `}</Text>
          </Grid.Col>
          <Grid.Col>
            <Grid>
              {order.order_return_info?.attachments
                ? order.order_return_info?.attachments.map((item) => {
                    if (item) {
                      return (
                        <Grid.Col key={item.file_id} xs={6} sm={4} md={3} lg={2}>
                          <Box pos='relative' h='100%' sx={{ borderRadius: '2px' }}>
                            <Flex
                              justify='center'
                              align='center'
                              bg='white'
                              py={16}
                              sx={(_theme) => ({
                                width: '100%',
                                height: '100%',
                                borderRadius: '2px',
                                background: _theme.colors.gray[3],
                                img: {
                                  width: '100%',
                                  height: '100%',
                                  borderRadius: '2px',
                                  objectFit: 'contain',
                                },
                              })}
                            >
                              <Image src={item.file?.file_path} alt='' height={150} />
                            </Flex>
                          </Box>
                        </Grid.Col>
                      )
                    } else {
                      return null
                    }
                  })
                : null}
            </Grid>
          </Grid.Col>
        </Grid>
      </Card>

      <Modal
        title={t('order.reject')}
        opened={rejectRequestOpened}
        onClose={rejectRequestModal.close}
      >
        <OrderRejectReturnRequestForm
          orderID={order.order_id}
          onSubmit={rejectRequestModal.close}
          onRefresh={onRefresh}
        />
      </Modal>

      <Modal
        title={t('order.approveOrderReturnHeading')}
        opened={approveReturnOpened}
        onClose={approveReturnModal.close}
        size='xl'
      >
        <OrderApproveReturnRequestForm
          order={order}
          onSubmit={approveReturnModal.close}
          onRefresh={onRefresh}
        />
      </Modal>
    </>
  )
}

export default OrderDetailReturnInfo
