import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { GoPencil } from 'react-icons/go'
import { HiOutlineTrash } from 'react-icons/hi'
import { useMutation } from 'react-query'
import { Link } from 'react-router-dom'
import { Models } from '@kicksplanet/interfaces'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Button, Flex, Text, Tooltip } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { notifications } from '@mantine/notifications'

import ConfirmationModal from '@/components/ModalForm/Confirmation'
import { getSizeChartUpdateUrl } from '@/utils/url'

type SizeChartListRowProps = {
  sizeChart: Models.SizeChart
  onRefresh: () => void
}

const SizeChartListRow: React.FC<SizeChartListRowProps> = ({ sizeChart, onRefresh }) => {
  const { t } = useTranslation()
  const sizeChartService = useAPIService('SizeChart')
  const [removeOpened, removeModal] = useDisclosure(false)

  const { mutate: remove, isLoading: isRemoveLoading } = useMutation(
    (sizeChartID: string) => {
      return sizeChartService.delete({ id: sizeChartID })
    },
    {
      onSuccess: async () => {
        removeModal.close()
        notifications.show({
          title: t('common.removeTitle'),
          variant: 'success',
          message: t('success.deletedSizeChart'),
        })
        onRefresh()
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        const errorMessage = t(err?.response?.data, {
          ns: 'backend',
        })

        notifications.show({
          variant: 'danger',
          message: errorMessage,
        })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        err?.response?.data?.errors?.forEach((err: any) => {
          notifications.show({
            variant: 'danger',
            message: t(err, {
              ns: 'backend',
            }),
          })
        })
      },
    },
  )

  const _onRemove = useCallback(() => {
    if (!sizeChart.size_chart_id) return
    remove(sizeChart.size_chart_id)
  }, [remove, sizeChart.size_chart_id])

  return (
    <>
      <tr>
        <td style={{ minWidth: '120px' }}>
          <Tooltip label={sizeChart.name}>
            <Text weight={600} lineClamp={1} w='fit-content'>
              {sizeChart.name}
            </Text>
          </Tooltip>
        </td>
        <td>
          <Flex justify='start' align='center'>
            <Link
              to={getSizeChartUpdateUrl(sizeChart.size_chart_id)}
              style={{ textDecoration: 'none' }}
            >
              <Button variant='subtle' p={10}>
                <GoPencil color='black' />
              </Button>
            </Link>
            <Button variant='subtle' p={10} onClick={removeModal.open}>
              <HiOutlineTrash color='red' />
            </Button>
          </Flex>
        </td>
      </tr>

      <ConfirmationModal
        title={t('common.removeTitle')}
        description={t('common.removeText', { title: sizeChart.name })}
        isLoading={isRemoveLoading}
        opened={removeOpened}
        onClose={removeModal.close}
        onConfirm={_onRemove}
      />
    </>
  )
}

export default SizeChartListRow
