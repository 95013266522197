import { useMemo } from 'react'
import { Flex, Grid, Image, Modal } from '@mantine/core'
import { Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import SwiperWrapper from '../SwiperWrapper'

type GalleryModalProps = {
  title?: string
  isOpen: boolean
  onClose: () => void
  imageUrls?: string[]
  selectedImage?: string
  loop?: boolean
}

const GalleryModal: React.FC<GalleryModalProps> = ({
  title,
  isOpen,
  onClose,
  imageUrls,
  selectedImage,
  loop = false,
}) => {
  const initialSlide = useMemo(() => {
    if (!selectedImage) return 0

    return imageUrls?.findIndex((x) => x == selectedImage)
  }, [imageUrls, selectedImage])

  if (!imageUrls?.length) return null

  return (
    <Modal
      title={title}
      opened={isOpen}
      onClose={onClose}
      size={1000}
      sx={(_theme) => ({
        '.mantine-Modal-inner': {
          alignItems: 'center',
          '.mantine-Modal-header, .mantine-Modal-body': {
            padding: 8,
          },
        },
      })}
    >
      <Grid>
        <Grid.Col>
          <SwiperWrapper singleSlidePerView>
            <Swiper
              slidesPerView={1}
              initialSlide={initialSlide}
              loop={loop}
              navigation
              modules={[Navigation]}
            >
              {imageUrls.map((item, index) => (
                <SwiperSlide key={index}>
                  <Flex
                    justify='center'
                    align='center'
                    bg='white'
                    mx='auto'
                    sx={{
                      width: '100%',
                      height: '100%',
                      img: {
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                      },
                    }}
                  >
                    <Image src={item} alt='' />
                  </Flex>
                </SwiperSlide>
              ))}
            </Swiper>
          </SwiperWrapper>
        </Grid.Col>
      </Grid>
    </Modal>
  )
}

export default GalleryModal
