import { createRef, useRef } from 'react'
import { Outlet } from 'react-router-dom'
import { Box } from '@mantine/core'

const AuthLayout = () => {
  const wrapper = createRef<HTMLDivElement>()
  const navRef = useRef<HTMLDivElement>(null)

  return (
    <Box ref={navRef} w='100%'>
      <Box w='100%'>
        <Box ref={wrapper} w='100%'>
          <Outlet />
        </Box>
      </Box>
    </Box>
  )
}

export default AuthLayout
