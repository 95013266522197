import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { GoPencil } from 'react-icons/go'
import { HiOutlineTrash } from 'react-icons/hi'
import { useMutation } from 'react-query'
import { Link } from 'react-router-dom'
import { GroupResponses } from '@kicksplanet/interfaces'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Button, Flex, Text, Tooltip } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { notifications } from '@mantine/notifications'

import ConfirmationModal from '@/components/ModalForm/Confirmation'
import { getGroupUpdateUrl } from '@/utils/url'

type GroupListRowProps = {
  group: GroupResponses.Group
  onRefresh: () => void
}
const GroupListRow: React.FC<GroupListRowProps> = ({ group, onRefresh }) => {
  const { t } = useTranslation()
  const groupService = useAPIService('Group')

  const [removeOpened, removeModal] = useDisclosure(false)

  const getSubGroups = useMemo(() => {
    if (!group.sub_groups || group.sub_groups.length <= 0) {
      return ''
    }
    return group.sub_groups.map((x) => x.name).join(', ')
  }, [group.sub_groups])

  const { mutate: remove, isLoading: isRemoveLoading } = useMutation(
    (groupID: string) => {
      return groupService.delete({ id: groupID })
    },
    {
      onSuccess: async () => {
        removeModal.close()
        notifications.show({
          title: t('common.removeTitle'),
          variant: 'success',
          message: t('success.deletedGroup'),
        })
        onRefresh()
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        const errorMessage = t(err?.response?.data, {
          ns: 'backend',
        })

        notifications.show({
          variant: 'danger',
          message: errorMessage,
        })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        err?.response?.data?.errors?.forEach((err: any) => {
          notifications.show({
            variant: 'danger',
            message: t(err, {
              ns: 'backend',
            }),
          })
        })
      },
    },
  )

  const _onRemove = useCallback(() => {
    if (!group.group_id) return
    remove(group.group_id)
  }, [group.group_id, remove])
  return (
    <>
      <tr>
        <td style={{ minWidth: '160px' }}>
          <Tooltip label={group.name}>
            <Text weight={600} lineClamp={1} w='fit-content'>
              {group.name}
            </Text>
          </Tooltip>
        </td>
        <td style={{ minWidth: '160px' }}>
          <Tooltip label={group.position}>
            <Text weight={600} lineClamp={1} w='fit-content'>
              {group.position}
            </Text>
          </Tooltip>
        </td>
        <td style={{ minWidth: '160px' }}>
          <Tooltip label={getSubGroups}>
            <Text weight={600} lineClamp={1} w='fit-content'>
              {getSubGroups}
            </Text>
          </Tooltip>
        </td>
        <td>
          <Flex justify='start' align='center'>
            <Link to={getGroupUpdateUrl(group.group_id)} style={{ textDecoration: 'none' }}>
              <Button variant='subtle' p={10}>
                <GoPencil color='black' />
              </Button>
            </Link>
            <Button variant='subtle' p={10} onClick={removeModal.open}>
              <HiOutlineTrash color='red' />
            </Button>
          </Flex>
        </td>
      </tr>

      <ConfirmationModal
        title={t('common.removeTitle')}
        description={t('common.removeText', { title: group.name })}
        isLoading={isRemoveLoading}
        opened={removeOpened}
        onClose={removeModal.close}
        onConfirm={_onRemove}
      />
    </>
  )
}

export default GroupListRow
