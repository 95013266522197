import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { ReportResponses } from '@kicksplanet/interfaces'
import { Box, Grid, Skeleton, Text } from '@mantine/core'

import { formatPrice } from '@/utils/number'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CardItem = styled(Box as any)`
  border-radius: 8px;
  background-color: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.colors.gray[1]};
`

type DashboardGeneralInfoProps = {
  revenue?: ReportResponses.AdminRevenue
  isLoading?: boolean
}

const DashboardGeneralInfo: React.FC<DashboardGeneralInfoProps> = ({ revenue, isLoading }) => {
  const { t } = useTranslation()

  return (
    <Grid>
      <Grid.Col xs={6} md={3}>
        <CardItem p={20}>
          <Text mb={15}>{t('dashboard.miniStatisticsTitle.orderQuantity')}</Text>
          <Skeleton visible={isLoading}>
            <Text weight={600} size='lg'>
              {revenue?.order?.totalOrders || 0}
            </Text>
          </Skeleton>
        </CardItem>
      </Grid.Col>
      <Grid.Col xs={6} md={3}>
        <CardItem p={20}>
          <Text mb={15}>{t('dashboard.miniStatisticsTitle.successfulOrder')}</Text>
          <Skeleton visible={isLoading}>
            <Text weight={600} size='lg'>
              {revenue?.order?.totalOrdersSucceed || 0}
            </Text>
          </Skeleton>
        </CardItem>
      </Grid.Col>
      <Grid.Col xs={6} md={3}>
        <CardItem p={20}>
          <Text mb={15}>{t('dashboard.miniStatisticsTitle.failedOrder')}</Text>
          <Skeleton visible={isLoading}>
            <Text weight={600} size='lg'>
              {revenue?.order?.totalOrdersFailed || 0}
            </Text>
          </Skeleton>
        </CardItem>
      </Grid.Col>
      <Grid.Col xs={6} md={3}>
        <CardItem p={20}>
          <Text mb={15}>{t('dashboard.miniStatisticsTitle.revenue')}</Text>
          <Skeleton visible={isLoading}>
            <Text weight={600} size='lg'>
              {formatPrice(revenue?.revenue?.totalRevenue || 0)}
            </Text>
          </Skeleton>
        </CardItem>
      </Grid.Col>
    </Grid>
  )
}

export default DashboardGeneralInfo
