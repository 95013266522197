import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { OrderResponses } from '@kicksplanet/interfaces'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Card, Divider, Grid, Text } from '@mantine/core'

import { formatAddress } from '@/utils/address'

type OrderDetailReturnPickupInfoProps = {
  order: OrderResponses.OrderDetails
}

const OrderDetailReturnPickupInfo: React.FC<OrderDetailReturnPickupInfoProps> = ({ order }) => {
  const { t } = useTranslation()
  const qrService = useAPIService('QR')

  const orderReturn = useMemo(() => {
    return order.order_return_info
  }, [order.order_return_info])

  const orderRefundPaymentInfo = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (order.order_return_info as any)?.order_refund_payment_info
  }, [order.order_return_info])

  const { data: banks = [] } = useQuery(['qrService', 'getAllBanks'], () =>
    qrService.getBanks().then((res) => res.data.data),
  )

  const getBankDetail = useMemo(() => {
    const bank = banks?.find((x) => x.code === orderRefundPaymentInfo?.bank_code)
    if (!bank) return

    return `${bank.name} (${bank.shortName})`
  }, [banks, orderRefundPaymentInfo.bank_code])

  return (
    <Grid mb={8}>
      <Grid.Col md={6}>
        <Card h='100%'>
          <Grid gutter={16} mb={30}>
            <Grid.Col>
              <Text weight={600} transform='uppercase'>
                {t('order.pickupInfo')}
              </Text>
              <Divider my={12} color='gray.2' />
            </Grid.Col>
            <Grid.Col>
              <Grid>
                <Grid.Col md={3}>
                  <Text weight={600}>{`${t('order.fullName')}: `}</Text>
                </Grid.Col>
                <Grid.Col md={9}>
                  <Text>{orderReturn?.pickup_name || '--'}</Text>
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col md={3}>
                  <Text weight={600}>{`${t('order.phoneNumber')}: `}</Text>
                </Grid.Col>
                <Grid.Col md={9}>
                  <Text>{orderReturn?.pickup_phone || '--'}</Text>
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col md={3}>
                  <Text weight={600}>{`${t('order.address')}: `}</Text>
                </Grid.Col>
                <Grid.Col md={9}>
                  <Text>
                    {formatAddress(
                      orderReturn?.pickup_address,
                      orderReturn?.pickup_ward,
                      orderReturn?.pickup_district,
                      orderReturn?.pickup_province,
                    ) || '--'}
                  </Text>
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col md={3}>
                  <Text weight={600}>{`${t('order.trackingNumber')}: `}</Text>
                </Grid.Col>
                <Grid.Col md={9}>
                  <Text>{orderReturn?.pickup_order_id || '--'}</Text>
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col md={3}>
                  <Text weight={600}>{`${t('order.shippingProvider')}: `}</Text>
                </Grid.Col>
                <Grid.Col md={9}>
                  <Text>{orderReturn?.pickup_provider || '--'}</Text>
                </Grid.Col>
              </Grid>
            </Grid.Col>
          </Grid>
        </Card>
      </Grid.Col>

      <Grid.Col md={6}>
        <Card h='100%'>
          <Grid>
            <Grid.Col>
              <Text weight={600} transform='uppercase'>
                {t('order.bankHeading')}
              </Text>
              <Divider my={12} color='gray.2' />
            </Grid.Col>
            <Grid.Col>
              <Grid>
                <Grid.Col md={3}>
                  <Text weight={600}>{`${t('order.bankAccountNumber')}: `}</Text>
                </Grid.Col>
                <Grid.Col md={9}>
                  <Text>{orderRefundPaymentInfo?.bank_account_number || '--'}</Text>
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col md={3}>
                  <Text weight={600}>{`${t('order.bankAccountName')}: `}</Text>
                </Grid.Col>
                <Grid.Col md={9}>
                  <Text>{orderRefundPaymentInfo?.bank_account_name || '--'}</Text>
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col md={3}>
                  <Text weight={600}>{`${t('order.bankName')}: `}</Text>
                </Grid.Col>
                <Grid.Col md={9}>
                  <Text>{getBankDetail || '--'}</Text>
                </Grid.Col>
              </Grid>
            </Grid.Col>
          </Grid>
        </Card>
      </Grid.Col>
    </Grid>
  )
}

export default OrderDetailReturnPickupInfo
