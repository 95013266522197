import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Grid, Select, Text } from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import get from 'lodash/get'

import { ForControlCheckStatusData, ForControlCheckStatusType } from '@/constants/forControl'
import { OrderStatusDataType, OrderStatusForControlData } from '@/constants/order'
import { useSelection } from '@/hooks/useSelection'
import { convertDateToTimestamp } from '@/utils/date'

type ForControlListFilterProps = {
  queryData: ForControlListSearchType
  onChangeQueries: (query: ForControlListSearchType) => void
}

const ForControlListFilter: React.FC<ForControlListFilterProps> = ({
  queryData,
  onChangeQueries,
}) => {
  const { t } = useTranslation()

  const [fromDate, setFromDate] = useState<Date>(
    queryData.from ? new Date(parseInt(queryData.from)) : new Date(new Date().setDate(1)),
  )
  const [toDate, setToDate] = useState<Date>(
    queryData.to ? new Date(parseInt(queryData.to)) : new Date(),
  )
  const [selectedOrderStatus, setSelectedOrderStatus] = useState<OrderStatusDataType | null>()
  const [selectedCheckStatus, setSelectedCheckStatus] = useState<ForControlCheckStatusType | null>()

  const onChangeFromDate = useCallback((date: Date) => {
    setFromDate(date)
  }, [])

  const onChangeToDate = useCallback((date: Date) => {
    setToDate(date)
  }, [])

  // Order Status
  const onSelectOrderStatusOption = useCallback((item?: OrderStatusDataType | null) => {
    setSelectedOrderStatus(item)
  }, [])

  const {
    data: orderStatusOptions,
    onSelect: onSelectOrderStatus,
    value: orderStatusValue,
    filter: orderStatusFilter,
  } = useSelection<OrderStatusDataType>({
    items: OrderStatusForControlData.map((item) => ({
      ...item,
      label: t(item.label),
    })),
    onSelect: onSelectOrderStatusOption,
    valueKey: 'label',
    allOption: true,
  })

  useEffect(() => {
    if (queryData.status) {
      const data = OrderStatusForControlData.find((x) => x.value == queryData.status)
      onSelectOrderStatus(t(data?.label || ''))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData.status])

  // Check Status
  const onSelectCheckStatusOption = useCallback((item?: ForControlCheckStatusType | null) => {
    setSelectedCheckStatus(item)
  }, [])

  const {
    data: checkStatusOptions,
    onSelect: onSelectCheckStatus,
    value: checkStatusValue,
  } = useSelection<ForControlCheckStatusType>({
    items: ForControlCheckStatusData.map((item) => ({
      ...item,
      label: t(item.label),
    })),
    onSelect: onSelectCheckStatusOption,
    valueKey: 'label',
    allOption: true,
  })

  useEffect(() => {
    if (queryData.checked) {
      const data = ForControlCheckStatusData.find((x) => x.value.toString() == queryData.checked)
      onSelectCheckStatus(t(data?.label || ''))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData.checked])

  const _onChangeQueries = useCallback(() => {
    const result = {
      ...(fromDate ? { from: convertDateToTimestamp(fromDate).toString() } : ''),
      ...(toDate ? { to: convertDateToTimestamp(toDate).toString() } : ''),
      ...(selectedOrderStatus ? { status: selectedOrderStatus.value.toString() } : {}),
      ...(selectedCheckStatus ? { checked: selectedCheckStatus.value.toString() } : {}),
    }

    onChangeQueries(result)
  }, [fromDate, onChangeQueries, selectedCheckStatus, selectedOrderStatus, toDate])

  return (
    <Grid gutter={16} align='end'>
      <Grid.Col md={3}>
        <DatePickerInput
          label={t('forControl.filter.fromDate')}
          valueFormat={'DD/MM/YYYY'}
          maxDate={toDate}
          value={fromDate ? new Date(fromDate) : null}
          onChange={onChangeFromDate}
        />
      </Grid.Col>
      <Grid.Col md={3}>
        <DatePickerInput
          label={t('forControl.filter.toDate')}
          valueFormat={'DD/MM/YYYY'}
          minDate={fromDate}
          maxDate={new Date()}
          value={toDate ? new Date(toDate) : null}
          onChange={onChangeToDate}
        />
      </Grid.Col>
      <Grid.Col md={3}>
        <Select
          label={t('forControl.filter.orderStatus')}
          data={orderStatusOptions}
          value={orderStatusValue}
          onChange={onSelectOrderStatus}
          filter={orderStatusFilter}
          defaultValue={get(orderStatusOptions[0], 'value')}
          searchable
        />
      </Grid.Col>
      <Grid.Col md={3}>
        <Select
          label={t('forControl.filter.checkStatus')}
          data={checkStatusOptions}
          value={checkStatusValue}
          onChange={onSelectCheckStatus}
          defaultValue={get(checkStatusOptions[0], 'value')}
        />
      </Grid.Col>
      <Grid.Col md={3}>
        <Button color='success.4' onClick={_onChangeQueries}>
          <Text>{t('common.searchButton')}</Text>
        </Button>
      </Grid.Col>
    </Grid>
  )
}

export default ForControlListFilter
