import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { BrandRequests, BrandResponses, Models } from '@kicksplanet/interfaces'
import { Box, Button, Flex, Grid, Input } from '@mantine/core'
import { object, string } from 'yup'

import CardImageForm from '@/components/CardImageForm'

export type BrandLogoType = {
  logoOnDark?: Models.File
  logoOnLight?: Models.File
}

type BrandFormProps = {
  brand?: BrandResponses.BrandResponse
  isSubmitLoading?: boolean
  brandLogo?: BrandLogoType | undefined
  onSetBrandLogo?: (data: BrandLogoType | undefined) => void
  onSubmit?: (data: BrandRequests.BrandUpsertPayload) => void
}

const validateSchema = object().shape({
  name: string().max(225, 'brand.brandNameTooLong').required('brand.missingName'),
})

const BrandForm: React.FC<BrandFormProps> = ({
  brand,
  isSubmitLoading,
  brandLogo,
  onSetBrandLogo,
  onSubmit,
}) => {
  const { t } = useTranslation()

  const {
    setValue,
    reset,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<BrandRequests.BrandUpsertPayload>({
    resolver: yupResolver(validateSchema),
    mode: 'all',
  })

  // Default value
  useEffect(() => {
    setDefaultValue()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brand])

  const setDefaultValue = useCallback(() => {
    if (!brand) {
      reset()
      return
    }
    setValue('name', brand.name || '', {
      shouldValidate: true,
    })
  }, [brand, reset, setValue])

  const onSuccessUploadLogoOnDark = useCallback(
    (data: Models.File[]) => {
      const result = { ...brandLogo, logoOnDark: data[0] || undefined }
      onSetBrandLogo?.(result)
    },
    [brandLogo, onSetBrandLogo],
  )

  const onSuccessUploadLogoOnLight = useCallback(
    (data: Models.File[]) => {
      const result = { ...brandLogo, logoOnLight: data[0] || undefined }
      onSetBrandLogo?.(result)
    },
    [brandLogo, onSetBrandLogo],
  )

  const _onSubmit = useCallback(
    (data: BrandRequests.BrandUpsertPayload) => {
      if (!data) return
      onSubmit?.(data)
    },
    [onSubmit],
  )

  return (
    <Box>
      <form>
        <Grid gutter={20}>
          <Grid.Col>
            <Input.Wrapper
              label={`${t('brand.brandName')} *`}
              error={errors.name && t(errors.name.message as string)}
            >
              <Input {...register('name')} />
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col xs={6} sm={4} md={3} lg={2}>
            <CardImageForm
              imageData={brandLogo?.logoOnDark ? [brandLogo.logoOnDark] : []}
              controlLabel={t('brand.logoOnDark')}
              onSubmit={onSuccessUploadLogoOnDark}
            />
          </Grid.Col>
          <Grid.Col xs={6} sm={4} md={3} lg={2}>
            <CardImageForm
              imageData={brandLogo?.logoOnLight ? [brandLogo.logoOnLight] : []}
              controlLabel={t('brand.logoOnLight')}
              onSubmit={onSuccessUploadLogoOnLight}
            />
          </Grid.Col>
        </Grid>

        <Flex my={40} justify='center' align='center'>
          <Button
            variant='highlight'
            loading={isSubmitLoading}
            disabled={!isValid}
            onClick={handleSubmit(_onSubmit)}
          >
            {t('common.save')}
          </Button>
        </Flex>
      </form>
    </Box>
  )
}

export default BrandForm
