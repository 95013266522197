export const APP_CONFIG = {
  ENV: process.env.REACT_APP_ENV,
  API_ROOT: process.env.REACT_APP_API_URL,
  API_V2_ROOT: process.env.REACT_APP_API_V2_URL,
  WALLET_SERVICE_ROOT: process.env.REACT_APP_WALLET_SERVICE_ROOT,
  ADDRESS_STATIC_API: process.env.REACT_APP_ADDRESS_API_URL,
  INVOICE_SERVICE_ROOT: process.env.REACT_APP_INVOICE_SERVICE_ROOT,
  APP_ID: process.env.REACT_APP_APP_ID,
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
}
