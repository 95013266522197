import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineTrash } from 'react-icons/hi'
import { Button, Flex, Input, Text, Tooltip } from '@mantine/core'

import { VoucherUserModel } from '@/types/voucher'
import { getUserFullName } from '@/utils/user'

type VoucherUserRowProps = {
  voucherUser: VoucherUserModel
  onRemove?: (user: VoucherUserModel) => void
  onUpdateQuantity?: (user: VoucherUserModel) => void
}

const VoucherUserRow: React.FC<VoucherUserRowProps> = ({
  voucherUser,
  onRemove,
  onUpdateQuantity,
}) => {
  const { t } = useTranslation()

  const _onRemove = useCallback(() => {
    onRemove?.(voucherUser)
  }, [onRemove, voucherUser])

  const _onUpdateQuantity = useCallback(
    (event?: React.ChangeEvent<HTMLInputElement>) => {
      const data = voucherUser
      data.quantity = parseInt(event?.target?.value || '') as number
      onUpdateQuantity?.(data)
    },
    [onUpdateQuantity, voucherUser],
  )

  return (
    <>
      <tr>
        <td style={{ minWidth: '180px' }}>
          {voucherUser.user_info ? (
            (() => {
              const value = getUserFullName(voucherUser.user_info)
              return (
                <Tooltip label={value}>
                  <Text lineClamp={1} w='fit-content'>
                    {value}
                  </Text>
                </Tooltip>
              )
            })()
          ) : (
            <></>
          )}
        </td>
        <td style={{ minWidth: '160px' }}>
          <Tooltip label={voucherUser.user_info?.phone_number}>
            <Text lineClamp={1} w='fit-content'>
              {voucherUser.user_info?.phone_number}
            </Text>
          </Tooltip>
        </td>
        <td style={{ minWidth: '160px' }}>
          <Tooltip label={voucherUser.user_info?.email}>
            <Text lineClamp={1} w='fit-content'>
              {voucherUser.user_info?.email}
            </Text>
          </Tooltip>
        </td>
        <td style={{ minWidth: '40px' }}>
          <Input
            type='number'
            min={0}
            placeholder={t('voucher.voucherQuantity')}
            defaultValue={voucherUser.quantity}
            onChange={_onUpdateQuantity}
          />
        </td>
        <td>
          <Flex justify='start' align='center'>
            <Button variant='subtle' p={10} onClick={_onRemove}>
              <HiOutlineTrash color='red' />
            </Button>
          </Flex>
        </td>
      </tr>
    </>
  )
}

export default VoucherUserRow
