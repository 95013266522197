import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { SellingItemCondition } from '@kicksplanet/enums'
import { SellingResponses } from '@kicksplanet/interfaces'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Grid } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { notifications } from '@mantine/notifications'

import ConfirmationModal from '@/components/ModalForm/Confirmation'
import { useApp } from '@/contexts/AppProvider'
import { ROUTES } from '@/routes'

import SellingItemDetailInfo from './Components/Info'
import SellingItemProductInfo from './Components/ProductInfo'
import SellingItemSellerInfo from './Components/SellerInfo'

const SellingItemDetail: React.FC = () => {
  const { t } = useTranslation()
  const { id } = useParams()

  const sellingService = useAPIService('Selling')

  const { registerBreadcrumb, unregisterBreadcrumb } = useApp()

  const [allowSellingOpened, allowSellingModal] = useDisclosure(false)

  const { data: selling, refetch: getSelling } = useQuery(
    ['sellingService', 'getOne', id],
    () => sellingService.getOne({ id: id ? id : '' }).then((res) => res.data.data),
    {
      enabled: Boolean(id?.toString()),
    },
  )

  useEffect(() => {
    registerBreadcrumb({
      key: 'sellingItems',
      label: t('page.sellingItems'),
      url: ROUTES.ADMIN.SELLING_ITEM.LIST,
    })

    if (selling) {
      registerBreadcrumb({
        key: `detail-${selling.selling_id}`,
        label: (selling as SellingResponses.SellingItem).product_info?.title,
      })
    }

    return () => {
      unregisterBreadcrumb('sellingItems')
      if (selling) {
        unregisterBreadcrumb(`detail-${selling.selling_id}`)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selling])

  const onRefresh = useCallback(() => {
    getSelling()
  }, [getSelling])

  const { mutate: approve, isLoading: allowLoading } = useMutation(
    async () => {
      if (!id) return null

      return sellingService.approveSellingItems({
        data: {
          ids: [id],
        },
      })
    },
    {
      onSuccess: () => {
        notifications.show({
          variant: 'success',
          message: t('success.allowSelling'),
        })
        onRefresh()
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        const errorMessage = t(err?.response?.data, {
          ns: 'backend',
        })

        notifications.show({
          variant: 'danger',
          message: errorMessage,
        })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        err?.response?.data?.errors?.forEach((err: any) => {
          notifications.show({
            variant: 'danger',
            message: t(err, {
              ns: 'backend',
            }),
          })
        })
      },
    },
  )

  const onOpenAllowSellingModal = useCallback(() => {
    if (!id || !selling) return
    if (selling?.condition === SellingItemCondition.USED && !selling.description) {
      notifications.show({
        variant: 'warning',
        message: t('sellingItem.requiredControl.description'),
      })
      return
    }
    allowSellingModal.open()
  }, [allowSellingModal, id, selling, t])

  const onAllowSelling = useCallback(() => {
    if (!id) return

    approve()
  }, [id, approve])

  return selling ? (
    <>
      <Grid>
        <Grid.Col>
          <SellingItemDetailInfo
            selling={selling}
            onAllowSelling={onOpenAllowSellingModal}
            onRefresh={onRefresh}
          />
        </Grid.Col>
        <Grid.Col md={6}>
          <SellingItemProductInfo selling={selling} onRefresh={onRefresh} />
        </Grid.Col>
        <Grid.Col md={6}>
          <SellingItemSellerInfo selling={selling} />
        </Grid.Col>
      </Grid>

      <ConfirmationModal
        title={t('sellingItem.confirmApproveModalHeader')}
        description={t('sellingItem.confirmApproveSingleMessage')}
        isLoading={allowLoading}
        opened={allowSellingOpened}
        onClose={allowSellingModal.close}
        onConfirm={onAllowSelling}
      />
    </>
  ) : null
}

export default SellingItemDetail
