import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { Models } from '@kicksplanet/interfaces'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Card } from '@mantine/core'
import { notifications } from '@mantine/notifications'

import { useApp } from '@/contexts/AppProvider'
import { ROUTES } from '@/routes'

import ProductForm, { ProductFormType } from '../Components/ProductForm'

const ProductCreate: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const productService = useAPIService('Product')

  const { registerBreadcrumb, unregisterBreadcrumb } = useApp()

  useEffect(() => {
    registerBreadcrumb({
      key: 'products',
      label: t('page.products'),
      url: ROUTES.ADMIN.PRODUCT.LIST,
    })

    registerBreadcrumb({
      key: 'addProduct',
      label: t('page.addProduct'),
    })

    return () => {
      unregisterBreadcrumb('products')
      unregisterBreadcrumb('addProduct')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [images, setImages] = useState<(Models.File | undefined)[]>([])

  const { mutate: create, isLoading: isCreateLoading } = useMutation(
    (data: ProductFormType) => {
      return productService.create({
        data: {
          ...data,
          images: images.map((x) => x?.file_id || ''),
          retail_price: data.retail_price,
        },
      })
    },
    {
      onSuccess: async () => {
        notifications.show({
          variant: 'success',
          message: t('success.createdProduct'),
        })
        navigate(ROUTES.ADMIN.PRODUCT.LIST)
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        const errorMessage = t(err?.response?.data, {
          ns: 'backend',
        })

        notifications.show({
          variant: 'danger',
          message: errorMessage,
        })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        err?.response?.data?.errors?.forEach((err: any) => {
          notifications.show({
            variant: 'danger',
            message: t(err, {
              ns: 'backend',
            }),
          })
        })
      },
    },
  )

  const onSetImages = useCallback((data: (Models.File | undefined)[]) => {
    setImages(data)
  }, [])

  const onCreate = useCallback(
    (data: ProductFormType) => {
      create(data)
    },
    [create],
  )

  return (
    <Card>
      <ProductForm
        isSubmitLoading={isCreateLoading}
        onSubmit={onCreate}
        images={images}
        onSetImages={onSetImages}
      />
    </Card>
  )
}

export default ProductCreate
