import { useTranslation } from 'react-i18next'
import { SellingResponses } from '@kicksplanet/interfaces'
import { Card, Divider, Grid, Text } from '@mantine/core'

import { getUserFullName } from '@/utils/user'

type SellingItemSellerInfoProps = {
  selling: SellingResponses.SellingItem
}

const SellingItemSellerInfo: React.FC<SellingItemSellerInfoProps> = ({ selling }) => {
  const { t } = useTranslation()

  return (
    <Card h='100%'>
      <Grid gutter={16} mb={30}>
        <Grid.Col>
          <Text weight={600} transform='uppercase'>
            {t('user.sellerHeading')}
          </Text>
          <Divider my={12} color='gray.2' />
        </Grid.Col>
        <Grid.Col>
          <Grid>
            <Grid.Col md={3}>
              <Text weight={600}>{`${t('user.name')}: `}</Text>
            </Grid.Col>
            <Grid.Col md={9}>
              {selling.user_info ? (
                <Text>{getUserFullName(selling.user_info)}</Text>
              ) : (
                <Text>--</Text>
              )}
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col md={3}>
              <Text weight={600}>{`${t('user.email')}: `}</Text>
            </Grid.Col>
            <Grid.Col md={9}>
              <Text>{selling.user_info?.email || '--'}</Text>
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col md={3}>
              <Text weight={600}>{`${t('user.phoneNumber')}: `}</Text>
            </Grid.Col>
            <Grid.Col md={9}>
              <Text>{selling.user_info?.phone_number || '--'}</Text>
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col md={3}>
              <Text weight={600}>{`${t('user.idCardNo')}: `}</Text>
            </Grid.Col>
            <Grid.Col md={9}>
              <Text>{(selling.user_info as any)?.id_card_no || '--'}</Text>
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col md={3}>
              <Text weight={600}>{`${t('user.taxNo')}: `}</Text>
            </Grid.Col>
            <Grid.Col md={9}>
              <Text>{(selling.user_info as any)?.tax_no || '--'}</Text>
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid>
    </Card>
  )
}

export default SellingItemSellerInfo
