import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { notifications } from '@mantine/notifications'

import { useAuth } from '@/contexts/SessionProvider'

const withAuth = <P extends object>(Component: React.ComponentType<P>): React.FC<P> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const WithAuthComponent = (props: any) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const { isInitialized, user } = useAuth()

    useEffect(() => {
      if (isInitialized && !user) {
        navigate(`/auth/signin?redirect=${window.location.pathname}${window.location.search}`)
        notifications.show({
          variant: 'danger',
          message: t('error.signIn'),
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInitialized, user])

    if (!user || !isInitialized) return null

    return <Component {...props} />
  }

  return WithAuthComponent
}

export default withAuth
