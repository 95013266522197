import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { OrderStatus } from '@kicksplanet/enums'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Grid, Tabs } from '@mantine/core'

import { useApp } from '@/contexts/AppProvider'
import { ROUTES } from '@/routes'

import OrderDetailInfo from './Components/Info'
import OrderDetailReturn from './Components/Return'
import OrderDetailSummary from './Components/Summary'
import OrderDetailSummaryCancelInfo from './Components/Summary/Components/CancelInfo'
import OrderDetailSummaryRejectInfo from './Components/Summary/Components/RejectInfo'

const OrderDetailTabs = {
  SUMMARY: 'summary',
  RETURN: 'return',
}

const OrderDetail: React.FC = () => {
  const { t } = useTranslation()
  const { id } = useParams()

  const orderService = useAPIService('Order')

  const { registerBreadcrumb, unregisterBreadcrumb } = useApp()

  const { data: order, refetch: getOrder } = useQuery(
    ['orderService', 'getOne', id],
    () => orderService.getOne({ id: id ? id : '' }).then((res) => res.data.data),
    {
      enabled: Boolean(id?.toString()),
    },
  )

  useEffect(() => {
    registerBreadcrumb({
      key: 'orders',
      label: t('page.orders'),
      url: ROUTES.ADMIN.ORDER.LIST,
    })

    if (order) {
      registerBreadcrumb({
        key: `detail-${order.code}`,
        label: order.code,
      })
    }

    return () => {
      unregisterBreadcrumb('orders')
      if (order) {
        unregisterBreadcrumb(`detail-${order.code}`)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order])

  const onRefresh = useCallback(() => {
    getOrder()
  }, [getOrder])

  return order ? (
    <Grid>
      <Grid.Col>
        <OrderDetailInfo order={order} />
      </Grid.Col>
      {order.status == OrderStatus.CANCELLED && (
        <Grid.Col>
          <OrderDetailSummaryCancelInfo order={order} />
        </Grid.Col>
      )}
      {order.status == OrderStatus.REJECTED && (
        <Grid.Col>
          <OrderDetailSummaryRejectInfo order={order} />
        </Grid.Col>
      )}
      <Grid.Col>
        <Tabs keepMounted={false} defaultValue={OrderDetailTabs.SUMMARY} my={12}>
          <Tabs.List>
            <Tabs.Tab value={OrderDetailTabs.SUMMARY} w={{ base: '100%', xs: 'auto' }}>
              {t('order.summary')}
            </Tabs.Tab>
            <Tabs.Tab value={OrderDetailTabs.RETURN} w={{ base: '100%', xs: 'auto' }}>
              {t('order.orderReturn')}
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value={OrderDetailTabs.SUMMARY} my={12}>
            <OrderDetailSummary order={order} onRefresh={onRefresh} />
          </Tabs.Panel>
          <Tabs.Panel value={OrderDetailTabs.RETURN} my={12}>
            <OrderDetailReturn order={order} onRefresh={onRefresh} />
          </Tabs.Panel>
        </Tabs>
      </Grid.Col>
    </Grid>
  ) : null
}

export default OrderDetail
