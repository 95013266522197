import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Card, Grid, Text } from '@mantine/core'

import { useApp } from '@/contexts/AppProvider'
import { ROUTES } from '@/routes'
import { formatDate } from '@/utils/date'
import { DATE_FORMATS } from '@/utils/date-format'
import { formatNumber } from '@/utils/number'

import ProductDetailGallery from './Components/Gallery'
import ProductDetailSize from './Components/Size'

const ProductDetail: React.FC = () => {
  const { t } = useTranslation()
  const { id } = useParams()

  const productService = useAPIService('Product')
  const sizeChartService = useAPIService('SizeChart')

  const { registerBreadcrumb, unregisterBreadcrumb } = useApp()

  const { data: product } = useQuery(
    ['productService', 'getOne', id],
    () => productService.getOne({ id: id ? id : '' }).then((res) => res.data.data),
    {
      enabled: Boolean(id?.toString()),
    },
  )

  const { data: productImages } = useQuery(
    ['productService', 'getProductImages', product?.product_id],
    () =>
      productService
        .getProductImages({ id: product ? product.product_id : '' })
        .then((res) => res.data.data),
    {
      enabled: Boolean(product),
    },
  )

  const { data: sizeChart } = useQuery(
    ['sizeChartService', 'getOne', product?.size_chart_id],
    () =>
      sizeChartService
        .getOne({
          id: product && product.size_chart_id ? product.size_chart_id : '',
        })
        .then((res) => res.data.data),
    {
      enabled: Boolean(product),
    },
  )

  useEffect(() => {
    registerBreadcrumb({
      key: 'products',
      label: t('page.products'),
      url: ROUTES.ADMIN.PRODUCT.LIST,
    })

    if (product) {
      registerBreadcrumb({
        key: `detail-${product.slug}`,
        label: product.title,
      })
    }

    return () => {
      unregisterBreadcrumb('products')
      if (product) {
        unregisterBreadcrumb(`detail-${product.slug}`)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product])

  const productDetailLine = useCallback((label: string, value?: string) => {
    return (
      <Grid gutter={8}>
        <Grid.Col md={4}>
          <Text>{label}</Text>
        </Grid.Col>
        <Grid.Col md={8}>
          <Text weight={600}>{value}</Text>
        </Grid.Col>
      </Grid>
    )
  }, [])

  if (!product) return null

  return (
    <Card>
      <Grid gutter={32}>
        <Grid.Col>
          <Text size='lg' weight={600}>
            {product.title}
          </Text>
          <Text>{`${product.subtitle} ${product.gender && `(${product.gender})`}`}</Text>
        </Grid.Col>
        <Grid.Col md={6}>
          <ProductDetailGallery product={product} productImages={productImages || []} />
        </Grid.Col>
        <Grid.Col md={6}>
          <Grid>
            <Grid.Col>
              <Text size='lg' weight={600} mb={12}>
                {t('product.productDetail')}
              </Text>
              {productDetailLine(t('product.sku'), product.code)}
              {productDetailLine(t('product.productColor'), product.color)}
              {productDetailLine(
                t('product.productRetailPrice'),
                `$${formatNumber(product.retail_price || 0)}`,
              )}
              {productDetailLine(
                t('product.productReleaseDate'),
                product.release_date
                  ? formatDate(product.release_date, DATE_FORMATS.HH_MM_AA_DOT_DD_MMMM_YYYY)
                  : '--',
              )}
              {productDetailLine(t('product.productCategory'), product.category_info?.name)}
              {productDetailLine(t('product.productBrand'), product.brand_info?.name)}
              {productDetailLine(t('product.productDescriptionDetail'), product.description)}
            </Grid.Col>
            <Grid.Col>
              <ProductDetailSize sizeChart={sizeChart} />
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid>
    </Card>
  )
}

export default ProductDetail
