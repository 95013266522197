import { useMemo } from 'react'
import { GrLanguage } from 'react-icons/gr'
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'
import { Button, Flex, Image, Menu, Text } from '@mantine/core'
import i18next from 'i18next'
import Cookies from 'universal-cookie'

import { Languages } from '@/constants/languages'

type LanguageMenuProps = {
  mobile?: boolean
}

const LanguageMenu: React.FC<LanguageMenuProps> = ({ mobile = false }) => {
  const cookies = new Cookies()
  const currentLanguageCode = cookies.get('i18next') || 'en'

  const currentLanguage = useMemo(() => {
    return Languages.find((x) => x.code === currentLanguageCode)
  }, [currentLanguageCode])

  return (
    <Menu trigger={mobile ? 'click' : 'hover'} defaultOpened={mobile ? true : false}>
      <Menu.Target>
        <Button
          variant='subtle'
          p={0}
          rightIcon={<MdOutlineKeyboardArrowDown size={20} />}
          styles={(theme) => ({
            inner: {
              justifyContent: 'space-between',
              color: theme.black,
            },
            root: {
              '&:hover': {
                backgroundColor: theme.colors.gray[2],
              },
            },
          })}
          fullWidth
        >
          {currentLanguage ? (
            <Flex justify='center' align='center' gap={6}>
              <Flex justify='center' align='center' sx={{ width: 20, height: 20 }}>
                <Image src={currentLanguage.icon} alt='' />
              </Flex>
              <Text>{currentLanguage.code}</Text>
            </Flex>
          ) : (
            <GrLanguage size={22} />
          )}
        </Button>
      </Menu.Target>

      <Menu.Dropdown>
        {Languages.map((item) => (
          <Menu.Item
            key={item.code}
            disabled={currentLanguage?.code === item.code}
            onClick={() => {
              i18next.changeLanguage(item.code)
            }}
          >
            <Flex justify='start' align='center' gap={5}>
              <Flex justify='center' align='center' sx={{ width: 20, height: 20 }}>
                <Image src={item.icon} alt='' />
              </Flex>
              {item.name}
            </Flex>
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  )
}

export default LanguageMenu
