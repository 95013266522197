import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoMdAddCircle } from 'react-icons/io'
import { MdCancel } from 'react-icons/md'
import { useQuery } from 'react-query'
import { Models, VoucherResponses } from '@kicksplanet/interfaces'
import { useAPIService } from '@kicksplanet/react/hooks'
import { GetListParams } from '@kicksplanet/services/dist/services/core/CURDService'
import {
  Box,
  Button,
  Flex,
  Grid,
  Paper,
  Skeleton,
  Text,
  TextInput,
  Transition,
} from '@mantine/core'
import { useClickOutside, useDebouncedState, useDisclosure } from '@mantine/hooks'

import Pagination from '@/components/Pagination/Pagination'
import TableWrapper from '@/components/TableWrapper'
import { VoucherUserModel } from '@/types/voucher'
import { formatKeyFilter } from '@/utils/string'
import { getUserInfoInLine } from '@/utils/user'

import VoucherUserRow from './Row'

type VoucherUserProps = {
  voucher?: VoucherResponses.VoucherDetail
  onSetVoucherUsers?: (users: VoucherUserModel[]) => void
}

const COLUMN_CAPTION = [
  'user.name',
  'user.phoneNumber',
  'user.email',
  'voucher.voucherQuantity',
  'common.action',
]

const enum VOUCHER_USER_LIST {
  DEFAULT_LIMIT = 10,
}

const VoucherUser: React.FC<VoucherUserProps> = ({ voucher, onSetVoucherUsers }) => {
  const { t } = useTranslation()
  const userService = useAPIService('User')

  const [voucherUsers, setVoucherUsers] = useState<VoucherUserModel[]>([])
  const [displayVoucherUsers, setDisplayVoucherUsers] = useState<VoucherUserModel[]>([])
  const [userKeysearch, setUserKeysearch] = useDebouncedState('', 400)

  const [userDropdownOpened, userDropdown] = useDisclosure(false)
  const [addUserOpened, addUser] = useDisclosure(false, {
    onClose: () => {
      userDropdown.close()
    },
  })

  const [voucherUserPage, setVoucherUserPage] = useState<number>(1)

  const ref = useClickOutside(() => {
    userDropdown.close()
  })

  useEffect(() => {
    onSetVoucherUsers?.(voucherUsers)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voucherUsers])

  const onPagingVoucherUserList = useCallback(
    (data?: VoucherUserModel[] | []) => {
      const tmpData = data && data?.length > 0 ? data : voucherUsers
      const startIndex = (voucherUserPage - 1) * VOUCHER_USER_LIST.DEFAULT_LIMIT
      const pagingData = tmpData?.slice(startIndex, startIndex + VOUCHER_USER_LIST.DEFAULT_LIMIT)

      setDisplayVoucherUsers(pagingData || [])
    },
    [voucherUserPage, voucherUsers],
  )

  const onRenewVoucherUser = useCallback(
    (newData: VoucherUserModel[] | []) => {
      setVoucherUsers(newData)
      onPagingVoucherUserList(newData)
    },
    [onPagingVoucherUserList],
  )

  const onChangeVoucherUserPage = (page: number) => {
    setVoucherUserPage(page)
  }

  useEffect(() => {
    onPagingVoucherUserList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voucherUserPage])

  useEffect(() => {
    if (voucher) {
      const voucherUsers = voucher?.user_vouchers as VoucherUserModel[]
      onRenewVoucherUser(voucherUsers)
    } else {
      onRenewVoucherUser([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voucher])

  const [userGetListQuery, setUserGetListQuery] = useState<GetListParams<Models.User>>({
    pagination: { page: 1, perPage: 0 },
  })

  const onSearchUser = useCallback(
    (event?: React.ChangeEvent<HTMLInputElement>) => {
      const value = event?.target?.value as string
      setUserKeysearch(value)
      userDropdown.open()
    },
    [userDropdown, setUserKeysearch],
  )

  const onSetGetListQuery = useCallback(() => {
    const likeFilter = formatKeyFilter(userKeysearch)

    const query: GetListParams<Models.User> = {
      pagination: { page: 1, perPage: userKeysearch ? 10 : 0 },
      filter: {
        'op.or': [
          {
            first_name: {
              'op.like': likeFilter,
            },
          },
          {
            last_name: {
              'op.like': likeFilter,
            },
          },
          {
            phone_number: {
              'op.like': likeFilter,
            },
          },
          {
            email: {
              'op.like': likeFilter,
            },
          },
        ],
      },
    }
    setUserGetListQuery(query)
  }, [userKeysearch])

  useEffect(() => {
    onSetGetListQuery()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userKeysearch])

  const { data: users = [], isFetching: isLoadingUser } = useQuery(
    ['userService', 'getList', 'selectOptions', userGetListQuery],
    () => userService.getList(userGetListQuery),
    {
      select: ({ data: res }) => {
        return (
          res?.data?.map((item) => ({
            ...item,
            label: getUserInfoInLine(item),
          })) || []
        )
      },
    },
  )

  const onSelectUser = useCallback(
    (item: Models.User) => {
      if (voucherUsers?.find((x) => x.user_id === item.user_id)) return

      const newUser: VoucherUserModel = {
        user_id: item.user_id,
        quantity: 1,
        voucher_id: voucher?.voucher_id || '',
        user_info: item,
      }

      const data = [newUser, ...voucherUsers]
      onRenewVoucherUser(data)
      userDropdown.close()
    },
    [onRenewVoucherUser, userDropdown, voucher?.voucher_id, voucherUsers],
  )

  const onRemoveVoucherUser = useCallback(
    (item: VoucherUserModel) => {
      if (!voucherUsers || voucherUsers.length <= 0) return
      if (!voucherUsers?.find((x) => x.user_id === item.user_id)) return

      const data = voucherUsers?.filter((x) => x.user_id !== item.user_id)
      onRenewVoucherUser(data)
    },
    [onRenewVoucherUser, voucherUsers],
  )

  const onUpdateVoucherUserQuantity = useCallback(
    (item: VoucherUserModel) => {
      const data = voucherUsers.find((x) => x.user_id === item.user_id)
      if (data) {
        data.quantity = item.quantity
      }
      onRenewVoucherUser(voucherUsers)
    },
    [onRenewVoucherUser, voucherUsers],
  )

  const RowData = useMemo(() => {
    if (displayVoucherUsers?.length <= 0) {
      return null
    }

    return displayVoucherUsers.map((item) => {
      return (
        <VoucherUserRow
          key={item.user_id}
          voucherUser={item}
          onRemove={onRemoveVoucherUser}
          onUpdateQuantity={onUpdateVoucherUserQuantity}
        />
      )
    })
  }, [onRemoveVoucherUser, onUpdateVoucherUserQuantity, displayVoucherUsers])

  return (
    <Grid>
      <Grid.Col>
        <TableWrapper>
          <thead>
            <tr>
              {COLUMN_CAPTION.map((item, key) => {
                return <th key={key}>{t(item)}</th>
              })}
            </tr>
          </thead>
          <tbody>{RowData}</tbody>
        </TableWrapper>
        <Flex mx={10} mt={12} gap={8} justify='space-between' align='center'>
          {addUserOpened ? (
            <>
              <Button p={8} variant='subtle' onClick={addUser.close}>
                <MdCancel size='20' color='red' />
              </Button>
              <Box ref={ref} pos='relative' w='100%'>
                <TextInput
                  placeholder={t('user.inputUserInfo')}
                  onChange={onSearchUser}
                  onClick={userDropdown.toggle}
                />
                <Transition
                  mounted={userDropdownOpened}
                  transition='scale-y'
                  duration={150}
                  timingFunction='ease'
                >
                  {(styles) => (
                    <Paper
                      pos='absolute'
                      shadow='sm'
                      p={8}
                      my={4}
                      w='100%'
                      style={{ ...styles }}
                      sx={(_theme) => ({
                        border: `1px solid ${_theme.colors.gray[2]}`,
                        borderRadius: '3px',
                        boxShadow: _theme.shadows.sm,
                        maxHeight: '250px',
                        overflowY: 'auto',
                        zIndex: 1000,
                      })}
                    >
                      <Box>
                        <Skeleton visible={isLoadingUser}>
                          {users &&
                            users.length > 0 &&
                            users.map((item) => (
                              <Box
                                key={item.user_id}
                                sx={(_theme) => ({
                                  cursor: 'pointer',
                                  transition: '0.1s',
                                  '&:hover': {
                                    background: _theme.colors.gray[2],
                                    transition: '0.1s',
                                  },
                                })}
                                py={4}
                                px={8}
                                onClick={() => onSelectUser(item)}
                              >
                                <Text>{item.label}</Text>
                              </Box>
                            ))}
                        </Skeleton>
                      </Box>
                    </Paper>
                  )}
                </Transition>
              </Box>
            </>
          ) : (
            <Button p={8} variant='subtle' onClick={addUser.open}>
              <IoMdAddCircle size='20' color='green' />
            </Button>
          )}
        </Flex>
        {voucherUsers?.length > 0 ? (
          <Pagination
            limit={VOUCHER_USER_LIST.DEFAULT_LIMIT}
            page={voucherUserPage}
            total={voucherUsers?.length}
            onChange={onChangeVoucherUserPage}
          />
        ) : null}
      </Grid.Col>
    </Grid>
  )
}

export default VoucherUser
