import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Group, Modal, ModalProps, Stack, Textarea } from '@mantine/core'
import { object, string } from 'yup'

const schema = object({
  reject_reason: string().required().max(500),
})

type RejectFormData = {
  reject_reason: string
}

export type WithdrawalRejectReasonModalProps = ModalProps & {
  onConfirm: (reason: string) => void
  isLoading: boolean
}

const WithdrawalRejectReasonModal: React.FC<WithdrawalRejectReasonModalProps> = ({
  onConfirm,
  isLoading,
  ...modalProps
}) => {
  const { t } = useTranslation()
  const form = useForm<RejectFormData>({
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    if (modalProps.opened) {
      return () => {
        form.reset()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalProps.opened])

  const onSubmit = useCallback(
    (data: RejectFormData) => {
      onConfirm(data.reject_reason)
    },
    [onConfirm],
  )

  return (
    <Modal {...modalProps} title={t('withdrawalRequest.reject_request')}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Stack>
          <Textarea
            {...form.register('reject_reason')}
            label={t('withdrawalRequest.reject_reason')}
            error={
              form.formState.errors.reject_reason?.message
                ? t(form.formState.errors.reject_reason?.message)
                : undefined
            }
          />
          <Group position='right'>
            <Button color='blue' type='submit' loading={isLoading}>
              {t('common.confirm')}
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  )
}

export default WithdrawalRejectReasonModal
