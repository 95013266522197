import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { yupResolver } from '@hookform/resolvers/yup'
import { CategoryRequests, CategoryResponses } from '@kicksplanet/interfaces'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Button, Flex, Grid, Input } from '@mantine/core'
import { notifications } from '@mantine/notifications'
import { object, string } from 'yup'

type SubCategoryFormProps = {
  category?: CategoryResponses.Category
  onSubmit?: () => void
}

const validateSchema = object().shape({
  name: string().max(225, 'category.categoryNameTooLong').required('category.missingCategoryName'),
})

const SubCategoryForm: React.FC<SubCategoryFormProps> = ({ category, onSubmit }) => {
  const { t } = useTranslation()
  const categoryService = useAPIService('Category')

  const {
    setValue,
    reset,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<CategoryRequests.CategoryUpsertPayload>({
    resolver: yupResolver(validateSchema),
    mode: 'all',
  })

  // Default value
  useEffect(() => {
    setDefaultValue()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category])

  const setDefaultValue = useCallback(() => {
    if (!category) {
      reset()
      return
    }
    setValue('name', category.name || '', {
      shouldValidate: true,
    })
  }, [category, reset, setValue])

  const { mutate: update, isLoading: isSubmitLoading } = useMutation(
    (data: CategoryRequests.CategoryUpsertPayload) => {
      return categoryService.update({
        id: category?.category_id?.toString() || '',
        data: {
          data: {
            ...data,
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any,
      })
    },
    {
      onSuccess: async () => {
        notifications.show({
          variant: 'success',
          message: t('success.updatedSubCategory'),
        })
        onSubmit?.()
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        const errorMessage = t(err?.response?.data, {
          ns: 'backend',
        })

        notifications.show({
          variant: 'danger',
          message: errorMessage,
        })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        err?.response?.data?.errors?.forEach((err: any) => {
          notifications.show({
            variant: 'danger',
            message: t(err, {
              ns: 'backend',
            }),
          })
        })
      },
    },
  )

  const _onSubmit = useCallback(
    (data: CategoryRequests.CategoryUpsertPayload) => {
      if (!category?.category_id) return
      update(data)
    },
    [category?.category_id, update],
  )

  return (
    <form>
      <Grid gutter={20}>
        <Grid.Col>
          <Input.Wrapper
            label={`${t('category.categoryName')} *`}
            error={errors.name && t(errors.name.message as string)}
          >
            <Input {...register('name')} />
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col>
          <Flex my={40} justify='center' align='center'>
            <Button
              variant='highlight'
              loading={isSubmitLoading}
              disabled={!isValid}
              onClick={handleSubmit(_onSubmit)}
            >
              {t('common.save')}
            </Button>
          </Flex>
        </Grid.Col>
      </Grid>
    </form>
  )
}

export default SubCategoryForm
