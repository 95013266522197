import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Card, Skeleton } from '@mantine/core'
import defaultTo from 'lodash/defaultTo'

import Empty from '@/components/Empty'
import ListFilterLayout from '@/components/ListFilterLayout'
import QueryPagination from '@/components/Pagination/QueryPagination'
import TableWrapper from '@/components/TableWrapper'
import { DEFAULT_PAGINATION_QUERY } from '@/constants/pagination'
import { useApp } from '@/contexts/AppProvider'
import { ROUTES } from '@/routes'
import { formatKeyFilter } from '@/utils/string'

import BrandListFilter from './Components/Filter'
import BrandListRow from './Components/Row/intex'

const COLUMN_CAPTION = ['brand.brandName', 'common.action']

const BrandList: React.FC = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const brandService = useAPIService('Brand')
  const { registerBreadcrumb, unregisterBreadcrumb } = useApp()

  useEffect(() => {
    registerBreadcrumb({
      key: 'brands',
      label: t('page.brands'),
    })

    return () => {
      unregisterBreadcrumb('brands')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const defaultQueryParams = useMemo(() => {
    return {
      page: defaultTo(parseInt(searchParams.get('page') as string), 1),
      perPage: defaultTo(
        parseInt(searchParams.get('per') as string),
        DEFAULT_PAGINATION_QUERY.limit,
      ),
      key: defaultTo(searchParams.get('key') as string, ''),
    }
  }, [searchParams])

  const { page: pageQuery, perPage: perPageQuery, key: keyQuery } = defaultQueryParams

  const getListQuery = useMemo(() => {
    const likeFilter = formatKeyFilter(keyQuery)
    return {
      pagination: {
        page: pageQuery,
        perPage: perPageQuery,
      },
      filter: {
        name: {
          'op.like': likeFilter,
        },
      },
    }
  }, [keyQuery, pageQuery, perPageQuery])

  const dataSearchQuery: BrandListSearchType = useMemo(() => {
    return {
      ...(keyQuery && { key: keyQuery }),
    } as BrandListSearchType
  }, [keyQuery])

  const {
    data: brands,
    isFetching: isLoadingTable,
    refetch: getBrands,
  } = useQuery(['brandService', 'getList', getListQuery], () =>
    brandService.getList(getListQuery).then((res) => res.data),
  )

  const onChangeQueries = useCallback(
    (query: BrandListSearchType) => {
      navigate({
        pathname: ROUTES.ADMIN.BRAND.LIST,
        search: createSearchParams({
          ...query,
        }).toString(),
      })
    },
    [navigate],
  )

  const onRefresh = useCallback(() => {
    getBrands()
  }, [getBrands])

  const RowData = useMemo(() => {
    if (isLoadingTable) {
      return (
        <tr>
          <td colSpan={2}>
            <Skeleton height={10} my={15} radius='md' />
            <Skeleton height={10} my={15} radius='md' />
            <Skeleton height={10} my={15} radius='md' />
          </td>
        </tr>
      )
    }

    if (!brands || !brands.data || brands.data.length <= 0)
      return (
        <tr>
          <td colSpan={2}>
            <Empty />
          </td>
        </tr>
      )

    return brands.data.map((item) => {
      return <BrandListRow key={item.brand_id} brand={item} onRefresh={onRefresh} />
    })
  }, [brands, isLoadingTable, onRefresh])

  return (
    <Card>
      <ListFilterLayout onRefresh={onRefresh}>
        <BrandListFilter queryData={dataSearchQuery} onChangeQueries={onChangeQueries} />
      </ListFilterLayout>
      <TableWrapper isStickyColumm={Boolean(brands?.data && brands?.data.length > 0)}>
        <thead>
          <tr>
            {COLUMN_CAPTION.map((item, key) => {
              return <th key={key}>{t(item)}</th>
            })}
          </tr>
        </thead>
        <tbody>{RowData}</tbody>
      </TableWrapper>
      <QueryPagination
        limit={perPageQuery}
        page={pageQuery}
        total={brands?.total}
        isShowLimitOptions
      />
    </Card>
  )
}

export default BrandList
