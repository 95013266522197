import { useCallback, useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { yupResolver } from '@hookform/resolvers/yup'
import { Models, ProductResponses } from '@kicksplanet/interfaces'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Box, Button, Flex, Grid, Input, Select, Textarea } from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import { number, object, string } from 'yup'

import CardImageForm from '@/components/CardImageForm'
import { useSelection } from '@/hooks/useSelection'

type ProductFormProps = {
  product?: ProductResponses.Product
  isSubmitLoading?: boolean
  images?: (Models.File | undefined)[]
  onSetImages?: (files: (Models.File | undefined)[]) => void
  onSubmit?: (data: ProductFormType) => void
}

export type ProductFormType = Pick<
  Models.Product,
  | 'title'
  | 'subtitle'
  | 'code'
  | 'color'
  | 'retail_price'
  | 'release_date'
  | 'gender'
  | 'description'
  | 'thumbnail'
  | 'brand_id'
  | 'category_id'
  | 'size_chart_id'
>

const validateSchema = object().shape({
  title: string().max(225, 'product.productTitleTooLong').required('product.missingTitle'),
  subtitle: string(),
  code: string().required('product.missingProductSKU'),
  color: string(),
  retail_price: number()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .nullable(),
  release_date: string(),
  gender: string(),
  description: string(),
  thumbnail: string(),
  brand_id: string().required('product.missingProductBrand'),
  category_id: string().required('product.missingProductCategory'),
  size_chart_id: string().required('product.missingProductSizeChart'),
})

const ProductForm: React.FC<ProductFormProps> = ({
  product,
  isSubmitLoading,
  images,
  onSetImages,
  onSubmit,
}) => {
  const { t } = useTranslation()
  const categoryService = useAPIService('Category')
  const sizeChartService = useAPIService('SizeChart')
  const brandService = useAPIService('Brand')

  const [thumbnailImage, setThumbnailImage] = useState<Models.File | undefined>()

  useEffect(() => {
    if (images && images.length > 0) {
      setThumbnailImage(images?.find((x) => x?.file_id === product?.thumbnail_info?.file_id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images, product])

  const {
    setValue,
    reset,
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<ProductFormType>({
    resolver: yupResolver(validateSchema),
    mode: 'all',
  })

  // Default value
  useEffect(() => {
    setDefaultValue()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product])

  const setDefaultValue = useCallback(() => {
    if (!product) {
      reset()
      return
    }
    setValue('title', product.title || '', {
      shouldValidate: true,
    })
    setValue('subtitle', product.subtitle || '', {
      shouldValidate: true,
    })
    setValue('code', product.code || '', {
      shouldValidate: true,
    })
    setValue('color', product.color || '', {
      shouldValidate: true,
    })
    setValue('retail_price', product?.retail_price, {
      shouldValidate: true,
    })
    setValue('release_date', product.release_date || '', {
      shouldValidate: true,
    })
    setValue('gender', product.gender || '', {
      shouldValidate: true,
    })
    setValue('description', product.description || ''),
      {
        shouldValidate: true,
      }
    setValue('thumbnail', product.thumbnail || '', {
      shouldValidate: true,
    })
    setValue('brand_id', product.brand_id || '', {
      shouldValidate: true,
    })
    setValue('category_id', product.category_id || '', {
      shouldValidate: true,
    })
    setValue('size_chart_id', product.size_chart_id || '', {
      shouldValidate: true,
    })
  }, [product, reset, setValue])

  // Brand
  const brandID = useWatch({
    name: 'brand_id',
    control,
  })

  const onSelectBrandOption = useCallback(
    (item?: Models.Brand | null) => {
      setValue('brand_id', item ? item.brand_id : '', {
        shouldValidate: true,
      })
    },
    [setValue],
  )

  const { data: brands } = useQuery(
    ['brandService', 'getList', 'selectOptions'],
    () =>
      brandService.getList({
        pagination: {
          page: 1,
          perPage: 1000000,
        },
      }),
    {
      select: ({ data: res }) => {
        return (
          res?.data?.map((item) => ({
            ...item,
            label: item.name,
          })) || []
        )
      },
      onSuccess: (data) => {
        if (product) {
          const brand = data?.find((x) => x.brand_id === product.brand_id)
          if (!brand) return
          onSelectBrandOption(brand)
        }
      },
    },
  )

  const {
    data: brandOptions,
    onSelect: onSelectBrand,
    value: brandValue,
    filter: brandFilter,
  } = useSelection<Models.Brand>({
    items: brands || [],
    onSelect: onSelectBrandOption,
    valueKey: 'brand_id',
    defaultValue: brands?.find((x) => x.brand_id === brandID),
  })

  useEffect(() => {
    if (brandID) {
      onSelectBrand(brandID)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandID])

  // Categories
  const categoryID = useWatch({
    name: 'category_id',
    control,
  })

  const onSelectCategoryOption = useCallback(
    (item?: Models.Category | null) => {
      setValue('category_id', item ? item.category_id : '', {
        shouldValidate: true,
      })
    },
    [setValue],
  )

  const { data: categories } = useQuery(
    ['categoryService', 'getList', 'selectOptions'],
    () => categoryService.getList({}),
    {
      select: ({ data: res }) => {
        return (
          res?.data?.map((item) => ({
            ...item,
            label: item.name,
          })) || []
        )
      },
      onSuccess: (data) => {
        if (product) {
          const category = data?.find((x) => x.category_id === product.category_id)
          if (!category) return
          onSelectCategoryOption(category)
        }
      },
    },
  )

  const {
    data: categoryOptions,
    onSelect: onSelectCategory,
    value: categoryValue,
    filter: categoryFilter,
  } = useSelection<Models.Category>({
    items: categories || [],
    onSelect: onSelectCategoryOption,
    valueKey: 'category_id',
    defaultValue: categories?.find((x) => x.category_id === categoryID),
  })

  useEffect(() => {
    if (categoryID) {
      onSelectCategory(categoryID)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryID])

  // Size Charts
  const sizeChartID = useWatch({
    name: 'size_chart_id',
    control,
  })

  const onSelectSizeChartOption = useCallback(
    (item?: Models.SizeChart | null) => {
      setValue('size_chart_id', item ? item.size_chart_id : '', {
        shouldValidate: true,
      })
    },
    [setValue],
  )

  const { data: sizeCharts } = useQuery(
    ['sizeChartService', 'getList', 'selectOptions'],
    () => sizeChartService.list({}),
    {
      select: ({ data: res }) => {
        return (
          res?.data?.map((item) => ({
            ...item,
            label: item.name,
          })) || []
        )
      },
      onSuccess: (data) => {
        if (product) {
          const sizeChart = data?.find((x) => x.size_chart_id === product.size_chart_id)
          if (!sizeChart) return
          onSelectSizeChartOption(sizeChart)
        }
      },
    },
  )

  const {
    data: sizeChartOptions,
    onSelect: onSelectSizeChart,
    value: sizeChartValue,
    filter: sizeChartFilter,
  } = useSelection<Models.SizeChart>({
    items: sizeCharts || [],
    onSelect: onSelectSizeChartOption,
    valueKey: 'size_chart_id',
    defaultValue: sizeCharts?.find((x) => x.size_chart_id === sizeChartID),
  })

  useEffect(() => {
    if (sizeChartID) {
      onSelectSizeChart(sizeChartID)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sizeChartID])

  // Release date
  const releaseDate = useWatch({
    name: 'release_date',
    control,
  })

  const onChangeReleaseDate = useCallback(
    (date: Date) => {
      setValue('release_date', date ? date.toString() : '', {
        shouldValidate: true,
      })
    },
    [setValue],
  )

  useEffect(() => {
    if (product && product.release_date) {
      onChangeReleaseDate(new Date(product.release_date))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product])

  // Thumbnail
  const onSetThumbnailImage = useCallback(
    (item: Models.File) => {
      setThumbnailImage(item)
      setValue('thumbnail', item.file_id, {
        shouldValidate: true,
      })
    },
    [setValue],
  )

  // Submit
  const _onSubmit = useCallback(
    (data?: ProductFormType) => {
      if (!data) return
      onSubmit?.(data)
    },
    [onSubmit],
  )

  const onSuccessUploadFile = useCallback(
    (data: Models.File[]) => {
      onSetImages?.(data)
    },
    [onSetImages],
  )

  return (
    <Box>
      <form>
        <Grid gutter={20}>
          <Grid.Col md={6}>
            <Input.Wrapper
              label={`${t('product.productTitle')} *`}
              error={errors.title && t(errors.title.message as string)}
            >
              <Input {...register('title')} />
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col md={6}>
            <Input.Wrapper label={t('product.productSubTitle')}>
              <Input {...register('subtitle')} />
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col md={6}>
            <Input.Wrapper
              label={`${t('product.sku')} *`}
              error={errors.code && t(errors.code.message as string)}
            >
              <Input {...register('code')} />
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col md={6}>
            <Input.Wrapper label={t('product.productColor')}>
              <Input {...register('color')} />
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col md={6}>
            <Select
              label={t('product.productBrand')}
              data={brandOptions}
              value={brandValue}
              onChange={onSelectBrand}
              filter={brandFilter}
              error={!brandID && t('product.missingProductBrand')}
              searchable
            />
          </Grid.Col>
          <Grid.Col md={6}>
            <Select
              label={t('product.productCategory')}
              data={categoryOptions}
              value={categoryValue}
              onChange={onSelectCategory}
              filter={categoryFilter}
              error={!categoryID && t('product.missingProductCategory')}
              searchable
            />
          </Grid.Col>
          <Grid.Col md={6}>
            <Input.Wrapper label={`${t('product.productRetailPrice')} ($)`}>
              <Input
                type='number'
                {...register('retail_price')}
                error={errors.retail_price && t(errors.retail_price.message as string)}
              />
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col md={6}>
            <DatePickerInput
              label={t('product.productReleaseDate')}
              valueFormat={'DD/MM/YYYY'}
              clearable
              value={releaseDate ? new Date(releaseDate) : null}
              onChange={onChangeReleaseDate}
            />
          </Grid.Col>
          <Grid.Col>
            <Input.Wrapper label={t('product.productGender')}>
              <Input {...register('gender')} />
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col>
            <Select
              label={t('product.productSizeCharts')}
              data={sizeChartOptions}
              value={sizeChartValue}
              onChange={onSelectSizeChart}
              filter={sizeChartFilter}
              error={!sizeChartID && t('product.missingProductSizeChart')}
              searchable
            />
          </Grid.Col>
          <Grid.Col>
            <Textarea
              label={t('product.productDescription')}
              autosize
              minRows={2}
              maxRows={4}
              {...register('description')}
            />
          </Grid.Col>
          <Grid.Col>
            <CardImageForm
              imageData={(images?.filter((x) => x) as Models.File[]) || []}
              isMainImage
              mainImageData={thumbnailImage}
              onSubmit={onSuccessUploadFile}
              onSetMainImage={onSetThumbnailImage}
              multiple
            />
          </Grid.Col>
        </Grid>

        <Flex my={40} justify='center' align='center'>
          <Button
            variant='highlight'
            loading={isSubmitLoading}
            disabled={!isValid}
            onClick={handleSubmit(_onSubmit)}
          >
            {t('common.save')}
          </Button>
        </Flex>
      </form>
    </Box>
  )
}

export default ProductForm
