import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BiSearch } from 'react-icons/bi'
import { Button, Grid, Select, Text, TextInput } from '@mantine/core'
import { useDebouncedState } from '@mantine/hooks'
import get from 'lodash/get'

import {
  ConditionData,
  ConditionDataType,
  SellingStatusData,
  SellingStatusDataType,
} from '@/constants/selling'
import { useSelection } from '@/hooks/useSelection'
import { cleanAccents } from '@/utils/string'

type SellingItemListFilterProps = {
  queryData: SellingItemListSearchType
  onChangeQueries: (query: SellingItemListSearchType) => void
}

const SellingItemListFilter: React.FC<SellingItemListFilterProps> = ({
  queryData,
  onChangeQueries,
}) => {
  const { t } = useTranslation()

  const [productKeysearch, setProductKeysearch] = useDebouncedState(queryData.product, 400)
  const [sellerKeysearch, setSellerKeysearch] = useDebouncedState(queryData.seller, 400)
  const [selectedSellingStatus, setSelectedSellingStatus] = useState<SellingStatusDataType | null>()
  const [selectedCondition, setSelectedCondition] = useState<ConditionDataType | null>()

  const onSetProductKeysearch = useCallback(
    async (event?: React.ChangeEvent<HTMLInputElement>) => {
      const value = event?.target?.value as string
      setProductKeysearch(value)
    },
    [setProductKeysearch],
  )

  const onSetSellerKeysearch = useCallback(
    async (event?: React.ChangeEvent<HTMLInputElement>) => {
      const value = event?.target?.value as string
      setSellerKeysearch(value)
    },
    [setSellerKeysearch],
  )

  // Status
  const onSelectSellingStatusOption = useCallback((item?: SellingStatusDataType | null) => {
    setSelectedSellingStatus(item)
  }, [])

  const {
    data: sellingStatusOptions,
    onSelect: onSelectSellingStatus,
    value: sellingStatusValue,
    filter: sellingStatusFilter,
  } = useSelection<SellingStatusDataType>({
    items: SellingStatusData.map((item) => ({
      ...item,
      label: t(item.label),
    })),
    onSelect: onSelectSellingStatusOption,
    valueKey: 'label',
    allOption: true,
  })

  // Condition
  const onSelectConditionOption = useCallback((item?: ConditionDataType | null) => {
    setSelectedCondition(item)
  }, [])

  const {
    data: conditionOptions,
    onSelect: onSelectCondition,
    value: conditionValue,
    filter: conditionFilter,
  } = useSelection<ConditionDataType>({
    items: ConditionData.map((item) => ({
      ...item,
      label: t(item.label),
    })),
    onSelect: onSelectConditionOption,
    valueKey: 'label',
    allOption: true,
  })

  useEffect(() => {
    if (queryData.status) {
      const data = SellingStatusData.find((x) => x.value.toString() == queryData.status)
      onSelectSellingStatus(t(data?.label || ''))
    }
    if (queryData.condition) {
      const data = ConditionData.find((x) => x.value.toString() == queryData.condition)
      onSelectCondition(t(data?.label || ''))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData.status, queryData.condition])

  const _onChangeQueries = useCallback(() => {
    const result = {
      ...(productKeysearch ? { product: cleanAccents(productKeysearch) } : ''),
      ...(sellerKeysearch ? { seller: cleanAccents(sellerKeysearch) } : ''),
      ...(selectedSellingStatus ? { status: selectedSellingStatus.value.toString() } : {}),
      ...(selectedCondition ? { condition: selectedCondition.value.toString() } : {}),
    }

    onChangeQueries(result)
  }, [onChangeQueries, productKeysearch, selectedCondition, selectedSellingStatus, sellerKeysearch])

  return (
    <Grid gutter={16} align='end'>
      <Grid.Col md={3}>
        <TextInput
          label={t('sellingItem.product')}
          icon={<BiSearch size='1rem' />}
          placeholder={t('sellingItem.searchByProductKeyword')}
          onChange={onSetProductKeysearch}
          defaultValue={queryData.product}
        />
      </Grid.Col>
      <Grid.Col md={3}>
        <TextInput
          label={t('sellingItem.seller')}
          icon={<BiSearch size='1rem' />}
          placeholder={t('sellingItem.searchByUserKeyword')}
          onChange={onSetSellerKeysearch}
          defaultValue={queryData.seller}
        />
      </Grid.Col>
      <Grid.Col md={3}>
        <Select
          label={t('sellingItem.sellingStatus')}
          data={sellingStatusOptions}
          value={sellingStatusValue}
          onChange={onSelectSellingStatus}
          filter={sellingStatusFilter}
          defaultValue={get(sellingStatusOptions[0], 'value')}
          searchable
        />
      </Grid.Col>
      <Grid.Col md={3}>
        <Select
          label={t('common.condition')}
          data={conditionOptions}
          value={conditionValue}
          onChange={onSelectCondition}
          filter={conditionFilter}
          defaultValue={get(conditionOptions[0], 'value')}
          searchable
        />
      </Grid.Col>
      <Grid.Col md={3}>
        <Button color='success.4' onClick={_onChangeQueries}>
          <Text>{t('common.searchButton')}</Text>
        </Button>
      </Grid.Col>
    </Grid>
  )
}

export default SellingItemListFilter
