import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineTrash } from 'react-icons/hi'
import { TbGridDots } from 'react-icons/tb'
import { useMutation } from 'react-query'
import { DraggableProvided } from '@hello-pangea/dnd'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Box, Button, createStyles, Flex, Input } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { notifications } from '@mantine/notifications'

import ConfirmationModal from '@/components/ModalForm/Confirmation'

import { SizeChartAddSizeType } from '../..'

const useStyles = createStyles((_theme) => ({
  edit_table: {
    width: 'fit-content',
    maxWidth: 36,
    '.mantine-Input-input': {
      width: 'fit-content',
      maxWidth: 36,
      borderRadius: '2px',
      '&:focus': {
        border: `1px solid ${_theme.colors.primary[2]}`,
      },
    },
  },
}))

type SizeChartListSizeRowProps = {
  size: SizeChartAddSizeType
  provided: DraggableProvided
  onEdit: (size: SizeChartAddSizeType) => void
  onRemove: (size: SizeChartAddSizeType) => void
  onRefresh: () => void
}

const SizeChartListSizeRow: React.FC<SizeChartListSizeRowProps> = ({
  size,
  provided,
  onEdit,
  onRemove,
  onRefresh,
}) => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const sizeService = useAPIService('Size')

  const [removeOpened, removeModal] = useDisclosure(false)

  const [sizeData, setSizeData] = useState<SizeChartAddSizeType>(size)

  useEffect(() => {
    setSizeData(size)
  }, [size])

  const onChangeValue = useCallback(
    (event?: React.ChangeEvent<HTMLInputElement>) => {
      if (!event) return

      const { name, value } = event?.target ?? {}
      const data: SizeChartAddSizeType = {
        ...sizeData,
        [name]: value,
      }
      setSizeData(data)
      onEdit(data)
    },
    [onEdit, sizeData],
  )

  const { mutate: remove, isLoading: isRemoveLoading } = useMutation(
    (sizeID: string) => {
      return sizeService.delete({ id: sizeID })
    },
    {
      onSuccess: async () => {
        removeModal.close()
        notifications.show({
          title: t('common.removeTitle'),
          variant: 'success',
          message: t('success.deletedSize'),
        })
        onRemove(size)
        onRefresh()
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        const errorMessage = t(err?.response?.data, {
          ns: 'backend',
        })

        notifications.show({
          variant: 'danger',
          message: errorMessage,
        })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        err?.response?.data?.errors?.forEach((err: any) => {
          notifications.show({
            variant: 'danger',
            message: t(err, {
              ns: 'backend',
            }),
          })
        })
      },
    },
  )

  const _onRemove = useCallback(() => {
    if (!size.size_id) return
    remove(size.size_id)
  }, [remove, size.size_id])

  const _onClickRemove = useCallback(() => {
    if (size.is_new) {
      onRemove(size)
    } else {
      removeModal.open()
    }
  }, [onRemove, removeModal, size])

  return (
    <tr ref={provided.innerRef} {...provided.draggableProps}>
      <td>
        <Box {...provided.dragHandleProps} style={{ cursor: 'grab' }}>
          <TbGridDots />
        </Box>
      </td>
      <td>
        <Input
          className={classes.edit_table}
          placeholder='--'
          variant='unstyled'
          name='EU'
          defaultValue={size.EU || ''}
          onChange={onChangeValue}
        />
      </td>
      <td>
        <Input
          className={classes.edit_table}
          placeholder='--'
          variant='unstyled'
          name='USM'
          defaultValue={size.USM || ''}
          onChange={onChangeValue}
        />
      </td>
      <td>
        <Input
          className={classes.edit_table}
          placeholder='--'
          variant='unstyled'
          name='USW'
          defaultValue={size.USW || ''}
          onChange={onChangeValue}
        />
      </td>
      <td>
        <Input
          className={classes.edit_table}
          placeholder='--'
          variant='unstyled'
          name='UK'
          defaultValue={size.UK || ''}
          onChange={onChangeValue}
        />
      </td>
      <td>
        <Input
          className={classes.edit_table}
          placeholder='--'
          variant='unstyled'
          name='CM'
          defaultValue={size.CM || ''}
          onChange={onChangeValue}
        />
      </td>
      <td>
        <Input
          className={classes.edit_table}
          placeholder='--'
          variant='unstyled'
          name='KR'
          defaultValue={size.KR || ''}
          onChange={onChangeValue}
        />
      </td>
      <td>
        <Input
          className={classes.edit_table}
          placeholder='--'
          variant='unstyled'
          name='US_GS'
          defaultValue={size.US_GS || ''}
          onChange={onChangeValue}
        />
      </td>
      <td>
        <Input
          className={classes.edit_table}
          placeholder='--'
          variant='unstyled'
          name='US_PS'
          defaultValue={size.US_PS || ''}
          onChange={onChangeValue}
        />
      </td>
      <td>
        <Input
          className={classes.edit_table}
          placeholder='--'
          variant='unstyled'
          name='US_TD'
          defaultValue={size.US_TD || ''}
          onChange={onChangeValue}
        />
      </td>
      <td>
        <Flex justify='start' align='center'>
          <Button variant='subtle' p={10} onClick={_onClickRemove}>
            <HiOutlineTrash color='red' />
          </Button>
        </Flex>
      </td>

      <ConfirmationModal
        title={t('common.removeTitle')}
        description={t('size.removeSize', { EU: size.EU })}
        isLoading={isRemoveLoading}
        opened={removeOpened}
        onClose={removeModal.close}
        onConfirm={_onRemove}
      />
    </tr>
  )
}

export default SizeChartListSizeRow
