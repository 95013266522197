import { ChangeEvent, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { InvoiceDTO } from '@kicksplanet/api-client'
import { Checkbox, Text, Tooltip } from '@mantine/core'

import { formatDate } from '@/utils/date'
import { DATE_FORMATS } from '@/utils/date-format'
import { formatPrice } from '@/utils/number'
import { getOrderDetailUrl } from '@/utils/url'

type InvoiceListRowProps = {
  invoice: InvoiceDTO
  checkedItems?: InvoiceDTO[]
  onToggleRow?: (event: ChangeEvent<HTMLInputElement>, item: InvoiceDTO) => void
}

const InvoiceListRow: React.FC<InvoiceListRowProps> = ({ checkedItems, invoice, onToggleRow }) => {
  const _onToggleRow = useCallback(
    (event: ChangeEvent<HTMLInputElement>, item: InvoiceDTO) => {
      onToggleRow?.(event, item)
    },
    [onToggleRow],
  )

  return (
    <tr>
      <td>
        <Checkbox
          size='sm'
          checked={checkedItems?.includes(invoice)}
          onChange={(e) => _onToggleRow(e, invoice)}
        />
      </td>
      <td>
        <Link
          to={getOrderDetailUrl(invoice.ref_id)}
          style={{ textDecoration: 'none', color: 'black' }}
        >
          <Tooltip label={invoice.ref_id}>
            <Text weight={600}>{invoice.ref_id}</Text>
          </Tooltip>
        </Link>
      </td>
      <td>
        <Tooltip label={invoice.customer_name}>
          <Text lineClamp={1} w='fit-content'>
            {invoice.customer_name}
          </Text>
        </Tooltip>
      </td>
      <td>
        <Tooltip label={formatDate(invoice.invoice_date)}>
          <Text lineClamp={1} w='fit-content'>
            {formatDate(invoice.invoice_date)}
          </Text>
        </Tooltip>
      </td>
      <td>
        {(() => {
          const value = formatPrice(invoice.total || 0)
          return (
            <Tooltip label={value}>
              <Text lineClamp={1} w='fit-content'>
                {value}
              </Text>
            </Tooltip>
          )
        })()}
      </td>
      <td>
        {invoice.exported_at ? (
          <Tooltip label={formatDate(invoice.exported_at, DATE_FORMATS.YYYY_DD_MM_HH_MM_SS_DASH)}>
            <Text lineClamp={1} w='fit-content'>
              {formatDate(invoice.exported_at, DATE_FORMATS.YYYY_DD_MM_HH_MM_SS_DASH)}
            </Text>
          </Tooltip>
        ) : null}
      </td>
      <td>
        <Tooltip label={`${invoice.vat_percentage}%`}>
          <Text lineClamp={1} w='fit-content'>
            {`${invoice.vat_percentage}%`}
          </Text>
        </Tooltip>
      </td>
    </tr>
  )
}

export default InvoiceListRow
