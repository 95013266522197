import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Flex, Grid, Image, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'

import Empty from '@/components/Empty'
import GalleryModal from '@/components/GalleryModal'
import Pagination from '@/components/Pagination/Pagination'
import TableWrapper from '@/components/TableWrapper'
import { DEFAULT_PAGINATION_QUERY } from '@/constants/pagination'
import { formatDate } from '@/utils/date'
import { DATE_FORMATS } from '@/utils/date-format'
import { formatNumber } from '@/utils/number'

import { ProductImportFileType } from '../SelectFile'

type ProductImportDataProps = {
  importData?: ProductImportFileType[]
}

const COLUMN_CAPTION = [
  'product.productImages',
  'brand.brand',
  'product.sku',
  'product.productTitle',
  'product.productColor',
  'product.productGender',
  'product.productSizeCharts',
  'product.productRetailPrice',
  'product.productReleaseDate',
]

const ProductImportData: React.FC<ProductImportDataProps> = ({ importData }) => {
  const { t } = useTranslation()

  const [galleryOpen, galleryModal] = useDisclosure(false, {
    onClose: () => {
      setImagesView([])
    },
  })

  const [imagesView, setImagesView] = useState<string[]>()
  const [listData, setListData] = useState<ProductImportFileType[]>([])
  const [page, setPage] = useState<number>(1)

  useEffect(() => {
    onSetListData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, importData])

  const onViewImages = useCallback(
    (imageUrl: string[]) => {
      setImagesView(imageUrl)
      galleryModal.open()
    },
    [galleryModal],
  )

  const onChangePage = useCallback((page: number) => {
    setPage(page)
  }, [])

  const onSetListData = useCallback(() => {
    const data = importData?.slice(
      (page - 1) * DEFAULT_PAGINATION_QUERY.limit,
      page * DEFAULT_PAGINATION_QUERY.limit,
    )
    setListData(data || [])
  }, [importData, page])

  const RowData = useMemo(() => {
    if (!listData || listData.length <= 0)
      return (
        <tr>
          <td colSpan={9}>
            <Empty />
          </td>
        </tr>
      )

    return listData.map((product) => {
      return (
        <tr key={product.code}>
          <td>
            {product.images?.length > 0 && (
              <Box
                pos='relative'
                sx={{
                  width: 150,
                  cursor: 'pointer',
                }}
                onClick={() => onViewImages(product.images)}
              >
                <Grid>
                  {product.images.slice(0, 2).map((image, imageIndex) => {
                    return (
                      <Grid.Col key={imageIndex} span={6} bg='gray.1'>
                        <Flex
                          justify='center'
                          align='center'
                          sx={(_theme) => ({
                            width: 68,
                            height: 68,
                            borderRadius: '2px',
                            background: _theme.colors.gray[5],
                            img: {
                              width: '100%',
                              height: '100%',
                              borderRadius: '2px',
                              objectFit: 'contain',
                            },
                          })}
                        >
                          <Image src={image} alt='' width={70} height={70} />
                        </Flex>
                      </Grid.Col>
                    )
                  })}
                </Grid>
                {product.images?.length > 2 && (
                  <Flex
                    justify='center'
                    align='center'
                    p={4}
                    sx={(_theme) => ({
                      position: 'absolute',
                      width: 30,
                      height: 30,
                      top: 4,
                      right: -4,
                      borderRadius: '50%',
                      background: _theme.colors.primary[4],
                    })}
                  >
                    <Text color='white' fs='xs'>
                      {`+${product.images?.length - 2}`}
                    </Text>
                  </Flex>
                )}
              </Box>
            )}
          </td>
          <td>
            <Text>{product.brand}</Text>
          </td>
          <td>
            <Text>{product.code}</Text>
          </td>
          <td>
            <Text>{product.title}</Text>
          </td>
          <td>
            <Text>{product.color}</Text>
          </td>
          <td>
            <Text>{product.gender || '--'}</Text>
          </td>
          <td>
            <Text>{product.size_chart}</Text>
          </td>
          <td>
            <Text>{`$${formatNumber(product.retail_price || 0)}`}</Text>
          </td>
          <td>
            <Text>
              {product.release_date ? formatDate(product.release_date, DATE_FORMATS.DEFAULT) : '--'}
            </Text>
          </td>
        </tr>
      )
    })
  }, [listData, onViewImages])

  return (
    <>
      <TableWrapper>
        <thead>
          <tr>
            {COLUMN_CAPTION.map((item, key) => {
              return <th key={key}>{t(item)}</th>
            })}
          </tr>
        </thead>
        <tbody>{RowData}</tbody>
      </TableWrapper>

      <Pagination
        limit={DEFAULT_PAGINATION_QUERY.limit}
        page={page}
        total={importData?.length || 0}
        onChange={onChangePage}
      />

      <GalleryModal
        title={t('gallery.images')}
        isOpen={galleryOpen}
        onClose={galleryModal.close}
        imageUrls={imagesView}
        loop
      />
    </>
  )
}

export default ProductImportData
