import { useCallback, useEffect } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { yupResolver } from '@hookform/resolvers/yup'
import { Models, UserResponses } from '@kicksplanet/interfaces'
import { BankModel } from '@kicksplanet/interfaces/dist/models/bank.model'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Box, Button, Flex, Grid, Input, Loader, Select } from '@mantine/core'
import { number, object, string } from 'yup'

import { UserRoleData, UserRoleDataType } from '@/constants/user'
import { useSelection } from '@/hooks/useSelection'

export type UserFormType = Pick<
  Models.User,
  'first_name' | 'last_name' | 'email' | 'phone_number' | 'credits' | 'warnings'
> &
  Pick<Models.UserProfile, 'id_card_no' | 'tax_no'> & {
    role: string
    bank_code: string
    bank_account_number: string
    bank_account_name: string
  }

type UserFormProps = {
  user?: UserResponses.User
  onSubmit?: (data: UserFormType) => void
  isSubmitLoading?: boolean
}

const validateSchema = object({
  first_name: string().max(255, 'user.firstNameTooLong').required('user.missingFirstName'),
  last_name: string().max(255, 'user.lastNameTooLong').required('user.missingLastName'),
  email: string().email().required('user.missingEmail'),
  id_card_no: string().max(20, 'user.idCardNoTooLong').nullable(),
  tax_no: string().max(20, 'user.taxNoTooLong').nullable(),
  phone_number: string().max(50, 'user.phoneNumberTooLong').required('user.missingPhoneNumber'),
  role: string().required('user.missingRole'),
  credits: number(),
  warnings: number(),
  bank_code: string(),
  bank_account_number: string().when('bank_code', {
    is: (bankCode: string) => !!bankCode,
    then: string().required('user.missingBankAccountNumber'),
    otherwise: string(),
  }),
  bank_account_name: string().when('bank_code', {
    is: (bankCode: string) => !!bankCode,
    then: string().required('user.missingBankAccountName'),
    otherwise: string(),
  }),
})

const UserForm: React.FC<UserFormProps> = ({ user, onSubmit, isSubmitLoading }) => {
  const { t } = useTranslation()
  const qrService = useAPIService('QR')

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors, isValid },
  } = useForm<UserFormType>({
    mode: 'all',
    resolver: yupResolver(validateSchema),
  })

  // Default value
  useEffect(() => {
    setDefaultValue()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const setDefaultValue = useCallback(() => {
    if (!user) {
      reset()
      return
    }
    setValue('first_name', user.first_name || '', {
      shouldValidate: true,
    })
    setValue('last_name', user.last_name || '', {
      shouldValidate: true,
    })
    setValue('email', user.email || '', {
      shouldValidate: true,
    })
    setValue('phone_number', user.phone_number || '', {
      shouldValidate: true,
    })
    setValue('id_card_no', user?.user_profile?.id_card_no || '', {
      shouldValidate: true,
    })
    setValue('tax_no', user?.user_profile?.tax_no || '', {
      shouldValidate: true,
    })
    setValue('credits', user.credits || 0, {
      shouldValidate: true,
    })
    setValue('warnings', user.warnings || 0, {
      shouldValidate: true,
    })
    setValue('role', user.role || '', {
      shouldValidate: true,
    })
    setValue('bank_code', user?.payment_info?.bank_code || '', {
      shouldValidate: true,
    })
    setValue('bank_account_name', user?.payment_info?.bank_account_name || '', {
      shouldValidate: true,
    })
    setValue('bank_account_number', user?.payment_info?.bank_account_number || '', {
      shouldValidate: true,
    })
  }, [user, setValue, reset])

  // Bank
  const bankCode = useWatch({
    name: 'bank_code',
    control,
  })

  const { data: banks = [], isLoading: banksLoading } = useQuery(
    ['qrService', 'getBanks', 'selectOptions'],
    () => qrService.getBanks().then((res) => res.data.data),
    {
      select: (data) => {
        return (
          data?.map((item) => ({
            ...item,
            label: `${item.name} (${item.shortName})`,
          })) || []
        )
      },
      onSuccess: (data) => {
        if ((user as any)?.payment_info) {
          const bank = data?.find((x) => x.code == (user as any)?.payment_info?.bank_code)

          if (!bank) return
          onSelectBankOptions(bank)
        }
      },
    },
  )

  const onSelectBankOptions = useCallback(
    (item?: BankModel | null) => {
      setValue('bank_code', item ? item.code : '', {
        shouldValidate: true,
      })
    },
    [setValue],
  )

  const {
    data: bankOptions,
    onSelect: onSelectBank,
    value: bankValue,
    filter: bankFilter,
  } = useSelection<BankModel>({
    items: banks,
    onSelect: onSelectBankOptions,
    valueKey: 'code',
    defaultValue: banks.find((x) => x.code.toString() == bankCode),
  })

  useEffect(() => {
    if (bankCode) {
      onSelectBank(bankCode)
    } else {
      onSelectBank('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankCode])

  // Role
  const role = useWatch({
    name: 'role',
    control,
  })

  const onSelectRoleOption = useCallback(
    (item?: UserRoleDataType | null) => {
      setValue('role', item ? item.value : '', {
        shouldValidate: true,
      })
    },
    [setValue],
  )

  const {
    data: roleOptions,
    onSelect: onSelectRole,
    value: roleValue,
  } = useSelection<UserRoleDataType>({
    items: UserRoleData.map((item) => ({
      ...item,
      label: t(item.label),
    })),
    onSelect: onSelectRoleOption,
    valueKey: 'value',
    defaultValue: UserRoleData?.find((x) => x.value === role),
  })

  useEffect(() => {
    if (user) {
      const data = UserRoleData?.find((x) => x.value === user.role)
      if (!data) return
      onSelectRoleOption(data)
    }

    if (role) {
      onSelectRole(role)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role])

  const _onSubmit = useCallback(
    (data: UserFormType) => {
      if (!data) return
      onSubmit?.(data)
    },
    [onSubmit],
  )

  return (
    <Box>
      <form>
        <Grid gutter={20}>
          <Grid.Col md={6}>
            <Input.Wrapper
              label={`${t('user.firstName')} *`}
              error={errors.first_name && t(errors.first_name.message as string)}
            >
              <Input {...register('first_name')} />
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col md={6}>
            <Input.Wrapper
              label={`${t('user.lastName')} *`}
              error={errors.last_name && t(errors.last_name.message as string)}
            >
              <Input {...register('last_name')} />
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col md={6}>
            <Input.Wrapper
              label={`${t('user.email')} *`}
              error={errors.email && t(errors.email.message as string)}
            >
              <Input {...register('email')} />
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col md={6}>
            <Input.Wrapper
              label={`${t('user.phoneNumber')} *`}
              error={errors.phone_number && t(errors.phone_number.message as string)}
            >
              <Input {...register('phone_number')} />
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col md={6}>
            <Input.Wrapper
              label={`${t('user.idCardNo')}`}
              error={errors.id_card_no && t(errors.id_card_no.message as string)}
            >
              <Input {...register('id_card_no')} />
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col md={6}>
            <Input.Wrapper
              label={`${t('user.taxNo')}`}
              error={errors.tax_no && t(errors.tax_no.message as string)}
            >
              <Input {...register('tax_no')} />
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col>
            <Select
              label={t('user.role')}
              data={roleOptions}
              value={roleValue}
              onChange={onSelectRole}
              error={!role && t('user.missingRole')}
              // defaultValue={get(UserRoleData[0], "value")}
            />
          </Grid.Col>
          <Grid.Col md={6}>
            <Input.Wrapper
              label={`${t('user.credits')}`}
              error={errors.credits && t(errors.credits.message as string)}
            >
              <Input type='number' {...register('credits')} />
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col md={6}>
            <Input.Wrapper
              label={`${t('user.warnings')}`}
              error={errors.warnings && t(errors.warnings.message as string)}
            >
              <Input type='number' {...register('warnings')} />
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col md={12}>
            <Select
              label={t('user.bank')}
              searchable
              data={bankOptions}
              value={bankValue}
              onChange={onSelectBank}
              nothingFound={t('bank.selectOption.noData')}
              filter={bankFilter}
              rightSection={banksLoading && <Loader size='xs' color='red.5' />}
            />
          </Grid.Col>
          <Grid.Col md={6}>
            <Input.Wrapper
              label={`${t('user.bankAccountName')}`}
              error={errors.bank_account_name && t(errors.bank_account_name.message as string)}
            >
              <Input {...register('bank_account_name')} />
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col md={6}>
            <Input.Wrapper
              label={`${t('user.bankAccountNumber')}`}
              error={errors.bank_account_number && t(errors.bank_account_number.message as string)}
            >
              <Input {...register('bank_account_number')} />
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col>
            <Flex my={40} justify='center' align='center'>
              <Button
                variant='highlight'
                loading={isSubmitLoading}
                disabled={!isValid}
                onClick={handleSubmit(_onSubmit)}
              >
                {t('common.save')}
              </Button>
            </Flex>
          </Grid.Col>
        </Grid>
      </form>
    </Box>
  )
}

export default UserForm
