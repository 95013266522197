import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BiSearch } from 'react-icons/bi'
import { Button, Grid, Select, Text, TextInput } from '@mantine/core'
import { useDebouncedState } from '@mantine/hooks'
import get from 'lodash/get'

import {
  VoucherApplyForData,
  VoucherApplyOnData,
  VoucherAttributeDataType,
  VoucherUnitData,
} from '@/constants/voucher'
import { useSelection } from '@/hooks/useSelection'
import { cleanAccents } from '@/utils/string'

type VoucherListFilterProps = {
  queryData: VoucherListSearchType
  onChangeQueries: (query: VoucherListSearchType) => void
}

const VoucherListFilter: React.FC<VoucherListFilterProps> = ({ queryData, onChangeQueries }) => {
  const { t } = useTranslation()

  const [keysearch, setKeysearch] = useDebouncedState(queryData.key, 400)
  const [selectedVoucherUnit, setSelectedVoucherUnit] = useState<VoucherAttributeDataType | null>()
  const [selectedVoucherApplyOn, setSelectedVoucherApplyOn] =
    useState<VoucherAttributeDataType | null>()
  const [selectedVoucherApplyFor, setSelectedVoucherApplyFor] =
    useState<VoucherAttributeDataType | null>()

  const onSetKeysearch = useCallback(
    async (event?: React.ChangeEvent<HTMLInputElement>) => {
      const value = event?.target?.value as string
      setKeysearch(value)
    },
    [setKeysearch],
  )

  // VoucherUnit
  const onSelectVoucherUnitOption = useCallback((item?: VoucherAttributeDataType | null) => {
    setSelectedVoucherUnit(item)
  }, [])

  const {
    data: voucherUnitOptions,
    onSelect: onSelectVoucherUnit,
    value: voucherUnitValue,
    filter: voucherUnitFilter,
  } = useSelection<VoucherAttributeDataType>({
    items: VoucherUnitData.map((item) => ({
      ...item,
      label: t(item.label),
    })),
    onSelect: onSelectVoucherUnitOption,
    valueKey: 'value',
    allOption: true,
  })

  useEffect(() => {
    if (queryData.unit) {
      const data = VoucherUnitData.find((x) => x.value.toString() == queryData.unit)
      onSelectVoucherUnit(data?.value as any)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData.unit])

  // VoucherApplyOn
  const onSelectVoucherApplyOnOption = useCallback((item?: VoucherAttributeDataType | null) => {
    setSelectedVoucherApplyOn(item)
  }, [])

  const {
    data: voucherApplyOnOptions,
    onSelect: onSelectVoucherApplyOn,
    value: voucherApplyOnValue,
    filter: voucherApplyOnFilter,
  } = useSelection<VoucherAttributeDataType>({
    items: VoucherApplyOnData.map((item) => ({
      ...item,
      label: t(item.label),
    })),
    onSelect: onSelectVoucherApplyOnOption,
    valueKey: 'value',
    allOption: true,
  })

  useEffect(() => {
    if (queryData.appliedOn) {
      const data = VoucherApplyOnData.find((x) => x.value.toString() == queryData.appliedOn)
      onSelectVoucherApplyOn(data?.value as any)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData.appliedOn])

  // VoucherApplyFor
  const onSelectVoucherApplyForOption = useCallback((item?: VoucherAttributeDataType | null) => {
    setSelectedVoucherApplyFor(item)
  }, [])

  const {
    data: voucherApplyForOptions,
    onSelect: onSelectVoucherApplyFor,
    value: voucherApplyForValue,
    filter: voucherApplyForFilter,
  } = useSelection<VoucherAttributeDataType>({
    items: VoucherApplyForData.map((item) => ({
      ...item,
      label: t(item.label),
    })),
    onSelect: onSelectVoucherApplyForOption,
    valueKey: 'value',
    allOption: true,
  })

  useEffect(() => {
    if (queryData.applicableFor) {
      const data = VoucherApplyForData.find((x) => x.value.toString() == queryData.applicableFor)
      onSelectVoucherApplyFor(data?.value as any)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData.applicableFor])

  const _onChangeQueries = useCallback(() => {
    const result = {
      ...(keysearch ? { key: cleanAccents(keysearch) } : ''),
      ...(selectedVoucherUnit ? { unit: selectedVoucherUnit.value.toString() } : {}),
      ...(selectedVoucherApplyOn ? { appliedOn: selectedVoucherApplyOn.value.toString() } : {}),
      ...(selectedVoucherApplyFor
        ? { applicableFor: selectedVoucherApplyFor.value.toString() }
        : {}),
    }

    onChangeQueries(result)
  }, [
    keysearch,
    onChangeQueries,
    selectedVoucherApplyFor,
    selectedVoucherApplyOn,
    selectedVoucherUnit,
  ])

  return (
    <Grid gutter={16}>
      <Grid.Col md={3}>
        <TextInput
          label={t('common.keyword')}
          icon={<BiSearch size='1rem' />}
          placeholder={t('voucher.searchByKeyword')}
          onChange={onSetKeysearch}
          defaultValue={keysearch}
        />
      </Grid.Col>
      <Grid.Col md={3}>
        <Select
          label={t('voucher.selectVoucherUnit')}
          placeholder={t('voucher.selectVoucherUnit')}
          data={voucherUnitOptions}
          value={voucherUnitValue}
          onChange={onSelectVoucherUnit}
          filter={voucherUnitFilter}
          defaultValue={get(voucherUnitOptions[0], 'value')}
          searchable
        />
      </Grid.Col>
      <Grid.Col md={3}>
        <Select
          label={t('voucher.selectApplyOn')}
          placeholder={t('voucher.selectApplyOn')}
          data={voucherApplyOnOptions}
          value={voucherApplyOnValue}
          onChange={onSelectVoucherApplyOn}
          filter={voucherApplyOnFilter}
          defaultValue={get(voucherApplyOnOptions[0], 'value')}
          searchable
        />
      </Grid.Col>
      <Grid.Col md={3}>
        <Select
          label={t('voucher.selectApplyFor')}
          placeholder={t('voucher.selectApplyFor')}
          data={voucherApplyForOptions}
          value={voucherApplyForValue}
          onChange={onSelectVoucherApplyFor}
          filter={voucherApplyForFilter}
          defaultValue={get(voucherApplyForOptions[0], 'value')}
          searchable
        />
      </Grid.Col>
      <Grid.Col md={2}>
        <Button color='success.4' onClick={_onChangeQueries}>
          <Text>{t('common.searchButton')}</Text>
        </Button>
      </Grid.Col>
    </Grid>
  )
}

export default VoucherListFilter
