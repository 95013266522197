import { SellingItemCondition } from '@kicksplanet/enums'

export const SellingStatusType = {
  APPROVED: 1,
  PENDING: 2,
  REJECTED: 3,
}

export type SellingStatusDataType = {
  value: number
  label: string
}

export const SellingStatusData: SellingStatusDataType[] = [
  {
    value: SellingStatusType.APPROVED,
    label: 'sellingItem.sellingApproved',
  },
  {
    value: SellingStatusType.PENDING,
    label: 'sellingItem.sellingPending',
  },
  {
    value: SellingStatusType.REJECTED,
    label: 'sellingItem.sellingRejected',
  },
]

export type ConditionDataType = {
  value: string
  label: string
}

export const ConditionData: ConditionDataType[] = [
  {
    value: SellingItemCondition.NEW,
    label: 'common.new',
  },
  {
    value: SellingItemCondition.USED,
    label: 'common.used',
  },
]
