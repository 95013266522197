import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaRegImage } from 'react-icons/fa'
import { HiOutlineTrash, HiUpload } from 'react-icons/hi'
import { MdCancel, MdUpload } from 'react-icons/md'
import { useQuery } from 'react-query'
import { Models } from '@kicksplanet/interfaces'
import { useAPIService } from '@kicksplanet/react/hooks'
import { GetListParams } from '@kicksplanet/services/dist/services/core/CURDService'
import {
  Badge,
  Box,
  Button,
  Card,
  Flex,
  Grid,
  Image,
  Modal,
  Skeleton,
  Tabs,
  Text,
} from '@mantine/core'
import { Dropzone, FileRejection, IMAGE_MIME_TYPE } from '@mantine/dropzone'
import { useDisclosure } from '@mantine/hooks'
import { notifications } from '@mantine/notifications'

import ModalFormSimpleNotice from '@/components/ModalForm/SimpleNotice'
import { DEFAULT_PAGINATION_QUERY } from '@/constants/pagination'
import { formatBytes } from '@/utils/byte'

import Empty from '../Empty'
import Pagination from '../Pagination/Pagination'

type UploadMediaProps = {
  opened: boolean
  multiple?: boolean
  onClose: () => void
  limitFiles?: number
  disabled?: boolean
  onRefresh?: () => void
  onSuccess?: (files: Models.File[]) => void
}

type PreviewImageType = {
  file: File
  convertUrl: string
}

const TabForm = {
  SELECT_FILE: 'SELECT_FILE',
  UPLOAD_FILE: 'UPLOAD_FILE',
}

const UploadMedia: React.FC<UploadMediaProps> = ({
  opened,
  multiple = false,
  onClose,
  limitFiles,
  disabled,
  onRefresh,
  onSuccess,
}) => {
  const { t } = useTranslation()
  const fileService = useAPIService('File')

  const openRef = useRef<() => void>(null)
  const [isLoading, setIsloading] = useState<boolean>(false)
  const [filesUpload, setFilesUpload] = useState<PreviewImageType[]>([])
  const [overFilesOpened, overFilesModal] = useDisclosure(false)

  const [currentTabForm, setCurrentTabForm] = useState<string>(TabForm.SELECT_FILE)

  // region select file
  const [selectedFiles, setSelectedFile] = useState<Models.File[]>()
  const [filesListpage, setFilesListPage] = useState<number>(1)
  const [filesListLimit, setFilesListLimit] = useState<number>(DEFAULT_PAGINATION_QUERY.limit)
  const [getFilesListQuery, setGetFilesListQuery] = useState<GetListParams<Models.File>>({
    pagination: { page: 1, perPage: DEFAULT_PAGINATION_QUERY.limit },
  })

  const { data: filesList, isFetching: isFilesListLoading } = useQuery(
    ['fileService', 'getList', getFilesListQuery],
    () => fileService.getList(getFilesListQuery).then((res) => res.data),
  )

  const [dimensions, setDimensions] = useState<{
    width: string
    height: string
  }>()

  useEffect(() => {
    setSelectedFile([])
  }, [opened])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onLoadImage = useCallback((event?: any) => {
    setDimensions({
      width: event?.target?.naturalWidth,
      height: event?.target?.naturalHeight,
    })
  }, [])

  const onSetGetListQuery = useCallback(() => {
    const query: GetListParams<Models.File> = {
      pagination: {
        page: filesListpage,
        perPage: filesListLimit,
      },
    }
    setGetFilesListQuery(query)
  }, [filesListLimit, filesListpage])

  useEffect(() => {
    onSetGetListQuery()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesListpage, filesListLimit])

  const onChangePage = (page: number) => {
    setFilesListPage(page)
  }

  const onChangeLimit = (limit: number) => {
    setFilesListLimit(limit)
  }

  const onSelectFile = useCallback(
    (data: Models.File) => {
      let newData: Models.File[] = []
      if (multiple) {
        if (selectedFiles?.find((x) => x.file_id === data.file_id)) {
          newData = selectedFiles.filter((x) => x.file_id !== data.file_id)
        } else {
          newData = [...(selectedFiles || []), data]
        }
      } else {
        newData = [data]
      }
      setSelectedFile(newData)
    },
    [multiple, selectedFiles],
  )

  // region upload file
  useEffect(() => {
    setFilesUpload([])
  }, [opened])

  const onDrop = useCallback(
    (data: File[]) => {
      let mapFiles = []
      if (multiple) {
        mapFiles = [
          ...filesUpload,
          ...data.map((x) => {
            return {
              file: x,
              convertUrl: URL.createObjectURL(x),
            } as PreviewImageType
          }),
        ]
      } else {
        mapFiles = [
          ...data.map((x) => {
            return {
              file: x,
              convertUrl: URL.createObjectURL(x),
            } as PreviewImageType
          }),
        ]
      }
      setFilesUpload(mapFiles)
    },
    [filesUpload, multiple],
  )

  const onReject = useCallback(
    (data: FileRejection[]) => {
      // current files and dropped files
      if (limitFiles && filesUpload.length + data.length > limitFiles) {
        overFilesModal.open()
        return
      }
    },
    [filesUpload.length, limitFiles, overFilesModal],
  )

  const onRemoveFile = useCallback(
    (data: File) => {
      if (!filesUpload || filesUpload.length <= 0) return
      const mapFiles = filesUpload.filter((x) => x.file != data)
      setFilesUpload(mapFiles)
    },
    [filesUpload],
  )

  const onUploadFile = useCallback(async () => {
    if (!filesUpload || filesUpload.length <= 0) return []
    setIsloading(true)

    try {
      const uploadPromises = filesUpload.map(async (x) => {
        const formData = new FormData()
        formData.append('file', x.file)

        const uploadedFile = await fileService.upload(formData)
        return uploadedFile
      })
      const res = await Promise.all(uploadPromises)

      if (!res || res.length <= 0) {
        notifications.show({
          variant: 'danger',
          message: t('error.uploadFile'),
        })
        return
      }

      notifications.show({
        variant: 'success',
        message: t('success.uploadFile'),
      })
      setFilesUpload([])
      onRefresh?.()
      onSuccess?.(res.map((x) => x.data.data))

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      const errorMessage = t(err?.response?.data, {
        ns: 'backend',
      })

      notifications.show({
        variant: 'danger',
        message: errorMessage,
      })

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      err?.response?.data?.errors?.forEach((err: any) => {
        notifications.show({
          variant: 'danger',
          message: t(err, {
            ns: 'backend',
          }),
        })
      })
    } finally {
      setIsloading(false)
    }
  }, [fileService, filesUpload, onRefresh, onSuccess, t])

  const onSubmit = useCallback(() => {
    if (currentTabForm === TabForm.SELECT_FILE) {
      if (!selectedFiles || selectedFiles.length <= 0) return
      onSuccess?.(selectedFiles)
    } else if (currentTabForm === TabForm.UPLOAD_FILE) {
      onUploadFile()
    } else {
      return
    }
  }, [currentTabForm, onSuccess, onUploadFile, selectedFiles])

  return (
    <>
      <Modal opened={opened} onClose={onClose} title={t('media.uploadMedia')} size='xl'>
        <Tabs defaultValue={currentTabForm}>
          <Tabs.List>
            <Tabs.Tab
              value={TabForm.SELECT_FILE}
              icon={<FaRegImage size={20} />}
              onClick={() => setCurrentTabForm(TabForm.SELECT_FILE)}
            >
              {t('media.chooseFile')}
            </Tabs.Tab>
            <Tabs.Tab
              value={TabForm.UPLOAD_FILE}
              icon={<MdUpload size={20} />}
              onClick={() => setCurrentTabForm(TabForm.UPLOAD_FILE)}
            >
              {t('media.uploadFile')}
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value={TabForm.SELECT_FILE} pt='xs'>
            <Grid>
              {isFilesListLoading ? (
                <Grid.Col>
                  <Grid>
                    {Array.from({ length: 12 }, (_, index) => (
                      <Grid.Col key={index} sm={6} md={4} lg={2}>
                        <Skeleton height={150} radius='md' />
                      </Grid.Col>
                    ))}
                  </Grid>
                </Grid.Col>
              ) : filesList && filesList.data && filesList.data.length > 0 ? (
                <Grid.Col>
                  <Grid>
                    {filesList.data.map((item) => (
                      <Grid.Col key={item.file_id} span={6} sm={3}>
                        <Box pos='relative' h='100%'>
                          <Card
                            shadow='sm'
                            padding='sm'
                            sx={(_theme) => ({
                              borderRadius: '8px',
                              border:
                                (multiple && selectedFiles?.includes(item)) ||
                                (!multiple &&
                                  selectedFiles &&
                                  selectedFiles?.length > 0 &&
                                  selectedFiles[0] === item)
                                  ? `2px solid ${_theme.colors.primary[5]}`
                                  : `1px solid ${_theme.colors.gray[2]}`,
                              cursor: 'pointer',
                            })}
                            onClick={() => onSelectFile(item)}
                            h='100%'
                          >
                            <Card.Section>
                              <Flex justify='center' align='center' bg='gray.0' p={8}>
                                <Image
                                  onLoad={onLoadImage}
                                  src={item.file_path}
                                  height={160}
                                  alt=''
                                  fit='contain'
                                />
                              </Flex>
                            </Card.Section>

                            <Flex
                              justify='space-between'
                              mt='md'
                              mb='xs'
                              direction={{ base: 'row', sm: 'column' }}
                              gap={4}
                            >
                              <Text fw={500} lineClamp={1}>
                                {item.file_id.substring(0, 15)}
                              </Text>
                              <Badge color='pink' variant='light' w='fit-content' radius={4}>
                                {item.file_type}
                              </Badge>
                            </Flex>

                            <Text size='sm' c='dimmed'>
                              {`${item.mime_type} - ${dimensions?.width}x${
                                dimensions?.height
                              } - ${formatBytes(item.file_size)}`}
                            </Text>
                          </Card>
                          {/* {multiple ? (
                            <Checkbox
                              size='xs'
                              checked={selectedFiles?.includes(item)}
                              onChange={(e) => onToggleFiles(e, item)}
                              sx={{
                                position: 'absolute',
                                top: 8,
                                left: 8,
                                zIndex: 1,
                              }}
                            />
                          ) : null} */}
                        </Box>
                      </Grid.Col>
                    ))}
                  </Grid>
                </Grid.Col>
              ) : (
                <Grid.Col>
                  <Empty />
                </Grid.Col>
              )}

              <Grid.Col>
                <Pagination
                  limit={filesListLimit}
                  page={filesListpage}
                  total={filesList?.total}
                  onChange={onChangePage}
                  onChangeLimit={onChangeLimit}
                  isShowLimitOptions
                />
              </Grid.Col>
            </Grid>
          </Tabs.Panel>

          <Tabs.Panel value={TabForm.UPLOAD_FILE} pt='xs'>
            <Dropzone
              openRef={openRef}
              onDrop={onDrop}
              accept={IMAGE_MIME_TYPE}
              maxFiles={limitFiles ? limitFiles - filesUpload.length : undefined} // remaining selectable files
              onReject={onReject}
              multiple={multiple}
              p={0}
              mb={20}
              disabled={disabled || Boolean(limitFiles && filesUpload.length < limitFiles)}
              sx={(_theme) => ({
                height: '100%',
                border: `2px dashed ${_theme.colors.gray[3]}`,
                '.mantine-Dropzone-inner': {
                  height: '100%',
                },
              })}
            >
              <Flex
                justify='center'
                align='center'
                gap='xl'
                p={20}
                mih={120}
                direction={{ base: 'column', xs: 'row' }}
                style={{ pointerEvents: 'none' }}
              >
                <Dropzone.Accept>
                  <HiUpload />
                </Dropzone.Accept>
                <Dropzone.Reject>
                  <MdCancel />
                </Dropzone.Reject>
                <Dropzone.Idle>
                  <FaRegImage size={40} />
                </Dropzone.Idle>

                <Flex justify='center' align='center'>
                  <Text size='xl' inline>
                    {t('media.dragUpload')}
                  </Text>
                </Flex>
              </Flex>
            </Dropzone>

            <Grid mb={30} gutter={10}>
              {filesUpload &&
                filesUpload.length > 0 &&
                filesUpload.map((item, index) => (
                  <Grid.Col key={index} xs={6} sm={4}>
                    <Box pos='relative' sx={{ borderRadius: '2px' }}>
                      <Flex
                        justify='center'
                        align='center'
                        sx={(_theme) => ({
                          width: '100%',
                          height: '100%',
                          borderRadius: '2px',
                          background: _theme.colors.gray[5],
                          img: {
                            borderRadius: '2px',
                          },
                        })}
                      >
                        <Image
                          src={item.convertUrl}
                          alt=''
                          height={250}
                          width='100%'
                          fit='contain'
                        />
                      </Flex>
                      <Box
                        pos='absolute'
                        sx={{
                          width: 40,
                          height: 40,
                          top: 8,
                          right: 8,
                          zIndex: 2,
                        }}
                      >
                        <Button
                          p={0}
                          sx={{
                            borderRadius: '0 0 2px 2px',
                          }}
                          fullWidth
                          onClick={() => onRemoveFile(item.file)}
                        >
                          <HiOutlineTrash color='white' size={16} />
                        </Button>
                      </Box>
                    </Box>
                  </Grid.Col>
                ))}
            </Grid>
          </Tabs.Panel>
        </Tabs>

        <Flex
          justify='end'
          align='center'
          gap={20}
          onClick={onClose}
          direction={{ base: 'column', md: 'row' }}
        >
          <Button variant='subtle' onClick={onClose} fullWidth>
            {t('common.cancel')}
          </Button>
          <Button
            variant='highlight'
            loading={isLoading}
            onClick={onSubmit}
            disabled={Boolean(
              (currentTabForm === TabForm.SELECT_FILE &&
                (!selectedFiles || selectedFiles?.length <= 0)) ||
                (currentTabForm === TabForm.UPLOAD_FILE &&
                  (!filesUpload || filesUpload.length <= 0)),
            )}
            fullWidth
          >
            {t(currentTabForm === TabForm.SELECT_FILE ? 'media.chooseFile' : 'media.uploadFile')}
          </Button>
        </Flex>
      </Modal>

      {/* Start over files Modal */}
      <Modal opened={overFilesOpened} onClose={overFilesModal.close}>
        <ModalFormSimpleNotice
          type='error'
          descriptions={t('error.overLimitFileWarning').replace(
            '{0}',
            limitFiles ? limitFiles.toString() : '',
          )}
        />
        <Button variant='highlight' onClick={overFilesModal.close} fullWidth>
          {t('common.close')}
        </Button>
      </Modal>
      {/* End over files Modal */}
    </>
  )
}

export default UploadMedia
