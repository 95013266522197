import { Role } from '@kicksplanet/enums'

export type RoleOptionType = {
  code: string
  name: string
}

export const RoleOptions: RoleOptionType[] = [
  {
    code: Role.ADMIN,
    name: 'Admin',
  },
  {
    code: Role.STAFF,
    name: 'Staff',
  },
  {
    code: Role.USER,
    name: 'User',
  },
]

export type ActiveStatusDataType = {
  value: boolean
  label: string
}

export const ActiveStatusData: ActiveStatusDataType[] = [
  {
    value: true,
    label: 'user.activate',
  },
  {
    value: false,
    label: 'user.banned',
  },
]

export type UserRoleDataType = {
  value: Role
  label: string
}

export const UserRoleData: UserRoleDataType[] = [
  {
    value: Role.ADMIN,
    label: 'Admin',
  },
  {
    value: Role.STAFF,
    label: 'Staff',
  },
  {
    value: Role.USER,
    label: 'User',
  },
]
