import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { OrderResponses } from '@kicksplanet/interfaces'
import { Box, Mark, Text } from '@mantine/core'

import { CancelReasonOptions } from '@/constants/order'

type OrderDetailSummaryRejectInfoProps = {
  order: OrderResponses.OrderDetails
}

const OrderDetailSummaryRejectInfo: React.FC<OrderDetailSummaryRejectInfoProps> = ({
  order: { cancel_reason },
}) => {
  const { t } = useTranslation()

  const cancelReasonName = useMemo(() => {
    let cancelReason = ''

    if (cancel_reason) {
      const reasonOption = CancelReasonOptions.find((x) => x.value == cancel_reason)?.label

      if (reasonOption) {
        cancelReason = t(`order.rejectOrder.reasonTypes.${reasonOption}`)
      } else {
        cancelReason = cancel_reason
      }
    }
    return cancelReason
  }, [cancel_reason, t])

  return (
    <>
      <Box
        py={10}
        px={15}
        bg='red.1'
        sx={{
          borderRadius: '8px',
        }}
      >
        <Text>
          {`${t('order.rejectOrder.rejectReason')}: `}
          <Mark variant='bold'>{cancelReasonName}</Mark>
        </Text>
      </Box>
    </>
  )
}

export default OrderDetailSummaryRejectInfo
