import { captureException, setExtras } from '@sentry/browser'
import httpStatus from 'http-status'

export const shouldCaptureError = (error: any) => {
  switch ((httpStatus as any)[`${error.status}_CLASS`]) {
    case httpStatus.classes.CLIENT_ERROR:
      return false
    default:
      return true
  }
}

export const reportError = (err: any) => {
  console.error(err)
  if (!shouldCaptureError(err)) return

  setExtras({
    error: err,
  })
  captureException(err)
}
