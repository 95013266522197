import { useCallback, useEffect, useMemo } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SlLocationPin } from 'react-icons/sl'
import { useMutation, useQuery } from 'react-query'
import { yupResolver } from '@hookform/resolvers/yup'
import { OrderRequests, OrderResponses } from '@kicksplanet/interfaces'
import { ProvinceModel } from '@kicksplanet/interfaces/dist/models/address.model'
import { BankModel } from '@kicksplanet/interfaces/dist/models/bank.model'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Button, Grid, Input, Loader, Select } from '@mantine/core'
import { notifications } from '@mantine/notifications'
import { object, string } from 'yup'

import { useSelection } from '@/hooks/useSelection'

type OrderApproveReturnRequestFormProps = {
  order: OrderResponses.OrderDetails
  onSubmit: () => void
  onRefresh: () => void
}

const approveReturnOrderSchema = object().shape({
  pickup_name: string().required('order.missingFullName'),
  pickup_phone: string().required('order.missingPhoneNumber'),
  pickup_address: string().required('order.missingAddress'),
  pickup_province: string().required('order.missingProvince'),
  pickup_district: string().required('order.missingDistrict'),
  pickup_ward: string().required('order.missingWard'),
  bank_code: string().required('order.missingBank'),
  bank_account_number: string().required('order.missingBankAccountNumber'),
  bank_account_name: string().required('order.missingBankAccountName'),
})

const OrderApproveReturnRequestForm: React.FC<OrderApproveReturnRequestFormProps> = ({
  order,
  onSubmit,
  onRefresh,
}) => {
  const { t } = useTranslation()
  const orderService = useAPIService('Order')
  const addressService = useAPIService('Address')
  const qrService = useAPIService('QR')

  const orderReturn = useMemo(() => {
    return order.order_return_info
  }, [order.order_return_info])

  const orderRefundPaymentInfo = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (order.order_return_info as any)?.order_refund_payment_info
  }, [order.order_return_info])

  const {
    register,
    setValue,
    control,
    reset,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<OrderRequests.ApproveReturnOrderPayload>({
    resolver: yupResolver(approveReturnOrderSchema),
    defaultValues: {
      pickup_name: orderReturn?.pickup_name || '',
      pickup_phone: orderReturn?.pickup_phone || '',
      pickup_address: orderReturn?.pickup_address || '',
      bank_account_number: orderRefundPaymentInfo?.bank_account_number || '',
      bank_account_name: orderRefundPaymentInfo?.bank_account_name || '',
    },
    mode: 'onChange',
  })

  const bankCode = useWatch({
    name: 'bank_code',
    control,
  })

  const provinceCode = useWatch({
    name: 'pickup_province',
    control,
  })

  const districtCode = useWatch({
    name: 'pickup_district',
    control,
  })

  const wardCode = useWatch({
    name: 'pickup_ward',
    control,
  })

  // Default value
  useEffect(() => {
    setDefaultValue()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderReturn])

  const setDefaultValue = useCallback(() => {
    if (!orderReturn) {
      reset()
      return
    }

    setValue('pickup_name', orderReturn?.pickup_name || '', {
      shouldValidate: true,
    })
    setValue('pickup_phone', orderReturn?.pickup_phone || '', {
      shouldValidate: true,
    })
    setValue('pickup_address', orderReturn?.pickup_address || '', {
      shouldValidate: true,
    })
    setValue('bank_account_number', orderRefundPaymentInfo?.bank_account_number || '', {
      shouldValidate: true,
    })
    setValue('bank_account_name', orderRefundPaymentInfo?.bank_account_name || '', {
      shouldValidate: true,
    })
  }, [
    orderRefundPaymentInfo?.bank_account_name,
    orderRefundPaymentInfo?.bank_account_number,
    orderReturn,
    reset,
    setValue,
  ])

  const { data: banks = [], isLoading: banksLoading } = useQuery(
    ['qrService', 'getBanks', 'selectOptions'],
    () => qrService.getBanks().then((res) => res.data.data),
    {
      select: (data) => {
        return (
          data?.map((item) => ({
            ...item,
            label: `${item.name} (${item.shortName})`,
          })) || []
        )
      },
      onSuccess: (data) => {
        if (orderReturn) {
          const bank = data?.find((x) => x.code == orderRefundPaymentInfo.bank_code)

          if (!bank) return
          onSelectBankOptions(bank)
        }
      },
    },
  )

  const { data: provinces = [], isLoading: isLoadingProvinces } = useQuery(
    ['address.provinces', 'selectOptions'],
    addressService.getProvinces,
    {
      select: ({ data }) => {
        const provinces = Object.values(data)
        return (
          provinces?.map((item) => ({
            ...item,
            label: item.name_with_type,
          })) || []
        )
      },
      onSuccess: (data) => {
        if (orderReturn) {
          const province = data?.find((x) => x.name_with_type == orderReturn?.pickup_province)
          if (!province) return
          onSelectProvinceOption(province)
        }
      },
    },
  )

  const { data: districts = [], isLoading: isLoadingDistricts } = useQuery(
    ['address.districts', 'selectOptions', provinceCode],
    () => addressService.getDistricts(provinceCode),
    {
      enabled: !!provinceCode,
      select: ({ data }) => {
        const districts = Object.values(data)
        return (
          districts?.map((item) => ({
            ...item,
            label: item.name_with_type,
          })) || []
        )
      },
      onSuccess: (data) => {
        if (orderReturn) {
          const district = data?.find((x) => x.name_with_type == orderReturn?.pickup_district)
          if (!district) return
          onSelectDistrictOption(district)
        }
      },
    },
  )

  const { data: wards = [], isLoading: isLoadingWards } = useQuery(
    ['address.wards', 'selectOptions', districtCode],
    () => addressService.getWards(districtCode),
    {
      enabled: !!districtCode,
      select: ({ data }) => {
        const wards = Object.values(data)
        return (
          wards?.map((item) => ({
            ...item,
            label: item.name_with_type,
          })) || []
        )
      },
      onSuccess: (data) => {
        if (orderReturn) {
          const ward = data?.find((x) => x.name_with_type == orderReturn?.pickup_ward)
          if (!ward) return
          onSelectWardOption(ward)
          // onSelectWard(ward?.code.toString())
        }
      },
    },
  )

  // ward
  const onSelectWardOption = useCallback(
    (item?: ProvinceModel | null) => {
      setValue('pickup_ward', item ? item.code.toString() : '', {
        shouldValidate: true,
      })
    },
    [setValue],
  )

  const {
    data: wardOptions,
    onSelect: onSelectWard,
    value: wardValue,
    filter: wardFilter,
  } = useSelection<ProvinceModel>({
    items: wards,
    onSelect: onSelectWardOption,
    valueKey: 'code',
  })

  // district
  const onSelectDistrictOption = useCallback(
    (item?: ProvinceModel | null) => {
      setValue('pickup_district', item ? item.code.toString() : '', {
        shouldValidate: true,
      })
    },
    [setValue],
  )

  const {
    data: districtOptions,
    onSelect: onSelectDistrict,
    value: districtValue,
    filter: districtFilter,
  } = useSelection<ProvinceModel>({
    items: districts,
    onSelect: onSelectDistrictOption,
    valueKey: 'code',
  })

  // province
  const onSelectProvinceOption = useCallback(
    (item?: ProvinceModel | null) => {
      setValue('pickup_province', item?.code.toString() || '', {
        shouldValidate: true,
      })
    },
    [setValue],
  )

  const {
    data: provinceOptions,
    onSelect: onSelectProvince,
    value: provinceValue,
    filter: provinceFilter,
  } = useSelection<ProvinceModel>({
    items: provinces,
    onSelect: onSelectProvinceOption,
    valueKey: 'code',
    defaultValue: provinces.find((x) => x.code.toString() == provinceCode),
  })

  useEffect(() => {
    if (provinceCode) {
      onSelectProvince(provinceCode)
    } else {
      onSelectProvince('')
    }

    if (districtCode) {
      onSelectDistrict(districtCode)
    } else {
      onSelectDistrict('')
    }

    if (wardCode) {
      onSelectWard(wardCode)
    } else {
      onSelectWard('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provinceCode, districtCode, wardCode])

  // Bank
  const onSelectBankOptions = useCallback(
    (item?: BankModel | null) => {
      setValue('bank_code', item ? item.code : '', {
        shouldValidate: true,
      })
    },
    [setValue],
  )

  const {
    data: bankOptions,
    onSelect: onSelectBank,
    value: bankValue,
    filter: bankFilter,
  } = useSelection<BankModel>({
    items: banks,
    onSelect: onSelectBankOptions,
    valueKey: 'code',
    defaultValue: banks.find((x) => x.code.toString() == bankCode),
  })

  useEffect(() => {
    if (bankCode) {
      onSelectBank(bankCode)
    } else {
      onSelectBank('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankCode])

  // Handle submit
  const { mutate: approveReturnOrder, isLoading: approveReturnOrderLoading } = useMutation(
    (data: OrderRequests.ApproveReturnOrderPayload) => {
      const province = provinces?.find(
        (x) => x.code.toString() === data?.pickup_province,
      )?.name_with_type
      const district = districts?.find(
        (x) => x.code.toString() === data?.pickup_district,
      )?.name_with_type
      const ward = wards?.find((x) => x.code.toString() === data?.pickup_ward)?.name_with_type

      return orderService.approveReturnOrder(
        {
          id: order?.order_id || '',
          returnId: order?.order_return_info?.order_return_id || '',
        },
        {
          data: {
            pickup_name: data.pickup_name,
            pickup_phone: data.pickup_phone,
            pickup_address: data.pickup_address,
            pickup_province: province,
            pickup_district: district,
            pickup_ward: ward,
            bank_account_number: data.bank_account_number,
            bank_account_name: data.bank_account_name,
            bank_code: data.bank_code,
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any,
      )
    },
    {
      onSuccess: () => {
        notifications.show({
          variant: 'success',
          message: t('success.approveReturnOrder'),
        })
        onSubmit()
        onRefresh()
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        const { response: { data: { errorKey = '', errors = [] } = {} } = {} } = err
        const errorMessage = t(errorKey, {
          ns: 'backend',
        })

        notifications.show({
          variant: 'danger',
          message: errorMessage,
        })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        errors?.forEach((err: any) => {
          notifications.show({
            variant: 'danger',
            message: t(err, {
              ns: 'backend',
            }),
          })
        })
      },
    },
  )

  const onRejectReturnOrder = useCallback(
    (data: OrderRequests.ApproveReturnOrderPayload) => {
      if (!order) return
      approveReturnOrder(data)
    },
    [approveReturnOrder, order],
  )

  return (
    <form>
      <Grid>
        <Grid.Col md={6}>
          <Input.Wrapper
            label={`${t('order.fullName')} *`}
            error={errors.pickup_name && t(errors.pickup_name.message as string)}
          >
            <Input {...register('pickup_name')} />
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col md={6}>
          <Input.Wrapper
            label={`${t('order.phoneNumber')} *`}
            error={errors.pickup_phone && t(errors.pickup_phone.message as string)}
          >
            <Input {...register('pickup_phone')} />
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col>
          <Input.Wrapper
            label={`${t('order.address')} *`}
            error={errors.pickup_address && t(errors.pickup_address.message as string)}
          >
            <Input {...register('pickup_address')} />
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col md={6}>
          <Select
            label={`${t('order.province')} *`}
            nothingFound={t('common.emptyData')}
            searchable
            clearable
            data={provinceOptions}
            value={provinceValue}
            icon={<SlLocationPin />}
            rightSection={isLoadingProvinces && <Loader size='xs' color='red.5' />}
            onChange={onSelectProvince}
            filter={provinceFilter}
            error={errors.pickup_province && t(errors.pickup_province.message as string)}
          />
        </Grid.Col>
        <Grid.Col md={6}>
          <Select
            label={`${t('order.district')} *`}
            nothingFound={t('common.emptyData')}
            searchable
            clearable
            data={districtOptions}
            value={districtValue}
            icon={<SlLocationPin />}
            onChange={onSelectDistrict}
            rightSection={isLoadingDistricts && <Loader size='xs' color='red.5' />}
            filter={districtFilter}
            error={errors.pickup_district && t(errors.pickup_district.message as string)}
          />
        </Grid.Col>
        <Grid.Col>
          <Select
            label={`${t('order.ward')} *`}
            nothingFound={t('common.emptyData')}
            searchable
            clearable
            data={wardOptions}
            value={wardValue}
            icon={<SlLocationPin />}
            onChange={onSelectWard}
            rightSection={isLoadingWards && <Loader size='xs' color='red.5' />}
            filter={wardFilter}
            error={errors.pickup_ward && t(errors.pickup_ward.message as string)}
          />
        </Grid.Col>
        <Grid.Col>
          <Select
            label={`${t('order.bank')} *`}
            nothingFound={t('common.emptyData')}
            searchable
            data={bankOptions}
            value={bankValue}
            onChange={onSelectBank}
            filter={bankFilter}
            rightSection={banksLoading && <Loader size='xs' color='red.5' />}
            error={errors.bank_code && t(errors.bank_code.message as string)}
          />
        </Grid.Col>
        <Grid.Col md={6}>
          <Input.Wrapper
            label={`${t('order.bankAccountNumber')} *`}
            error={errors.bank_account_number && t(errors.bank_account_number.message as string)}
          >
            <Input {...register('bank_account_number')} />
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col md={6}>
          <Input.Wrapper
            label={`${t('order.bankAccountName')} *`}
            error={errors.bank_account_name && t(errors.bank_account_name.message as string)}
          >
            <Input {...register('bank_account_name')} />
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col>
          <Button
            variant='highlight'
            loading={approveReturnOrderLoading}
            disabled={!isValid}
            onClick={handleSubmit(onRejectReturnOrder)}
            fullWidth
          >
            {t('common.save')}
          </Button>
        </Grid.Col>
      </Grid>
    </form>
  )
}

export default OrderApproveReturnRequestForm
