import { useCallback, useEffect, useMemo } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { Box, Burger, Flex, Grid, useMantineTheme } from '@mantine/core'
import { useClickOutside, useDisclosure, useMediaQuery } from '@mantine/hooks'

import AppBreadcrumbs from '@/components/App/AppBreadcrumbs'
import AppHeader from '@/components/App/AppHeader'
import AppSidebar from '@/components/App/AppSidebar'
import withAuth from '@/HOCs/withAuth'

const AdminLayout = () => {
  const location = useLocation()

  const theme = useMantineTheme()
  const mobileSize = useMediaQuery(`(max-width: ${theme.breakpoints.lg})`)

  const [navbarOpened, navbar] = useDisclosure()

  const ref = useClickOutside(() => {
    navbar.close()
  })

  useEffect(() => {
    if (mobileSize) {
      navbar.close()
    } else {
      navbar.open()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileSize])

  const getGlobalStyles = useCallback(() => {
    return `body {
			overflow: hidden !important;
		}`
  }, [])

  const navBarMenu = useMemo(() => {
    return (
      <>
        {mobileSize && <style>{getGlobalStyles()}</style>}
        <Flex
          ref={mobileSize ? ref : null}
          sx={(_theme) => ({
            height: '100vh',
            position: mobileSize ? 'absolute' : 'relative',
            zIndex: 2,
            overflowY: 'auto',
            overflowX: 'hidden',
            background: _theme.colors.gray[0],
          })}
          direction='column'
        >
          {mobileSize ? (
            <Burger
              opened={navbarOpened}
              onClick={navbar.toggle}
              size='sm'
              ml='auto'
              mr={20}
              mt={12}
            />
          ) : null}
          <AppSidebar pathname={location.pathname} />
        </Flex>
      </>
    )
  }, [getGlobalStyles, location.pathname, mobileSize, navbar.toggle, navbarOpened, ref])

  return (
    <Grid bg='gray.0' pos='relative'>
      {navbarOpened ? (
        mobileSize ? (
          navBarMenu
        ) : (
          <Grid.Col
            md={!mobileSize && navbarOpened ? 4 : 12}
            lg={!mobileSize && navbarOpened ? 3 : 12}
            xl={!mobileSize && navbarOpened ? 2 : 12}
          >
            {navBarMenu}
          </Grid.Col>
        )
      ) : null}
      <Grid.Col
        md={!mobileSize && navbarOpened ? 8 : 12}
        lg={!mobileSize && navbarOpened ? 9 : 12}
        xl={!mobileSize && navbarOpened ? 10 : 12}
      >
        <Grid>
          <Grid.Col>
            <Flex justify='space-between' align='center' gap={12} p={12} bg='gray.0'>
              <Burger opened={navbarOpened} onClick={navbar.toggle} size='sm' />
              <Box ml='auto'>
                <AppHeader />
              </Box>
            </Flex>
          </Grid.Col>
          <Grid.Col>
            <Box px={20} w='100%'>
              <AppBreadcrumbs location={location} />
              <Outlet />
            </Box>
          </Grid.Col>
        </Grid>
      </Grid.Col>
    </Grid>
  )
}

export default withAuth(AdminLayout)
