import EnglishFlag from '@/assets/images/languages/en.svg'
import VietnameseFlag from '@/assets/images/languages/vi.svg'

type LanguagesType = {
  code: string
  name: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: any
}

export const Languages: LanguagesType[] = [
  {
    code: 'vi',
    name: 'Vietnamese',
    icon: VietnameseFlag,
  },
  {
    code: 'en',
    name: 'English',
    icon: EnglishFlag,
  },
]
