import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router'
import { Role } from '@kicksplanet/enums'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Card } from '@mantine/core'
import { notifications } from '@mantine/notifications'

import { useApp } from '@/contexts/AppProvider'
import { ROUTES } from '@/routes'
import { getUserInfoInLine } from '@/utils/user'

import UserForm, { UserFormType } from '../Components/UserForm'

const UserUpdate: React.FC = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const userService = useAPIService('User')

  const { registerBreadcrumb, unregisterBreadcrumb } = useApp()

  const { data: user } = useQuery(
    ['userService', 'getById', id],
    () => userService.getById({ id: id ? id : '' }).then((res) => res.data.data),
    {
      enabled: Boolean(id?.toString()),
    },
  )

  useEffect(() => {
    registerBreadcrumb({
      key: 'users',
      label: t('page.users'),
      url: ROUTES.ADMIN.USER.LIST,
    })

    if (user) {
      registerBreadcrumb({
        key: user.user_id,
        label: getUserInfoInLine(user),
      })
    }

    return () => {
      unregisterBreadcrumb('users')
      if (user) {
        unregisterBreadcrumb(user.user_id)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const { mutate: update, isLoading: isUpdateLoading } = useMutation(
    (data: UserFormType) => {
      return userService.adminUpdate({
        id: id?.toString() || '',
        data: {
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          phone_number: data.phone_number,
          role: data.role as Role,
          credits: data.credits,
          warnings: data.warnings,
          bank_code: data.bank_code,
          bank_account_name: data.bank_account_name,
          bank_account_number: data.bank_account_number,
        },
      })
    },
    {
      onSuccess: async () => {
        notifications.show({
          variant: 'success',
          message: t('success.updatedUser'),
        })
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        const errorMessage = t(err?.response?.data, {
          ns: 'backend',
        })

        notifications.show({
          variant: 'danger',
          message: errorMessage,
        })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        err?.response?.data?.errors?.forEach((err: any) => {
          notifications.show({
            variant: 'danger',
            message: t(err, {
              ns: 'backend',
            }),
          })
        })
      },
    },
  )

  const onUpdate = useCallback(
    (data: UserFormType) => {
      if (!id) return
      update(data)
    },
    [id, update],
  )

  return (
    <Card>
      <UserForm user={user} isSubmitLoading={isUpdateLoading} onSubmit={onUpdate} />
    </Card>
  )
}

export default UserUpdate
