import { useCallback, useEffect, useMemo } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { yupResolver } from '@hookform/resolvers/yup'
import { Models, OrderResponses } from '@kicksplanet/interfaces'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Button, Card, Divider, Flex, Grid, Input, Text, Textarea } from '@mantine/core'
import { notifications } from '@mantine/notifications'
import { number, object, string } from 'yup'

type FormControlFormAConfirmProps = {
  inhouseItem?: OrderResponses.BO_GetInhouseItemBySeller
  order?: Models.Order
  onRefresh?: () => void
}

const validateSchema = object().shape({
  price: number(),
  pickup_cost: number(),
  transaction_fee: number(),
  commission_fee: number(),
  addition_fee: number(),
  note: string(),
  payable_amount: number(),
})

const FormControlFormAConfirm: React.FC<FormControlFormAConfirmProps> = ({
  inhouseItem,
  order,
  onRefresh,
}) => {
  const { t } = useTranslation()
  const orderService = useAPIService('Order')

  const inhouseItemData = useMemo(() => {
    return inhouseItem?.data
  }, [inhouseItem?.data])

  const {
    setValue,
    reset,
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<Models.OrderCheck>({
    resolver: yupResolver(validateSchema),
    mode: 'all',
  })

  // Default value
  useEffect(() => {
    setDefaultValue()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inhouseItemData])

  const setDefaultValue = useCallback(() => {
    if (!inhouseItemData) {
      reset()
      return
    }

    if (order) {
      const { pickup_shipping_fee, transaction_fee, comission_fee, note } = order

      setValue('pickup_cost', pickup_shipping_fee || 0, {
        shouldValidate: true,
      })
      setValue('transaction_fee', transaction_fee || 0, {
        shouldValidate: true,
      })
      setValue('commission_fee', comission_fee || 0, {
        shouldValidate: true,
      })
      setValue('addition_fee', 0, {
        shouldValidate: true,
      })
      setValue('note', note || '', {
        shouldValidate: true,
      })
    }
  }, [inhouseItemData, order, reset, setValue])

  const additionalFee = useWatch({
    name: 'addition_fee',
    control,
  })

  const pickupCost = useWatch({
    name: 'pickup_cost',
    control,
  })

  const commissionFee = useWatch({
    name: 'commission_fee',
    control,
  })

  useEffect(() => {
    if (order?.actual_receive_amount) {
      const payableAmount =
        order.actual_receive_amount -
        (pickupCost || 0) -
        (commissionFee || 0) -
        (additionalFee || 0)
      setValue('payable_amount', payableAmount, {
        shouldValidate: true,
      })
    }
  }, [additionalFee, setValue, order?.actual_receive_amount, pickupCost, commissionFee])

  const { isLoading: isSubmitting, mutate: submit } = useMutation(
    async (data: Models.OrderCheck) => {
      if (!isValid) return null

      return orderService.check(
        {
          id: order?.order_id || '',
        },
        {
          data,
        },
      )
    },
    {
      onSuccess: () => {
        notifications.show({
          variant: 'success',
          message: t('success.checkOrder'),
        })
        onRefresh?.()
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        const errorMessage = t(err?.response?.data, {
          ns: 'backend',
        })

        notifications.show({
          variant: 'danger',
          message: errorMessage,
        })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        err?.response?.data?.errors?.forEach((err: any) => {
          notifications.show({
            variant: 'danger',
            message: t(err, {
              ns: 'backend',
            }),
          })
        })
      },
    },
  )

  const _onSubmit = useCallback(
    (data: Models.OrderCheck) => {
      if (!inhouseItemData) return

      const payload = {
        ...data,
        addition_fee: parseInt(`${data.addition_fee || 0}`),
        commission_fee: parseInt(`${data.commission_fee || 0}`),
        payable_amount: parseInt(`${data.payable_amount || 0}`),
        pickup_cost: parseInt(`${data.pickup_cost || 0}`),
        transaction_fee: parseInt(`${data.transaction_fee || 0}`),
        item_id: inhouseItemData.item_id,
        seller_id: inhouseItemData.seller_id,
      }

      submit(payload)
    },
    [inhouseItemData, submit],
  )

  return (
    <Card>
      <Grid gutter={16} mb={30}>
        <Grid.Col>
          <Text weight={600} transform='uppercase'>
            {t('forControl.cardTitle.packageInfo')}
          </Text>
          <Divider my={12} color='gray.2' />
        </Grid.Col>
        <Grid.Col>
          <form>
            <Grid>
              <Grid.Col>
                <Input.Wrapper label={t('forControl.formFields.sellingPrice')}>
                  <Input
                    rightSection={<Text weight={600}>VND</Text>}
                    type='number'
                    value={inhouseItemData?.price}
                    readOnly
                  />
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col>
                <Input.Wrapper label={t('forControl.formFields.pickupCost')}>
                  <Input
                    rightSection={<Text weight={600}>VND</Text>}
                    type='number'
                    min={0}
                    readOnly={Boolean(order?.is_checked)}
                    {...register('pickup_cost')}
                    error={errors.pickup_cost && t(errors.pickup_cost.message as string)}
                  />
                </Input.Wrapper>
              </Grid.Col>
              {/* <Grid.Col>
                <Input.Wrapper label={t('forControl.formFields.transactionFee')}>
                  <Input
                    rightSection={<Text weight={600}>VND</Text>}
                    type='number'
                    min={0}
                    readOnly={Boolean(order?.is_checked)}
                    {...register('transaction_fee')}
                    error={errors.transaction_fee && t(errors.transaction_fee.message as string)}
                  />
                </Input.Wrapper>
              </Grid.Col> */}
              <Grid.Col>
                <Input.Wrapper label={t('forControl.formFields.commissionFee')}>
                  <Input
                    rightSection={<Text weight={600}>VND</Text>}
                    type='number'
                    min={0}
                    readOnly={Boolean(order?.is_checked)}
                    {...register('commission_fee')}
                    error={errors.commission_fee && t(errors.commission_fee.message as string)}
                  />
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col>
                <Input.Wrapper label={t('forControl.formFields.additionalFee')}>
                  <Input
                    rightSection={<Text weight={600}>VND</Text>}
                    type='number'
                    min={0}
                    readOnly={Boolean(order?.is_checked)}
                    {...register('addition_fee')}
                    error={errors.addition_fee && t(errors.addition_fee.message as string)}
                  />
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col>
                <Input.Wrapper label={t('forControl.formFields.note')}>
                  <Textarea
                    autosize
                    minRows={2}
                    maxRows={4}
                    readOnly={Boolean(order?.is_checked)}
                    {...register('note')}
                  />
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col>
                <Input.Wrapper label={t('forControl.formFields.payableAmount')}>
                  <Input
                    rightSection={<Text weight={600}>VND</Text>}
                    type='number'
                    min={0}
                    readOnly
                    {...register('payable_amount')}
                  />
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col>
                <Flex my={40} justify='center' align='center'>
                  <Button
                    variant='highlight'
                    loading={isSubmitting}
                    disabled={Boolean(order?.is_checked) || !isValid}
                    onClick={handleSubmit(_onSubmit)}
                  >
                    {t('forControl.confirm')}
                  </Button>
                </Flex>
              </Grid.Col>
            </Grid>
          </form>
        </Grid.Col>
      </Grid>
    </Card>
  )
}

export default FormControlFormAConfirm
