import { Box, Flex } from '@mantine/core'

import RefreshButton from '../RefreshButton'

type ListFilterLayoutProps = {
  onRefresh: () => void
  children: React.ReactNode
}

const ListFilterLayout: React.FC<ListFilterLayoutProps> = ({ onRefresh, children }) => {
  return (
    <Flex
      justify='space-between'
      align='end'
      direction={{ base: 'column', md: 'row' }}
      gap={12}
      mb={30}
    >
      <Box w='100%'>{children}</Box>
      <RefreshButton onRefresh={onRefresh} />
    </Flex>
  )
}

export default ListFilterLayout
