import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { LiaAngleLeftSolid, LiaAngleRightSolid } from 'react-icons/lia'
import { createSearchParams, Link, useLocation, useNavigate } from 'react-router-dom'
import { Flex, Select, Text } from '@mantine/core'
import qs from 'query-string'

import { DEFAULT_PAGINATION_QUERY } from '@/constants/pagination'

import { BasePaginationProps } from './types'
import { calculateTotalPages } from './utils'

export type QueryPaginationProps = BasePaginationProps & {
  isShowLimitOptions?: boolean
}

const LimitItemsData: string[] = [DEFAULT_PAGINATION_QUERY.limit.toString(), '40', '50', '100']

const QueryPagination: React.FC<QueryPaginationProps> = ({
  total = 0,
  page,
  limit,
  isShowLimitOptions = false,
}) => {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()

  const queryParams = useMemo(() => {
    const searchParams = new URLSearchParams(location.search) as any
    const searchParamsObject = {} as any

    for (const [key, value] of searchParams) {
      searchParamsObject[key] = value
    }

    return searchParamsObject
  }, [location.search])

  const totalPages = useMemo(() => {
    const value = calculateTotalPages(total, limit)
    if (isNaN(value)) return 0
    return value
  }, [limit, total])

  const pageListStartIndex = useMemo(() => {
    let value = 0
    if (totalPages > 5) {
      if (page >= totalPages - 1 && page < totalPages) {
        value = page - 3
      } else if (page == totalPages) {
        value = page - 4
      } else {
        value = page >= 4 ? page - 2 : 0
      }
    }

    return value
  }, [page, totalPages])

  const pageListEndIndex = useMemo(() => {
    let value = 5
    if (totalPages > 5) {
      value = page >= 4 ? page + 1 : 4
    }

    return value
  }, [page, totalPages])

  const prevPageUrl = useMemo(() => {
    const query = {
      ...queryParams,
      page: page - 1 || 1,
    } as any
    return qs.stringifyUrl({
      url: location.pathname,
      query,
    })
  }, [queryParams, location.pathname, page])

  const nextPageUrl = useMemo(() => {
    const nextPage = page >= totalPages ? totalPages : page + 1
    const query = {
      ...queryParams,
      page: nextPage,
    } as any
    return qs.stringifyUrl({
      url: location.pathname,
      query,
    })
  }, [queryParams, location.pathname, page, totalPages])

  const buildPageUrl = (page: number) => {
    const query = {
      ...queryParams,
      page,
    } as any
    return qs.stringifyUrl({
      url: location.pathname,
      query,
    })
  }

  const fromItem = useMemo(() => {
    if (!limit || limit == 0) return 0
    return (page - 1) * limit + 1
  }, [limit, page])

  const toItem = useMemo(() => {
    let toItem = 0
    if (limit < total) {
      toItem = limit * page
      if (toItem > total) {
        toItem = total
      }
    } else if (limit >= total) {
      toItem = total
    }

    return toItem
  }, [limit, page, total])

  const onChangeLimit = useCallback(
    (limit: string) => {
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          ...queryParams,
          per: parseInt(limit),
        }).toString(),
      })
    },
    [location.pathname, navigate, queryParams],
  )

  return (
    <Flex my={40} justify='center' align='center' direction='column' gap={12}>
      <Flex
        sx={{
          a: {
            color: 'black',
            textDecoration: 'none',
          },
        }}
      >
        {page > 1 && (
          <Link to={prevPageUrl} style={{ textDecoration: 'none', color: 'black' }}>
            <Flex justify='center' align='center' w={20} h={40} sx={{ cursor: 'pointer' }}>
              <LiaAngleLeftSolid />
            </Flex>
          </Link>
        )}
        {totalPages > 5 && pageListStartIndex > 1 && (
          <>
            <Link to={buildPageUrl(1)}>
              <Flex
                justify='center'
                align='center'
                w={40}
                h={40}
                sx={{
                  cursor: 'pointer',
                  transition: '0.2s ease-in-out',
                  '&:hover': {
                    background: 'black',
                    color: 'white',
                  },
                }}
              >
                <Text>1</Text>
              </Flex>
            </Link>
            <Flex justify='center' align='center' w={20} h={40}>
              <Text>...</Text>
            </Flex>
          </>
        )}
        {Array(totalPages)
          .fill(0, pageListStartIndex, pageListEndIndex)
          .map((_, index) =>
            page === index + 1 ? (
              <Flex key={index} justify='center' align='center' w={40} h={40} bg='black'>
                <Text color='white' align='center'>
                  {index + 1}
                </Text>
              </Flex>
            ) : (
              <Link key={index} to={buildPageUrl(index + 1)}>
                <Flex
                  key={index}
                  justify='center'
                  align='center'
                  w={40}
                  h={40}
                  sx={{
                    cursor: 'pointer',
                    transition: '0.2s ease-in-out',
                    '&:hover': {
                      background: 'black',
                      color: 'white',
                    },
                  }}
                >
                  <Text align='center'>{index + 1}</Text>
                </Flex>
              </Link>
            ),
          )}
        {totalPages > 5 && pageListEndIndex < totalPages - 1 && (
          <Flex justify='center' align='center' w={20} h={40}>
            <Text>...</Text>
          </Flex>
        )}
        {totalPages > 5 &&
          page < totalPages - 1 &&
          (page === totalPages ? (
            <Flex justify='center' align='center' w={40} h={40}>
              <Text>{totalPages}</Text>
            </Flex>
          ) : (
            <Link to={buildPageUrl(totalPages)}>
              <Flex
                justify='center'
                align='center'
                w={40}
                h={40}
                sx={{
                  cursor: 'pointer',
                  transition: '0.2s ease-in-out',
                  '&:hover': {
                    background: 'black',
                    color: 'white',
                  },
                }}
              >
                <Text>{totalPages}</Text>
              </Flex>
            </Link>
          ))}
        {page < totalPages && (
          <Link to={nextPageUrl}>
            <Flex justify='center' align='center' w={20} h={40} sx={{ cursor: 'pointer' }}>
              <LiaAngleRightSolid />
            </Flex>
          </Link>
        )}
      </Flex>

      {isShowLimitOptions ? (
        <Flex justify='space-between' align='center' gap={12}>
          <Select data={LimitItemsData} value={limit.toString()} onChange={onChangeLimit} w={100} />
          <Text>{` / ${t('common.page')}`}</Text>
        </Flex>
      ) : null}

      {total > 0 && (
        <Text align='center'>
          {`${t('pagination.show')} ${fromItem} - ${toItem} ${t('pagination.of')} ${total}`}
        </Text>
      )}
    </Flex>
  )
}

export default QueryPagination
