import { useCallback, useState } from 'react'
import { Models } from '@kicksplanet/interfaces'
import { Flex, Grid, Image, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'

import GalleryModal from '../GalleryModal'

type GalleryInLineProps = {
  images: Models.File[]
  imgAlt?: string
}

const GalleryInLine: React.FC<GalleryInLineProps> = ({ images, imgAlt = '' }) => {
  const [selectedImage, setSeletedImage] = useState<Models.File | null>()
  const [galleryOpened, galleryModal] = useDisclosure(false, {
    onClose: () => {
      setSeletedImage(null)
    },
  })

  const onSelectImage = useCallback(
    (image: Models.File | null) => {
      setSeletedImage(image)
      galleryModal.open()
    },
    [galleryModal],
  )

  return (
    <>
      <Grid
        gutter={4}
        sx={(_theme) => ({
          background: _theme.colors.gray[0],
        })}
      >
        {images.slice(0, 6).map((item, index) => {
          if (!item) return null
          return (
            <Grid.Col
              key={item.file_id}
              md={2}
              pos='relative'
              span={2}
              style={{
                aspectRatio: '1/1',
                cursor: 'pointer',
              }}
              onClick={() => onSelectImage(item)}
            >
              <Flex
                justify='center'
                align='center'
                bg='white'
                mx='auto'
                p={4}
                sx={(_theme) => ({
                  width: '100%',
                  height: '100%',
                  border: `1px solid ${_theme.colors.green[4]}`,
                  borderRadius: '2px',
                  transition: '0.2s ease-in-out',
                  '&:hover': {
                    borderColor: _theme.colors.green[4],
                  },
                  img: {
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                  },
                })}
              >
                {item && <Image src={item.image_lg_path || item.file_path || ''} alt={imgAlt} />}
              </Flex>
              {images.length > 6 && index === 5 ? (
                <Flex
                  justify='center'
                  align='center'
                  p={4}
                  sx={() => ({
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    top: 0,
                    left: 0,
                    borderRadius: '2px',
                    background: '#6f6f6f5c',
                  })}
                >
                  <Text color='white' size='lg'>
                    {`+${images?.length - 6}`}
                  </Text>
                </Flex>
              ) : null}
            </Grid.Col>
          )
        })}
      </Grid>

      <GalleryModal
        isOpen={galleryOpened}
        onClose={galleryModal.close}
        loop
        imageUrls={images?.map((x) => {
          if (!x) return ''
          return x.image_lg_path || x.file_path
        })}
        selectedImage={selectedImage?.image_lg_path || selectedImage?.file_path || ''}
      />
    </>
  )
}

export default GalleryInLine
