import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { AiFillCheckCircle } from 'react-icons/ai'
import { BiMoney } from 'react-icons/bi'
import { MdInfoOutline } from 'react-icons/md'
import { OnlinePaymentStatus, PaymentMethod } from '@kicksplanet/enums'
import { OrderResponses } from '@kicksplanet/interfaces'
import { Badge, Card, Divider, Flex, Grid, Text } from '@mantine/core'
import find from 'lodash/find'

import { OrderStatusData } from '@/constants/order'
import { formatDate } from '@/utils/date'
import { DATE_FORMATS } from '@/utils/date-format'

type OrderDetailInfoProps = {
  order: OrderResponses.OrderDetails
}

const OrderDetailInfo: React.FC<OrderDetailInfoProps> = ({ order }) => {
  const { t } = useTranslation()

  const paymentInfoText = useMemo(() => {
    if (order.payment_method === PaymentMethod.COD) return 'order.paymentStatus.COD'
    if (order.is_paid) {
      return 'order.paymentStatus.paid'
    }
    switch (order.payment_transaction?.status) {
      case OnlinePaymentStatus.Pending: {
        return 'order.paymentStatus.pending'
      }
      case OnlinePaymentStatus.Success: {
        return 'order.paymentStatus.paid'
      }
      case OnlinePaymentStatus.Error: {
        return 'order.paymentStatus.failed'
      }
      default: {
        return null
      }
    }
  }, [order.is_paid, order.payment_method, order.payment_transaction?.status])

  return (
    <Card>
      <Grid gutter={16} mb={30}>
        <Grid.Col>
          <Flex justify='start' align='center' wrap='wrap' gap={12}>
            <Text weight={600} transform='uppercase'>
              {t('order.orderHeading')}
            </Text>
            <Badge
              p={8}
              radius={4}
              sx={(_theme) => ({
                color: _theme.white,
                backgroundColor: _theme.colors.gray[5],
                borderColor: 'transparent',
              })}
            >
              <Flex justify='space-between' align='center' gap={4}>
                <BiMoney size={16} />
                {t(paymentInfoText || '')}
              </Flex>
            </Badge>
            <Badge
              p={8}
              radius={4}
              sx={(_theme) => ({
                color: _theme.white,
                backgroundColor: order?.is_refunded
                  ? _theme.colors.success[5]
                  : _theme.colors.warning[5],
                borderColor: 'transparent',
              })}
            >
              <Flex justify='space-between' align='center' gap={4}>
                {order?.is_refunded ? <AiFillCheckCircle size={16} /> : <MdInfoOutline size={16} />}
                {order?.is_refunded ? t('order.refunded') : t('order.notRefunded')}
              </Flex>
            </Badge>
          </Flex>
          <Divider my={12} color='gray.2' />
        </Grid.Col>
        <Grid.Col>
          <Grid gutter={8} justify='space-between'>
            <Grid.Col md={2}>
              <Text weight={600}>{t('order.code')}</Text>
              <Text>{order.code}</Text>
            </Grid.Col>
            <Grid.Col md={2}>
              <Text weight={600}>{t('order.status')}</Text>
              <Text>
                {order.status ? (
                  <Badge
                    variant='checked-outline'
                    p={8}
                    sx={(_theme) => ({
                      color: _theme.white,
                      backgroundColor: find(OrderStatusData, { value: order?.status })?.color || '',
                      borderColor: 'transparent',
                    })}
                  >
                    <Text>{t(find(OrderStatusData, { value: order?.status })?.label || '')}</Text>
                  </Badge>
                ) : null}
              </Text>
            </Grid.Col>
            <Grid.Col md={2}>
              <Text weight={600}>{t('order.paymentMethod')}</Text>
              <Text>{order.payment_method}</Text>
            </Grid.Col>
            <Grid.Col md={2}>
              <Text weight={600}>{t('common.createdAt')}</Text>
              <Text>
                {order.created_at
                  ? formatDate(order.created_at, DATE_FORMATS.HH_MM_AA_DOT_DD_MMMM_YYYY)
                  : '--'}
              </Text>
            </Grid.Col>
            <Grid.Col md={2}>
              <Text weight={600}>{t('common.updatedAt')}</Text>
              <Text>
                {order.updated_at
                  ? formatDate(order.updated_at, DATE_FORMATS.HH_MM_AA_DOT_DD_MMMM_YYYY)
                  : '--'}
              </Text>
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid>
    </Card>
  )
}

export default OrderDetailInfo
