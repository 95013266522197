import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { Models } from '@kicksplanet/interfaces'
import { useAPIService } from '@kicksplanet/react/hooks'
import { GetListParams } from '@kicksplanet/services/dist/services/core/CURDService'
import { Box, Button, Card, Checkbox, Flex, Grid, Skeleton } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { notifications } from '@mantine/notifications'

import Empty from '@/components/Empty'
import ConfirmationModal from '@/components/ModalForm/Confirmation'
import Pagination from '@/components/Pagination/Pagination'
import RefreshButton from '@/components/RefreshButton'
import UploadMedia from '@/components/UploadMedia'
import { DEFAULT_PAGINATION_QUERY } from '@/constants/pagination'
import { useApp } from '@/contexts/AppProvider'

import MediaListItem from '../Components/MediaItem'

const MediaList: React.FC = () => {
  const { t } = useTranslation()
  const fileService = useAPIService('File')

  const { registerBreadcrumb, unregisterBreadcrumb } = useApp()

  const [uploadMediaOpend, uploadMediaModal] = useDisclosure(false)
  const [removeOpened, removeModal] = useDisclosure(false)
  const [isRemoveLoading, setIsRemoveLoading] = useState<boolean>(false)

  const [page, setPage] = useState<number>(1)
  const [limit, setLimit] = useState<number>(DEFAULT_PAGINATION_QUERY.limit)
  const [getListQuery, setGetListQuery] = useState<GetListParams<Models.File>>({
    pagination: { page: 1, perPage: DEFAULT_PAGINATION_QUERY.limit },
  })
  const [checkedItems, setCheckedItems] = useState<Models.File[]>()

  useEffect(() => {
    registerBreadcrumb({
      key: 'mediaLibrary',
      label: t('page.mediaLibrary'),
    })

    return () => {
      unregisterBreadcrumb('mediaLibrary')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    data: files,
    isFetching: isFilesLoading,
    refetch: getFiles,
  } = useQuery(['fileService', 'getList', getListQuery], () =>
    fileService.getList(getListQuery).then((res) => res.data),
  )

  const onSetGetListQuery = useCallback(() => {
    const query: GetListParams<Models.File> = {
      pagination: {
        page: page,
        perPage: limit,
      },
    }
    setGetListQuery(query)
  }, [limit, page])

  useEffect(() => {
    onSetGetListQuery()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit])

  const onRefresh = useCallback(() => {
    getFiles()
  }, [getFiles])

  const onChangePage = useCallback((page: number) => {
    setPage(page)
    setCheckedItems([])
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  const onChangeLimit = useCallback((limit: number) => {
    setLimit(limit)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  const onRemoves = useCallback(async () => {
    if (!checkedItems || checkedItems.length <= 0) return

    setIsRemoveLoading(true)
    try {
      const deletePromises = checkedItems.map(async (file) => {
        const uploadedFile = await fileService.delete({ id: file.file_id })
        return uploadedFile
      })
      await Promise.all(deletePromises)

      notifications.show({
        title: t('common.removeTitle'),
        variant: 'success',
        message: t('success.deletedFile'),
      })
      onRefresh()
      setCheckedItems([])
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      const errorMessage = t(err?.response?.data, {
        ns: 'backend',
      })

      notifications.show({
        variant: 'danger',
        message: errorMessage,
      })

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      err?.response?.data?.errors?.forEach((err: any) => {
        notifications.show({
          variant: 'danger',
          message: t(err, {
            ns: 'backend',
          }),
        })
      })
    } finally {
      setIsRemoveLoading(false)
    }
  }, [checkedItems, fileService, onRefresh, t])

  const onToggleAllRow = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.target.checked

      if (isChecked) {
        setCheckedItems(files?.data || [])
      } else {
        setCheckedItems([])
      }
    },
    [files?.data],
  )

  const onToggleRow = useCallback(
    (event: ChangeEvent<HTMLInputElement>, item: Models.File) => {
      const isChecked = event.target.checked

      if (isChecked) {
        if (checkedItems && checkedItems.length > 0) {
          setCheckedItems([...checkedItems, item])
        } else {
          setCheckedItems([item])
        }
      } else {
        setCheckedItems(checkedItems?.filter((x) => x != item))
      }
    },
    [checkedItems],
  )

  return (
    <>
      <Card>
        <Grid>
          <Grid.Col>
            <Flex justify='start' align='center' gap={8}>
              <Button
                onClick={removeModal.open}
                disabled={Boolean(!checkedItems || checkedItems.length <= 0)}
              >
                {t('common.delete')}
              </Button>
              <Button color='success.4' onClick={uploadMediaModal.open}>
                {t('media.uploadMedia')}
              </Button>
              <RefreshButton onRefresh={onRefresh} />
            </Flex>
          </Grid.Col>

          <Grid.Col>
            <Checkbox
              size='md'
              label={t('common.selectAll')}
              checked={Boolean(checkedItems && checkedItems?.length === files?.data?.length)}
              onChange={(e) => onToggleAllRow(e)}
            />
          </Grid.Col>

          {isFilesLoading ? (
            <Grid.Col>
              <Grid>
                {Array.from({ length: 12 }, (_, index) => (
                  <Grid.Col key={index} sm={6} md={4} lg={2}>
                    <Skeleton height={150} radius='md' />
                  </Grid.Col>
                ))}
              </Grid>
            </Grid.Col>
          ) : files && files.data && files.data.length > 0 ? (
            <Grid.Col>
              <Grid>
                {files.data.map((item) => (
                  <Grid.Col key={item.file_id} span={6} sm={4} md={3} lg={2}>
                    <Box pos='relative' h='100%'>
                      <MediaListItem file={item} onRefresh={onRefresh} />
                      <Checkbox
                        size='xs'
                        checked={checkedItems?.includes(item)}
                        onChange={(e) => onToggleRow(e, item)}
                        sx={{
                          position: 'absolute',
                          top: 8,
                          left: 8,
                          zIndex: 1,
                        }}
                      />
                    </Box>
                  </Grid.Col>
                ))}
              </Grid>
            </Grid.Col>
          ) : (
            <Grid.Col>
              <Empty />
            </Grid.Col>
          )}

          <Grid.Col>
            <Pagination
              limit={limit}
              page={page}
              total={files?.total}
              onChange={onChangePage}
              onChangeLimit={onChangeLimit}
              isShowLimitOptions
            />
          </Grid.Col>
        </Grid>
      </Card>

      <UploadMedia
        opened={uploadMediaOpend}
        onClose={uploadMediaModal.close}
        onRefresh={onRefresh}
      />

      <ConfirmationModal
        title={t('common.removeTitle')}
        description={t('media.removeText')}
        isLoading={isRemoveLoading}
        opened={removeOpened}
        onClose={removeModal.close}
        onConfirm={onRemoves}
      />
    </>
  )
}

export default MediaList
