import React from 'react'
import { Box, createStyles, Table } from '@mantine/core'

type TableWrapperProps = React.PropsWithChildren & {
  isStickyColumm?: boolean
}

const useStyles = createStyles((_theme) => ({
  table: {
    thead: {
      tr: {
        th: {
          whiteSpace: 'nowrap',
          border: 0,
          padding: '10px 20px',
          textTransform: 'uppercase',
        },
      },
    },
    tbody: {
      tr: {
        td: {
          borderColor: _theme.colors.gray[0],
          background: _theme.white,
          padding: '10px 20px',
        },
      },
    },
  },
}))

const TableWrapper: React.FC<TableWrapperProps> = ({ children, isStickyColumm = false }) => {
  const { classes, cx } = useStyles()

  return (
    <Box
      sx={(_theme) => ({
        width: '100%',
        overflowX: 'auto',
        tr: {
          ':hover': {
            transition: '0.1s',
            td: {
              background: _theme.colors.gray[0],
              transition: '0.1s',
            },
          },
        },
        ...(isStickyColumm && {
          'th, td': {
            ':last-child': {
              position: 'sticky',
              right: 0,
              padding: 10,
              background: _theme.white,
              display: 'flex',
              justifyContent: 'center',
              width: '100% !important',
              alignItems: 'center',
              transition: 'box-shadow 150ms ease',
              '&::before': {
                content: '""',
                position: 'absolute',
                right: 0,
                top: 0,
                bottom: 0,
                left: 0,
                boxShadow: '-5px 10px 12px 0px rgba(0, 0, 0, 0.15)',
              },
            },
          },
        }),
      })}
    >
      <Table className={cx(classes.table)} horizontalSpacing={50}>
        {children}
      </Table>
    </Box>
  )
}

export default TableWrapper
