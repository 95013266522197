import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { BsTelephone } from 'react-icons/bs'
import { PiLockKeyBold } from 'react-icons/pi'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { LoginParams } from '@kicksplanet/services/dist/services/auth/types'
import { Box, Button, Container, Flex, Image, Input, PasswordInput, Text } from '@mantine/core'
import { notifications } from '@mantine/notifications'
import { object, string } from 'yup'

import webLogo from '@/assets/images/logo-black-horizon.png'
import { phoneRegExp } from '@/constants/regExp'
import { useAuth } from '@/contexts/SessionProvider'
import { useQueryParams } from '@/hooks/useQueryParams'
import { ROUTES } from '@/routes'

const loginSchema = object().shape({
  phone: string()
    .required('login.missingPhoneNumber')
    .matches(phoneRegExp, 'login.invalidPhoneNumber'),
  password: string().required('login.missingPassword'),
})

type LoginFormData = {
  phone: string
  password: string
}

const SignIn: React.FC = () => {
  const { t } = useTranslation()
  const { loginUser } = useAuth()
  const query = useQueryParams()
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<LoginFormData>({
    resolver: yupResolver(loginSchema),
    defaultValues: {
      phone: '',
      password: '',
    },
    mode: 'onChange',
  })

  const {
    mutate: login,
    isLoading: isSubmitLoading,
    isSuccess,
  } = useMutation(
    async (variables: LoginParams) => {
      return await loginUser({
        phone: variables.phone,
        password: variables.password,
      })
    },
    {
      onSuccess: async () => {
        navigate(query.get('redirect') || ROUTES.ADMIN.DASHBOARD.INDEX)
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        const errorMessage = t(err?.response?.data, {
          ns: 'backend',
        })

        notifications.show({
          variant: 'danger',
          message: errorMessage,
        })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        err?.response?.data?.errors?.forEach((err: any) => {
          notifications.show({
            variant: 'danger',
            message: t(err, {
              ns: 'backend',
            }),
          })
        })
      },
    },
  )

  const onSubmit = useCallback(
    async (data: LoginParams) => {
      login(data)
    },
    [login],
  )

  return (
    <Container>
      <Box w={{ base: '100%', md: '50%' }} m='auto' py={100}>
        <Flex
          justify='center'
          align='center'
          mb={30}
          sx={{
            img: {
              objectFit: 'contain',
            },
          }}
        >
          <Image src={webLogo} alt='' width={269.92} height={16} />
        </Flex>
        <Text align='center' mb={60}>
          {t('login.loginInfo')}
        </Text>
        <form method='post' onSubmit={handleSubmit(onSubmit)}>
          <Box mb={20}>
            <Input.Wrapper
              label={`${t('login.yourPhone')} *`}
              error={errors.phone && t(errors.phone.message as string)}
            >
              <Input
                placeholder={t('login.yourPhone')}
                icon={<BsTelephone />}
                {...register('phone')}
              />
            </Input.Wrapper>
          </Box>
          <Box mb={30}>
            <PasswordInput
              placeholder={t('login.yourPassword')}
              label={`${t('login.yourPassword')} *`}
              icon={<PiLockKeyBold />}
              {...register('password')}
              error={errors.password && t(errors.password.message as string)}
            />
          </Box>
          <Box mb={20}>
            <Button
              variant='highlight'
              type='submit'
              loading={isSubmitLoading}
              disabled={!isValid || isSuccess}
              fullWidth
            >
              {t('login.signInButton')}
            </Button>
          </Box>
        </form>
      </Box>
    </Container>
  )
}

export default SignIn
