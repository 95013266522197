import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Card } from '@mantine/core'
import { notifications } from '@mantine/notifications'

import { useApp } from '@/contexts/AppProvider'
import { ROUTES } from '@/routes'

import CategoryForm, { CategoryFormType } from '../Components/CategoryForm'

const CategoryUpdate: React.FC = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const categoryService = useAPIService('Category')

  const { registerBreadcrumb, unregisterBreadcrumb } = useApp()

  const { data: categrory, refetch: getCategory } = useQuery(
    ['categoryService', 'getOne', id],
    () => categoryService.getOne({ id: id ? id : '' }).then((res) => res.data.data),
    {
      enabled: Boolean(id?.toString()),
    },
  )

  useEffect(() => {
    registerBreadcrumb({
      key: 'categories',
      label: t('page.categories'),
      url: ROUTES.ADMIN.CATEGORIES.LIST,
    })

    if (categrory) {
      registerBreadcrumb({
        key: categrory.category_id,
        label: categrory.name,
      })
    }

    return () => {
      unregisterBreadcrumb('categories')
      if (categrory) {
        unregisterBreadcrumb(categrory.category_id)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categrory])

  const { mutate: update, isLoading: isUpdateLoading } = useMutation(
    (data: CategoryFormType) => {
      return categoryService.update({
        id: id?.toString() || '',
        data: {
          data: {
            ...data,
            parent_id: data.parent_id ? data.parent_id : undefined,
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any,
      })
    },
    {
      onSuccess: async () => {
        notifications.show({
          variant: 'success',
          message: t('success.updatedCategory'),
        })
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        const errorMessage = t(err?.response?.data, {
          ns: 'backend',
        })

        notifications.show({
          variant: 'danger',
          message: errorMessage,
        })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        err?.response?.data?.errors?.forEach((err: any) => {
          notifications.show({
            variant: 'danger',
            message: t(err, {
              ns: 'backend',
            }),
          })
        })
      },
    },
  )

  const onUpdate = useCallback(
    (data: CategoryFormType) => {
      if (!id) return
      update(data)
    },
    [id, update],
  )

  return (
    <Card>
      <CategoryForm
        category={categrory}
        isSubmitLoading={isUpdateLoading}
        onSubmit={onUpdate}
        isParent={false}
        onRefresh={getCategory}
      />
    </Card>
  )
}

export default CategoryUpdate
