import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BiSearch } from 'react-icons/bi'
import { HiOutlineTrash } from 'react-icons/hi'
import { RiCloseLine } from 'react-icons/ri'
import { TbGridDots } from 'react-icons/tb'
import { DragDropContext, Draggable, Droppable, DropResult } from '@hello-pangea/dnd'
import {
  Badge,
  Box,
  Button,
  Flex,
  Grid,
  Paper,
  Skeleton,
  Text,
  TextInput,
  Tooltip,
  Transition,
} from '@mantine/core'
import { useClickOutside, useDebouncedState, useDisclosure, useListState } from '@mantine/hooks'

import Empty from '@/components/Empty'
import TableWrapper from '@/components/TableWrapper'
import { formatNumber, formatPrice } from '@/utils/number'

type TagAutocompleteProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  optionData: any
  isLoading?: boolean
  isShowBadge?: boolean
  isShowTable?: boolean
  isSort?: boolean
  searchControl: {
    valueKeySearch: string
    labelKeySearch: string
    labelControl: string
    onSearch: (keysearch: string) => void
  }
  selectedData: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectedItems: any[] | null | undefined
    selectedLabelKey: string
    selectedValueKey: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSelectItem: (item: any) => void
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onRemoveItem: (item: any) => void
  }
  columnKeys?: {
    caption: string
    valueKey: string
    type?: 'number' | 'price'
  }[]
  sorting?: {
    onSort: (data: any) => void
  }
}

const TagAutocomplete: React.FC<TagAutocompleteProps> = ({
  optionData = [],
  isShowBadge = false,
  isShowTable = false,
  isSort = false,
  searchControl,
  isLoading = false,
  columnKeys = [],
  selectedData,
  sorting,
}) => {
  const { t } = useTranslation()

  const [keysearch, setKeysearch] = useDebouncedState('', 400)

  const [dataState, dataHandlers] = useListState<any>([])
  const [reorder, setReorder] = useState<boolean>(false)

  const [resultDropdownOpened, resultDropdown] = useDisclosure(false)

  const ref = useClickOutside(() => {
    resultDropdown.close()
  })

  const _onSearch = useCallback(
    (event?: React.ChangeEvent<HTMLInputElement>) => {
      const value = event?.target?.value as string
      setKeysearch(value)
      resultDropdown.open()
    },
    [resultDropdown, setKeysearch],
  )

  useEffect(() => {
    searchControl.onSearch(keysearch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keysearch])

  const _onSelectItem = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (item: any) => {
      selectedData.onSelectItem(item)
      resultDropdown.close()
    },
    [selectedData, resultDropdown],
  )

  const _onRemoveItem = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (item: any) => {
      selectedData.onRemoveItem(item)
    },
    [selectedData],
  )

  // sorting
  useEffect(() => {
    dataHandlers.setState(selectedData.selectedItems || [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedData.selectedItems])

  const onDragEnd = useCallback(
    (result: DropResult) => {
      const { source, destination } = result

      if (!destination) {
        return
      }
      if (destination.index === source.index) {
        return
      }

      const items = Array.from(dataState || [])
      const [reorderedItem] = items.splice(source.index, 1)
      items.splice(destination.index, 0, reorderedItem)

      const updateOrdinal = items.map((item, index) => ({
        ...item,
        ordinal: index + 1,
      }))

      dataHandlers.setState(updateOrdinal)
      setReorder(true)
    },
    [dataHandlers, dataState],
  )

  const onSort = useCallback(() => {
    if (!isSort) return

    if (reorder) {
      sorting?.onSort?.(dataState)
      setReorder(false)
    }
  }, [dataState, isSort, reorder, sorting])

  useEffect(() => {
    onSort()
  }, [onSort])

  const displayColumnValue = useCallback((type: any, value: any) => {
    switch (type) {
      case 'number':
        return formatNumber(value || 0)
      case 'price':
        return formatPrice(value || 0)
      default:
        return value
    }
  }, [])

  const RowData = useMemo(() => {
    if (!dataState || dataState.length <= 0) {
      return (
        <tr>
          <td colSpan={columnKeys.length + (isSort ? 2 : 1)}>
            <Empty />
          </td>
        </tr>
      )
    }

    return dataState.map((item, itemIndex) => {
      return (
        <Draggable
          key={item[selectedData.selectedValueKey]}
          index={itemIndex}
          draggableId={item[selectedData.selectedValueKey]}
        >
          {(provided) => (
            <tr ref={provided.innerRef} {...provided.draggableProps}>
              {isSort ? (
                <td>
                  <Box {...provided.dragHandleProps} style={{ cursor: 'grab' }}>
                    <TbGridDots />
                  </Box>
                </td>
              ) : null}
              {columnKeys.map((column, columnIndex) => {
                return (
                  <td key={columnIndex} style={{ minWidth: '200px' }}>
                    {(() => {
                      const value = displayColumnValue(column.type, item[column.valueKey])
                      return (
                        <Tooltip label={value}>
                          <Text lineClamp={1} w='fit-content'>
                            {value}
                          </Text>
                        </Tooltip>
                      )
                    })()}
                  </td>
                )
              })}
              <td>
                <Flex justify='center' align='center'>
                  <Button variant='subtle' p={10} onClick={() => _onRemoveItem(item)}>
                    <HiOutlineTrash color='red' />
                  </Button>
                </Flex>
              </td>
            </tr>
          )}
        </Draggable>
      )
    })
  }, [
    dataState,
    columnKeys,
    isSort,
    selectedData.selectedValueKey,
    displayColumnValue,
    _onRemoveItem,
  ])

  return (
    <Grid>
      <Grid.Col>
        <Box ref={ref} pos='relative' w='100%'>
          <TextInput
            icon={<BiSearch size='1rem' />}
            label={searchControl.labelControl}
            placeholder={searchControl.labelControl}
            onChange={_onSearch}
            onClick={resultDropdown.toggle}
          />

          <Transition
            mounted={resultDropdownOpened}
            transition='scale-y'
            duration={150}
            timingFunction='ease'
          >
            {(styles) => (
              <Paper
                pos='absolute'
                shadow='sm'
                p={8}
                my={4}
                w='100%'
                style={{ ...styles }}
                sx={(_theme) => ({
                  border: `1px solid ${_theme.colors.gray[2]}`,
                  borderRadius: '3px',
                  boxShadow: _theme.shadows.sm,
                  maxHeight: '250px',
                  overflowY: 'auto',
                  zIndex: 1000,
                })}
              >
                <Box>
                  <Skeleton visible={isLoading}>
                    {optionData &&
                      optionData.length > 0 &&
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      optionData.map((item: any) => (
                        <Box
                          key={item[searchControl.valueKeySearch]}
                          onClick={() => _onSelectItem?.(item)}
                          sx={(_theme) => ({
                            cursor: 'pointer',
                            transition: '0.1s',
                            '&:hover': {
                              background: _theme.colors.gray[2],
                              transition: '0.1s',
                            },
                          })}
                          py={4}
                          px={8}
                        >
                          <Text>{item[searchControl.labelKeySearch]}</Text>
                        </Box>
                      ))}
                  </Skeleton>
                </Box>
              </Paper>
            )}
          </Transition>
        </Box>
      </Grid.Col>

      {isShowBadge && (
        <Grid.Col>
          <Flex justify='start' align='center' wrap='wrap' gap={4}>
            {selectedData &&
              selectedData.selectedItems &&
              selectedData.selectedItems.length > 0 &&
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              selectedData.selectedItems.map((item: any, index) => (
                <Badge
                  key={index}
                  p={12}
                  sx={(_theme) => ({
                    color: _theme.white,
                    backgroundColor: _theme.colors.success[6],
                    borderRadius: _theme.radius.sm,
                    borderColor: 'transparent',
                    cursor: 'pointer',
                    svg: {
                      display: 'flex',
                    },
                  })}
                  rightSection={<RiCloseLine size={16} />}
                  onClick={() => _onRemoveItem(item)}
                >
                  {item[selectedData.selectedLabelKey]}
                </Badge>
              ))}
          </Flex>
        </Grid.Col>
      )}

      {isShowTable && (
        <Grid.Col>
          <DragDropContext onDragEnd={onDragEnd}>
            <TableWrapper
              isStickyColumm={Boolean(
                !isSort && selectedData.selectedItems && selectedData.selectedItems.length > 0,
              )}
            >
              <thead>
                <tr>
                  {isSort ? <th></th> : null}
                  {columnKeys.map((item, index) => {
                    return <th key={index}>{t(item.caption)}</th>
                  })}
                  <th>{t('common.action')}</th>
                </tr>
              </thead>
              <Droppable droppableId='dnd-list' direction='vertical'>
                {(provided) => (
                  <tbody {...provided.droppableProps} ref={provided.innerRef}>
                    {RowData}
                    {provided.placeholder}
                  </tbody>
                )}
              </Droppable>
            </TableWrapper>
          </DragDropContext>
        </Grid.Col>
      )}
    </Grid>
  )
}

export default TagAutocomplete
