import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BiSearch } from 'react-icons/bi'
import { Button, Flex, Grid, Select, Text, TextInput } from '@mantine/core'
import { useDebouncedState } from '@mantine/hooks'
import get from 'lodash/get'

import {
  OrderStatusData,
  OrderStatusDataType,
  RefundStatusData,
  RefundStatusDataType,
} from '@/constants/order'
import { ConditionData, ConditionDataType } from '@/constants/selling'
import { useSelection } from '@/hooks/useSelection'
import { formatNumber } from '@/utils/number'
import { cleanAccents } from '@/utils/string'

type OrderListFilterProps = {
  total?: number
  queryData: OrderListSearchType
  onChangeQueries: (query: OrderListSearchType) => void
}

const OrderListFilter: React.FC<OrderListFilterProps> = ({ total, queryData, onChangeQueries }) => {
  const { t } = useTranslation()

  const [keysearch, setKeysearch] = useDebouncedState(queryData.key, 400)
  const [selectedOrderStatus, setSelectedOrderStatus] = useState<OrderStatusDataType | null>()
  const [selectedCondition, setSelectedCondition] = useState<ConditionDataType | null>()

  const onSetKeysearch = useCallback(
    async (event?: React.ChangeEvent<HTMLInputElement>) => {
      const value = event?.target?.value as string
      setKeysearch(value)
    },
    [setKeysearch],
  )

  // Status
  const onSelectOrderStatusOption = useCallback((item?: OrderStatusDataType | null) => {
    setSelectedOrderStatus(item)
  }, [])

  const {
    data: orderStatusOptions,
    onSelect: onSelectOrderStatus,
    value: orderStatusValue,
    filter: orderStatusFilter,
  } = useSelection<OrderStatusDataType>({
    items: OrderStatusData.map((item) => ({
      ...item,
      label: t(item.label),
    })),
    onSelect: onSelectOrderStatusOption,
    valueKey: 'label',
    allOption: true,
  })

  // Condition
  const onSelectConditionOption = useCallback((item?: ConditionDataType | null) => {
    setSelectedCondition(item)
  }, [])

  const {
    data: conditionOptions,
    onSelect: onSelectCondition,
    value: conditionValue,
    filter: conditionFilter,
  } = useSelection<ConditionDataType>({
    items: ConditionData.map((item) => ({
      ...item,
      label: t(item.label),
    })),
    onSelect: onSelectConditionOption,
    valueKey: 'label',
    allOption: true,
  })

  useEffect(() => {
    if (queryData.status) {
      const data = OrderStatusData.find((x) => x.value == queryData.status)
      onSelectOrderStatus(t(data?.label || ''))
    }
    if (queryData.condition) {
      const data = ConditionData.find((x) => x.value.toString() == queryData.condition)
      onSelectCondition(t(data?.label || ''))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData.status, queryData.condition])

  // Refund
  const onSetSelectedRefundStatus = useCallback(
    (data: RefundStatusDataType) => {
      onChangeQueries({
        ...queryData,
        refund: data.value.toString(),
      })
    },
    [onChangeQueries, queryData],
  )

  const _onChangeQueries = useCallback(() => {
    const result = {
      ...(keysearch ? { key: cleanAccents(keysearch) } : ''),
      ...(selectedOrderStatus ? { status: selectedOrderStatus.value.toString() } : {}),
      ...(selectedCondition ? { condition: selectedCondition.value.toString() } : {}),
    }

    onChangeQueries(result)
  }, [keysearch, onChangeQueries, selectedCondition, selectedOrderStatus])

  return (
    <Grid gutter={16} align='end'>
      <Grid.Col md={3}>
        <TextInput
          label={t('common.keyword')}
          icon={<BiSearch size='1rem' />}
          placeholder={t('order.searchByKeyword')}
          onChange={onSetKeysearch}
          defaultValue={keysearch}
        />
      </Grid.Col>
      <Grid.Col md={3}>
        <Select
          label={t('order.status')}
          data={orderStatusOptions}
          value={orderStatusValue}
          onChange={onSelectOrderStatus}
          filter={orderStatusFilter}
          defaultValue={get(orderStatusOptions[0], 'value')}
          searchable
        />
      </Grid.Col>
      <Grid.Col md={3}>
        <Select
          label={t('common.condition')}
          data={conditionOptions}
          value={conditionValue}
          onChange={onSelectCondition}
          filter={conditionFilter}
          defaultValue={get(conditionOptions[0], 'value')}
          searchable
        />
      </Grid.Col>
      <Grid.Col md={3}>
        <Button color='success.4' onClick={_onChangeQueries}>
          <Text>{t('common.searchButton')}</Text>
        </Button>
      </Grid.Col>
      <Grid.Col>
        <Flex justify='start' align={{ base: 'start', md: 'center' }} wrap='wrap' gap={8}>
          {RefundStatusData.map((item) => {
            const itemValue = item.value.toString()
            const refund = queryData?.refund?.toString()
            return (
              <Button
                key={item.value}
                variant='outline'
                p={8}
                sx={(_theme) => ({
                  borderColor: refund == itemValue ? 'white' : item.colorOutline,
                  color: refund == itemValue ? 'white' : item.colorOutline,
                  background: refund == itemValue ? item.colorOutline : 'white',
                  '&:hover': {
                    color: _theme.white,
                    background: item.colorOutline,
                  },
                })}
                onClick={() => onSetSelectedRefundStatus(item)}
              >
                {`${item.name} ${refund == itemValue ? `(${formatNumber(total || 0)})` : ''} `}
              </Button>
            )
          })}
        </Flex>
      </Grid.Col>
    </Grid>
  )
}

export default OrderListFilter
