import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BiSearch } from 'react-icons/bi'
import { useQuery } from 'react-query'
import { Models } from '@kicksplanet/interfaces'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Button, Grid, Select, Text, TextInput } from '@mantine/core'
import { useDebouncedState } from '@mantine/hooks'
import get from 'lodash/get'

import { GenderOptionType, GenderOtions } from '@/constants/gender'
import { useSelection } from '@/hooks/useSelection'
import { cleanAccents } from '@/utils/string'

type ProductListFilterProps = {
  queryData: ProductListSearchType
  onChangeQueries: (query: ProductListSearchType) => void
}

const ProductListFilter: React.FC<ProductListFilterProps> = ({ queryData, onChangeQueries }) => {
  const { t } = useTranslation()
  const brandService = useAPIService('Brand')

  const [keysearch, setKeysearch] = useDebouncedState(queryData.key || '', 400)
  const [selectedBrand, setSelectedBrand] = useState<Models.Brand | null>()
  const [selectedGender, setSelectedGender] = useState<GenderOptionType | null>()

  const onSetKeysearch = useCallback(
    async (event?: React.ChangeEvent<HTMLInputElement>) => {
      const value = event?.target?.value as string
      setKeysearch(value)
    },
    [setKeysearch],
  )

  // Brand
  const onSelectBrandOption = useCallback((item?: Models.Brand | null) => {
    setSelectedBrand(item)
  }, [])

  const { data: brands } = useQuery(
    ['brandService', 'getList'],
    () =>
      brandService.getList({
        pagination: {
          page: 1,
          perPage: 1000000,
        },
      }),
    {
      select: ({ data: res }) => {
        return (
          res?.data?.map((item) => ({
            ...item,
            label: item.name,
          })) || []
        )
      },
      onSuccess: (data) => {
        if (queryData.brand) {
          const brand = data?.find((x) => x.name === queryData.brand)
          if (!brand) return
          onSelectBrandOption(brand)
        }
      },
    },
  )

  const {
    data: brandOptions,
    onSelect: onSelectBrand,
    value: brandValue,
    filter: brandFilter,
  } = useSelection<Models.Brand>({
    items: brands || [],
    onSelect: onSelectBrandOption,
    valueKey: 'label',
    allOption: true,
  })

  useEffect(() => {
    if (queryData.brand) {
      onSelectBrand(queryData.brand)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData.brand])

  // Gender
  const onSelectGenderOption = useCallback((item?: GenderOptionType | null) => {
    setSelectedGender(item)
  }, [])

  const {
    data: genderOptions,
    onSelect: onSelectGender,
    value: genderValue,
  } = useSelection<GenderOptionType>({
    items: GenderOtions.map((item) => ({
      ...item,
      label: t(item.label),
    })),
    onSelect: onSelectGenderOption,
    valueKey: 'label',
    allOption: true,
  })

  useEffect(() => {
    if (queryData.gender) {
      const gender = GenderOtions.find((x) => x.value == queryData.gender)
      onSelectGender(t(gender?.label || ''))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData.gender])

  const _onChangeQueries = useCallback(() => {
    const result = {
      ...(keysearch ? { key: cleanAccents(keysearch) } : ''),
      ...(selectedBrand ? { brand: selectedBrand.name } : {}),
      ...(selectedGender ? { gender: selectedGender?.value } : {}),
    }

    onChangeQueries(result)
  }, [keysearch, onChangeQueries, selectedBrand, selectedGender])

  return (
    <Grid gutter={16} align='end'>
      <Grid.Col md={4}>
        <TextInput
          label={t('common.keyword')}
          icon={<BiSearch size='1rem' />}
          placeholder={t('product.searchByKeyword')}
          onChange={onSetKeysearch}
          defaultValue={queryData.key}
        />
      </Grid.Col>
      <Grid.Col md={2}>
        <Select
          label={t('product.productBrand')}
          placeholder={t('product.productBrand')}
          data={brandOptions}
          value={brandValue}
          onChange={onSelectBrand}
          filter={brandFilter}
          defaultValue={get(brandOptions[0], 'value')}
          searchable
        />
      </Grid.Col>
      <Grid.Col md={2}>
        <Select
          label={t('product.productGender')}
          placeholder={t('product.productGender')}
          data={genderOptions}
          value={genderValue}
          onChange={onSelectGender}
          defaultValue={get(genderOptions[0], 'value')}
        />
      </Grid.Col>
      <Grid.Col md={2}>
        <Button color='success.4' onClick={_onChangeQueries}>
          <Text>{t('common.searchButton')}</Text>
        </Button>
      </Grid.Col>
    </Grid>
  )
}

export default ProductListFilter
