import { useTranslation } from 'react-i18next'
import { SizeChartResponses } from '@kicksplanet/interfaces'
import { Accordion, Badge, Box, Grid, Text } from '@mantine/core'

type ProductDetailSizeProps = {
  sizeChart?: SizeChartResponses.SizeChart
}

const ProductDetailSize: React.FC<ProductDetailSizeProps> = ({ sizeChart }) => {
  const { t } = useTranslation()

  if (!sizeChart) return null

  return (
    <Box sx={(_theme) => ({ border: `1px solid ${_theme.colors.gray[3]}` })}>
      <Accordion>
        <Accordion.Item value='sizes' py={8}>
          <Accordion.Control
            sx={{
              '.mantine-Accordion-label': { paddingTop: 8, paddingBottom: 8 },
            }}
          >
            <Text weight={600}>{t('common.size')}</Text>
          </Accordion.Control>
          <Accordion.Panel py={12}>
            <Grid>
              {sizeChart &&
                sizeChart.sizes.length > 0 &&
                sizeChart.sizes.map((item) => {
                  return (
                    <Grid.Col span={6} xs={3} md={4} lg={3} key={item.size_id}>
                      <Badge
                        variant='checked-outline'
                        size='lg'
                        py={15}
                        px={20}
                        fullWidth
                        sx={(_theme) => ({
                          color: _theme.colors.gray[5],
                          borderColor: _theme.colors.gray[3],
                          cursor: 'pointer',
                        })}
                      >
                        {`EU ${item.EU}`}
                      </Badge>
                    </Grid.Col>
                  )
                })}
            </Grid>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Box>
  )
}

export default ProductDetailSize
