import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { SellingItemCondition } from '@kicksplanet/enums'
import { SellingResponses } from '@kicksplanet/interfaces'
import { Badge, Button, Card, Divider, Flex, Grid, Modal, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'

import { formatPrice } from '@/utils/number'

import SellingItemDetailRejectForm from '../RejectForm'

type SellingItemDetailInfoProps = {
  selling: SellingResponses.SellingItem
  onAllowSelling?: () => void
  isAllowLoading?: boolean
  onRefresh: () => void
}

const SellingItemDetailInfo: React.FC<SellingItemDetailInfoProps> = ({
  selling,
  onAllowSelling,
  isAllowLoading = false,
  onRefresh,
}) => {
  const { t } = useTranslation()

  const [rejectSellingOpened, rejectSellingModal] = useDisclosure(false)

  const _onAllowSelling = useCallback(() => {
    onAllowSelling?.()
  }, [onAllowSelling])

  return (
    <>
      <Card h='100%'>
        <Grid gutter={16} mb={30}>
          <Grid.Col>
            <Flex justify='space-between' direction={{ base: 'column', md: 'row' }}>
              <Text weight={600} transform='uppercase'>
                {t('sellingItem.sellingHeading')}
              </Text>
              {!selling.is_validated && !selling.is_rejected ? (
                <Flex
                  justify='end'
                  align='center'
                  gap={20}
                  direction={{ base: 'column', md: 'row' }}
                >
                  <Button variant='subtle' fullWidth onClick={rejectSellingModal.toggle}>
                    {t('sellingItem.rejectSelling')}
                  </Button>
                  <Button
                    variant='highlight'
                    fullWidth
                    loading={isAllowLoading}
                    onClick={_onAllowSelling}
                  >
                    {t('sellingItem.allowSelling')}
                  </Button>
                </Flex>
              ) : null}
            </Flex>
            <Divider my={12} color='gray.2' />
          </Grid.Col>
          <Grid.Col>
            <Grid gutter={32} justify='space-between'>
              <Grid.Col md={2}>
                <Text weight={600}>{t('product.productTitle')}</Text>
                <Text>{selling.product_info?.title}</Text>
              </Grid.Col>
              <Grid.Col md={2}>
                <Text weight={600}>{t('product.productSize')}</Text>
                <Text>{`EU ${selling.size_info?.EU}`}</Text>
              </Grid.Col>
              <Grid.Col md={2}>
                <Text weight={600}>{t('sellingItem.sellingPrice')}</Text>
                <Text>{formatPrice(selling.price || 0)}</Text>
              </Grid.Col>
              <Grid.Col md={2}>
                <Text weight={600}>{t('sellingItem.sellingQuantity')}</Text>
                <Text>{selling.quantity}</Text>
              </Grid.Col>
              <Grid.Col md={2}>
                <Text weight={600}>{t('common.condition')}</Text>
                <Text>
                  <Badge
                    variant='checked-outline'
                    p={8}
                    sx={(_theme) => ({
                      color: _theme.white,
                      backgroundColor:
                        selling.condition === SellingItemCondition.NEW
                          ? _theme.colors.success[4]
                          : _theme.colors.warning[5],
                      borderColor: 'transparent',
                    })}
                  >
                    <Text color='white'>
                      {selling.condition === SellingItemCondition.NEW
                        ? t('common.new')
                        : t('common.used')}
                    </Text>
                  </Badge>
                </Text>
              </Grid.Col>
              <Grid.Col md={2}>
                <Text weight={600}>{t('sellingItem.sellingStatus')}</Text>
                <Text>
                  <Badge
                    variant='checked-outline'
                    p={8}
                    sx={(_theme) => ({
                      color: _theme.white,
                      backgroundColor: selling.is_validated
                        ? _theme.colors.success[4]
                        : selling.is_rejected
                        ? _theme.colors.danger[4]
                        : _theme.colors.warning[4],
                      borderColor: 'transparent',
                    })}
                  >
                    <Text color='white'>
                      {selling.is_validated
                        ? t('sellingItem.sellingApproved')
                        : selling.is_rejected
                        ? t('sellingItem.sellingRejected')
                        : t('sellingItem.sellingPending')}
                    </Text>
                  </Badge>
                </Text>
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>
      </Card>

      <Modal
        title={t('sellingItem.rejectSelling')}
        opened={rejectSellingOpened}
        onClose={rejectSellingModal.close}
      >
        <SellingItemDetailRejectForm
          sellingIDs={[selling.selling_id]}
          onSubmit={rejectSellingModal.close}
          onRefresh={onRefresh}
        />
      </Modal>
    </>
  )
}

export default SellingItemDetailInfo
