import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { BrandRequests, BrandResponses } from '@kicksplanet/interfaces'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Card } from '@mantine/core'
import { notifications } from '@mantine/notifications'

import { useApp } from '@/contexts/AppProvider'
import { ROUTES } from '@/routes'

import BrandForm, { BrandLogoType } from '../Components/BrandForm'

const BrandUpdate: React.FC = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const brandService = useAPIService('Brand')

  const { registerBreadcrumb, unregisterBreadcrumb } = useApp()

  const [brandLogo, setBrandLogo] = useState<BrandLogoType | undefined>()

  const { data: brand } = useQuery(
    ['brandService', 'getOne', id],
    () => brandService.getOne({ id: id ? id : '' }).then((res) => res.data.data),
    {
      enabled: Boolean(id?.toString()),
      onSuccess: (data: BrandResponses.BrandResponse) => {
        setBrandLogo({
          logoOnDark: data.thumbnail_info,
          logoOnLight: data.thumbnail_on_light_info,
        })
      },
    },
  )

  useEffect(() => {
    registerBreadcrumb({
      key: 'brands',
      label: t('page.brands'),
      url: ROUTES.ADMIN.BRAND.LIST,
    })

    if (brand) {
      registerBreadcrumb({
        key: brand.brand_id,
        label: brand.name,
      })
    }

    return () => {
      unregisterBreadcrumb('brands')
      if (brand) {
        unregisterBreadcrumb(brand.brand_id)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brand])

  const onSetBrandLogo = useCallback(
    (data: BrandLogoType | undefined) => {
      if (!data) return
      setBrandLogo(data)
    },
    [setBrandLogo],
  )

  const { mutate: update, isLoading: isUpdateLoading } = useMutation(
    (data: BrandRequests.BrandUpsertPayload) => {
      return brandService.update({
        id: id?.toString() || '',
        data: {
          data: {
            ...data,
            ...(brandLogo?.logoOnDark && { thumbnail: brandLogo?.logoOnDark.file_id }),
            ...(brandLogo?.logoOnLight && { thumbnail_on_light: brandLogo?.logoOnLight.file_id }),
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any,
      })
    },
    {
      onSuccess: async () => {
        notifications.show({
          variant: 'success',
          message: t('success.updateBrand'),
        })
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        const errorMessage = t(err?.response?.data, {
          ns: 'backend',
        })

        notifications.show({
          variant: 'danger',
          message: errorMessage,
        })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        err?.response?.data?.errors?.forEach((err: any) => {
          notifications.show({
            variant: 'danger',
            message: t(err, {
              ns: 'backend',
            }),
          })
        })
      },
    },
  )

  const onUpdate = useCallback(
    (data: BrandRequests.BrandUpsertPayload) => {
      if (!id) return
      update(data)
    },
    [id, update],
  )

  return (
    <Card>
      <BrandForm
        brand={brand}
        isSubmitLoading={isUpdateLoading}
        brandLogo={brandLogo}
        onSetBrandLogo={onSetBrandLogo}
        onSubmit={onUpdate}
      />
    </Card>
  )
}

export default BrandUpdate
