export function formatNumber(value: number) {
  return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function formatPrice(value: number, placeholder = '--', currency = 'VND') {
  const price =
    value && value > 0
      ? new Intl.NumberFormat('vi-VN', {
          style: 'currency',
          currency,
          // currencyDisplay: 'code',
        }).format(value)
      : placeholder

  return price
}
