import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { BiSearch } from 'react-icons/bi'
import { Button, Grid, Text, TextInput } from '@mantine/core'
import { useDebouncedState } from '@mantine/hooks'

import { cleanAccents } from '@/utils/string'

type BrandListFilterProps = {
  queryData: BrandListSearchType
  onChangeQueries: (query: BrandListSearchType) => void
}

const BrandListFilter: React.FC<BrandListFilterProps> = ({ queryData, onChangeQueries }) => {
  const { t } = useTranslation()

  const [keysearch, setKeysearch] = useDebouncedState(queryData.key || '', 400)

  const onSetKeysearch = useCallback(
    async (event?: React.ChangeEvent<HTMLInputElement>) => {
      const value = event?.target?.value as string
      setKeysearch(value)
    },
    [setKeysearch],
  )

  const _onChangeQueries = useCallback(() => {
    const result = {
      ...(keysearch ? { key: cleanAccents(keysearch) } : ''),
    }

    onChangeQueries(result)
  }, [keysearch, onChangeQueries])

  return (
    <Grid gutter={16} align='end'>
      <Grid.Col md={8}>
        <TextInput
          label={t('common.keyword')}
          icon={<BiSearch size='1rem' />}
          placeholder={t('brand.searchByKeyword')}
          onChange={onSetKeysearch}
        />
      </Grid.Col>
      <Grid.Col md={4}>
        <Button color='success.4' onClick={_onChangeQueries}>
          <Text>{t('common.searchButton')}</Text>
        </Button>
      </Grid.Col>
    </Grid>
  )
}

export default BrandListFilter
