import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { GoPencil } from 'react-icons/go'
import { HiOutlineTrash } from 'react-icons/hi'
import { useMutation } from 'react-query'
import { Models } from '@kicksplanet/interfaces'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Button, Flex, Modal, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { notifications } from '@mantine/notifications'

import ConfirmationModal from '@/components/ModalForm/Confirmation'

import SubCategoryForm from '../SubCategoryForm'

type SubCategoryRowProps = {
  category: Models.Category
  onRefresh?: () => void
}

const SubCategoryRow: React.FC<SubCategoryRowProps> = ({ category, onRefresh }) => {
  const { t } = useTranslation()
  const categoryService = useAPIService('Category')

  const [removeOpened, removeModal] = useDisclosure(false)
  const [editOpened, editModal] = useDisclosure(false)

  const { mutate: remove, isLoading: isRemoveLoading } = useMutation(
    (categoryID: string) => {
      return categoryService.delete({ id: categoryID })
    },
    {
      onSuccess: async () => {
        removeModal.close()
        notifications.show({
          title: t('common.removeTitle'),
          variant: 'success',
          message: t('success.deletedSubCategory'),
        })
        onRefresh?.()
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        const errorMessage = t(err?.response?.data, {
          ns: 'backend',
        })

        notifications.show({
          variant: 'danger',
          message: errorMessage,
        })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        err?.response?.data?.errors?.forEach((err: any) => {
          notifications.show({
            variant: 'danger',
            message: t(err, {
              ns: 'backend',
            }),
          })
        })
      },
    },
  )

  const _onRemove = useCallback(() => {
    if (!category.category_id) return
    remove(category.category_id)
  }, [category.category_id, remove])

  const _onUpdate = useCallback(() => {
    editModal.close()
    onRefresh?.()
  }, [editModal, onRefresh])

  return (
    <>
      <Flex justify='space-between' align='center'>
        <Text weight={600}>{category.name}</Text>
        <Flex>
          <Button variant='subtle' p={10} onClick={editModal.open}>
            <GoPencil color='black' />
          </Button>
          <Button variant='subtle' p={10} onClick={removeModal.open}>
            <HiOutlineTrash color='red' />
          </Button>
        </Flex>
      </Flex>

      <ConfirmationModal
        title={t('common.removeTitle')}
        description={t('common.removeText', { title: category.name })}
        isLoading={isRemoveLoading}
        opened={removeOpened}
        onClose={removeModal.close}
        onConfirm={_onRemove}
      />

      <Modal title={t('category.updateSubCategory')} opened={editOpened} onClose={editModal.close}>
        <SubCategoryForm category={category} onSubmit={_onUpdate} />
      </Modal>
    </>
  )
}

export default SubCategoryRow
