import { useCallback, useMemo, useState } from 'react'
import { ProductResponses } from '@kicksplanet/interfaces'
import { Box, Flex, Grid, Image } from '@mantine/core'
import { Navigation, Thumbs } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import SwiperWrapper from '@/components/SwiperWrapper'

type ProductDetailGalleryProps = {
  product: ProductResponses.Product
  productImages: ProductResponses.ProductImage[]
}

const ProductDetailGallery: React.FC<ProductDetailGalleryProps> = ({ product, productImages }) => {
  const [initialSlide, setInitialSlide] = useState<number>(0)

  const swiperKey = useMemo(() => {
    return `swiper-${initialSlide}`
  }, [initialSlide])

  const onChangeImageIndex = useCallback((index: number) => {
    setInitialSlide(index)
  }, [])

  if (!productImages?.length) return null

  return (
    <Grid>
      <Grid.Col>
        {/* Start product large image */}
        <SwiperWrapper>
          <Swiper key={swiperKey} slidesPerView={1} initialSlide={initialSlide} loop>
            {productImages.map((item) => (
              <SwiperSlide key={item.file_id}>
                <Flex
                  justify='center'
                  align='center'
                  bg='white'
                  p={5}
                  mx='auto'
                  sx={{
                    width: 500,
                    height: 500,
                    maxWidth: '100%',
                    msxHeight: '100%',
                    img: {
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                    },
                  }}
                >
                  {item.image_info && (
                    <Image
                      src={item.image_info.image_lg_path || item.image_info.file_path}
                      alt={`${product.title} ${product.code}`}
                    />
                  )}
                </Flex>
              </SwiperSlide>
            ))}
          </Swiper>
        </SwiperWrapper>
        {/* End product large image */}
      </Grid.Col>
      <Grid.Col>
        {/* Start product images list */}
        <SwiperWrapper type='horizontal'>
          <Box sx={{ height: '100%' }}>
            <Swiper
              spaceBetween={5}
              direction='horizontal'
              breakpoints={{
                540: { slidesPerView: 5 },
                420: { slidesPerView: 4 },
                0: { slidesPerView: 3 },
              }}
              navigation
              modules={[Navigation, Thumbs]}
            >
              {productImages.map((item, index) => (
                <SwiperSlide key={item.file_id} onClick={() => onChangeImageIndex(index)}>
                  <Flex
                    justify='center'
                    align='center'
                    bg='white'
                    mx='auto'
                    p={5}
                    sx={(_theme) => ({
                      width: 80,
                      height: 80,
                      maxWidth: '100%',
                      msxHeight: '100%',
                      margin: 'auto 0',
                      cursor: 'pointer',
                      border: '1px',
                      borderStyle: 'solid',
                      borderColor: index == initialSlide ? _theme.colors.green[4] : 'transparent',
                      borderRadius: '2px',
                      transition: '0.2s ease-in-out',
                      '&:hover': {
                        borderColor: _theme.colors.green[4],
                      },
                      img: {
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                      },
                    })}
                  >
                    {item.image_info && (
                      <Image
                        src={
                          item.image_info.image_sm_path ||
                          item.image_info.image_md_path ||
                          item.image_info.image_lg_path ||
                          item.image_info.file_path
                        }
                        alt={`${product.title} ${product.code}`}
                      />
                    )}
                  </Flex>
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>
        </SwiperWrapper>
        {/* End product images list */}
      </Grid.Col>
    </Grid>
  )
}

export default ProductDetailGallery
