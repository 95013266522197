import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { DragDropContext, Draggable, Droppable, DropResult } from '@hello-pangea/dnd'
import { Models } from '@kicksplanet/interfaces'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Button, Flex } from '@mantine/core'
import { useListState } from '@mantine/hooks'
import { notifications } from '@mantine/notifications'
import { v4 as uuidv4 } from 'uuid'

import Empty from '@/components/Empty'
import TableWrapper from '@/components/TableWrapper'

import SizeChartListSizeRow from './Components/Row'

export type SizeChartAddSizeType = Omit<
  Models.Size,
  'created_at' | 'updated_at' | 'size_chart_id'
> & {
  is_new?: boolean
}

type SizeChartListSizeProps = {
  sizes?: SizeChartAddSizeType[]
  onSetSizes: (sizes: SizeChartAddSizeType[]) => void
  onRefresh: () => void
}

const COLUMN_CAPTION = [
  '',
  'EU',
  'USM',
  'USW',
  'UK',
  'CM',
  'KR',
  'US_GS',
  'US_PS',
  'US_TD',
  'common.action',
]

const SizeChartListSize: React.FC<SizeChartListSizeProps> = ({ sizes, onSetSizes, onRefresh }) => {
  const { t } = useTranslation()
  const sizeService = useAPIService('Size')

  const [sizeState, sizeStateHandlers] = useListState<SizeChartAddSizeType>([])
  const [reorder, setReorder] = useState<boolean>(false)

  useEffect(() => {
    sizeStateHandlers.setState(sizes || [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sizes])

  useEffect(() => {
    onSetSizes(sizeState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sizeState])

  const { mutate: sortSize } = useMutation(
    (data: SizeChartAddSizeType[]) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return sizeService.sort({ sizes: data } as any)
    },
    {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        const errorMessage = t(err?.response?.data, {
          ns: 'backend',
        })

        notifications.show({
          variant: 'danger',
          message: errorMessage,
        })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        err?.response?.data?.errors?.forEach((err: any) => {
          notifications.show({
            variant: 'danger',
            message: t(err, {
              ns: 'backend',
            }),
          })
        })
      },
    },
  )

  const onAddSize = useCallback(() => {
    const data: SizeChartAddSizeType = {
      size_id: uuidv4(),
      is_new: true,
      ordinal: sizeState.length + 1,
    }
    sizeStateHandlers.setState([...sizeState, data] || [])
  }, [sizeState, sizeStateHandlers])

  const onEditSize = useCallback(
    (size: SizeChartAddSizeType) => {
      const _sizes = sizeState
      for (let i = 0; i < _sizes.length; i++) {
        if (_sizes[i].size_id === size.size_id) {
          _sizes[i] = size
        }
      }
      sizeStateHandlers.setState(_sizes || [])
    },
    [sizeState, sizeStateHandlers],
  )

  const onRemoveSize = useCallback(
    (size: SizeChartAddSizeType) => {
      sizeStateHandlers.setState(sizeState?.filter((x) => x.size_id !== size.size_id) || [])
    },
    [sizeStateHandlers, sizeState],
  )

  const onSortSize = useCallback(() => {
    if (reorder) {
      sortSize(sizeState)
      setReorder(false)
    }
  }, [reorder, sizeState, sortSize])

  useEffect(() => {
    onSortSize()
  }, [onSortSize])

  const onDragEnd = useCallback(
    (result: DropResult) => {
      const { source, destination } = result

      if (!destination) {
        return
      }
      if (destination.index === source.index) {
        return
      }

      const items = Array.from(sizeState)
      const [reorderedItem] = items.splice(source.index, 1)
      items.splice(destination.index, 0, reorderedItem)

      const updatedSizes = items.map((item, index) => ({
        ...item,
        ordinal: index + 1,
      }))

      sizeStateHandlers.setState(updatedSizes)
      setReorder(true)

      // sizeStateHandlers.reorder({
      //   from: source.index,
      //   to: destination?.index || 0,
      // });
    },
    [sizeState, sizeStateHandlers],
  )

  const RowData = useMemo(() => {
    if (!sizeState || sizeState.length <= 0)
      return (
        <tr>
          <td colSpan={11}>
            <Empty />
          </td>
        </tr>
      )

    return sizeState.map((item, index) => {
      return (
        <Draggable key={item.size_id} index={index} draggableId={item.size_id}>
          {(provided) => (
            <SizeChartListSizeRow
              size={item}
              provided={provided}
              onEdit={onEditSize}
              onRemove={onRemoveSize}
              onRefresh={onRefresh}
            />
          )}
        </Draggable>
      )
    })
  }, [onEditSize, onRefresh, onRemoveSize, sizeState])

  return (
    <>
      <Flex justify='start' align='center' mb={24}>
        <Button variant='highlight' onClick={onAddSize}>
          {t('size.addSize')}
        </Button>
      </Flex>

      <DragDropContext onDragEnd={onDragEnd}>
        <TableWrapper>
          <thead>
            <tr>
              {COLUMN_CAPTION.map((item, key) => {
                return <th key={key}>{t(item)}</th>
              })}
            </tr>
          </thead>
          <Droppable droppableId='dnd-list' direction='vertical'>
            {(provided) => (
              <tbody {...provided.droppableProps} ref={provided.innerRef}>
                {RowData}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </TableWrapper>
      </DragDropContext>
    </>
  )
}

export default SizeChartListSize
