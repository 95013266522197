import { ChangeEvent, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { Models, OrderResponses } from '@kicksplanet/interfaces'
import { Checkbox, Text, Tooltip } from '@mantine/core'

import { formatPrice } from '@/utils/number'
import { getOrderDetailUrl } from '@/utils/url'

type OrderListRowProps = {
  order: OrderResponses.OrderListItem
  checkedItems?: Models.Order[]
  onToggleRow?: (event: ChangeEvent<HTMLInputElement>, item: Models.Order) => void
}

const OrderListRow: React.FC<OrderListRowProps> = ({ checkedItems, order, onToggleRow }) => {
  const _onToggleRow = useCallback(
    (event: ChangeEvent<HTMLInputElement>, item: Models.Order) => {
      onToggleRow?.(event, item)
    },
    [onToggleRow],
  )

  return (
    <>
      <tr>
        <td>
          <Checkbox
            size='sm'
            checked={checkedItems?.includes(order)}
            onChange={(e) => _onToggleRow(e, order)}
          />
        </td>
        <td>
          <Link
            to={getOrderDetailUrl(order.order_id)}
            style={{ textDecoration: 'none', color: 'black' }}
          >
            <Tooltip label={order.code}>
              <Text weight={600}>{order.code}</Text>
            </Tooltip>
          </Link>
        </td>
        <td>
          <Tooltip label={order.payment_method}>
            <Text lineClamp={1} w='fit-content'>
              {order.payment_method}
            </Text>
          </Tooltip>
        </td>
        <td>
          {(() => {
            const value = formatPrice(order.service_fee || 0)
            return (
              <Tooltip label={value}>
                <Text lineClamp={1} w='fit-content'>
                  {value}
                </Text>
              </Tooltip>
            )
          })()}
        </td>
        <td>
          {(() => {
            const value = formatPrice(order.total || 0)
            return (
              <Tooltip label={value}>
                <Text lineClamp={1} w='fit-content'>
                  {value}
                </Text>
              </Tooltip>
            )
          })()}
        </td>
      </tr>
    </>
  )
}

export default OrderListRow
