import { VoucherApplyFor, VoucherApplyOn, VoucherUnit } from '@kicksplanet/enums'

export type VoucherAttributeDataType = {
  value: string
  label: string
}

export const VoucherApplyOnData: VoucherAttributeDataType[] = [
  {
    value: VoucherApplyOn.SHIPPING_FEE,
    label: 'Shipping Fee',
  },
  {
    value: VoucherApplyOn.SERVICE_FEE,
    label: 'Service Fee',
  },
  {
    value: VoucherApplyOn.TOTAL,
    label: 'Total',
  },
]

export const VoucherApplyForData: VoucherAttributeDataType[] = [
  {
    value: VoucherApplyFor.ALL,
    label: 'All',
  },
  {
    value: VoucherApplyFor.INDIVIDUALS,
    label: 'Individual',
  },
]

export const VoucherUnitData: VoucherAttributeDataType[] = [
  {
    value: VoucherUnit.CURRENCY,
    label: 'Currency',
  },
  {
    value: VoucherUnit.PERCENTAGE,
    label: 'Percentage',
  },
]
