import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Card, Skeleton } from '@mantine/core'

import Empty from '@/components/Empty'
import RefreshButton from '@/components/RefreshButton'
import TableWrapper from '@/components/TableWrapper'
import { useApp } from '@/contexts/AppProvider'

import SizeChartListRow from './Components/Row'

const COLUMN_CAPTION = ['sizeChart.sizeChartName', 'common.action']

const SizeChartList: React.FC = () => {
  const { t } = useTranslation()
  const sizeChartService = useAPIService('SizeChart')

  const { registerBreadcrumb, unregisterBreadcrumb } = useApp()

  useEffect(() => {
    registerBreadcrumb({
      key: 'sizeCharts',
      label: t('page.sizeCharts'),
    })

    return () => {
      unregisterBreadcrumb('sizeCharts')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    data: sizeCharts,
    isFetching: isLoadingTable,
    refetch: getSizeCharts,
  } = useQuery(['sizeChartService', 'getList'], () =>
    sizeChartService.getList({}).then((res) => res.data),
  )

  const onRefresh = useCallback(() => {
    getSizeCharts()
  }, [getSizeCharts])

  const RowData = useMemo(() => {
    if (isLoadingTable) {
      return (
        <tr>
          <td colSpan={2}>
            <Skeleton height={10} my={15} radius='md' />
            <Skeleton height={10} my={15} radius='md' />
            <Skeleton height={10} my={15} radius='md' />
          </td>
        </tr>
      )
    }

    if (!sizeCharts || !sizeCharts.data || sizeCharts.data.length <= 0)
      return (
        <tr>
          <td colSpan={2}>
            <Empty />
          </td>
        </tr>
      )

    return sizeCharts.data.map((item) => {
      return <SizeChartListRow key={item.size_chart_id} sizeChart={item} onRefresh={onRefresh} />
    })
  }, [isLoadingTable, onRefresh, sizeCharts])

  return (
    <Card>
      <RefreshButton onRefresh={onRefresh} />
      <TableWrapper isStickyColumm={Boolean(sizeCharts?.data && sizeCharts?.data.length > 0)}>
        <thead>
          <tr>
            {COLUMN_CAPTION.map((item, key) => {
              return <th key={key}>{t(item)}</th>
            })}
          </tr>
        </thead>
        <tbody>{RowData}</tbody>
      </TableWrapper>
    </Card>
  )
}

export default SizeChartList
