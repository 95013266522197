import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { Box, Card, Tabs } from '@mantine/core'

import { useApp } from '@/contexts/AppProvider'

import Invoices_InvoiceList from './Components/InvoiceList'
import Invoices_OrderList from './Components/OrderList'

enum TabItems {
  ORDERS = 'orders',
  INVOICES = 'invoices',
}

const InvoiceListPage: React.FC = () => {
  const { t } = useTranslation()
  const { registerBreadcrumb, unregisterBreadcrumb } = useApp()
  const [searchParams, setSearchParams] = useSearchParams(
    new URLSearchParams({
      tab: TabItems.ORDERS,
    }),
  )

  useEffect(() => {
    registerBreadcrumb({
      key: 'accounting',
      label: t('page.accounting'),
    })

    registerBreadcrumb({
      key: 'invoices',
      label: t('page.invoices'),
    })

    return () => {
      unregisterBreadcrumb('accounting')
      unregisterBreadcrumb('invoices')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onChangeTab = (tab: TabItems) => {
    const newSearchParams = new URLSearchParams({
      tab,
    })
    setSearchParams(newSearchParams)
  }

  return (
    <Tabs defaultValue={TabItems.ORDERS} value={searchParams.get('tab')} keepMounted={false}>
      <Card>
        <Card.Section
          sx={{
            overflow: 'hidden',
          }}
        >
          <Tabs.List bg='#fff'>
            <Tabs.Tab value={TabItems.ORDERS} onClick={() => onChangeTab(TabItems.ORDERS)}>
              {t('invoice.tabs.orders')}
            </Tabs.Tab>
            <Tabs.Tab value={TabItems.INVOICES} onClick={() => onChangeTab(TabItems.INVOICES)}>
              {t('invoice.tabs.invoices')}
            </Tabs.Tab>
          </Tabs.List>
        </Card.Section>

        <Box py={16}>
          <Tabs.Panel value={TabItems.ORDERS}>
            <Invoices_OrderList />
          </Tabs.Panel>
          <Tabs.Panel value={TabItems.INVOICES}>
            <Invoices_InvoiceList />
          </Tabs.Panel>
        </Box>
      </Card>
    </Tabs>
  )
}

export default InvoiceListPage
