import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { SizeChartRequests } from '@kicksplanet/interfaces'
import { useAPIService } from '@kicksplanet/react/hooks'
import { notifications } from '@mantine/notifications'
import { ACCEPTED } from 'http-status'

import { useApp } from '@/contexts/AppProvider'
import { ROUTES } from '@/routes'

import SizeChartForm from '../Components/Form'
import { SizeChartAddSizeType } from '../Components/ListSize'

const SizeChartUpdate: React.FC = () => {
  const { t } = useTranslation()
  const { id } = useParams()

  const sizeChartService = useAPIService('SizeChart')
  const sizeService = useAPIService('Size')

  const { registerBreadcrumb, unregisterBreadcrumb } = useApp()

  const [isUpdateLoading, setIsUpdateLoading] = useState<boolean>(false)

  const { data: sizeChart, refetch: getSizeChart } = useQuery(
    ['sizeChartService', 'getOne', id],
    () => sizeChartService.getOne({ id: id ? id : '' }).then((res) => res.data.data),
    {
      enabled: Boolean(id?.toString()),
    },
  )

  useEffect(() => {
    registerBreadcrumb({
      key: 'sizeCharts',
      label: t('page.sizeCharts'),
      url: ROUTES.ADMIN.SIZE_CHART.LIST,
    })

    if (sizeChart) {
      registerBreadcrumb({
        key: sizeChart.size_chart_id,
        label: sizeChart.name,
      })
    }

    return () => {
      unregisterBreadcrumb('sizeCharts')
      if (sizeChart) {
        unregisterBreadcrumb(sizeChart.size_chart_id)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sizeChart])

  const _onRefresh = useCallback(() => {
    getSizeChart()
  }, [getSizeChart])

  const onUpdate = useCallback(
    async (
      sizeChartData: SizeChartRequests.SizeChartUpsertPayload,
      sizeData: SizeChartAddSizeType[],
    ) => {
      if (!id) return

      setIsUpdateLoading(true)

      try {
        const createSizesData = sizeData
          .filter((x) => x.is_new)
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          .map(({ is_new, size_id, ...keepAttrs }) => keepAttrs)
        const updateSizesData = sizeData.filter((x) => !x.is_new)

        const [updateSizeChart] = await Promise.all([
          sizeChartService.update({
            id: id?.toString() || '',
            data: {
              data: { name: sizeChartData.name },
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any,
          }),
          sizeChartService.createSize(id ? id : '', {
            data: {
              sizes: createSizesData,
            },
          }),
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          sizeService.updateSize({ sizes: updateSizesData } as any),
        ])

        if (updateSizeChart.status === ACCEPTED) {
          notifications.show({
            variant: 'success',
            message: t('success.updateSizeChart'),
          })
        }

        _onRefresh()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        const errorMessage = t(err?.response?.data, {
          ns: 'backend',
        })

        notifications.show({
          variant: 'danger',
          message: errorMessage,
        })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        err?.response?.data?.errors?.forEach((err: any) => {
          notifications.show({
            variant: 'danger',
            message: t(err, {
              ns: 'backend',
            }),
          })
        })
      } finally {
        setIsUpdateLoading(false)
      }
    },
    [_onRefresh, id, sizeChartService, sizeService, t],
  )

  return (
    <SizeChartForm
      sizeChart={sizeChart}
      isSubmitLoading={isUpdateLoading}
      onSubmit={onUpdate}
      onRefresh={_onRefresh}
    />
  )
}

export default SizeChartUpdate
