import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import { OrderStatus } from '@kicksplanet/enums'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Card, Skeleton } from '@mantine/core'
import { endOfDay, startOfDay } from 'date-fns'
import defaultTo from 'lodash/defaultTo'

import Empty from '@/components/Empty'
import ListFilterLayout from '@/components/ListFilterLayout'
import QueryPagination from '@/components/Pagination/QueryPagination'
import TableWrapper from '@/components/TableWrapper'
import { DEFAULT_PAGINATION_QUERY } from '@/constants/pagination'
import { useApp } from '@/contexts/AppProvider'
import { ROUTES } from '@/routes'

import ForControlListFilter from './Components/Filter'
import ForControlListRow from './Components/Row'

const COLUMN_CAPTION = [
  'forControl.orderCode',
  'forControl.orderStatus',
  'forControl.completedAt',
  'forControl.checkStatus',
  'forControl.checkedAt',
  'common.action',
]

const ForControlList: React.FC = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const orderService = useAPIService('Order')
  const { registerBreadcrumb, unregisterBreadcrumb } = useApp()

  useEffect(() => {
    registerBreadcrumb({
      key: 'accounting',
      label: t('page.accounting'),
    })

    registerBreadcrumb({
      key: 'forControl',
      label: t('page.forControl'),
    })

    return () => {
      unregisterBreadcrumb('accounting')
      unregisterBreadcrumb('forControl')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const defaultQueryParams = useMemo(() => {
    return {
      page: defaultTo(parseInt(searchParams.get('page') as string), 1),
      perPage: defaultTo(
        parseInt(searchParams.get('per') as string),
        DEFAULT_PAGINATION_QUERY.limit,
      ),
      from: defaultTo(searchParams.get('from') as string, undefined),
      to: defaultTo(searchParams.get('to') as string, undefined),
      status: defaultTo(searchParams.get('status') as string, ''),
      checked: defaultTo(searchParams.get('checked') as string, ''),
    }
  }, [searchParams])

  const {
    page: pageQuery,
    perPage: perPageQuery,
    from: fromDateQuery,
    to: toDateQuery,
    status: statusQuery,
    checked: checkedQuery,
  } = defaultQueryParams

  const getListQuery = useMemo(() => {
    return {
      pagination: {
        page: pageQuery,
        perPage: perPageQuery,
      },
      filter: {
        completed_at: {
          'op.lte': endOfDay(toDateQuery ? new Date(parseInt(toDateQuery)) : new Date()),
          ...(fromDateQuery && {
            'op.gte': startOfDay(new Date(parseInt(fromDateQuery))),
          }),
        },
        status: {
          'op.eq': statusQuery ? statusQuery : OrderStatus.COMPLETED,
        },
        ...(checkedQuery && {
          is_checked: { 'op.eq': checkedQuery },
        }),
      },
      sort: {
        field: 'updated_at',
        order: 'DESC',
      },
    }
  }, [checkedQuery, fromDateQuery, pageQuery, perPageQuery, statusQuery, toDateQuery])

  const dataSearchQuery: ForControlListSearchType = useMemo(() => {
    return {
      ...(fromDateQuery && { from: fromDateQuery }),
      ...(toDateQuery && { to: toDateQuery }),
      ...(statusQuery && { status: statusQuery }),
      ...(checkedQuery && { checked: checkedQuery }),
    } as ForControlListSearchType
  }, [checkedQuery, fromDateQuery, statusQuery, toDateQuery])

  const {
    data: orders,
    refetch: getOrders,
    isFetching: isLoadingTable,
  } = useQuery({
    queryKey: ['orderService.getList', getListQuery],
    queryFn: () => orderService.getList(getListQuery as any).then((res) => res.data),
  })

  const onChangeQueries = useCallback(
    (query: ForControlListSearchType) => {
      navigate({
        pathname: ROUTES.ADMIN.ACCOUNTING.LIST,
        search: createSearchParams({
          ...query,
        }).toString(),
      })
    },
    [navigate],
  )

  const onRefresh = useCallback(() => {
    getOrders()
  }, [getOrders])

  const RowData = useMemo(() => {
    if (isLoadingTable) {
      return (
        <tr>
          <td colSpan={6}>
            <Skeleton height={10} my={15} radius='md' />
            <Skeleton height={10} my={15} radius='md' />
            <Skeleton height={10} my={15} radius='md' />
          </td>
        </tr>
      )
    }

    if (!orders || !orders.data || orders.data.length <= 0)
      return (
        <tr>
          <td colSpan={6}>
            <Empty />
          </td>
        </tr>
      )

    return orders.data.map((item) => {
      return <ForControlListRow key={item.order_id} order={item} onRefresh={onRefresh} />
    })
  }, [orders, isLoadingTable, onRefresh])

  return (
    <Card>
      <ListFilterLayout onRefresh={onRefresh}>
        <ForControlListFilter queryData={dataSearchQuery} onChangeQueries={onChangeQueries} />
      </ListFilterLayout>
      <TableWrapper isStickyColumm={Boolean(orders?.data && orders?.data.length > 0)}>
        <thead>
          <tr>
            {COLUMN_CAPTION.map((item, key) => {
              return <th key={key}>{t(item)}</th>
            })}
          </tr>
        </thead>
        <tbody>{RowData}</tbody>
      </TableWrapper>
      {orders?.data && orders.data.length > 0 && (
        <QueryPagination
          limit={perPageQuery}
          page={pageQuery}
          total={orders?.total}
          isShowLimitOptions
        />
      )}
    </Card>
  )
}

export default ForControlList
