import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { yupResolver } from '@hookform/resolvers/yup'
import { Models } from '@kicksplanet/interfaces'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Button, Grid, Textarea } from '@mantine/core'
import { notifications } from '@mantine/notifications'
import { object, string } from 'yup'

type UserBandFormProps = {
  user: Models.User
  onSubmit: () => void
  onRefresh: () => void
}

type BanFormType = {
  ban_reason: string
}

const validateSchema = object({
  ban_reason: string().required('user.missingBanReason'),
})

const UserBandForm: React.FC<UserBandFormProps> = ({ user, onSubmit, onRefresh }) => {
  const { t } = useTranslation()
  const userService = useAPIService('User')

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<BanFormType>({
    mode: 'all',
    resolver: yupResolver(validateSchema),
  })

  const { mutate: banUser, isLoading: isBanLoading } = useMutation(
    (data: BanFormType) => {
      return userService.banUser({
        id: user.user_id,
        data: {
          ...data,
        },
      })
    },
    {
      onSuccess: async () => {
        notifications.show({
          variant: 'success',
          message: t('success.bannedUser'),
        })
        onRefresh()
        onSubmit()
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        const errorMessage = t(err?.response?.data, {
          ns: 'backend',
        })

        notifications.show({
          variant: 'danger',
          message: errorMessage,
        })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        err?.response?.data?.errors?.forEach((err: any) => {
          notifications.show({
            variant: 'danger',
            message: t(err, {
              ns: 'backend',
            }),
          })
        })
      },
    },
  )

  const onBan = useCallback(
    (data: BanFormType) => {
      if (!isValid) return
      banUser(data)
    },
    [banUser, isValid],
  )

  return (
    <form onSubmit={handleSubmit(onBan)}>
      <Grid>
        <Grid.Col>
          <Textarea
            label={`${t('user.banReason')} *`}
            error={errors.ban_reason && t(errors.ban_reason.message as string)}
            {...register('ban_reason')}
          />
        </Grid.Col>
        <Grid.Col>
          <Button
            type='submit'
            variant='highlight'
            loading={isBanLoading}
            disabled={!isValid}
            fullWidth
          >
            {t('common.save')}
          </Button>
        </Grid.Col>
      </Grid>
    </form>
  )
}

export default UserBandForm
