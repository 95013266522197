import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { ReportResponses } from '@kicksplanet/interfaces'
import { Box, Grid, Progress, Text } from '@mantine/core'

import { formatDate } from '@/utils/date'
import { DATE_FORMATS } from '@/utils/date-format'
import { formatPrice } from '@/utils/number'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SectionItem = styled(Box as any)`
  padding: 20px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.colors.gray[1]};
`

type DashboardProgressProps = {
  revenue?: ReportResponses.AdminRevenue
  fromDate?: string
  toDate?: string
}

const DashboardProgress: React.FC<DashboardProgressProps> = ({ revenue, fromDate, toDate }) => {
  const { t } = useTranslation()

  const displayOrderProgress = useCallback(
    (label: string, count: number, type: 'order' | 'revenue') => {
      const total = type == 'order' ? revenue?.order?.totalOrders : revenue?.revenue?.totalRevenue
      const progressValue = total && total > 0 ? (count * 100) / total : 0

      return (
        <Grid>
          <Grid.Col lg={4}>
            <Text>{t(label)}</Text>
          </Grid.Col>
          <Grid.Col lg={8}>
            <Grid align='center'>
              <Grid.Col lg={8}>
                <Progress size='md' value={progressValue} w='100%' />
              </Grid.Col>
              <Grid.Col lg={4}>
                <Text align='end'>
                  {type == 'revenue'
                    ? formatPrice(count)
                    : `${count} ${t('dashboard.units.order')}`}
                </Text>
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>
      )
    },
    [revenue?.order?.totalOrders, revenue?.revenue?.totalRevenue, t],
  )

  return (
    <Grid>
      <Grid.Col md={6}>
        <SectionItem h='100%'>
          <Box mb={20}>
            <Text weight={600} size='lg'>
              {t('dashboard.inMoth.order')}
            </Text>
            <Text color='gray.4'>
              {`${t('dashboard.inMoth.orderStatistics')} `}
              {fromDate &&
              toDate &&
              new Date(fromDate).getMonth() === new Date().getMonth() &&
              new Date(toDate).getMonth() === new Date().getMonth()
                ? t('dashboard.inMoth.label').toLowerCase()
                : `${t('dashboard.inMoth.from').toLowerCase()} ${formatDate(
                    new Date(fromDate || ''),
                    DATE_FORMATS.DD_MM_YYYY,
                  )} - ${formatDate(new Date(toDate || ''), DATE_FORMATS.DD_MM_YYYY)}`}
            </Text>
          </Box>
          <Grid>
            <Grid.Col>
              {displayOrderProgress(
                'dashboard.inMoth.orderStatisticsItems.total',
                revenue?.order?.totalOrders || 0,
                'order',
              )}
            </Grid.Col>
            <Grid.Col>
              {displayOrderProgress(
                'dashboard.inMoth.orderStatisticsItems.success',
                revenue?.order?.totalOrdersSucceed || 0,
                'order',
              )}
            </Grid.Col>
            <Grid.Col>
              {displayOrderProgress(
                'dashboard.inMoth.orderStatisticsItems.processing',
                revenue?.order?.totalOrdersProcessing || 0,
                'order',
              )}
            </Grid.Col>
            <Grid.Col>
              {displayOrderProgress(
                'dashboard.inMoth.orderStatisticsItems.failed',
                revenue?.order?.totalOrdersFailed || 0,
                'order',
              )}
            </Grid.Col>
          </Grid>
        </SectionItem>
      </Grid.Col>
      <Grid.Col md={6}>
        <SectionItem h='100%'>
          <Box mb={20}>
            <Text weight={600} size='lg'>
              {t('dashboard.inMoth.revenue')}
            </Text>
            <Text color='gray.4'>
              {`${t('dashboard.inMoth.revenueStatistics')} `}
              {fromDate &&
              toDate &&
              new Date(fromDate).getMonth() === new Date().getMonth() &&
              new Date(toDate).getMonth() === new Date().getMonth()
                ? t('dashboard.inMoth.label').toLowerCase()
                : `${t('dashboard.inMoth.from').toLowerCase()} ${formatDate(
                    new Date(fromDate || ''),
                    DATE_FORMATS.DD_MM_YYYY,
                  )} - ${formatDate(new Date(toDate || ''), DATE_FORMATS.DD_MM_YYYY)}`}
            </Text>
          </Box>
          <Grid>
            <Grid.Col>
              {displayOrderProgress(
                'dashboard.inMoth.revenueStatisticsItems.total',
                revenue?.revenue?.totalRevenue || 0,
                'revenue',
              )}
            </Grid.Col>
            <Grid.Col>
              {displayOrderProgress(
                'dashboard.inMoth.revenueStatisticsItems.profit',
                revenue?.revenue?.totalProfit || 0,
                'revenue',
              )}
            </Grid.Col>
          </Grid>
        </SectionItem>
      </Grid.Col>
    </Grid>
  )
}

export default DashboardProgress
