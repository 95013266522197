import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineCheckCircle } from 'react-icons/ai'
import { FaBan } from 'react-icons/fa'
import { GoPencil } from 'react-icons/go'
import { HiOutlineTrash } from 'react-icons/hi'
import { useMutation } from 'react-query'
import { Link } from 'react-router-dom'
import { Models } from '@kicksplanet/interfaces'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Badge, Button, Flex, Modal, Text, Tooltip } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { notifications } from '@mantine/notifications'

import ConfirmationModal from '@/components/ModalForm/Confirmation'
import { formatDate } from '@/utils/date'
import { DATE_FORMATS } from '@/utils/date-format'
import { getUserUpdateUrl } from '@/utils/url'
import UserBandForm from '@/views/User/Components/BanForm'

type UserRow = {
  user: Models.User
  onRefresh: () => void
}

const UserRow: React.FC<UserRow> = ({ user, onRefresh }) => {
  const { t } = useTranslation()
  const userService = useAPIService('User')

  const [removeOpened, removeModal] = useDisclosure(false)
  const [banOpened, banModal] = useDisclosure(false)

  const {
    user_id,
    first_name,
    last_name,
    email,
    phone_number,
    role,
    credits,
    warnings,
    is_banned,
    first_sell_at,
  } = user

  const { mutate: reactiveUser } = useMutation(
    (userID: string) => {
      return userService.reactivateUser({ id: userID })
    },
    {
      onSuccess: async () => {
        notifications.show({
          variant: 'success',
          message: t('success.reactivatedUser'),
        })
        onRefresh()
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        const errorMessage = t(err?.response?.data, {
          ns: 'backend',
        })

        notifications.show({
          variant: 'danger',
          message: errorMessage,
        })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        err?.response?.data?.errors?.forEach((err: any) => {
          notifications.show({
            variant: 'danger',
            message: t(err, {
              ns: 'backend',
            }),
          })
        })
      },
    },
  )

  const { mutate: remove, isLoading: isRemoveLoading } = useMutation(
    (userID: string) => {
      return userService.delete({ id: userID })
    },
    {
      onSuccess: async () => {
        removeModal.close()
        notifications.show({
          title: t('common.removeTitle'),
          variant: 'success',
          message: t('success.deletedUser'),
        })
        onRefresh()
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        const errorMessage = t(err?.response?.data, {
          ns: 'backend',
        })

        notifications.show({
          variant: 'danger',
          message: errorMessage,
        })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        err?.response?.data?.errors?.forEach((err: any) => {
          notifications.show({
            variant: 'danger',
            message: t(err, {
              ns: 'backend',
            }),
          })
        })
      },
    },
  )

  const onReactive = useCallback(() => {
    if (!user.user_id) return
    reactiveUser(user.user_id)
  }, [reactiveUser, user.user_id])

  const _onRemove = useCallback(() => {
    if (!user.user_id) return
    remove(user.user_id)
  }, [remove, user.user_id])

  return (
    <>
      <tr>
        <td style={{ minWidth: '250px' }}>
          {(() => {
            const value = `${first_name} ${last_name}`
            return (
              <Tooltip label={value}>
                <Text lineClamp={1} w='fit-content'>
                  {value}
                </Text>
              </Tooltip>
            )
          })()}
        </td>
        <td style={{ minWidth: '160px' }}>
          <Tooltip label={email}>
            <Text lineClamp={1} w='fit-content'>
              {email}
            </Text>
          </Tooltip>
        </td>
        <td style={{ minWidth: '160px' }}>
          <Tooltip label={phone_number}>
            <Text lineClamp={1} w='fit-content'>
              {phone_number}
            </Text>
          </Tooltip>
        </td>
        <td style={{ minWidth: '120px' }}>
          <Tooltip label={role}>
            <Text lineClamp={1} w='fit-content'>
              {role}
            </Text>
          </Tooltip>
        </td>
        <td style={{ minWidth: '160px' }}>
          <Tooltip label={credits}>
            <Text lineClamp={1} w='fit-content'>
              {credits}
            </Text>
          </Tooltip>
        </td>
        <td style={{ minWidth: '160px' }}>
          <Tooltip label={warnings}>
            <Text lineClamp={1} w='fit-content'>
              {warnings}
            </Text>
          </Tooltip>
        </td>
        <td>
          {is_banned ? (
            <Badge
              variant='checked-outline'
              sx={(_theme) => ({
                color: _theme.white,
                backgroundColor: _theme.colors.warning[3],
                borderColor: 'transparent',
              })}
            >
              {t('user.banned')}
            </Badge>
          ) : (
            <Badge
              variant='checked-outline'
              sx={(_theme) => ({
                color: _theme.white,
                backgroundColor: _theme.colors.success[3],
                borderColor: 'transparent',
              })}
            >
              {t('user.activate')}
            </Badge>
          )}
        </td>
        <td style={{ minWidth: '200px' }}>
          {first_sell_at ? (
            (() => {
              const value = formatDate(first_sell_at, DATE_FORMATS.HH_MM_AA_DOT_DD_MMMM_YYYY)
              return (
                <Tooltip label={value}>
                  <Text lineClamp={1} w='fit-content'>
                    {value}
                  </Text>
                </Tooltip>
              )
            })()
          ) : (
            <Text>--</Text>
          )}
        </td>
        <td>
          <Flex justify='center' align='center'>
            {is_banned ? (
              <Button variant='subtle' p={10} onClick={onReactive}>
                <AiOutlineCheckCircle color='green' />
              </Button>
            ) : (
              <Button variant='subtle' p={10} onClick={banModal.open}>
                <FaBan color='red' />
              </Button>
            )}
            <Link to={getUserUpdateUrl(user_id)} style={{ textDecoration: 'none', color: 'black' }}>
              <Button variant='subtle' p={10}>
                <GoPencil color='black' />
              </Button>
            </Link>
            <Button variant='subtle' p={10} onClick={removeModal.open}>
              <HiOutlineTrash color='red' />
            </Button>
          </Flex>
        </td>
      </tr>

      <Modal title={t('user.banUser')} opened={banOpened} onClose={banModal.close}>
        <UserBandForm user={user} onSubmit={banModal.close} onRefresh={onRefresh} />
      </Modal>

      <ConfirmationModal
        title={t('common.removeTitle')}
        description={t('common.removeText', {
          title: `${first_name} ${last_name}`,
        })}
        isLoading={isRemoveLoading}
        opened={removeOpened}
        onClose={removeModal.close}
        onConfirm={_onRemove}
      />
    </>
  )
}

export default UserRow
