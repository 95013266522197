import { useCallback } from 'react'

export const usePrintPDF = () => {
  const onPrintPDF = useCallback((pdfData: Blob) => {
    if (!pdfData) return

    document.getElementById('tracking-label')?.remove()

    const url = URL.createObjectURL(pdfData)
    const iframe = document.createElement('iframe')
    iframe.id = 'tracking-label'
    iframe.style.display = 'none'
    iframe.src = url

    document.body.appendChild(iframe)
    iframe.onload = () => {
      iframe.contentWindow?.print()
    }
  }, [])

  return { onPrintPDF }
}
