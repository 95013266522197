import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Card, Skeleton } from '@mantine/core'
import { defaultTo } from 'lodash'

import Empty from '@/components/Empty'
import ListFilterLayout from '@/components/ListFilterLayout'
import QueryPagination from '@/components/Pagination/QueryPagination'
import TableWrapper from '@/components/TableWrapper'
import { DEFAULT_PAGINATION_QUERY } from '@/constants/pagination'
import { useApp } from '@/contexts/AppProvider'
import { ROUTES } from '@/routes'
import { formatKeyFilter } from '@/utils/string'

import VoucherListFilter from './Components/Filter'
import VoucherListRow from './Components/Row'

const COLUMN_CAPTION = [
  'voucher.voucherTitle',
  'voucher.voucherCode',
  'voucher.voucherValue',
  'voucher.voucherQuantity',
  'voucher.voucherUnit',
  'voucher.voucherValidFrom',
  'voucher.voucherValidThrough',
  'voucher.voucherApplyOn',
  'voucher.voucherApplyFor',
  'common.action',
]

const VoucherList: React.FC = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const voucherVoucher = useAPIService('Voucher')
  const { registerBreadcrumb, unregisterBreadcrumb } = useApp()

  useEffect(() => {
    registerBreadcrumb({
      key: 'vouchers',
      label: t('page.vouchers'),
    })

    return () => {
      unregisterBreadcrumb('vouchers')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const defaultQueryParams = useMemo(() => {
    return {
      page: defaultTo(parseInt(searchParams.get('page') as string), 1),
      perPage: defaultTo(
        parseInt(searchParams.get('per') as string),
        DEFAULT_PAGINATION_QUERY.limit,
      ),
      key: defaultTo(searchParams.get('key') as string, ''),
      unit: defaultTo(searchParams.get('unit') as string, ''),
      appliedon: defaultTo(searchParams.get('appliedOn') as string, ''),
      applicableFor: defaultTo(searchParams.get('applicableFor') as string, ''),
    }
  }, [searchParams])

  const {
    page: pageQuery,
    perPage: perPageQuery,
    key: keyQuery,
    unit: unitQuery,
    appliedon: appliedOnQuery,
    applicableFor: applicableForQuery,
  } = defaultQueryParams

  const getListQuery = useMemo(() => {
    const likeFilter = formatKeyFilter(keyQuery)

    return {
      pagination: {
        page: pageQuery,
        perPage: perPageQuery,
      },
      filter: {
        'op.or': [
          {
            title: {
              'op.like': likeFilter,
            },
          },
          {
            code: {
              'op.like': likeFilter,
            },
          },
        ],
        ...(unitQuery && {
          unit: { 'op.eq': unitQuery },
        }),
        ...(appliedOnQuery && {
          applied_on: { 'op.eq': appliedOnQuery },
        }),
        ...(applicableForQuery && {
          applicable_for: { 'op.eq': applicableForQuery },
        }),
      },
    }
  }, [applicableForQuery, appliedOnQuery, keyQuery, pageQuery, perPageQuery, unitQuery])

  const dataSearchQuery: VoucherListSearchType = useMemo(() => {
    return {
      ...(keyQuery && { key: keyQuery }),
      ...(unitQuery && { unit: unitQuery }),
      ...(appliedOnQuery && { appliedOn: appliedOnQuery }),
      ...(applicableForQuery && { applicableFor: applicableForQuery }),
    } as VoucherListSearchType
  }, [applicableForQuery, appliedOnQuery, keyQuery, unitQuery])

  const {
    data: vouchers,
    isFetching: isLoadingTable,
    refetch: getVouchers,
  } = useQuery(['voucherVoucher', 'getList', getListQuery], () =>
    voucherVoucher.getList(getListQuery).then((res) => res.data),
  )

  const onChangeQueries = useCallback(
    (query: VoucherListSearchType) => {
      navigate({
        pathname: ROUTES.ADMIN.VOUCHER.LIST,
        search: createSearchParams({
          ...query,
        }).toString(),
      })
    },
    [navigate],
  )

  const onRefresh = useCallback(() => {
    getVouchers()
  }, [getVouchers])

  const RowData = useMemo(() => {
    if (isLoadingTable) {
      return (
        <tr>
          <td colSpan={9}>
            <Skeleton height={10} my={15} radius='md' />
            <Skeleton height={10} my={15} radius='md' />
            <Skeleton height={10} my={15} radius='md' />
          </td>
        </tr>
      )
    }

    if (!vouchers || !vouchers.data || vouchers.data.length <= 0)
      return (
        <tr>
          <td colSpan={9}>
            <Empty />
          </td>
        </tr>
      )

    return vouchers.data.map((item) => {
      return <VoucherListRow key={item.voucher_id} voucher={item} onRefresh={onRefresh} />
    })
  }, [isLoadingTable, onRefresh, vouchers])

  return (
    <Card>
      <ListFilterLayout onRefresh={onRefresh}>
        <VoucherListFilter queryData={dataSearchQuery} onChangeQueries={onChangeQueries} />
      </ListFilterLayout>
      <TableWrapper isStickyColumm={Boolean(vouchers?.data && vouchers?.data.length > 0)}>
        <thead>
          <tr>
            {COLUMN_CAPTION.map((item, key) => {
              return <th key={key}>{t(item)}</th>
            })}
          </tr>
        </thead>
        <tbody>{RowData}</tbody>
      </TableWrapper>
      <QueryPagination
        limit={perPageQuery}
        page={pageQuery}
        total={vouchers?.total}
        isShowLimitOptions
      />
    </Card>
  )
}

export default VoucherList
