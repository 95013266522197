import { MantineTheme, MantineThemeOverride, ScrollArea } from '@mantine/core'

export const lightTheme: MantineThemeOverride = {
  colorScheme: 'light',

  /** Colors */
  colors: {
    primary: [
      '#FCE2D5',
      '#FAC0AC',
      '#F29380',
      '#E6695E',
      '#D62E2F',
      '#B8212F',
      '#9A172E',
      '#7C0E2B',
      '#66082A',
      '#66082A',
    ],
    success: [
      '#D6FBD3',
      '#A8F7AA',
      '#78E886',
      '#54D26F',
      '#24B552',
      '#1A9B50',
      '#12824B',
      '#0B6844',
      '#06563F',
      '#06563F',
    ],
    info: [
      '#CBFAFE',
      '#98EFFD',
      '#64DCFB',
      '#3DC5F7',
      '#00A1F2',
      '#007DD0',
      '#005DAE',
      '#00428C',
      '#002F74',
      '#002F74',
    ],
    warning: [
      '#FFF8CC',
      '#FFF099',
      '#FFE666',
      '#FFDC3F',
      '#FFCB00',
      '#DBA900',
      '#B78A00',
      '#936C00',
      '#7A5700',
      '#7A5700',
    ],
    danger: [
      '#FEDDD6',
      '#FEB4AD',
      '#FD8585',
      '#FB6675',
      '#F9345B',
      '#D62659',
      '#B31A55',
      '#90104E',
      '#770949',
      '#770949',
    ],
    gray: [
      '#F2F2F2',
      '#E5E5E5',
      '#E5E5E5',
      '#A3A3A3',
      '#B2B2B2',
      '#666666',
      '#000000',
      '#000000',
      '#000000',
      '#000000',
    ],
    green: [
      '#D8F5CE',
      '#ACEBA1',
      '#6DC468',
      '#388A3B',
      '#0D3C13',
      '#093312',
      '#062B12',
      '#042210',
      '#021C0F',
      '#021C0F',
    ],
  },
  primaryColor: 'primary',
  primaryShade: 4,

  /** Typography */
  lineHeight: 1.5,
  fontSizes: {
    xs: '12px',
    sm: '14px',
    md: '16px',
    lg: '24px',
    xl: '32px',
  },
  headings: {
    fontWeight: 600,
    sizes: {
      h1: { fontSize: '2.4rem' },
      h2: { fontSize: '2.2rem' },
      h3: { fontSize: '2rem' },
      h4: { fontWeight: 500, fontSize: '1.8rem' },
    },
  },
  components: {
    Button: {
      variants: {
        highlight: (theme) => {
          return {
            root: {
              fontSize: theme.fontSizes.md,
              background: theme.white,
              borderRadius: 2,
              borderWidth: 1,
              borderStyle: 'solid',
              color: theme.colors.primary[4],
              boxShadow: `4px 4px 0px 0px ${theme.colors.primary[4]}`,
              transition: '0.2s ease-in-out',
              ':hover': {
                background: theme.colors.primary[4],
                color: theme.white,
                boxShadow: 'none',
              },
              '&[data-disabled="true"]': {
                background: theme.white,
                color: theme.colors.gray[3],
                borderColor: theme.colors.gray[3],
                boxShadow: `4px 4px 0px 0px ${theme.colors.gray[3]}`,
              },
            },
          }
        },
        subtle: (theme) => ({
          inner: {
            color: theme.black,
          },
          root: {
            '&:hover': {
              backgroundColor: theme.colors.gray[2],
            },
          },
        }),
      },
      defaultProps: {
        loaderPosition: 'right',
        loaderProps: { color: 'primary.4' },
        sx: {
          '&[data-loading]': { svg: { fill: 'red' } },
        },
      },
    },
    Container: {
      defaultProps: {
        sizes: {
          xs: 540,
          sm: 720,
          md: 960,
          lg: 1140,
          xl: 1320,
        },
        size: 'xl',
      },
    },
    RangeSlider: {
      defaultProps: (theme) => ({
        thumbSize: 14,
        color: theme.colors.primary[4],
        labelTransition: 'skew-down',
        labelTransitionDuration: 150,
        labelTransitionTimingFunction: 'ease',
        sx: (theme: MantineTheme) => ({
          '.mantine-Slider-label': {
            backgroundColor: theme.colors.primary[4],
            color: 'white',
            fontWeight: 600,
          },
        }),
      }),
    },
    Badge: {
      variants: {
        'checked-outline': (theme) => {
          return {
            root: {
              position: 'relative',
              border: '1px',
              borderStyle: 'solid',
              borderColor: theme.colors.gray[5],
              borderRadius: theme.radius.sm,
              fontWeight: 600,
              color: 'black',
              transition: '0.2s ease-in-out',
              '&:hover': {
                borderColor: theme.colors.black,
              },
              svg: {
                position: 'absolute',
                top: 0,
                right: 0,
                color: 'white',
              },
            },
          }
        },
      },
    },
    Table: {
      variants: {
        'no-border': () => {
          return {
            root: {
              td: {
                border: '0 !important',
              },
            },
          }
        },
      },
    },
    Input: {
      defaultProps: {
        radius: 8,
        size: 'md',
        sx: (theme: MantineTheme) => ({
          '.mantine-Input-input': {
            '&:focus': {
              borderColor: `${theme.colors.gray[4]} !important`,
            },
          },
        }),
      },
    },
    InputWrapper: {
      defaultProps: {
        radius: 8,
        size: 'md',
        sx: () => ({
          label: {
            marginBottom: 8,
          },
        }),
      },
    },
    TextInput: {
      defaultProps: {
        radius: 8,
        size: 'md',
        sx: () => ({
          label: {
            marginBottom: 8,
          },
        }),
      },
    },
    PasswordInput: {
      defaultProps: {
        radius: 8,
        size: 'md',
        sx: () => ({
          label: {
            marginBottom: 8,
          },
        }),
      },
    },
    Notification: {
      variants: {
        success: (theme: MantineTheme) => {
          return {
            root: {
              padding: '0 20px',
              maxWidth: 385,
              '&::before': {
                backgroundColor: theme.colors.success[4],
              },
            },
            body: {
              padding: 15,
            },
            title: {
              fontWeight: 600,
              lineHeight: 2,
            },
            closeButton: {
              height: '100%',
              marginBottom: 'auto',
              marginTop: 20,
            },
          }
        },
        danger: (theme: MantineTheme) => {
          return {
            root: {
              padding: '0 20px',
              maxWidth: 385,
              '&::before': {
                backgroundColor: theme.colors.danger[4],
              },
            },
            body: {
              padding: 15,
            },
            title: {
              fontWeight: 600,
              lineHeight: 2,
            },
            closeButton: {
              height: '100%',
              marginBottom: 'auto',
              marginTop: 20,
            },
          }
        },
        warning: (theme: MantineTheme) => {
          return {
            root: {
              padding: '0 20px',
              maxWidth: 385,
              '&::before': {
                backgroundColor: theme.colors.warning[4],
              },
            },
            body: {
              padding: 15,
            },
            title: {
              fontWeight: 600,
              lineHeight: 2,
            },
            closeButton: {
              height: '100%',
              marginBottom: 'auto',
              marginTop: 20,
            },
          }
        },
      },
    },
    Menu: {
      defaultProps: {
        openDelay: 100,
        closeDelay: 100,
        transitionProps: { transition: 'scale-y', duration: 150 },
      },
    },
    TabsTab: {
      defaultProps: {
        sx: (theme: MantineTheme) => ({
          borderRadius: 2,
          minWidth: 180,
          padding: '8px 12px',
          lineHeight: '24px',
          fontSize: 16,
          fontWeight: 600,
          borderBottom: 0,
          marginBottom: -1,
          '&[data-active]': {
            borderBottom: `2px solid ${theme.colors.success[4]}`,
            ...theme.fn.hover({
              borderColor: theme.colors.success[4],
            }),
          },
          ...theme.fn.hover({
            borderBottom: `2px solid ${theme.colors.success[4]}`,
          }),
        }),
      },
    },
    TabsList: {
      defaultProps: {
        sx: (theme: MantineTheme) => ({
          borderBottom: 0,
          backgroundColor: theme.colors.gray[0],
        }),
      },
    },
    Modal: {
      defaultProps: {
        radius: 8,
        centered: true,
        scrollAreaComponent: ScrollArea.Autosize,
        sx: (theme: MantineTheme) => ({
          '.mantine-Modal-body': {
            marginTop: 10,
            padding: '20px 20px !important',
          },
          '.mantine-Modal-title': {
            fontWeight: '500',
            fontSize: theme.fontSizes.lg,
          },
        }),
      },
    },
    Select: {
      defaultProps: {
        withinPortal: true,
        size: 'md',
        sx: (theme: MantineTheme) => ({
          '.mantine-Select-error': {
            fontSize: theme.fontSizes.sm,
          },
          label: {
            marginBottom: 8,
          },
        }),
      },
    },
    Radio: {
      defaultProps: {
        sx: () => ({
          '.mantine-Radio-labelWrapper': {
            width: '100%',
          },
        }),
      },
    },
    Mark: {
      variants: {
        bold: () => {
          return {
            root: {
              backgroundColor: 'transparent',
              fontWeight: 600,
            },
          }
        },
      },
    },
    Card: {
      defaultProps: {
        radius: '10px',
        sx: {
          overflow: 'unset',
        },
      },
    },
    Tooltip: {
      defaultProps: {
        events: {
          hover: true,
          focus: true,
          touch: true,
        },
        withArrow: true,
        multiline: true,
        w: 'fit-content',
        sx: {
          maxWidth: 220,
        },
      },
    },
  },
}
