export type GenderOptionType = {
  value: string
  label: string
}

export const GenderOtions: GenderOptionType[] = [
  {
    value: 'M',
    label: 'gender.man',
  },
  {
    value: 'W',
    label: 'gender.woman',
  },
  {
    value: 'Unisex',
    label: 'gender.unisex',
  },
  {
    value: 'GS',
    label: 'gender.gs',
  },
  {
    value: 'PS',
    label: 'gender.ps',
  },
  {
    value: 'TD',
    label: 'gender.td',
  },
]
