import { useMemo } from 'react'
import { Link, Location } from 'react-router-dom'
import { Breadcrumbs, createStyles, Space, Text } from '@mantine/core'

import { useApp } from '@/contexts/AppProvider'

type AppBreadcrumbsProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  location: Location<any>
}

const useStyles = createStyles((theme) => ({
  item: {
    color: theme.black,
  },
  currentItem: {
    textDecoration: 'none',
    color: theme.colors.gray[5],
  },
}))

const AppBreadcrumbs: React.FC<AppBreadcrumbsProps> = () => {
  const { classes, cx } = useStyles()
  const { breadcrumbs } = useApp()

  const items = useMemo(() => {
    return breadcrumbs.map((item, index) =>
      item.url ? (
        <Link
          key={item.key}
          to={item.url || ''}
          className={cx(classes.item, {
            [classes.currentItem]: index === breadcrumbs.length - 1,
          })}
        >
          <Text fz='sm' fw={600} sx={{ whiteSpace: 'initial' }}>
            {item.label}
          </Text>
        </Link>
      ) : (
        <Text key={item.key} fz='sm' fw={600} sx={{ whiteSpace: 'initial' }}>
          {item.label}
        </Text>
      ),
    )
  }, [breadcrumbs, classes.currentItem, classes.item, cx])

  if (!breadcrumbs?.length) return null

  return (
    <>
      <Breadcrumbs
        maw='100%'
        sx={{
          flexWrap: 'wrap',
          overflow: 'hidden',
        }}
      >
        {items}
      </Breadcrumbs>
      <Space h={24} />
    </>
  )
}

export default AppBreadcrumbs
