import { ROUTES } from '@/routes'

// Product
export function getProductDetailUrl(productID: string) {
  return ROUTES.ADMIN.PRODUCT.DETAIL.replace(':id', productID)
}

export function getProductUpdateUrl(productID: string) {
  return ROUTES.ADMIN.PRODUCT.UPDATE.replace(':id', productID)
}

// Brand
export function getBrandUpdateUrl(brandID: string) {
  return ROUTES.ADMIN.BRAND.UPDATE.replace(':id', brandID)
}

// Category
export function getCategoryUpdateUrl(categoryID: string) {
  return ROUTES.ADMIN.CATEGORIES.UPDATE.replace(':id', categoryID)
}

// Order
export function getOrderDetailUrl(orderID: string) {
  return ROUTES.ADMIN.ORDER.DETAIL.replace(':id', orderID)
}

// Voucher
export function getVoucherUpdateUrl(voucherID: string) {
  return ROUTES.ADMIN.VOUCHER.UPDATE.replace(':id', voucherID)
}

// Selling
export function getSellingItemDetailUrl(sellingID: string) {
  return ROUTES.ADMIN.SELLING_ITEM.DETAIL.replace(':id', sellingID)
}

// ForControl
export function getOrderCheckFormUrl(sellingID: string) {
  return ROUTES.ADMIN.ACCOUNTING.CHECKFORM.replace(':id', sellingID)
}

// User
export function getUserUpdateUrl(userID: string) {
  return ROUTES.ADMIN.USER.UPDATE.replace(':id', userID)
}

// Size Chart
export function getSizeChartUpdateUrl(sizeChartID: string) {
  return ROUTES.ADMIN.SIZE_CHART.UPDATE.replace(':id', sizeChartID)
}

// Group
export function getGroupUpdateUrl(groupID: string) {
  return ROUTES.ADMIN.GROUP.UPDATE.replace(':id', groupID)
}
