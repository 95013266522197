export function formatBytes(fileSize: number) {
  const sizes = ['Bytes', 'KB', 'MB']

  for (let i = 0; i < sizes.length; i++) {
    if (fileSize <= 1024) {
      return `${fileSize} ${sizes[i]}`
    } else {
      fileSize = parseFloat(parseFloat((fileSize / 1024).toString()).toFixed(2))
    }
  }
}
