import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Button, Card, Group, Skeleton, Stack, Text } from '@mantine/core'

import Empty from '@/components/Empty'
import Pagination from '@/components/Pagination/Pagination'
import TableWrapper from '@/components/TableWrapper'
import WithdrawalRequestStatusBadge from '@/components/WithdrawalRequestStatusBadge'
import { DEFAULT_PAGINATION_QUERY } from '@/constants/pagination'
import { useApp } from '@/contexts/AppProvider'
import { ROUTES } from '@/routes'
import { formatDate } from '@/utils/date'
import { DATE_FORMATS } from '@/utils/date-format'
import { formatPrice } from '@/utils/number'

const COLUMN_HEADERS: string[] = [
  'withdrawalRequest.code',
  'withdrawalRequest.type',
  'withdrawalRequest.amount',
  'withdrawalRequest.status',
  'withdrawalRequest.created_at',
]

const WithdrawalRequestList: React.FC = () => {
  const { t } = useTranslation()
  const { registerBreadcrumb, unregisterBreadcrumb } = useApp()
  const walletService = useAPIService('Wallet')
  const [filter] = useState()
  const [page, setPage] = useState<number>(1)
  const [limit, setLimit] = useState<number>(DEFAULT_PAGINATION_QUERY.limit)

  const { data: withdrawalList, isFetching: isLoadingTable } = useQuery({
    queryKey: [
      'walletService',
      'listWithdrawalRequests',
      {
        filter,
        page,
        limit,
      },
    ],
    queryFn: () =>
      walletService.listWithdrawalRequests({
        filter,
      }),
    select: (res) => res.data,
  })

  useEffect(() => {
    registerBreadcrumb({
      key: 'accounting',
      label: t('page.accounting'),
    })

    registerBreadcrumb({
      key: 'withdrawalRequests',
      label: t('page.withdrawalRequests'),
    })

    return () => {
      unregisterBreadcrumb('accounting')
      unregisterBreadcrumb('withdrawalRequests')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const RowData = useMemo(() => {
    if (isLoadingTable) {
      return (
        <tr>
          <td colSpan={COLUMN_HEADERS.length}>
            <Skeleton height={10} my={15} radius='md' />
            <Skeleton height={10} my={15} radius='md' />
            <Skeleton height={10} my={15} radius='md' />
          </td>
        </tr>
      )
    }

    if (!withdrawalList || !withdrawalList.data || withdrawalList.data.length <= 0)
      return (
        <tr>
          <td colSpan={COLUMN_HEADERS.length}>
            <Empty />
          </td>
        </tr>
      )

    return withdrawalList.data.map((item) => {
      return (
        <tr key={item.request_id}>
          <td>
            <Text
              to={ROUTES.ADMIN.ACCOUNTING.WITHDRAWAL_REQUEST_DETAIL.replaceAll(
                ':id',
                item.request_id,
              )}
              component={Link}
              fw='bold'
              td='none'
            >
              {item.code}
            </Text>
          </td>
          <td>{t(`withdrawalRequest.types.${item.type}`)}</td>
          <td>{formatPrice(item.amount, '', item.__wallet__?.currency)}</td>
          <td>{<WithdrawalRequestStatusBadge status={item.status} />}</td>
          <td>{formatDate(item.created_at, DATE_FORMATS.HH_MM_AA_DOT_DD_MMMM_YYYY)}</td>
        </tr>
      )
    })
  }, [isLoadingTable, t, withdrawalList])

  const onChangePage = useCallback((page: number) => {
    setPage(page)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  const onChangeLimit = useCallback((limit: number) => {
    setLimit(limit)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  return (
    <Stack>
      <Group position='right'>
        <Button
          color='blue'
          component={Link}
          to={ROUTES.ADMIN.ACCOUNTING.CREATE_WITHDRAWAL_REQUEST}
        >
          {t('withdrawalRequest.actions.createWithdrawalRequest')}
        </Button>
      </Group>
      <Card>
        <TableWrapper>
          <thead>
            <tr>
              {COLUMN_HEADERS.map((item) => {
                return <th key={item}>{t(item)}</th>
              })}
            </tr>
          </thead>
          <tbody>{RowData}</tbody>
        </TableWrapper>
        {withdrawalList?.data?.length ? (
          <Pagination
            isShowLimitOptions
            limit={limit}
            page={page}
            total={withdrawalList?.total}
            onChange={onChangePage}
            onChangeLimit={onChangeLimit}
          />
        ) : null}
      </Card>
    </Stack>
  )
}

export default WithdrawalRequestList
