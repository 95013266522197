import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { VoucherApplyFor, VoucherApplyOn, VoucherUnit } from '@kicksplanet/enums'
import { Models } from '@kicksplanet/interfaces'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Card } from '@mantine/core'
import { notifications } from '@mantine/notifications'

import { useApp } from '@/contexts/AppProvider'
import { ROUTES } from '@/routes'
import { VoucherUserModel } from '@/types/voucher'

import VoucherForm, { VoucherFormType } from '../Components/VoucherForm'

const VoucherCreate: React.FC = () => {
  const { t } = useTranslation()
  const voucherService = useAPIService('Voucher')

  const { registerBreadcrumb, unregisterBreadcrumb } = useApp()

  const navigate = useNavigate()

  const [image, setImage] = useState<Models.File | undefined>()
  const [voucherUsers, setVoucherUsers] = useState<VoucherUserModel[]>()

  useEffect(() => {
    registerBreadcrumb({
      key: 'vouchers',
      label: t('page.vouchers'),
      url: ROUTES.ADMIN.VOUCHER.LIST,
    })

    registerBreadcrumb({
      key: 'addVoucher',
      label: t('page.addVoucher'),
    })

    return () => {
      unregisterBreadcrumb('vouchers')
      unregisterBreadcrumb('addVoucher')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSetImages = useCallback((data: Models.File | undefined) => {
    if (!data) return
    setImage(data)
  }, [])

  const { mutate: create, isLoading: isUpdateLoading } = useMutation(
    (data: VoucherFormType) => {
      return voucherService.create({
        data: {
          ...data,
          unit: VoucherUnit[data.unit as VoucherUnit],
          applied_on: VoucherApplyOn[data.applied_on as VoucherApplyOn],
          applicable_for: VoucherApplyFor[data.applicable_for as VoucherApplyFor],
          ...(image && { thumbnail: image.file_id }),
          ...(voucherUsers &&
            data.applicable_for === VoucherApplyFor.INDIVIDUALS && {
              users: voucherUsers.map(({ user_id, quantity }) => ({ user_id, quantity })),
            }),
          // eslint-disable-next-line prettier/prettier
          quantity: data.quantity === null ? null : data.quantity as any,
        },
      })
    },
    {
      onSuccess: async () => {
        notifications.show({
          variant: 'success',
          message: t('success.createdVoucher'),
        })
        navigate(ROUTES.ADMIN.VOUCHER.LIST)
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        const errorMessage = t(err?.response?.data, {
          ns: 'backend',
        })

        notifications.show({
          variant: 'danger',
          message: errorMessage,
        })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        err?.response?.data?.errors?.forEach((err: any) => {
          notifications.show({
            variant: 'danger',
            message: t(err, {
              ns: 'backend',
            }),
          })
        })
      },
    },
  )

  const onCreate = useCallback(
    (data: VoucherFormType) => {
      create(data)
    },
    [create],
  )

  return (
    <Card>
      <VoucherForm
        isSubmitLoading={isUpdateLoading}
        onSetVoucherUsers={setVoucherUsers}
        image={image}
        onSetImage={onSetImages}
        onSubmit={onCreate}
      />
    </Card>
  )
}

export default VoucherCreate
