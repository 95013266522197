import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { GroupResponses, Models } from '@kicksplanet/interfaces'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Card } from '@mantine/core'
import { notifications } from '@mantine/notifications'

import { useApp } from '@/contexts/AppProvider'
import { ROUTES } from '@/routes'

import GroupForm, { GroupFormType, GroupImageType } from '../Components/GroupForm'

const GroupUpdate = () => {
  const { t } = useTranslation()
  const { id } = useParams()

  const groupService = useAPIService('Group')

  const { registerBreadcrumb, unregisterBreadcrumb } = useApp()

  const [image, setImage] = useState<GroupImageType | undefined>()
  const [productGroups, setProductGroups] = useState<GroupResponses.Group['product_groups']>([])

  const { data: group, refetch: getGroup } = useQuery(
    ['groupService', 'getOne', id],
    () => groupService.getOne({ id: id ? id : '' }).then((res) => res.data.data),
    {
      enabled: Boolean(id?.toString()),
      onSuccess: (data) => {
        if (data.thumbnail_info) {
          setImage({
            thumbnail: data.thumbnail_info as Models.File,
            banner: data.banner_info as Models.File,
          })
        }
        if (data && data.product_groups) {
          const _products = data.product_groups
          setProductGroups(_products)
        }
      },
    },
  )

  useEffect(() => {
    registerBreadcrumb({
      key: 'groups',
      label: t('page.groups'),
      url: ROUTES.ADMIN.GROUP.LIST,
    })

    if (group) {
      registerBreadcrumb({
        key: group.group_id,
        label: group.name,
      })
    }

    return () => {
      unregisterBreadcrumb('groups')
      if (group) {
        unregisterBreadcrumb(group.group_id)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group])

  const onSetImage = useCallback((data: GroupImageType | undefined) => {
    setImage(data)
  }, [])

  const { mutate: update, isLoading: isUpdateLoading } = useMutation(
    (data: GroupFormType) => {
      return groupService.update({
        id: id?.toString() || '',
        data: {
          data: {
            ...data,
            thumbnail: image?.thumbnail ? image.thumbnail.file_id : null,
            banner: image?.banner ? image.banner.file_id : null,
            parent_id: group?.parent_id ? group?.parent_id : undefined,
            ...(productGroups && {
              products: productGroups.map((item) => {
                return { product_id: item.product_id, ordinal: item.ordinal || 0 }
              }),
            }),
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any,
      })
    },
    {
      onSuccess: async () => {
        notifications.show({
          variant: 'success',
          message: t('success.updatedGroup'),
        })
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        const errorMessage = t(err?.response?.data, {
          ns: 'backend',
        })

        notifications.show({
          variant: 'danger',
          message: errorMessage,
        })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        err?.response?.data?.errors?.forEach((err: any) => {
          notifications.show({
            variant: 'danger',
            message: t(err, {
              ns: 'backend',
            }),
          })
        })
      },
    },
  )

  const onUpdate = useCallback(
    (data: GroupFormType) => {
      if (!id) return
      update(data)
    },
    [id, update],
  )

  return (
    <Card>
      <GroupForm
        group={group}
        isSubmitLoading={isUpdateLoading}
        onSubmit={onUpdate}
        image={image}
        isParent={false}
        onSetImage={onSetImage}
        onRefresh={getGroup}
        onSetProductGroups={setProductGroups}
      />
    </Card>
  )
}

export default GroupUpdate
