import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { RiRefund2Line } from 'react-icons/ri'
import { OrderStatus, VoucherUnit } from '@kicksplanet/enums'
import { Models, OrderResponses } from '@kicksplanet/interfaces'
import {
  Badge,
  Box,
  Button,
  Card,
  Divider,
  Flex,
  Grid,
  Image,
  Mark,
  Modal,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'

import OrderConditionBage from '@/components/ConditionBage'
import GalleryInLine from '@/components/GalleryInLine'
import { formatPrice } from '@/utils/number'

import OrderRefundForm from '../../../Refund/RefundForm'

type OrderDetailSummaryDetailProps = {
  order: OrderResponses.OrderDetails
  onRefresh: () => void
}

const OrderDetailSummaryOrderItem: React.FC<OrderDetailSummaryDetailProps> = ({
  order,
  onRefresh,
}) => {
  const { t } = useTranslation()

  const [refundFormOpened, refundFormModal] = useDisclosure(false)

  const displayDiscount = useCallback(
    (originalPrice: number, discountedPrice: number, isTotal = false) => {
      if (discountedPrice && discountedPrice > 0 && discountedPrice !== originalPrice) {
        return (
          <Flex
            justify={{ base: 'start', xs: 'space-between' }}
            align='end'
            gap={{ base: 2, xs: 12 }}
            direction={{
              base: 'column-reverse',
              xs: 'row',
            }}
          >
            <Text
              td='line-through'
              size='sm'
              weight={isTotal ? 600 : undefined}
              color={isTotal ? 'red.4' : undefined}
            >
              {formatPrice(originalPrice)}
            </Text>
            <Text weight={isTotal ? 600 : undefined} color={isTotal ? 'red.4' : undefined}>
              {formatPrice(discountedPrice)}
            </Text>
          </Flex>
        )
      } else {
        return (
          <Text weight={isTotal ? 600 : undefined} color={isTotal ? 'red.4' : undefined}>
            {formatPrice(originalPrice)}
          </Text>
        )
      }
    },
    [],
  )

  const getVoucherTooltip = useCallback((voucher: Models.OrderVoucher) => {
    let tooltip = voucher.title

    if (voucher.unit === VoucherUnit.CURRENCY) {
      tooltip = `${tooltip} (-${formatPrice(voucher.value)})`
    } else if (voucher.unit === VoucherUnit.PERCENTAGE) {
      tooltip = `${tooltip} (-${voucher.value}%)`
    }

    return tooltip
  }, [])

  const orderItem = useMemo(() => {
    return order.order_item
  }, [order.order_item])

  const orderItemImages = useMemo(() => {
    if (!orderItem) return []

    return (orderItem.order_item_images?.map((x) => x.image_info) || []) as Models.File[]
  }, [orderItem])

  return (
    <>
      <Card>
        <Grid gutter={16} mb={30}>
          <Grid.Col>
            <Flex
              justify='space-between'
              align={{ base: 'start', md: 'center' }}
              gap={12}
              direction={{ base: 'column', md: 'row' }}
            >
              <Text weight={600} transform='uppercase'>
                {t('order.orderDetailHeading')}
              </Text>
              {order?.is_paid &&
                !order?.is_refunded &&
                [OrderStatus.CANCELLED, OrderStatus.RETURNED].includes(order?.status) && (
                  <Button variant='outline' onClick={refundFormModal.open}>
                    <Flex justify='space-between' align='center' gap={4}>
                      <RiRefund2Line size={16} />
                      {t('order.refund')}
                    </Flex>
                  </Button>
                )}
            </Flex>
            <Divider my={12} color='gray.2' />
          </Grid.Col>
          <Grid.Col>
            <Stack spacing={24}>
              <Flex
                justify='start'
                align='start'
                direction={{ base: 'column', sm: 'row' }}
                gap={20}
              >
                <Flex
                  justify='center'
                  align='center'
                  bg='white'
                  px={40}
                  sx={{
                    img: {
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                    },
                  }}
                >
                  <Image
                    src={orderItem.thumbnail_info?.file_path}
                    alt=''
                    width={150}
                    height={150}
                    fit='contain'
                  />
                </Flex>
                <Box>
                  <Text>
                    <Mark variant='bold'>{`${t('product.sku')}: `}</Mark>
                    {orderItem?.code}
                  </Text>
                  <Text weight={600} my={4}>
                    {orderItem.title}
                  </Text>
                  <Text>{`EU ${orderItem.size_info?.EU} | ${orderItem.color}`}</Text>
                  <OrderConditionBage condition={orderItem?.condition} />
                  <Text size='lg' color='primary.4' weight={600}>
                    {formatPrice(orderItem.price)}
                  </Text>
                </Box>
              </Flex>
              <Stack spacing={12}>
                {orderItem?.description ? (
                  <Flex gap={4} align='center' wrap='wrap'>
                    <Text fw={600}>{`${t('common.condition')}: `}</Text>
                    <Text>{orderItem?.description}</Text>
                  </Flex>
                ) : null}
                {orderItemImages && orderItemImages.length > 0 ? (
                  <GalleryInLine images={orderItemImages} />
                ) : null}
              </Stack>
            </Stack>
            <Divider my={12} color='gray.2' />
          </Grid.Col>
          <Grid.Col>
            <Text>
              <Mark variant='bold'>{`${t('order.note')}: `}</Mark>
              {order.note || '--'}
            </Text>
            <Divider mt={24} color='gray.2' />
          </Grid.Col>
          <Grid.Col>
            <Box>
              {/* <Flex justify='space-between' my={{ base: 15, xs: 5 }}>
                <Text>{t('product.productPrice')}</Text>
                <Text>{formatPrice(orderItem.price || 0)}</Text>
              </Flex> */}
              <Flex justify='space-between' align='start' gap={10} my={{ base: 15, xs: 5 }}>
                <Text>{t('order.shippingFee')}</Text>
                {displayDiscount(order.original_shipping_fee, order.shipping_fee || 0)}
              </Flex>
              <Flex justify='space-between' align='start' gap={10} my={{ base: 15, xs: 5 }}>
                <Text>{t('order.serviceFee')}</Text>
                {displayDiscount(order.original_service_fee, order.service_fee || 0)}
              </Flex>
              <Divider my={12} color='gray.2' />
              {order.vouchers_info && order.vouchers_info.length > 0 ? (
                <Flex justify='space-between' align='start' gap={10} my={{ base: 15, xs: 5 }}>
                  <Text>{t('order.voucher')}</Text>
                  <Box>
                    {order.vouchers_info.map((item) => (
                      <Tooltip key={item.voucher_id} label={getVoucherTooltip(item)}>
                        <Badge
                          variant='checked-outline'
                          sx={(theme) => ({
                            color: theme.colors.primary[4],
                            borderColor: theme.colors.primary[4],
                          })}
                        >
                          <Text>{item.code}</Text>
                        </Badge>
                      </Tooltip>
                    ))}
                  </Box>
                </Flex>
              ) : null}
              <Flex justify='space-between' align='start' gap={10} my={{ base: 15, xs: 5 }}>
                <Text>{t('order.totalAmount')}</Text>
                {displayDiscount(order.original_total, order.total || 0, true)}
              </Flex>
            </Box>
          </Grid.Col>
        </Grid>
      </Card>

      <Modal
        title={t('order.refundOrderHeading')}
        opened={refundFormOpened}
        onClose={refundFormModal.close}
        size='xl'
      >
        <OrderRefundForm order={order} onSubmit={refundFormModal.close} onRefresh={onRefresh} />
      </Modal>
    </>
  )
}

export default OrderDetailSummaryOrderItem
