import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Card, Skeleton } from '@mantine/core'
import defaultTo from 'lodash/defaultTo'

import Empty from '@/components/Empty'
import ListFilterLayout from '@/components/ListFilterLayout'
import QueryPagination from '@/components/Pagination/QueryPagination'
import TableWrapper from '@/components/TableWrapper'
import { DEFAULT_PAGINATION_QUERY } from '@/constants/pagination'
import { useApp } from '@/contexts/AppProvider'
import { ROUTES } from '@/routes'
import { formatKeyFilter } from '@/utils/string'

import UserListFilter from './Components/Filter'
import UserRow from './Components/Row'

const USER_CAPTIONS = [
  'user.name',
  'user.email',
  'user.phoneNumber',
  'user.role',
  'user.credits',
  'user.warnings',
  'user.isBanned',
  'user.firstSellAt',
  'common.action',
]

const UserList: React.FC = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const userService = useAPIService('User')
  const { registerBreadcrumb, unregisterBreadcrumb } = useApp()

  useEffect(() => {
    registerBreadcrumb({
      key: 'users',
      label: t('page.users'),
    })

    return () => {
      unregisterBreadcrumb('users')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const defaultQueryParams = useMemo(() => {
    return {
      page: defaultTo(parseInt(searchParams.get('page') as string), 1),
      perPage: defaultTo(
        parseInt(searchParams.get('per') as string),
        DEFAULT_PAGINATION_QUERY.limit,
      ),
      key: defaultTo(searchParams.get('key') as string, ''),
      active: defaultTo(searchParams.get('active') as string, ''),
    }
  }, [searchParams])

  const {
    page: pageQuery,
    perPage: perPageQuery,
    key: keyQuery,
    active: activeQuery,
  } = defaultQueryParams

  const getListQuery = useMemo(() => {
    const likeFilter = formatKeyFilter(keyQuery)
    return {
      pagination: {
        page: pageQuery,
        perPage: perPageQuery,
      },
      filter: {
        'op.or': [
          {
            first_name: {
              'op.like': likeFilter,
            },
          },
          {
            last_name: {
              'op.like': likeFilter,
            },
          },
          {
            phone_number: {
              'op.like': likeFilter,
            },
          },
          {
            email: {
              'op.like': likeFilter,
            },
          },
        ],
        ...(activeQuery && {
          is_banned: { 'op.eq': activeQuery == 'true' ? false : true },
        }),
      },
    }
  }, [activeQuery, keyQuery, pageQuery, perPageQuery])

  const dataSearchQuery: UserListSearchType = useMemo(() => {
    return {
      ...(keyQuery && { key: keyQuery }),
      ...(activeQuery && { active: activeQuery }),
    } as UserListSearchType
  }, [activeQuery, keyQuery])

  const {
    data: users,
    isFetching: isLoadingTable,
    refetch: getUsers,
  } = useQuery(['userService', 'getList', getListQuery], () =>
    userService.getList(getListQuery).then((res) => res.data),
  )

  const onChangeQueries = useCallback(
    (query: UserListSearchType) => {
      navigate({
        pathname: ROUTES.ADMIN.USER.LIST,
        search: createSearchParams({
          ...query,
        }).toString(),
      })
    },
    [navigate],
  )

  const onRefresh = useCallback(() => {
    getUsers()
  }, [getUsers])

  const RowData = useMemo(() => {
    if (isLoadingTable) {
      return (
        <tr>
          <td colSpan={9}>
            <Skeleton height={10} my={15} radius='md' />
            <Skeleton height={10} my={15} radius='md' />
            <Skeleton height={10} my={15} radius='md' />
          </td>
        </tr>
      )
    }

    if (!users || !users.data || !users.data.length) {
      return (
        <tr>
          <td colSpan={9}>
            <Empty />
          </td>
        </tr>
      )
    }

    return users?.data.map((user) => {
      return <UserRow key={user.user_id} user={user} onRefresh={onRefresh} />
    })
  }, [isLoadingTable, onRefresh, users])

  return (
    <Card>
      <ListFilterLayout onRefresh={onRefresh}>
        <UserListFilter queryData={dataSearchQuery} onChangeQueries={onChangeQueries} />
      </ListFilterLayout>
      <TableWrapper isStickyColumm={Boolean(users?.data && users?.data.length > 0)}>
        <thead>
          <tr>
            {USER_CAPTIONS.map((item, index) => {
              return <th key={index}>{t(item)}</th>
            })}
          </tr>
        </thead>
        <tbody>{RowData}</tbody>
      </TableWrapper>
      <QueryPagination
        limit={perPageQuery}
        page={pageQuery}
        total={users?.total}
        isShowLimitOptions
      />
    </Card>
  )
}

export default UserList
