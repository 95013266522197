import { Box, BoxProps } from '@mantine/core'

type SwiperWrapperProps = BoxProps & {
  type?: 'horizontal' | 'vertical'
  singleSlidePerView?: boolean
  children: React.ReactNode
}

const SwiperWrapper: React.FC<SwiperWrapperProps> = ({
  type = 'horizontal',
  singleSlidePerView = false,
  children,
}) => {
  return (
    <Box
      sx={(_theme) => ({
        '.swiper': {
          height: '100%',
          '.swiper-slide': {
            ...(type == 'horizontal' && {
              height: 'auto !important',
            }),
            ...(singleSlidePerView && {
              width: '100% !important',
            }),
          },
          '.swiper-button-prev, .swiper-button-next': {
            width: 26,
            height: 26,
            background: _theme.white,
            border: `1px solid ${_theme.colors.gray[5]}`,
            borderRadius: 2,
            boxShadow:
              '0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 0 1.25rem 1.5625rem -0.3125rem, rgba(0, 0, 0, 0.04) 0 0.625rem 0.625rem -0.3125rem',

            '::after': {
              fontSize: 10,
              fontWeight: 600,
              width: '100%',
              height: '100%',
              color: _theme.black,
              padding: 8,
              margin: 'auto',
              textAlign: 'center',
              transition: '0.2s',
            },
          },
          '.swiper-button-prev': {
            left: 0,
            top: '60%',
            ...(type == 'vertical' && {
              transform: 'rotate(90deg)',
              top: '0px',
              left: '40%',
              margin: 0,
            }),
          },
          '.swiper-button-next': {
            right: 0,
            top: '60%',
            ...(type == 'vertical' && {
              transform: 'rotate(90deg)',
              bottom: 0,
              left: '40%',
              marginTop: 'auto',
            }),
          },
          '.swiper-button-disabled': {
            opacity: 0.6,
            transition: '0.2s',
          },
        },
      })}
    >
      {children}
    </Box>
  )
}

export default SwiperWrapper
