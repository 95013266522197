import { useCallback, useEffect } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { MdAddCircle } from 'react-icons/md'
import { useMutation, useQuery } from 'react-query'
import { yupResolver } from '@hookform/resolvers/yup'
import { CategoryRequests, CategoryResponses, Models } from '@kicksplanet/interfaces'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Box, Button, Flex, Grid, Input, Select, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { notifications } from '@mantine/notifications'
import { object, string } from 'yup'

import { useSelection } from '@/hooks/useSelection'

import SubCategoryRow from '../SubCategoryRow'

type CategoryFormProps = {
  category?: CategoryResponses.Category
  isParent?: boolean
  isSubmitLoading?: boolean
  onSubmit?: (data: CategoryFormType) => void
  onRefresh?: () => void
}

export type CategoryFormType = CategoryRequests.CategoryUpsertPayload & {
  sub_category?: string
}

const validateSchema = object().shape({
  name: string().max(225, 'category.categoryNameTooLong').required('category.missingCategoryName'),
  parent_id: string(),
})

const CategoryForm: React.FC<CategoryFormProps> = ({
  category,
  isParent = false,
  isSubmitLoading,
  onSubmit,
  onRefresh,
}) => {
  const { t } = useTranslation()
  const categoryService = useAPIService('Category')

  const [addSubCategoryOpened, addSubCategoryForm] = useDisclosure(false)

  const {
    setValue,
    reset,
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<CategoryFormType>({
    resolver: yupResolver(validateSchema),
    mode: 'all',
  })

  // Parent Category
  const parentID = useWatch({
    name: 'parent_id',
    control,
  })

  const onSelectParentCategoryOption = useCallback(
    (item?: Models.Category | null) => {
      setValue('parent_id', item ? item.category_id : '', {
        shouldValidate: true,
      })
    },
    [setValue],
  )

  const { data: parentCategories } = useQuery(
    ['categoryService', 'getList', 'selectOptions'],
    () => categoryService.getList({}),
    {
      select: ({ data: res }) => {
        return (
          res?.data?.map((item) => ({
            ...item,
            label: item.name,
          })) || []
        )
      },
      onSuccess: (data) => {
        if (category) {
          const parentCategory = data?.find((x) => x.category_id === category.parent_id)
          if (!parentCategory) return
          onSelectParentCategoryOption(parentCategory)
        }
      },
    },
  )

  const {
    data: parentCategoryOptions,
    onSelect: onSelectParentCategory,
    value: parentCategoryValue,
    filter: parentCategoryFilter,
  } = useSelection<Models.Category>({
    items: parentCategories || [],
    onSelect: onSelectParentCategoryOption,
    valueKey: 'category_id',
    defaultValue: parentCategories?.find((x) => x.category_id === parentID),
  })

  // Default value
  useEffect(() => {
    setDefaultValue()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category])

  const setDefaultValue = useCallback(() => {
    if (!category) {
      reset()
      return
    }
    setValue('name', category.name || '', {
      shouldValidate: true,
    })
  }, [category, reset, setValue])

  const _onSubmit = useCallback(
    (data: CategoryFormType) => {
      if (!data) return
      onSubmit?.(data)
    },
    [onSubmit],
  )

  const _onRefresh = useCallback(() => {
    onRefresh?.()
  }, [onRefresh])

  // Sub category
  const subCategoryValue = useWatch({
    name: 'sub_category',
    control,
  })

  const { mutate: createSubCategory, isLoading: isCreateSubCategoryLoading } = useMutation(
    () => {
      return categoryService.create({
        data: { name: subCategoryValue, parent_id: category?.category_id },
      })
    },
    {
      onSuccess: async () => {
        notifications.show({
          variant: 'success',
          message: t('success.createdSubCategory'),
        })
        setValue('sub_category', '', {
          shouldValidate: true,
        })
        onRefresh?.()
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        const { response: { data: { errorKey = '', errors = [] } = {} } = {} } = err
        const errorMessage = t(errorKey, {
          ns: 'backend',
        })

        notifications.show({
          variant: 'danger',
          message: errorMessage,
        })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        errors?.forEach((err: any) => {
          notifications.show({
            variant: 'danger',
            message: t(err, {
              ns: 'backend',
            }),
          })
        })
      },
    },
  )

  const onCreateSubCategory = useCallback(() => {
    if (!subCategoryValue) return

    createSubCategory()
  }, [createSubCategory, subCategoryValue])

  return (
    <Box>
      <form>
        <Grid gutter={20}>
          <Grid.Col>
            <Input.Wrapper
              label={`${t('category.categoryName')} *`}
              error={errors.name && t(errors.name.message as string)}
            >
              <Input {...register('name')} />
            </Input.Wrapper>
          </Grid.Col>

          {category ? (
            <Grid.Col>
              <Grid>
                <Grid.Col>
                  <Text weight={600} mb={8}>
                    {t('category.subCategories')}
                  </Text>
                  <Button
                    variant='subtle'
                    leftIcon={<MdAddCircle size={24} />}
                    sx={(_theme) => ({
                      '.mantine-Button-icon': {
                        color: _theme.colors.primary[4],
                      },
                      '.mantine-Button-label': {
                        color: _theme.colors.primary[4],
                      },
                    })}
                    onClick={addSubCategoryForm.open}
                  >
                    {t('category.addSubCategory')}
                  </Button>

                  {addSubCategoryOpened ? (
                    <Box my={12}>
                      <Flex justify='space-between' align='center' gap={4}>
                        <Input.Wrapper label={`${t('category.subCategoryName')} *`} w='100%'>
                          <Input {...register('sub_category')} />
                        </Input.Wrapper>
                        <Flex align='center' gap={4}>
                          <Button variant='subtle' onClick={addSubCategoryForm.close}>
                            <Text>{t('common.cancel')}</Text>
                          </Button>
                          <Button
                            variant='highlight'
                            onClick={onCreateSubCategory}
                            loading={isCreateSubCategoryLoading}
                          >
                            <Text>{t('common.save')}</Text>
                          </Button>
                        </Flex>
                      </Flex>
                    </Box>
                  ) : null}
                </Grid.Col>

                {category?.sub_categories &&
                  category?.sub_categories.length > 0 &&
                  category.sub_categories.map((item) => (
                    <Grid.Col
                      key={item.category_id}
                      p={12}
                      sx={(_theme) => ({
                        borderBottom: `1px solid ${_theme.colors.gray[2]}`,
                      })}
                    >
                      <SubCategoryRow category={item} onRefresh={_onRefresh} />
                    </Grid.Col>
                  ))}
              </Grid>
            </Grid.Col>
          ) : null}

          {isParent ? (
            <Grid.Col>
              <Select
                label={t('category.parentCategory')}
                data={parentCategoryOptions}
                value={parentCategoryValue}
                onChange={onSelectParentCategory}
                filter={parentCategoryFilter}
                clearable
                searchable
              />
            </Grid.Col>
          ) : null}

          <Grid.Col>
            <Flex my={40} justify='center' align='center'>
              <Button
                variant='highlight'
                loading={isSubmitLoading}
                disabled={!isValid}
                onClick={handleSubmit(_onSubmit)}
              >
                {t('common.save')}
              </Button>
            </Flex>
          </Grid.Col>
        </Grid>
      </form>
    </Box>
  )
}

export default CategoryForm
