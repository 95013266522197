import { useTranslation } from 'react-i18next'
import { WithdrawalRequestStatus } from '@kicksplanet/enums'
import { Badge, MantineColor } from '@mantine/core'

const badgeColor: Record<WithdrawalRequestStatus, MantineColor> = {
  [WithdrawalRequestStatus.PENDING]: 'orange',
  [WithdrawalRequestStatus.CONFIRMED]: 'blue',
  [WithdrawalRequestStatus.APPROVED]: 'green',
  [WithdrawalRequestStatus.PAID]: 'red',
  [WithdrawalRequestStatus.REJECTED]: 'gray',
}

export type WithdrawalRequestStatusBadgeProps = {
  status: WithdrawalRequestStatus
}

const WithdrawalRequestStatusBadge: React.FC<WithdrawalRequestStatusBadgeProps> = ({ status }) => {
  const { t } = useTranslation()
  return (
    <Badge color={badgeColor[status]} size='lg'>
      {t(`withdrawalRequest.statuses.${status}`)}
    </Badge>
  )
}

export default WithdrawalRequestStatusBadge
