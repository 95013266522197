import { ReactNode } from 'react'
import { AiFillCheckCircle } from 'react-icons/ai'
import { GiCancel } from 'react-icons/gi'
import { PiWarningFill } from 'react-icons/pi'
import { Box, Center, Text } from '@mantine/core'

type ModalFormSimpleNoticeProps = {
  type: 'success' | 'error' | 'warn'
  title?: string
  descriptions?: string | ReactNode
}

const ICON_SIZE = 53

const ModalFormSimpleNotice: React.FC<ModalFormSimpleNoticeProps> = ({
  type,
  title,
  descriptions,
}) => {
  const noticeTypeIconMap = {
    success: <AiFillCheckCircle size={ICON_SIZE} />,
    error: <GiCancel size={ICON_SIZE} />,
    warn: <PiWarningFill size={ICON_SIZE} />,
  }

  return (
    <Box mb={20}>
      <Center mb={10}>{noticeTypeIconMap[type]}</Center>
      {title && (
        <Text weight={600} size='lg' mb={10} align='center'>
          {title}
        </Text>
      )}
      {descriptions && <Text align='center'>{descriptions}</Text>}
    </Box>
  )
}

export default ModalFormSimpleNotice
