import { ShippingProvider } from '@kicksplanet/enums'

export type ShippingProviderDataType = {
  code: string
  name: string
}

export const ShippingProviderData: ShippingProviderDataType[] = [
  {
    code: ShippingProvider.GHTK,
    name: 'order.shippingProviderType.ghtk',
  },
]
