import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { GroupResponses, Models, ProductResponses } from '@kicksplanet/interfaces'
import { useAPIService } from '@kicksplanet/react/hooks'
import { GetListParams } from '@kicksplanet/services/dist/services/core/CURDService'
import { notifications } from '@mantine/notifications'

import TagAutocomplete from '@/components/TagAutocomplete'
import { getProductInfoInLine } from '@/utils/product'
import { formatKeyFilter } from '@/utils/string'

import { ProdGroupElementType } from '../GroupForm'

type ProductGroupProps = {
  group?: GroupResponses.Group
  onRefresh?: () => void
  onSetProductGroups?: (products: GroupResponses.Group['product_groups']) => void
}

const ProductGroup: React.FC<ProductGroupProps> = ({ group, onRefresh, onSetProductGroups }) => {
  const { t } = useTranslation()

  const productService = useAPIService('Product')
  const groupService = useAPIService('Group')

  const [keysearch, setKeysearch] = useState<string>('')

  const [selectedProductGroups, setSeletedProductGroups] = useState<
    GroupResponses.Group['product_groups']
  >([])
  const [productGetListQuery, setProductGetListQuery] = useState<GetListParams<Models.Product>>({
    pagination: { page: 1, perPage: 0 },
  })

  useEffect(() => {
    if (group) {
      const productGroups = group?.product_groups?.map((item) => ({
        ...item,
        label: getProductInfoInLine(item.product?.title, item.product?.code),
      }))
      setSeletedProductGroups(productGroups)
    }
  }, [group])

  const onSetGetListQuery = useCallback(() => {
    const likeFilter = formatKeyFilter(keysearch)

    const query: GetListParams<ProductResponses.Product> = {
      pagination: { page: 1, perPage: keysearch ? 10 : 10 },
      filter: {
        'op.or': [
          {
            title: {
              'op.like': likeFilter,
            },
          },
          {
            code: {
              'op.like': likeFilter,
            },
          },
        ],
        min_price: {
          'op.gte': 0,
        },
      },
    }
    setProductGetListQuery(query)
  }, [keysearch])

  useEffect(() => {
    onSetGetListQuery()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keysearch])

  const onSetKeysearch = useCallback((keysearch: string) => {
    setKeysearch(keysearch)
  }, [])

  const _onRefresh = useCallback(() => {
    onRefresh?.()
  }, [onRefresh])

  const mappingProductGroupData = useCallback(
    (data: Models.Product) => {
      const result: ProdGroupElementType = {
        group_id: group?.group_id || '',
        product_id: data.product_id,
        ordinal: 0,
        product: data,
      } as any

      return result
    },
    [group?.group_id],
  )

  const onSelectProduct = useCallback(
    (item: Models.Product) => {
      if (selectedProductGroups?.find((x) => x.product_id === item.product_id)) return

      const _productGroups = [
        mappingProductGroupData(item),
        ...(selectedProductGroups ? selectedProductGroups : []),
      ]
      _productGroups?.forEach((x, index) => {
        ;(x as any).ordinal = index + 1
      })
      setSeletedProductGroups(_productGroups)
      onSetProductGroups?.(_productGroups)
    },
    [mappingProductGroupData, onSetProductGroups, selectedProductGroups],
  )

  const onRemoveProduct = useCallback(
    (item: Models.Product) => {
      if (!selectedProductGroups || selectedProductGroups.length <= 0) return
      if (!selectedProductGroups?.find((x) => x.product_id === item.product_id)) return

      const products = selectedProductGroups?.filter((x) => x.product_id !== item.product_id)
      products?.forEach((item, index) => {
        ;(item as any).ordinal = index + 1
      })
      setSeletedProductGroups(products)
      onSetProductGroups?.(products)
    },
    [onSetProductGroups, selectedProductGroups],
  )

  const { data: products = [], isFetching: isLoadingProduct } = useQuery(
    ['productService', 'getList', 'selectOptions', productGetListQuery],
    () => productService.getList(productGetListQuery),
    {
      select: ({ data: res }) => {
        return (
          res?.data?.map((item) => ({
            ...item,
            label: getProductInfoInLine(item.title, item.code),
          })) || []
        )
      },
    },
  )

  const { mutate: sortProduct } = useMutation(
    (data: Models.ProductGroup[]) => {
      return groupService.sortProductGroup({
        product_groups: data.map((item) => {
          return {
            group_id: group?.group_id,
            product_id: item.product_id,
            ordinal: item.ordinal,
          }
        }),
      } as any)
    },
    {
      onSuccess: () => {
        _onRefresh()
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        const errorMessage = t(err?.response?.data, {
          ns: 'backend',
        })

        notifications.show({
          variant: 'danger',
          message: errorMessage,
        })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        err?.response?.data?.errors?.forEach((err: any) => {
          notifications.show({
            variant: 'danger',
            message: t(err, {
              ns: 'backend',
            }),
          })
        })
      },
    },
  )

  const onSortProduct = useCallback(
    (data: Models.ProductGroup[]) => {
      if (!group || !group.group_id) return

      sortProduct(data)
    },
    [group, sortProduct],
  )

  return (
    <TagAutocomplete
      optionData={products}
      isSort
      isShowTable
      searchControl={{
        valueKeySearch: 'product_id',
        labelKeySearch: 'label',
        labelControl: t('group.productGroups'),
        onSearch: onSetKeysearch,
      }}
      isLoading={isLoadingProduct}
      selectedData={{
        selectedItems: selectedProductGroups?.map((item) => {
          return { ...item.product, ordinal: item.ordinal }
        }),
        selectedValueKey: 'product_id',
        selectedLabelKey: 'label',
        onSelectItem: onSelectProduct,
        onRemoveItem: onRemoveProduct,
      }}
      columnKeys={[
        {
          caption: 'product.productTitle',
          valueKey: 'title',
        },
        {
          caption: 'product.sku',
          valueKey: 'code',
        },
        {
          caption: 'product.productColor',
          valueKey: 'color',
        },
        {
          caption: 'product.productPrice',
          valueKey: 'min_price',
          type: 'price',
        },
      ]}
      sorting={{ onSort: onSortProduct }}
    />
  )
}

export default ProductGroup
