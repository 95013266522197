import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Card, Skeleton } from '@mantine/core'

import Empty from '@/components/Empty'
import RefreshButton from '@/components/RefreshButton'
import TableWrapper from '@/components/TableWrapper'
import { useApp } from '@/contexts/AppProvider'

import GroupListRow from './Components/Row'

const GROUP_CAPTIONS = ['group.name', 'group.position', 'group.subGroups', 'common.action']
const GroupList: React.FC = () => {
  const { t } = useTranslation()
  const groupService = useAPIService('Group')

  const { registerBreadcrumb, unregisterBreadcrumb } = useApp()

  useEffect(() => {
    registerBreadcrumb({
      key: 'groups',
      label: t('page.groups'),
    })

    return () => {
      unregisterBreadcrumb('groups')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    data: groups,
    isFetching: isLoadingTable,
    refetch: getGroups,
  } = useQuery(['groupService', 'list'], () => groupService.list({}).then((res: any) => res.data))

  const onRefresh = useCallback(() => {
    getGroups()
  }, [getGroups])

  useEffect(() => {
    getGroups()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const RowData = useMemo(() => {
    if (isLoadingTable) {
      return (
        <tr>
          <td colSpan={4}>
            <Skeleton height={10} my={15} radius='md' />
            <Skeleton height={10} my={15} radius='md' />
            <Skeleton height={10} my={15} radius='md' />
          </td>
        </tr>
      )
    }

    if (!groups || !groups.data || groups.data.length <= 0) {
      return (
        <td colSpan={4}>
          <Empty />
        </td>
      )
    }

    return groups.data.map((item: any) => {
      return <GroupListRow key={item.group_id} group={item} onRefresh={onRefresh} />
    })
  }, [groups, isLoadingTable, onRefresh])

  return (
    <Card>
      <RefreshButton onRefresh={onRefresh} />
      <TableWrapper isStickyColumm={Boolean(groups?.data && groups?.data.length > 0)}>
        <thead>
          <tr>
            {GROUP_CAPTIONS.map((item, index) => {
              return <th key={index}>{t(item)}</th>
            })}
          </tr>
        </thead>
        <tbody>{RowData}</tbody>
      </TableWrapper>
    </Card>
  )
}

export default GroupList
