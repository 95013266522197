import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { ShippingProvider } from '@kicksplanet/enums'
import { OrderResponses } from '@kicksplanet/interfaces'
import { useAPIService } from '@kicksplanet/react/hooks'
import { Grid } from '@mantine/core'
import { notifications } from '@mantine/notifications'

import { usePrintPDF } from '@/hooks/usePrintPDF'

import OrderDetailSummaryOrderItem from './Components/OrderItem'
import OrderDetailSummarySellerInfo from './Components/SellerInfo'
import OrderDetailSummaryShippingInfo from './Components/ShippingInfo'

type OrderDetailSummaryProps = {
  order: OrderResponses.OrderDetails
  onRefresh: () => void
}

const OrderDetailSummary: React.FC<OrderDetailSummaryProps> = ({ order, onRefresh }) => {
  const { t } = useTranslation()
  const shippingService = useAPIService('Shipping')

  const { onPrintPDF } = usePrintPDF()

  const { mutate: printTrackingID, isLoading: printTrackingIDLoading } = useMutation(
    async (trackingID: string) => {
      const { data: response } = await shippingService.printTrackingLabel(
        trackingID || '',
        ShippingProvider.GHTK,
      )

      return response
    },
    {
      onSuccess: (data) => {
        onPrintPDF(data)
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        const { response: { data: { errorKey = '', errors = [] } = {} } = {} } = err
        const errorMessage = t(errorKey, {
          ns: 'backend',
        })

        notifications.show({
          variant: 'danger',
          message: errorMessage,
        })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        errors?.forEach((err: any) => {
          notifications.show({
            variant: 'danger',
            message: t(err, {
              ns: 'backend',
            }),
          })
        })
      },
    },
  )

  const onPrintTrackingID = async (trackingID: string) => {
    if (!order.pickup_order_id) return
    printTrackingID(trackingID)
  }

  return (
    <Grid>
      <Grid.Col md={6}>
        <OrderDetailSummarySellerInfo
          order={order}
          isPrintTrackingIDLoading={printTrackingIDLoading}
          onPrintTrackingID={onPrintTrackingID}
        />
      </Grid.Col>
      <Grid.Col md={6}>
        <OrderDetailSummaryShippingInfo
          order={order}
          isPrintTrackingIDLoading={printTrackingIDLoading}
          onPrintTrackingID={onPrintTrackingID}
        />
      </Grid.Col>
      <Grid.Col>
        <OrderDetailSummaryOrderItem order={order} onRefresh={onRefresh} />
      </Grid.Col>
    </Grid>
  )
}

export default OrderDetailSummary
